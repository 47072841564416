import { ConditionType, EventLevel, ValueType } from 'types/trigger.enum';

export function parseSpecialKey(specialKey: string): {
  samplePointId: number,
  eventLevel: EventLevel,
  valueType: ValueType,
  conditionType: ConditionType
} {
  const [samplePointIdStr, eventLevelStr, valueTypeStr, conditionTypeStr] = specialKey.split('.');
  const samplePointId: number = parseInt(samplePointIdStr, 10);
  const eventLevel: EventLevel = parseInt(eventLevelStr, 10);
  const valueType: ValueType = parseInt(valueTypeStr, 10);
  const conditionType: ConditionType = parseInt(conditionTypeStr, 10);

  return {
    samplePointId,
    eventLevel,
    valueType,
    conditionType
  };
}