import { createSelector } from 'reselect';

import { makeSelectIsLoading } from 'store/modules/loading/selectors';
import { ApplicationRootState } from 'store/types';
import { SamplePointId } from 'types/models/samplePoint';

export const selectCamerasState = (state: ApplicationRootState) =>
  state.cameras;

const makeSelectCamera = (samplePointId: SamplePointId) =>
  createSelector(
    selectCamerasState,
    (camerasState) => camerasState[samplePointId]
  );

export const makeSelectCameraImages = (samplePointId: SamplePointId) =>
  createSelector(makeSelectCamera(samplePointId), (camera) => camera?.images);

export const makeSelectCameraPagination = (samplePointId: SamplePointId) =>
  createSelector(
    makeSelectCamera(samplePointId),
    (camera) => camera?.pagination
  );

export const makeSelectCamerasDates = (samplePointId: SamplePointId) =>
  createSelector(makeSelectCamera(samplePointId), (camera) => camera?.dates);

export const makeSelectCameraStatus = (samplePointId: SamplePointId) =>
  createSelector(makeSelectCamera(samplePointId), (camera) => camera?.status);

export const makeSelectCameraSchedules = (samplePointId: SamplePointId) =>
  createSelector(
    makeSelectCamera(samplePointId),
    (camera) => camera?.schedules
  );

export const selectIsLoading = makeSelectIsLoading(['LOAD_CAMERA_IMAGES']);
