import { Space, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import DefaultCard from 'components/atoms/DefaultCard';
import DefaultTable from 'components/composites/DefaultTable';
import { PAGE_SIZE } from 'constants/list';
import { CiboSubscription } from 'store/modules/backOfficeCiboSubscriptions/types';
import useBackOfficeEnterprise from 'store/modules/backOfficeEnterprise/hooks';
import { CiboLocation } from 'store/modules/ciboLocations/types';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';
import { supportsCibo as supportsCiboFunc } from 'utils/integration/supports-cibo';

import { dataColumns } from './columns';
import AddSubscriptionForm from './components/AddSubscriptionForm';
import { AutoRenewCheckbox } from './components/AutoRenewCheckbox';
import BackNavigation from './components/BackNavigation';
import CancelSubscriptionButton from './components/CancelSubscriptionButton';
import SummaryCard from './components/SummaryCard';
import { formatSubscriptionDate } from './format-date.util';

import './styles.less';

const MIN_PURCHASE = 5;

interface CiboSubscriptionListPageProps {
  enterpriseId: number;
  isFetchingSubscriptions: boolean;
  subscriptions: CiboSubscription[];
  locations: CiboLocation[];
  onSubscriptionAdd: (numberOfLocations: number) => void;
  isAddingSubscription: boolean;
  onSubscriptionCancel: (subscriptionId: number) => void;
  isCancellingSubscription: boolean;
  onAutoRenewUpdate: (subscriptionId: number, isAutoRenewActive: boolean) => void;
}

function CiboSubscriptionListPage({
  enterpriseId,
  isFetchingSubscriptions,
  subscriptions,
  locations,
  onSubscriptionAdd,
  isAddingSubscription,
  onSubscriptionCancel,
  isCancellingSubscription,
  onAutoRenewUpdate
}: CiboSubscriptionListPageProps) {
  const enterprise = useBackOfficeEnterprise();
  if (!enterprise) return null;

  const enterpriseDomain = constructEnterpriseDomain(enterprise);

  const supportsCibo = supportsCiboFunc(enterprise?.billingAddress?.country);

  const totalPurchased = subscriptions
    .filter(s => s.status === 'active')
    .reduce((sum, { numberOfLocations }) => sum + numberOfLocations, 0);
  const totalPlaced = locations.length;

  const isInitialPurchase = totalPurchased < MIN_PURCHASE;
  const minAdd = isInitialPurchase ? MIN_PURCHASE : 1;

  return (
    <DefaultCard className="CiboSubscriptionListPage">
      <Space direction="vertical" size={30}>
        <div>
          <BackNavigation enterpriseId={enterpriseId} enterpriseName={enterprise.name} />
        </div>

        {!supportsCibo && 'No information available for this enterprise'}

        {supportsCibo && (
          <div>
            <Typography.Title level={5}>Cibo Labs Pasture Zones</Typography.Title>
            <Typography.Paragraph>
              Add Pasture Zones to an enterprise and check the Total Purchased
              <br />against Zones that have already been placed on the map.
            </Typography.Paragraph>

            <section id="summary">
              <Space size={16}>
                <SummaryCard title="Total Purchased" value={totalPurchased} />
                <SummaryCard title="Placed on Map" value={totalPlaced} />
              </Space>
            </section>

            <section id="add">
              <AddSubscriptionForm
                label="Add Pasture Zones"
                min={minAdd}
                max={20}
                defaultValue={minAdd}
                onAdd={onSubscriptionAdd}
                isAdding={isAddingSubscription}
              />
            </section>

            <section id="list">
              <DefaultTable
                columns={[
                  ...dataColumns,
                  {
                    title: 'Auto-Renew',
                    key: 'autoRenew',
                    width: 110,
                    render: (_, record) => (
                      <AutoRenewCheckbox
                        checked={record.isAutoRenewalActive}
                        disabled={record.status === 'cancelled' || record.status === 'expired'}
                        onAutoRenewUpdate={onAutoRenewUpdate}
                        subscriptionId={record.id}
                      />
                    )
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <CancelSubscriptionButton
                        disabled={record.status === 'cancelled' || record.status === 'expired'}
                        enterpriseDomain={enterpriseDomain}
                        dateAdded={formatSubscriptionDate(record.startDate)}
                        subscriptionId={record.id}
                        onCancel={onSubscriptionCancel}
                        isCancelling={isCancellingSubscription}
                      />
                    )
                  }
                ]}
                rowKey="id"
                dataSource={subscriptions}
                loading={isFetchingSubscriptions}
                total={subscriptions.length}
                pageSize={PAGE_SIZE.SUBSCRIPTION_TABLE}
                empty="No pasture zones added"
                scroll={{ x: '100%' }}
                bordered
                className="no-data-row-fix"
                rowClassName={(record) => classNames('default-cursor', {
                  disabled: record.status === 'cancelled' || record.status === 'expired'
                })}
              />
            </section>
          </div>
        )}
      </Space>
    </DefaultCard>
  );
}

export default CiboSubscriptionListPage;