import { LEGACY_DAILY_RAINFALL_RESET_HOUR } from 'constants/samplePoint';
import Address from 'types/models/address';
import { EnterpriseId } from 'types/models/enterprise';
import Site from 'types/models/site';
import convertImperialToMetric from 'utils/convert-Imperial-to-metric';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';

import { SiteDTO } from './types';

export function parseSites(sites: Site[], country: string) {
  return sites.map((site) => {
    const siteSize = convertMetricToImperial(
      UnitType.SIZE,
      country,
      site.siteSize,
      1
    );
    return {
      ...site,
      siteSize,
      rainfallResetTime: site.rainfallResetTime ?? LEGACY_DAILY_RAINFALL_RESET_HOUR
    };
  });
}

export default function parseSiteFromPayload(
  siteId: number | null,
  values: Partial<Site>,
  enterpriseId: EnterpriseId,
  enterpriseBillingAddress: Address
): Partial<Site> {
  // Make shallow copy to prevent mutating given parameter.
  const site = { ...values };
  const { country } = enterpriseBillingAddress;

  if (siteId) site.id = siteId;
  if (site.shippingAddress) site.shippingAddress.id = site.shippingAddressId;
  const wasUsingAccountBillingAddress =
    site.shippingAddressId === enterpriseBillingAddress.id;

  if (site.useEnterpriseBilling) {
    site.shippingAddressId = enterpriseBillingAddress.id;
    site.shippingAddress = enterpriseBillingAddress; // Required by API
  } else if (wasUsingAccountBillingAddress) {
    // If change from account billing address to a new one
    site.shippingAddress = values.shippingAddress;
    delete site.shippingAddressId;
    delete site.shippingAddress?.id;
  }

  delete site.useEnterpriseBilling;

  const siteSize: number | undefined = convertImperialToMetric(UnitType.SIZE, country, site.siteSize, 5);

  return {
    ...site,
    siteSize,
    enterpriseId
  };
}

export function parseSiteToBackOfficeSite(
  siteDto: SiteDTO,
  enterpriseBillingAddress: Address,
  useEnterpriseBilling = false
): Site {
  const { country } = enterpriseBillingAddress;
  const siteSize = Number(
    convertMetricToImperial(UnitType.SIZE, country, siteDto.siteSize, 1)
  );

  const site: Site = {
    id: siteDto.id,
    sid: siteDto.sid,
    name: siteDto.name,
    timezoneCode: siteDto.timezoneCode,
    rainfallResetTime: siteDto.rainfallResetTime,
    shippingAddress: siteDto.shippingAddress,
    shippingAddressId: siteDto.shippingAddressId,
    note: siteDto.note,
    siteSize,
    ownerId: siteDto.ownerId,
    owner: siteDto.owner,
    useEnterpriseBilling,
    enterpriseId: siteDto.enterpriseId,
    createdAt: siteDto.createdAt,
    updatedAt: siteDto.updatedAt,
    userSitePreference: siteDto.userSitePreference
  };

  if (useEnterpriseBilling) {
    site.shippingAddress = enterpriseBillingAddress;
    site.useEnterpriseBilling = true;
  }
  return site;
}
