// Prefixing the index routePath with an underscore so that we do not sort it alphabetically like the other routePaths.
export const ROUTE_PATHS = {
  enterprise: {
    _index: '/enterprise',
    account: {
      _index: '/enterprise/account',
      profileSettings: '/enterprise/account/profile-settings',
      teamMembers: '/enterprise/account/team-members',
      teamNotificationManagement: {
        _index: '/enterprise/account/team-notification-management',
        monitors: '/enterprise/account/team-notification-management?tab=monitors',
        thirdParty: '/enterprise/account/team-notification-management?tab=third-party'
      }
    },
    ciboLocation: {
      locationDetail: '/enterprise/cibo-location/:ciboLocationId',
      buildLocationDetailPath: (ciboLocationId: number) => `/enterprise/cibo-location/${ciboLocationId}`
    },
    samplePoints: {
      mapView: '/enterprise/sample-points/map-view'
    }
  }
};