import moment from 'moment-timezone';

/**
 * Formats a time duration from milliseconds to "1h 10m" like format.
 * It rounds the duration up to the nearest minute/hour.
 */
function formatTimeDuration(timePeriodInMilliseconds: number) {
  const duration = moment.duration(timePeriodInMilliseconds);
  const days = duration.days();
  let hours = duration.hours();
  let minutes = duration.minutes();

  if (days) {
    if (hours < 1) {
      return `${days}d`;
    }
    return `${days}d ${hours}h`;
  }

  if (timePeriodInMilliseconds < 60_000) {
    return 'Less than 1 min';
  }

  if (duration.seconds() >= 30) {
    // round up to next minute
    minutes += 1;
  }

  if (minutes === 60) {
    // round up to next hour
    hours += 1;
    minutes = 0;
  }

  if (hours < 1) {
    return `${minutes}m`;
  }

  if (minutes === 0) {
    return `${hours}h`;
  }
  return `${hours}h ${minutes}m`;
}

export default formatTimeDuration;
