import { Middleware } from 'redux';

import rollbarLogger from 'config/rollbar';

import { ApplicationRootState } from '../../types';

const rollbarMiddleware: Middleware<
  Record<string, unknown>, // legacy type parameter added to satisfy interface signature
  ApplicationRootState
> = () => (next) => (action) => {
  // Capture all redux errors as exceptions
  if (action.error) {
    rollbarLogger.warning(`Redux error: ${action.type}`, { action });
  }
  return next(action);
};

export default rollbarMiddleware;
