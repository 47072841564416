export const AGRIWEBB_ORGANISATION_ID_KEY = 'agriWebbOrganisationId';
export const AGRIWEBB_ORGANIZATION_PARAM = 'organization';
export const INSTALL_RESPONSE_TYPE = 'code';
export const AUTHORIZE_URL = '/oauth2/authorize';
export const AGRIWEBB_SCOPES =
  'read:farms read:fields read:enterprise read:rainfalls write:rainfalls read:map-features write:map-features read:level-readings write:level-readings';
export const AGRIWEBB_MARKETPLACE_URL =
  'https://portal.agriwebb.com/accounts/marketplace';

export const AGRIWEBB_MARKETPLACE_FARMBOT_DEV_URL =
  'https://portal-staging.agriwebb.com/accounts/marketplace/farmbot-dev';
export const AGRIWEBB_MARKETPLACE_RANCHBOT_DEV_URL =
  'https://portal-staging.agriwebb.com/accounts/marketplace/ranchbot-dev';
export const AGRIWEBB_MARKETPLACE_FARMBOT_STAGING_URL =
  'https://portal-staging.agriwebb.com/accounts/marketplace/farmbot-staging';
export const AGRIWEBB_MARKETPLACE_RANCHBOT_STAGING_URL =
  'https://portal-staging.agriwebb.com/accounts/marketplace/ranchbot-staging';
export const AGRIWEBB_MARKETPLACE_FARMBOT_PROD_URL =
  'https://portal.agriwebb.com/accounts/marketplace/farmbot';
export const AGRIWEBB_MARKETPLACE_RANCHBOT_PROD_URL =
  'https://portal.agriwebb.com/accounts/marketplace/ranchbot';
