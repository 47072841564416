import { Alert, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import BackOfficeUserFormItems from 'components/forms/BackOfficeUserFormItems';
import BackOfficeUsersActionType from 'store/modules/backOfficeUsers/constants';
import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import User from 'types/models/user';

const actions = [BackOfficeUsersActionType.EDIT_BACK_OFFICE_USER_REQUEST];

const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  form: FormInstance;
  initialValues?: Partial<User>;
  onFinish?: (values: Record<string, unknown>) => void;
  isLoading?: boolean;
}

function BackOfficeUserForm({
  form,
  initialValues,
  onFinish,
  isLoading
}: Props): JSX.Element | null {
  const errorMessage = useSelector(selectErrorMessage);

  useDeepCompareEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  return (
    <DefaultForm
      form={form}
      layout="vertical"
      name="backOfficeUser"
      autoComplete="chrome-off"
      initialValues={initialValues}
      onFinish={isLoading ? undefined : onFinish}
      requiredMark="optional"
      closeDrawerOnSubmit
      closeDrawerIfFieldsAreValid={false}
    >
      <BackOfficeUserFormItems initialValues={initialValues} />
      {errorMessage && (
        <DefaultFormItem>
          <Alert message={errorMessage} type="error" />
        </DefaultFormItem>
      )}
      <Button htmlType="submit" style={{ display: 'none' }} />
    </DefaultForm>
  );
}

export default memo(BackOfficeUserForm, isEqual);
