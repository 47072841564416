import { Alert } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import { useBackOfficeUser } from 'store/modules/backOfficeUser/hooks';
import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import { UserId } from 'types/models/user';

const actions = ['LOAD_BACK_OFFICE_USER'];

export const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  children: JSX.Element;
  userId: UserId | undefined;
}

function BackOfficeUserLoadingGate({ children, userId }: Props): JSX.Element {
  const backOfficeUser = useBackOfficeUser();
  const errorMessage = useSelector(selectErrorMessage);

  if (backOfficeUser && backOfficeUser.id === userId) {
    return children;
  }

  if (errorMessage) {
    return <Alert message={errorMessage} type="error" />;
  }

  return <LoadingSpinner />;
}

export default BackOfficeUserLoadingGate;
