import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';

import { SearchTypes } from './types';

const selectSearchState = (state: ApplicationRootState) => state.search;

const makeSelectSearchResult = (searchType: SearchTypes) =>
  createSelector(
    selectSearchState,
    (searchResults) => searchResults[searchType]
  );

export default makeSelectSearchResult;
