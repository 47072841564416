import isEqual from 'lodash/isEqual';
import { shallowEqual, useSelector } from 'react-redux';

import {
  selectCurrentEnterprise,
  selectCurrentEnterpriseCountry,
  selectCurrentEnterpriseId,
  selectCurrentEnterpriseName
} from './selectors';
import { selectBackOfficeEnterprisesAsArray } from '../backOfficeEnterprises/selectors';

export function useEnterprise() {
  return useSelector(selectCurrentEnterprise, isEqual);
}

export function useEnterpriseId() {
  return useSelector(selectCurrentEnterpriseId, isEqual);
}

export function useEnterpriseName() {
  return useSelector(selectCurrentEnterpriseName, isEqual);
}

export function useEnterpriseCountry() {
  return useSelector(selectCurrentEnterpriseCountry);
}

export function useIsUSEnterprise() {
  return useSelector(selectCurrentEnterpriseCountry) === 'USA';
}

export const useBackOfficeEnterprises = () =>
  useSelector(selectBackOfficeEnterprisesAsArray, shallowEqual);
