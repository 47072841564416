import { message as antdMessage } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import {
  getRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  editVolumeMappings,
  editVolumeMappingsFailure,
  editVolumeMappingsSuccess,
  getCalculatedVolumeMappings,
  getCalculatedVolumeMappingsFailure,
  getCalculatedVolumeMappingsSuccess,
  getVolumeMappings,
  getVolumeMappingsFailure,
  getVolumeMappingsSuccess,
  setCalculatedVolumeMappings,
  setVolumeMappings,
  uploadVolumeMappings,
  uploadVolumeMappingsFailure,
  uploadVolumeMappingsSuccess
} from './actions';
import ActionTypes from './constants';
import { CalculatedVolumeMapping, VolumeMapping } from './types';
import { setSamplePoint } from '../samplePoints/actions';

export function* requestUploadVolumeMappings(
  action: ReturnType<typeof uploadVolumeMappings>
) {
  const {
    payload: { samplePoint, file, onSuccess }
  } = action;
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response: AxiosResponse<VolumeMapping[]> = yield call(
      postRequest,
      `/volume-mapping/${samplePoint.id}/upload`,
      formData
    );
    onSuccess();

    antdMessage.success('Survey uploaded. Please proceed', 2);
    samplePoint.config = {
      ...samplePoint.config,
      enabledVolumeMapping: true
    };
    yield all([
      put(setVolumeMappings(samplePoint.id, response.data)),
      put(setCalculatedVolumeMappings(samplePoint.id, [])),
      put(uploadVolumeMappingsSuccess(response)),
      put(setSamplePoint(samplePoint))
    ]);
  } catch (error) {
    antdMessage.error(
      'Failed to upload survey. Please check the format/content of the file',
      3
    );
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(uploadVolumeMappingsFailure(message, error as AxiosError));
  }
}

export function* watchUploadVolumeMappingsRequest() {
  yield takeLatest(
    ActionTypes.UPLOAD_VOLUME_MAPPINGS_REQUEST,
    requestUploadVolumeMappings
  );
}

export function* requestGetVolumeMappings(
  action: ReturnType<typeof getVolumeMappings>
) {
  const {
    payload: { samplePointId }
  } = action;
  try {
    const response: AxiosResponse<VolumeMapping[]> = yield call(
      getRequest,
      `/volume-mapping/${samplePointId}`
    );
    yield all([
      put(setVolumeMappings(samplePointId, response.data)),
      put(getVolumeMappingsSuccess(response))
    ]);
  } catch (error) {
    antdMessage.error('Failed to get volume mappings');
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(getVolumeMappingsFailure(message, error as AxiosError));
  }
}

export function* watchGetVolumeMappingsRequest() {
  yield takeLatest(
    ActionTypes.GET_VOLUME_MAPPINGS_REQUEST,
    requestGetVolumeMappings
  );
}

export function* requestEditVolumeMapping(
  action: ReturnType<typeof editVolumeMappings>
) {
  const {
    payload: { samplePointId, updatedVolumeMapping }
  } = action;
  const { volumeMappings = [] }: { volumeMappings: VolumeMapping[] } =
    yield select((state) => state.volumeMappings[samplePointId]);
  const index = volumeMappings.findIndex(
    (vm) => +vm.id === +updatedVolumeMapping.id
  );
  volumeMappings[index] = updatedVolumeMapping;
  try {
    const response: AxiosResponse<VolumeMapping[]> = yield call(
      patchRequest,
      `/volume-mapping/${samplePointId}`,
      volumeMappings
    );
    yield all([
      put(setVolumeMappings(samplePointId, response.data)),
      put(editVolumeMappingsSuccess(response))
    ]);
  } catch (error) {
    antdMessage.error('Failed to edit volume mappings');
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(editVolumeMappingsFailure(message, error as AxiosError));
  }
}

export function* watchEditVolumeMappingsRequest() {
  yield takeLatest(
    ActionTypes.EDIT_VOLUME_MAPPINGS_REQUEST,
    requestEditVolumeMapping
  );
}

export function* requestGetCalculatedVolumeMappings(
  action: ReturnType<typeof getCalculatedVolumeMappings>
) {
  const {
    payload: { samplePointId, query }
  } = action;
  try {
    const response: AxiosResponse<CalculatedVolumeMapping[]> = yield call(
      getRequest,
      `/volume-mapping/${samplePointId}/calculations`,
      {
        params: query
      }
    );
    // Save requestedVolume into the response
    if (query.volume && response.data.length > 0) {
      response.data[0].requestedVolume = query.volume;
    }
    yield all([
      put(setCalculatedVolumeMappings(samplePointId, response.data)),
      put(getCalculatedVolumeMappingsSuccess(response))
    ]);
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(getCalculatedVolumeMappingsFailure(message, error as AxiosError));
  }
}

export function* watchGetCalculatedVolumeMappingsRequest() {
  yield takeEvery(
    ActionTypes.GET_CALCULATED_VOLUME_MAPPINGS_REQUEST,
    requestGetCalculatedVolumeMappings
  );
}

export default function* volumeMappingsSaga() {
  yield all([
    fork(watchUploadVolumeMappingsRequest),
    fork(watchGetVolumeMappingsRequest),
    fork(watchEditVolumeMappingsRequest),
    fork(watchGetCalculatedVolumeMappingsRequest)
  ]);
}
