import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import InstallCallbackAgriWebb from './AgriWebb/InstallCallback';
import SiteFarmMappingAgriWebb from './AgriWebb/SiteFarmMapping';

const Integrations = () => {
  const { path: parentPath } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${parentPath}/agriwebb/install-callback`}>
        <InstallCallbackAgriWebb />
      </Route>
      <Route exact path={`${parentPath}/agriwebb/site-mapping`}>
        <SiteFarmMappingAgriWebb />
      </Route>
    </Switch>
  );
};

export default Integrations;
