import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'types/models/enterprise';
import Site, { SiteId, UserSitePreference } from 'types/models/site';

import ActionTypes from './constants';

export const addSite = (values: Partial<Site>) =>
  action(ActionTypes.ADD_SITE_REQUEST, { values });

export const addSiteSuccess = (response: AxiosResponse) =>
  action(ActionTypes.ADD_SITE_SUCCESS, { response });

export const addSiteFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.ADD_SITE_FAILURE, { message, error }, undefined, true);

export const editSite = (siteId: SiteId, values: Partial<Site>) =>
  action(ActionTypes.EDIT_SITE_REQUEST, { siteId, values });

export const editSiteSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_SITE_SUCCESS, { response });

export const editSiteFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_SITE_FAILURE, { message, error }, undefined, true);

export const deleteSite = (id: SiteId) =>
  action(ActionTypes.DELETE_SITE_REQUEST, id);

export const deleteSiteSuccess = (response: AxiosResponse) =>
  action(ActionTypes.DELETE_SITE_SUCCESS, { response });

export const deleteSiteFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.DELETE_SITE_FAILURE, { message, error }, undefined, true);

export const editSitePreference = (siteId: SiteId, preference: UserSitePreference) =>
  action(ActionTypes.EDIT_SITE_PREFERENCE_REQUEST, { siteId, preference });

export const editSitePreferenceSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_SITE_PREFERENCE_SUCCESS, { response });

export const editSitePreferenceFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_SITE_PREFERENCE_FAILURE, { message, error }, undefined, true);

export const setSites = (sites: Record<SiteId, Site>) =>
  action(ActionTypes.SET_SITES, sites);

export const setSite = (site: Site) => action(ActionTypes.SET_SITE, site);

export const unsetSite = (id: SiteId) => action(ActionTypes.UNSET_SITE, id);

export const loadSites = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.LOAD_SITES_REQUEST, { enterpriseId });

export const loadSitesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_SITES_SUCCESS, { response });

export const loadSitesFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.LOAD_SITES_FAILURE, { message, error }, undefined, true);
