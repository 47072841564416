// ref: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/1727299603/Port+numbers+Physical+to+Logical+Virtual

import { EM_DASH } from 'constants/index';

/**
 * This mapping is not production ready for all sensors. Please verify the
 * mapping against database before using it. For example, we've seen cameras on
 * logical port number 5.
 */
const getPhysicalPortNumberByLogicalPortNumber = (
  portNo: number
): number | string => {
  switch (portNo) {
    case -1:
      return 'wireless';
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return 0;
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      return 1;
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
      return 2;
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
      return 3;
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
      return 4;
    default:
      return EM_DASH;
  }
};

export default getPhysicalPortNumberByLogicalPortNumber;
