import React from 'react';

import YouAreHereIcon from 'components/icons/YouAreHereIcon';

import './styles.less';

interface YouAreHereMapMarkerProps {
  lat: number;
  lng: number;
}

function YouAreHereMapMarker({ lat, lng }: YouAreHereMapMarkerProps) {
  return (
    <div className="YouAreHereMapMarker">
      <YouAreHereIcon size="40px" />
    </div>
  );
};

export default YouAreHereMapMarker;
