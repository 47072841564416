import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import SamplePoint, { SamplePointId } from 'types/models/samplePoint';

import ActionTypes from './constants';
import {
  CalculatedVolumeMapping,
  VolumeMapping,
  VolumeMappingQuery
} from './types';

export const setVolumeMappings = (
  samplePointId: SamplePointId,
  volumeMappings: VolumeMapping[]
) =>
  action(ActionTypes.SET_VOLUME_MAPPINGS, {
    samplePointId,
    volumeMappings
  });

export const setCalculatedVolumeMappings = (
  samplePointId: SamplePointId,
  calculatedVolumeMappings: CalculatedVolumeMapping[]
) =>
  action(ActionTypes.SET_CALCULATED_VOLUME_MAPPINGS, {
    samplePointId,
    calculatedVolumeMappings
  });

export const uploadVolumeMappings = (
  samplePoint: SamplePoint,
  file: File,
  onSuccess: () => void
) =>
  action(ActionTypes.UPLOAD_VOLUME_MAPPINGS_REQUEST, {
    samplePoint,
    file,
    onSuccess
  });

export const uploadVolumeMappingsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.UPLOAD_VOLUME_MAPPINGS_SUCCESS, { response });

export const uploadVolumeMappingsFailure = (
  message: string,
  error?: AxiosError
) => action(ActionTypes.UPLOAD_VOLUME_MAPPINGS_FAILURE, { message, error });

export const editVolumeMappings = (
  samplePointId: SamplePointId,
  updatedVolumeMapping: VolumeMapping
) =>
  action(ActionTypes.EDIT_VOLUME_MAPPINGS_REQUEST, {
    samplePointId,
    updatedVolumeMapping
  });

export const editVolumeMappingsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_VOLUME_MAPPINGS_SUCCESS, { response });

export const editVolumeMappingsFailure = (
  message: string,
  error?: AxiosError
) => action(ActionTypes.EDIT_VOLUME_MAPPINGS_FAILURE, { message, error });

/** Get full volume mappings */
export const getVolumeMappings = (samplePointId: SamplePointId) =>
  action(ActionTypes.GET_VOLUME_MAPPINGS_REQUEST, {
    samplePointId
  });

export const getVolumeMappingsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.GET_VOLUME_MAPPINGS_SUCCESS, { response });

export const getVolumeMappingsFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.GET_VOLUME_MAPPINGS_FAILURE, { message, error });

/**
 * Get calculated volume mappings when the requested value in not in the
 * original volume mapping
 */
export const getCalculatedVolumeMappings = (
  samplePointId: SamplePointId,
  query: VolumeMappingQuery
) =>
  action(ActionTypes.GET_CALCULATED_VOLUME_MAPPINGS_REQUEST, {
    samplePointId,
    query
  });

export const getCalculatedVolumeMappingsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.GET_CALCULATED_VOLUME_MAPPINGS_SUCCESS, { response });

export const getCalculatedVolumeMappingsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(ActionTypes.GET_CALCULATED_VOLUME_MAPPINGS_FAILURE, {
    message,
    error
  });
