import { useSelector } from 'react-redux';

import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import { makeSelectIsLoaded, makeSelectIsLoading } from 'store/modules/loading/selectors';

const actions = [
  'LOAD_ENTERPRISE_SCAFFOLD',
  'LOAD_SAMPLE_POINTS',
  'LOAD_RAISED_EVENTS'
];

export const useErrorMessage = (key?: string | string[]) => {
  const keys = Array.isArray(key)
    ? key
    : key
      ? [key]
      : actions;
  return useSelector(makeSelectFirstErrorMessage(keys));
};

export const useIsLoading = (key?: string | string[]) => {
  const keys = Array.isArray(key)
    ? key
    : key
      ? [key]
      : actions;

  // TODO: refactor this, general useIsLoading is used for app loading plus there's unnecessary derivation
  // just for accessing loading states. That is NOT good.
  return useSelector(makeSelectIsLoading(keys));
};

export const useIsLoaded = (key: string) => {
  return useSelector(makeSelectIsLoaded([key]));
};
