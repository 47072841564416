import { Alert, Modal } from 'antd';
import React from 'react';

import LogOutButton from 'components/buttons/LogOutButton';

interface Props {
  errorMessage: string;
}

function ErrorModal({ errorMessage }: Props): JSX.Element {
  return (
    <Modal
      title="Sorry, something went wrong."
      visible
      closable={false}
      footer={[<LogOutButton key="logout" />]}
    >
      <Alert message={errorMessage} type="error" />
    </Modal>
  );
}

export default ErrorModal;
