import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest } from 'utils/redux-saga-requests';

import {
  loadBackOfficeDevice,
  loadBackOfficeDeviceFailure,
  loadBackOfficeDeviceSuccess,
  setBackOfficeDevice
} from './actions';
import ActionTypes from './constants';

export function* requestBackOfficeDevice(
  action: ReturnType<typeof loadBackOfficeDevice>
): SagaIterator {
  const {
    payload: { deviceId }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `device/${deviceId}?join=site&join=site.enterprise`
    );

    yield all([
      put(loadBackOfficeDeviceSuccess(data)),
      put(setBackOfficeDevice(data))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadBackOfficeDeviceFailure(message, error));
  }
}

export function* watchLoadBackOfficeDeviceRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_REQUEST,
    requestBackOfficeDevice
  );
}

export default function* backOfficeDeviceSaga() {
  yield all([fork(watchLoadBackOfficeDeviceRequest)]);
}
