import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { TimeState } from './types';

export const initialState: TimeState = null;

function timeReducer(
  state: TimeState = initialState,
  action: ApplicationActions
): TimeState {
  switch (action.type) {
    case ActionTypes.SET_TIME:
      return action.payload;
    default:
      return state;
  }
}

export default timeReducer;
