import { Typography } from 'antd';
import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import DefaultCard from 'components/atoms/DefaultCard';
import { ReactComponent as AgriWebbLogo } from 'components/icons/AgriWebbLogo/agriwebb-logo.svg';
import FarmbotIcon from 'components/icons/FarmbotIcon';

import './styles.less';

const FarmbotAgriWebbIntegrationTitle = ({
  children
}: {
  children: string;
}) => (
  <DefaultCard className="FarmbotAgriWebbIntegrationTitle-card">
    <div className="FarmbotAgriWebbIntegrationTitle-icons">
      <FarmbotIcon style={{ height: '44px' }} />
      <MdKeyboardArrowLeft className="FarmbotAgriWebbIntegrationTitle-arrows left" />
      <MdKeyboardArrowRight className="FarmbotAgriWebbIntegrationTitle-arrows" />
      <AgriWebbLogo style={{ width: '60px' }} />
    </div>
    <Typography.Text style={{ fontSize: 16 }}>{children}</Typography.Text>
  </DefaultCard>
);

export default FarmbotAgriWebbIntegrationTitle;
