import isEqual from 'lodash/isEqual';

// Accept any props with a 'freeze' property.
interface PropsWithFreeze {
  freeze?: boolean;
  [x: string]: any;
}

/**
 * Use with react memo to prevent the component rendering if freeze is true.
 * This help prevent the map from changing when it has a page overlaid on top
 * of it.
 */
function preventRenderIfFrozen(
  prevProps: PropsWithFreeze,
  nextProps: PropsWithFreeze
) {
  if (nextProps.freeze) {
    return true;
  }

  return isEqual(prevProps, nextProps);
}

export default preventRenderIfFrozen;
