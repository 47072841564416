import { message as antdMessage } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  RawSamplePoint
} from 'types/models/samplePoint';
import getOriginalSamplePointIdsFromMergedSamplePoint from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import parseRawSamplePoints from 'utils/associated-sample-points/parse-raw-samplepoints';
import { patchRequest } from 'utils/redux-saga-requests';

import {
  editSamplePoint,
  editSamplePointFailure,
  editSamplePointSuccess,
  removeSamplePoint,
  removeSamplePointFailure,
  removeSamplePointRequest,
  removeSamplePointSuccess,
  setSamplePoint
} from './actions';
import ActionTypes from './constants';
import { convertImperialConfigToMetric } from './helpers/convert-imperial-config-to-metric';
import { makeSelectAssetTypeById } from '../assetTypes/selectors';
import { selectCurrentEnterpriseCountry } from '../enterprise/selectors';
import { removeLastSubPath } from '../routerUtils/actions';

export function* requestEditSamplePoint(
  action: ReturnType<typeof editSamplePoint>
) {
  const {
    payload: { samplePoint, values, onSuccess }
  } = action;
  const country: string = yield select(selectCurrentEnterpriseCountry);

  const { name: assetTypeName } = yield select(
    makeSelectAssetTypeById(samplePoint.assetTypeId)
  );
  const { config } = values;
  const updatedConfig = convertImperialConfigToMetric(config, samplePoint, country);

  const requestSamplePointIds =
    getOriginalSamplePointIdsFromMergedSamplePoint(samplePoint);

  try {
    const responses: AxiosResponse<RawSamplePoint>[] = yield all(
      requestSamplePointIds.map((id) =>
        call(patchRequest, `samplepoint/${id}`, {
          ...values,
          config: updatedConfig
        })
      )
    );

    const [responseData] = parseRawSamplePoints(
      responses.map(({ data }) => data),
      country
    );

    yield all([
      put(setSamplePoint(responseData)),
      put(editSamplePointSuccess(responses[0]))
    ]);
    if (onSuccess) {
      onSuccess();
    } else {
      antdMessage.success(`${assetTypeName} updated`);
    }
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error(`Failed to edit ${assetTypeName}.`);
    yield put(editSamplePointFailure(message, error as AxiosError));
  }
}

export function* watchEditSamplePointRequest() {
  yield takeLatest(
    ActionTypes.EDIT_SAMPLE_POINT_REQUEST,
    requestEditSamplePoint
  );
}

export function* requestRemoveSamplePoint(
  action: ReturnType<typeof removeSamplePointRequest>
) {
  const {
    payload: { samplePoint }
  } = action;

  const requestSamplePointIds =
    getOriginalSamplePointIdsFromMergedSamplePoint(samplePoint);
  const { name: assetTypeName } = yield select(
    makeSelectAssetTypeById(samplePoint.assetTypeId)
  );

  try {
    const responses: AxiosResponse<RawSamplePoint>[] = yield all(
      requestSamplePointIds.map((id) =>
        call(patchRequest, `samplepoint/${id}`, {
          statusActive: 0
        })
      )
    );
    antdMessage.success('Sensor removed');
    yield put(removeLastSubPath());
    yield all([
      put(removeSamplePoint(requestSamplePointIds)),
      put(removeSamplePointSuccess(responses[0]))
    ]);
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error(`Failed to remove ${assetTypeName}.`);
    yield put(removeSamplePointFailure(message, error as AxiosError));
  }
}

export function* watchRemoveSamplePointRequest() {
  yield takeLatest(
    ActionTypes.REMOVE_SAMPLE_POINT_REQUEST,
    requestRemoveSamplePoint
  );
}

export default function* samplePointSaga() {
  yield all([
    fork(watchEditSamplePointRequest),
    fork(watchRemoveSamplePointRequest)
  ]);
}
