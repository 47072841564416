import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import FarmbotLogoHorizontal from 'components/atoms/FarmbotLogoHorizontal';

import './styles.less';

interface Props {
  /**
   * Specify if the farmbot logo should be used instead of a loading spinner.
   */
  useLogo?: boolean;
  size?: number;
  color?: React.CSSProperties['color'];
  /** Delay in ms */
  delay?: number;
}

function LoadingSpinner({
  useLogo,
  size = 30,
  color,
  delay = 300
}: Props): JSX.Element {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) {
        setShowSpinner(true);
      }
    }, delay);
    return () => {
      isMounted = false;
    };
  }, [delay]);

  return (
    <div className="LoadingSpinner">
      {useLogo && <FarmbotLogoHorizontal />}
      {!useLogo && showSpinner && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: size,
                color
              }}
              spin
            />
          }
        />
      )}
    </div>
  );
}

export default LoadingSpinner;
