import { Typography } from 'antd';
import React from 'react';

interface Props {
  className?: string;
  email?: string | null | undefined;
  children?: React.ReactNode;
}

/**
 * Creates a mailto link based on an email address.
 */
function AnchorMailto({
  className,
  email,
  children
}: Props): JSX.Element | null {
  if (!email) {
    return null;
  }

  return (
    <Typography.Link
      className={className}
      href={`mailto:${email}`}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children || email}
    </Typography.Link>
  );
}

export default AnchorMailto;
