/**
 * Request statuses of all the sample points for the current enterprise.
 */

import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { getDateKey } from 'components/features/camera/helpers';
import rollbarLogger from 'config/rollbar';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import { selectSamplePointsAsArray } from 'store/modules/samplePoints/selectors';
import { AssetTypeCode } from 'types/models/asset-type';
import {
  CameraImageStatus,
  CameraImageType,
  CameraStatus
} from 'types/models/camera';
import SamplePoint, {
  MergedSamplePoint,
  SamplePointId
} from 'types/models/samplePoint';
import SamplePointStatistic, {
  MachineControlStatistic,
  SamplePointStatisticWithAggregates
} from 'types/models/samplePointsStatistic';
import { mergeSafetyCheckInSamplePointStatistics } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import { mergeSoilSamplePointStatistics } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import { getRequest } from 'utils/redux-saga-requests';

import {
  loadSamplePointsStatistics,
  loadSamplePointsStatisticsFailure,
  loadSamplePointsStatisticsSuccess,
  setSamplePointsStatistics
} from './actions';
import ActionTypes from './constants';
import { SamplePointsStatisticsState } from './types';
import {
  loadCameraImages,
  loadCameraImagesSuccess,
  loadCameraStatusSuccess
} from '../cameras/actions';
import { makeSelectCameraStatus } from '../cameras/selectors';
import { CamerasState } from '../cameras/types';
import mockMachineControls from '../controlPoints/__test__/data/mockMachineControls';
import mergeDualStatusesAndCodes from '../controlPoints/helpers';
import { parseStatusCode } from '../controlPoints/saga';
import { MachineControlStatus } from '../controlPoints/types';

/** A temporary solution as BE can't provide the image id */
export const DEFAULT_COVER_IMAGE_ID = -1;

const getStatisticBySamplePoint = (
  samplePoint: SamplePoint,
  samplePointStatistics: Record<number, SamplePointStatistic>
) => {
  switch (samplePoint.assetTypeId) {
    case AssetTypeCode.SOIL:
      return mergeSoilSamplePointStatistics(
        samplePointStatistics[
        samplePoint.id
        ] as SamplePointStatisticWithAggregates,
        samplePointStatistics[
        (samplePoint as any)?._hidden.id
        ] as SamplePointStatisticWithAggregates
      );
    case AssetTypeCode.SAFETY_CHECK_IN:
      if ((samplePoint as MergedSamplePoint)?._hidden) {
        return mergeSafetyCheckInSamplePointStatistics(
          samplePointStatistics[samplePoint.id],
          samplePointStatistics[(samplePoint as any)?._hidden.id]
        );
      }
      rollbarLogger.warning(
        `Paired SOS sensor not found. Check-in sensor id: ${samplePoint.id}`,
        samplePoint
      );
      return samplePointStatistics[samplePoint.id];
    case AssetTypeCode.MACHINE_CONTROL: {
      const primaryMachineControlStatistic = samplePointStatistics[
        samplePoint.id
      ] as MachineControlStatistic;
      // Dual. Merge primary and secondary machine control statuses.
      if ((samplePoint as MergedSamplePoint)?._hidden) {
        const secondaryMachineControlStatus = samplePointStatistics[
          (samplePoint as any)?._hidden.id
        ] as MachineControlStatistic;
        const [mergedStatus, mergedStatusCode] = mergeDualStatusesAndCodes(
          primaryMachineControlStatistic.status ?? MachineControlStatus.ERROR,
          primaryMachineControlStatistic.statusCode,
          secondaryMachineControlStatus.status ?? MachineControlStatus.ERROR,
          secondaryMachineControlStatus.statusCode
        );

        return {
          ...primaryMachineControlStatistic,
          _status: primaryMachineControlStatistic.status,
          _statusCode: primaryMachineControlStatistic.statusCode,
          status: mergedStatus,
          statusCode: mergedStatusCode,
          statusText: parseStatusCode(mergedStatusCode)
        } as MachineControlStatistic;
      }
      // Single / Double
      return {
        ...primaryMachineControlStatistic,
        _status: primaryMachineControlStatistic.status,
        _statusCode: primaryMachineControlStatistic.statusCode,
        statusText: parseStatusCode(primaryMachineControlStatistic.statusCode)
      } as MachineControlStatistic;
    }
    default:
      return samplePointStatistics[samplePoint.id];
  }
};

export function* parseSamplePointStatistics(
  samplePointOrControlPointStatistics: SamplePointsStatisticsState['data']
) {
  // Be careful. The content of samplePointStatistics is subject to change.
  // It can be a list of control points or sample points.
  const samplePoints: SamplePoint[] = (
    (yield select(selectSamplePointsAsArray)) as SamplePoint[]
  ).map((sp) => ({
    ...sp,
    // Update the sample points in store by the latest lastSampleDate
    // If samplePointStatistics[sp.id] is a control point, it doesn't have lastSample
    lastSampleDate:
      samplePointOrControlPointStatistics[sp.id]?.lastSample?.date ??
      sp.lastSampleDate
  }));

  // Expose secondary machine control in the store for its status
  for (const samplePoint of samplePoints as MergedSamplePoint[]) {
    if (
      samplePoint.assetTypeId === AssetTypeCode.MACHINE_CONTROL &&
      samplePoint._hidden
    ) {
      samplePoints.push(samplePoint._hidden as unknown as SamplePoint);
    }
  }

  return samplePoints.reduce((statistics, samplePoint) => {
    if (samplePointOrControlPointStatistics[samplePoint.id]) {
      const statistic = getStatisticBySamplePoint(
        samplePoint,
        samplePointOrControlPointStatistics
      );

      return {
        ...statistics,
        [samplePoint.id]: statistic
      };
    }
    return statistics;
  }, {});
}

export function* requestSamplePointsStatistics(
  action: ReturnType<typeof loadSamplePointsStatistics>
) {
  const {
    payload: { enterpriseId }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `enterprise/v2/${enterpriseId}/status`
    );
    const samplePoints: MergedSamplePoint[] = yield select(
      selectSamplePointsAsArray
    );
    const hasControlPoints = samplePoints.some(
      (sp) => sp.assetTypeId === AssetTypeCode.MACHINE_CONTROL
    );
    if (hasControlPoints) {
      // For testing only. It works with mocks in 1) store/modules/enterpriseScaffold/saga.ts
      // 2) components/features/machineControl/MachineControlPanel/index.tsx
      if (process.env.REACT_APP_ENV !== 'production') {
        data.push(...mockMachineControls);
      }
    }

    const parsedStatistics: SamplePointsStatisticsState['data'] = yield call(
      parseSamplePointStatistics,
      keyBy(data, 'samplePointId')
    );

    for (const camera of data.filter(
      (sp: any) => sp.assetTypeId === AssetTypeCode.CAMERA
    )) {
      const { samplePointId, status, statusCode, updatedAt, url } = camera;
      const previousCameraStatus:
        | CamerasState[SamplePointId]['status']
        | undefined = yield select(makeSelectCameraStatus(samplePointId));
      // If the camera was processing but is now ready, fetch the new image.
      if (
        previousCameraStatus &&
        previousCameraStatus.status === CameraStatus.PROCESSING &&
        status === CameraImageStatus.READY
      ) {
        yield put(loadCameraImages(samplePointId, { page: 1 }) as any);
      }

      const samplePoint = samplePoints.find(sp => sp.id === samplePointId);
      const timezoneCode = samplePoint?.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;

      yield put(loadCameraStatusSuccess(samplePointId, status, statusCode));
      // Add default image for each camera
      yield put(
        loadCameraImagesSuccess(
          samplePointId,
          {
            images: [
              {
                id: DEFAULT_COVER_IMAGE_ID,
                updatedAt,
                url,
                status,
                statusCode
              } as CameraImageType
            ],
            count: 1
          },
          1,
          getDateKey(updatedAt, timezoneCode)
        )
      );
    }

    yield all([
      put(loadSamplePointsStatisticsSuccess(data)),
      put(setSamplePointsStatistics(enterpriseId, parsedStatistics))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadSamplePointsStatisticsFailure(message, error));
  }
}

export function* watchLoadSamplePointsStatisticsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_SAMPLE_POINTS_STATISTICS_REQUEST,
    requestSamplePointsStatistics
  );
}

export default function* samplePointsSaga() {
  yield all([fork(watchLoadSamplePointsStatisticsRequest)]);
}
