import React from 'react';

import { ApplicationEventEmitter } from 'config/event/ApplicationEventEmitter';

// ==============================
// INSTANCES
// ==============================
export const applicationEventEmitter = new ApplicationEventEmitter();

// ==============================
// TYPES
// ==============================
type ApplicationGlobalContextType = {
  applicationEventEmitter: ApplicationEventEmitter;
};

// ==============================
// CONTEXT
// ==============================
export const ApplGlobalContext = React.createContext<ApplicationGlobalContextType>({
  applicationEventEmitter: applicationEventEmitter
});