import { AssetTypeCode } from 'types/models/asset-type';

enum ControlPointActionType {
  LOAD_CONTROL_POINT_STATUS_REQUEST = 'LOAD_CONTROL_POINT_STATUS_REQUEST',
  LOAD_CONTROL_POINT_STATUS_FAILURE = 'LOAD_CONTROL_POINT_STATUS_FAILURE',

  SEND_MACHINE_CONTROL_ACTION_REQUEST = 'SEND_MACHINE_CONTROL_ACTION_REQUEST',
  SEND_MACHINE_CONTROL_ACTION_SUCCESS = 'SEND_MACHINE_CONTROL_ACTION_SUCCESS',
  SEND_MACHINE_CONTROL_ACTION_FAILURE = 'SEND_MACHINE_CONTROL_ACTION_FAILURE',

  PATCH_CONTROL_POINT_REQUEST = 'PATCH_CONTROL_POINT_REQUEST',
  PATCH_CONTROL_POINT_SUCCESS = 'PATCH_CONTROL_POINT_SUCCESS',
  PATCH_CONTROL_POINT_FAILURE = 'PATCH_CONTROL_POINT_FAILURE',

  GET_CONTROL_POINT_ACTIVITY_LOG_REQUEST = 'GET_CONTROL_POINT_ACTIVITY_LOG_REQUEST',
  GET_CONTROL_POINT_ACTIVITY_LOG_SUCCESS = 'GET_CONTROL_POINT_ACTIVITY_LOG_SUCCESS',
  GET_CONTROL_POINT_ACTIVITY_LOG_FAILURE = 'GET_CONTROL_POINT_ACTIVITY_LOG_FAILURE'
}

export const CONTROL_POINT_ASSET_TYPES = [
  AssetTypeCode.CAMERA,
  AssetTypeCode.MACHINE_CONTROL
];

export default ControlPointActionType;
