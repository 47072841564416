import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Enterprise from 'types/models/enterprise';

import ActionTypes from './constants';

export const loadEnterprise = (enterpriseSubdomain: string) =>
  action(ActionTypes.LOAD_ENTERPRISE_REQUEST, { enterpriseSubdomain });

export const loadEnterpriseSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_ENTERPRISE_SUCCESS, { response });

export const loadEnterpriseFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.LOAD_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editEnterprise = (values: Partial<Enterprise>) =>
  action(ActionTypes.EDIT_ENTERPRISE_REQUEST, {
    values
  });

export const editEnterpriseSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_ENTERPRISE_SUCCESS, { response });

export const editEnterpriseFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.EDIT_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setEnterprise = (enterprise: Enterprise) =>
  action(ActionTypes.SET_ENTERPRISE, enterprise);
