/**
 * The error state selectors
 */

import get from 'lodash/get';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';

const selectError = (state: ApplicationRootState) => state.error;

const makeSelectFirstErrorMessage = (actions: string[]) =>
  // Returns the first error messages for actions
  // * For any request fails on a page that requires multiple API calls, we shows
  // the first error only.
  createSelector(
    selectError,
    (errors) =>
      actions
        .map((action) => get(errors, action.replace('_REQUEST', '')))
        .filter((value) => value)[0]
  );

export { selectError, makeSelectFirstErrorMessage };
