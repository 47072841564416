enum ActionTypes {
  LOAD_CURRENT_ENTERPRISE_INVITATIONS_REQUEST = 'LOAD_CURRENT_ENTERPRISE_INVITATIONS_REQUEST',
  LOAD_CURRENT_ENTERPRISE_INVITATIONS_SUCCESS = 'LOAD_CURRENT_ENTERPRISE_INVITATIONS_SUCCESS',
  LOAD_CURRENT_ENTERPRISE_INVITATIONS_FAILURE = 'LOAD_CURRENT_ENTERPRISE_INVITATIONS_FAILURE',

  LOAD_MY_INVITATIONS_REQUEST = 'LOAD_MY_INVITATIONS_REQUEST',
  LOAD_MY_INVITATIONS_SUCCESS = 'LOAD_MY_INVITATIONS_SUCCESS',
  LOAD_MY_INVITATIONS_FAILURE = 'LOAD_MY_INVITATIONS_FAILURE',

  LOAD_INVITATION_REQUEST = 'LOAD_INVITATION_REQUEST',
  LOAD_INVITATION_SUCCESS = 'LOAD_INVITATION_SUCCESS',
  LOAD_INVITATION_FAILURE = 'LOAD_INVITATION_FAILURE',

  ADD_INVITATION_REQUEST = 'ADD_INVITATION_REQUEST',
  ADD_INVITATION_SUCCESS = 'ADD_INVITATION_SUCCESS',
  ADD_INVITATION_FAILURE = 'ADD_INVITATION_FAILURE',

  ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST',
  ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_FAILURE = 'ACCEPT_INVITATION_FAILURE',

  RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST',
  RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAILURE = 'RESEND_INVITATION_FAILURE',

  DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST',
  DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAILURE = 'DELETE_INVITATION_FAILURE',

  UPDATE_INVITATION_REQUEST = 'UPDATE_INVITATION_REQUEST',
  UPDATE_INVITATION_SUCCESS = 'UPDATE_INVITATION_SUCCESS',
  UPDATE_INVITATION_FAILURE = 'UPDATE_INVITATION_FAILURE'
}

export default ActionTypes;
