import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { UserRole } from 'types/models/membership';
import User, { UserId } from 'types/models/user';

const selectUsersState = (state: ApplicationRootState) => state.users;

const selectUsers = createSelector([selectUsersState], (usersState) =>
  mapValues(usersState, (user: User) => {
    const { firstName, lastName, memberships } = user;
    let role;
    if (memberships && memberships.length) {
      role = memberships[0].role;
    }
    return {
      ...user,
      fullName: `${firstName} ${lastName}`,
      role
    };
  })
);

const selectUsersAsArray = createSelector(selectUsers, (users) =>
  orderBy(Object.values(users), 'fullName')
);

const selectNumberOfUsers = createSelector(
  selectUsersAsArray,
  (users) => users.length
);

const makeSelectUserByUserRole = (userRole: UserRole) =>
  createSelector(selectUsersAsArray, (users) =>
    users.filter((user) => user.role === userRole)
  );

const makeSelectUser = (userId: UserId) =>
  createSelector(selectUsers, (users) => users[userId]);

export {
  selectUsers,
  selectUsersAsArray,
  selectNumberOfUsers,
  makeSelectUser,
  makeSelectUserByUserRole
};
