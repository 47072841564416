/*
 * AuthConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here.
 */

enum ActionTypes {
  LOGOUT = 'LOGOUT'
}

export default ActionTypes;
