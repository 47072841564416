import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const setNetworkOnline = () => action(ActionTypes.SET_NETWORK_ONLINE);

export const setNetworkOffline = () => action(ActionTypes.SET_NETWORK_OFFLINE);

export const setNetworkReady = (isReady: boolean) =>
  action(ActionTypes.SET_NETWORK_READY, isReady);
