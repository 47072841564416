import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import FarmbotLogoHorizontal from 'components/atoms/FarmbotLogoHorizontal';

import './styles.less';

interface Props {
  children: React.ReactNode;
  bottomMenu?: JSX.Element;
}

function MainNavigationLogoWithPusher({
  children,
  bottomMenu
}: Props): JSX.Element {
  return (
    <div className="MainNavigationLogoWithPusher">
      <div
        className={classNames('MainNavigationLogoWithPusher-inner', {
          'has-bottom-menu': bottomMenu
        })}
      >
        {children}
      </div>
      <div className="MainNavigationLogoWithPusher-bottom">{bottomMenu}</div>
      <Link id="logo" to="/">
        <FarmbotLogoHorizontal />
      </Link>
    </div>
  );
}

export default MainNavigationLogoWithPusher;
