import React, { CSSProperties } from 'react';

import { ReactComponent as Sensor } from './sensor.svg';

function SensorIcon({ size, style }: { size?: number; style?: CSSProperties; }) {
  return (
    <Sensor
      // due to the viewBox, the width of this svg must always
      // be 1 greater than the height
      {...size ? { width: size + 1, height: size } : {}}
      style={style}
    />
  );
}

export default SensorIcon;
