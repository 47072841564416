import { Typography } from 'antd';
import React from 'react';

interface Props {
  id?: string;
  className?: string;
  phoneNumber?: string | null;
  inheritsColor?: boolean;
  underline?: boolean;
  children?: React.ReactNode;
}

/**
 * Creates a tel link based on a phone number.
 */
function AnchorTel({
  id,
  className,
  phoneNumber,
  inheritsColor = true,
  underline,
  children
}: Props): JSX.Element | null {
  if (!phoneNumber) {
    return null;
  }

  return (
    <Typography.Link
      id={id}
      className={className}
      style={inheritsColor ? { color: 'inherit' } : undefined}
      underline={underline}
      href={`tel:${phoneNumber}`}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children || phoneNumber}
    </Typography.Link>
  );
}

export default AnchorTel;
