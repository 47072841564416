import { ApplicationActions } from 'store/types';

import ControlPointActionType from './constants';
import { ControlPointsState, MachineControlStatusCode } from './types';

export const initialState: ControlPointsState = {};

/** The data in this store comes from machine control status API. Therefore the
 * main setter is dealing with the control point statuses. */
function controlPointsReducer(
  state: ControlPointsState = initialState,
  action: ApplicationActions
): ControlPointsState {
  switch (action.type) {
    case ControlPointActionType.SEND_MACHINE_CONTROL_ACTION_FAILURE: {
      const {
        payload: { samplePointId }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          _statusCode: MachineControlStatusCode.MS_POSTED_TO_GATEWAY_FAILED
        }
      };
    }
    case ControlPointActionType.GET_CONTROL_POINT_ACTIVITY_LOG_SUCCESS: {
      const {
        payload: { samplePointId, activityLog }
      } = action;

      return {
        ...state,
        [samplePointId]: {
          ...state[samplePointId],
          activityLog
        }
      };
    }
    default:
      return state;
  }
}

export default controlPointsReducer;
