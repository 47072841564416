import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { AppScaffoldState } from './types';

export const initialState: AppScaffoldState = {
  initialDataLoaded: false
};

function appScaffoldReducer(
  state: AppScaffoldState = initialState,
  action: ApplicationActions
): AppScaffoldState {
  switch (action.type) {
    case ActionTypes.LOAD_APP_SCAFFOLD_SUCCESS:
      return {
        initialDataLoaded: true
      };
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default appScaffoldReducer;
