import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { DeviceId } from 'types/models/device';

const selectBackOfficeDevicesState = (state: ApplicationRootState) =>
  state.backOfficeDevices;

const selectBackOfficeDevicesTotal = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.total
);

const selectBackOfficeDevicesData = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.data
);

const selectBackOfficeDevicesIds = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.ids
);

const selectBackOfficeDeviceFilters = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.deviceFilters
);

const selectBackOfficeDeviceDataSources = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.dataSources
);

const selectBackOfficeDeviceCameraSamplePoints = createSelector(
  selectBackOfficeDevicesState,
  (devicesState) => devicesState.cameraSamplePoints
);

const makeSelectBackOfficeDeviceCameraSamplePoint = (deviceId?: DeviceId) =>
  createSelector(
    selectBackOfficeDeviceCameraSamplePoints,
    (backOfficeDeviceCameraSamplePoints) =>
      backOfficeDeviceCameraSamplePoints.find(
        (samplePoint) => samplePoint.deviceId === deviceId
      )
  );

const selectBackOfficeDevicesAsArray = createSelector(
  [selectBackOfficeDevicesData, selectBackOfficeDevicesIds],
  (backOfficeDevicesData, backOfficeDevicesIds) =>
    backOfficeDevicesIds.map((deviceId) => backOfficeDevicesData[deviceId])
);

const makeSelectBackOfficeDeviceById = (deviceId: DeviceId) =>
  createSelector(
    selectBackOfficeDevicesData,
    (backOfficeDevicesData) => backOfficeDevicesData[deviceId]
  );

export {
  selectBackOfficeDevicesTotal,
  selectBackOfficeDevicesData,
  selectBackOfficeDevicesIds,
  selectBackOfficeDevicesAsArray,
  selectBackOfficeDeviceFilters,
  selectBackOfficeDeviceDataSources,
  selectBackOfficeDeviceCameraSamplePoints,
  makeSelectBackOfficeDeviceCameraSamplePoint,
  makeSelectBackOfficeDeviceById
};
