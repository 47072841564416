// NumericInput is derived from Ant Design's official documentation.

import { Input, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { useState } from 'react';

interface NumericInputProps {
  id?: string;
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  suffix?: React.ReactNode;
  /** Show number preview with decimal separator */
  showTooltip?: boolean;
  tooltipPlacement?: TooltipPlacement;
  /** By default we use formatted value as the dynamic tooltip title */
  staticTooltipTitle?: string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
}

const formatNumber = (value: number) =>
  new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 20
  }).format(value);

/**
 * Use the Input in conjunction with Tooltip component to create a Numeric Input,
 * which provides a good experience for extra-long content display.
 */
const MFBNumericInput = (props: NumericInputProps) => {
  const {
    id,
    value,
    onChange,
    suffix,
    showTooltip = false,
    maxLength = 16,
    placeholder,
    tooltipPlacement = 'topLeft',
    staticTooltipTitle,
    disabled
  } = props;

  const [enableTooltip, setEnableTooltip] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange?.(inputValue);
    }
    setEnableTooltip(true);
  };

  // Not allow '.' at the end or only '-' in the input box
  // normalize() works not as expected
  const handleBlur = () => {
    let inputValue = value?.toString();
    if (
      inputValue?.charAt(inputValue.length - 1) === '.' ||
      inputValue === '-'
    ) {
      inputValue = inputValue.slice(0, -1);
    }
    onChange?.(inputValue?.replace(/0*(\d+)/, '$1') ?? '');
    setEnableTooltip(false);
  };

  const title = (() => {
    if (staticTooltipTitle) {
      return staticTooltipTitle;
    }
    return value ? (
      <span>{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
    ) : (
      placeholder
    );
  })();

  return (
    <Tooltip
      visible={showTooltip && enableTooltip}
      trigger={['focus']}
      title={title}
      placement={tooltipPlacement}
    >
      <Input
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        suffix={suffix}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default MFBNumericInput;
