import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';

import { selectDefaultSite } from '../localUserSettings/selectors';

export const selectCiboLocationsState = (state: ApplicationRootState) =>
  state.ciboLocations;

export const selectCiboSubscriptionSummary = createSelector(
  [selectCiboLocationsState],
  (locationsState) => ({
    ...locationsState.subscriptionSummary,
    totalPlacedLocations: Object.keys(locationsState.locations).length
  })
);

const selectCiboLocationsAsArray = createSelector(
  [selectCiboLocationsState],
  (locationsState) => orderBy(Object.values(locationsState.locations), 'name')
);

export const selectCiboLocationsForDefaultSite = () =>
  createSelector([selectCiboLocationsAsArray, selectDefaultSite], (locations, { id }) =>
    locations.filter((location) => location.siteId === id)
  );

export const makeSelectCiboLocationByLocationId = (
  locationId: number
) => createSelector([selectCiboLocationsState], (locationsState) => locationsState.locations[locationId]);

export const makeSelectCiboLocationDataPointsByLocationId = (
  locationId: number
) =>
  createSelector([selectCiboLocationsState], (locationsState) =>
    locationsState.locationDataPoints.filter(dp => dp.locationId === locationId)
  );