import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import configAxios from 'config/axios';
import { loadAppScaffold } from 'store/modules/appScaffold/actions';
import { loadMyUser, mergeMyUser } from 'store/modules/myUser/actions';
import { setNetworkReady } from 'store/modules/network/actions';

const { REACT_APP_API_URL } = process.env;
export const USER_ID_URL = 'https://myfarmbot.com/userId';

interface Props {
  children: JSX.Element;
}

/**
 * A component for Auth0, axios initialisation, and init API calls.
 */
const Auth0TokenHandler = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  // After axios is configured, we load user profile and Core API.
  // Have to use callback here, because Auth0TokenHandler cannot be async.
  if (isAuthenticated) {
    configAxios(getAccessTokenSilently, loginWithRedirect).then(() => {
      dispatch(setNetworkReady(true));
      // Set user id in store. (Currently we cannot get 100% user profile from token.)
      // Then loadMyUser uses this id to get the complete user profile from BE.
      if (user) {
        const userId = +user[USER_ID_URL];
        if (userId) {
          dispatch(
            mergeMyUser({
              id: userId
            })
          );
          dispatch(loadMyUser());
          dispatch(loadAppScaffold());
        }
      } else {
        throw new Error('Auth0 user info is missing');
      }
    });
  }
  // Config axios minimally when user is not authenticated.
  axios.defaults.baseURL = REACT_APP_API_URL;
  // We don't block un-authenticated access in this component. It's handled
  // by <ProtectedRoutesLoadingGate />.
  return children;
};

export default Auth0TokenHandler;
