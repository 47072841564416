import { message as antdMessage } from 'antd';
import round from 'lodash/round';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { BORE_LEVEL_PRECISION } from 'constants/samplePoint';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import downloadFile from 'utils/download-file';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';

import { constructSampleUrlForCSVExport } from './utils/construct-sample-url-for-csv-export';

import { CSV_DATE_FORMAT, CSV_FILE_NAME_DATE_FORMAT } from '.';

// Check more shared comments in useExportRainGaugeCsv
export default function useExportBoreCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const country = useEnterpriseCountry();
  const { id, name, config, siteTimezoneCode, assetTypeId } = samplePoint;
  const assetType = useAssetType(assetTypeId);

  if (!siteTimezoneCode) {
    antdMessage.error('Site time zone is not set');
    return;
  }

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (config.maxDepth === undefined) {
      antdMessage.error('The depth is not set');
      throw new Error('Bore depth is not set');
    }
    try {
      const samples = await new Promise<Sample[]>((resolve, reject) => {
        setLoading(true);
        const url = constructSampleUrlForCSVExport(id, siteTimezoneCode, dateRange);
        dispatch(
          ephemeralGetRequest<Sample[]>(url, resolve, reject)
        );
      });
      const levelUnit = getUnitByCountry({
        unitType: UnitType.LEVEL_MD,
        country
      });
      const csvHeader = `Date and Time,${levelUnit} (below ground)`;
      const csvArray = [csvHeader];
      const csvRows = samples
        .sort((a, b) => b.date - a.date)
        .map((s) => {
          let level = convertMetricToImperial(
            UnitType.LEVEL_MD,
            country,
            (config.maxDepth as number) - s.rwValue
          );
          if (level !== undefined) {
            level = round(level, BORE_LEVEL_PRECISION);
          }
          const date = moment(s.date)
            .tz(siteTimezoneCode)
            .format(CSV_DATE_FORMAT);
          return `${date},${level}`;
        });
      csvArray.push(...csvRows);

      downloadFile(
        `${name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        csvArray.join('\n'),
        'text/csv'
      );

      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
