enum ActionTypes {
  LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST = 'LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST',
  LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_SUCCESS = 'LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_SUCCESS',
  LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_FAILURE = 'LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_FAILURE',

  FETCH_SAMPLES_CSV_CONTENT_REQUEST = 'FETCH_SAMPLES_CSV_CONTENT_REQUEST',
  FETCH_SAMPLES_CSV_CONTENT_SUCCESS = 'FETCH_SAMPLES_CSV_CONTENT_SUCCESS',
  FETCH_SAMPLES_CSV_CONTENT_FAILURE = 'FETCH_SAMPLES_CSV_CONTENT_FAILURE'
}

export default ActionTypes;
