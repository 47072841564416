import moment from 'moment-timezone';

import { DATE_TIME_FORMAT, DEFAULT_TIMEZONE_CODE, DEFAULT_TIMEZONE_CODE_RANCHBOT } from 'constants/time';
import { isRanchbot } from 'utils/is-x-bot';

/**
 * Formats a given date as a string according to the staff office's timezone.
 * Country of staff office is inferred from the app's domain.
 */
export const formatSubscriptionDate = (date: number) => {
  return moment(date)
    .tz(isRanchbot() ? DEFAULT_TIMEZONE_CODE_RANCHBOT : DEFAULT_TIMEZONE_CODE)
    .format(DATE_TIME_FORMAT.SUBSCRIPTION);
};