// The chart type here is the unit of y-axis in the chart.

export enum TankChartType {
  LEVEL = 'units',
  PERCENT = 'percent',
  LITRES = 'litres',
  GALLONS = 'gallons'
}

export enum FlowChartType {
  VOLUME = 'litres',
  VOLUME_PER_MIN = 'litresPerMinute'
}

/*
 * We added prefix `advancedDam` to differentiate from SimpleDamChartType, because TypeScript doesn't check duplicate
 * enum values in switch case.
 */
export enum AdvancedDamChartType {
  VOLUME = 'advancedDamVolume',
  PERCENT = 'advancedDamPercent',
  REDUCED_LEVEL = 'advancedDamReducedLevel',
  LEVEL = 'advancedDamLevel'
}

export enum SimpleDamChartType {
  VOLUME = 'volume',
  PERCENT = 'percent',
  LEVEL = 'level'
}

export enum TroughDirectChartType {
  LEVEL = 'troughDirectLevel',
  PERCENT = 'troughDirectPercent'
}

export type SampleChartType =
  | TankChartType
  | FlowChartType
  | AdvancedDamChartType
  | SimpleDamChartType
  | TroughDirectChartType;

// export enum PressureChartType {
//   kPa = "kPa",
//   psi = "psi",
//   percent = "percent",
// }
