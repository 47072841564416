import { shallowEqual, useSelector } from 'react-redux';

import { LegalPolicyDocument } from 'types/models/legal-policy-document';

import { selectLegalPoliciesLastChecked, selectLegalPolicyDocuments, selectLegalPolicyUserAcceptance } from './selector';
import { useMyUser } from '../myUser/hooks';

export const useLegalPolicyDocuments = (): Record<number, LegalPolicyDocument> => {
  return useSelector(selectLegalPolicyDocuments, shallowEqual);
};

export const useLegalPolicyDocumentsAsArray = (): LegalPolicyDocument[] => {
  const documents = useLegalPolicyDocuments();

  return Object.values(documents);
};

export const useAreLatestPoliciesAccepted = (): boolean | undefined => {
  const user = useMyUser();

  if (!user) throw new Error('User not found');

  return useSelector(selectLegalPolicyUserAcceptance(user.id), shallowEqual);
};

export const useLegalPolicyLastChecked = (): number => {
  return useSelector(selectLegalPoliciesLastChecked, shallowEqual);
};