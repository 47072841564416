import { message as antdMessage } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { ciboClient } from 'clients';
import { APIResponseParsed } from 'types/response';

import {
  addBackOfficeCiboSubscription,
  addBackOfficeCiboSubscriptionFailure,
  addBackOfficeCiboSubscriptionSuccess,
  cancelBackOfficeCiboSubscription,
  cancelBackOfficeCiboSubscriptionFailure,
  cancelBackOfficeCiboSubscriptionSuccess,
  closeBackOfficeCiboCancellationModal,
  fetchAllBackOfficeCiboLocations,
  fetchAllBackOfficeCiboSubscriptionsLatestFirst,
  fetchBackOfficeCiboLocationsFailure,
  fetchBackOfficeCiboLocationsSuccess,
  fetchBackOfficeCiboSubscriptionsFailure,
  fetchBackOfficeCiboSubscriptionsSuccess,
  openBackOfficeCiboPlacedZonesModal,
  setBackOfficeCiboLocations,
  setBackOfficeCiboSubscriptions,
  updateBackOfficeCiboSubscriptionRenew,
  updateBackOfficeCiboSubscriptionRenewFailure,
  updateBackOfficeCiboSubscriptionRenewSuccess
} from './actions';
import ActionTypes from './constants';
import { CiboSubscription } from './types';
import { CiboLocation } from '../ciboLocations/types';

// ==============================
// WORKER SAGAS
// ==============================
export function* requestFetchCiboSubscriptions(
  action: ReturnType<typeof fetchAllBackOfficeCiboSubscriptionsLatestFirst>
) {
  const { payload } = action;
  const response: APIResponseParsed<CiboSubscription[]> = yield call(ciboClient.fetchAllCiboSubscriptions, payload);
  if (response.data) {
    yield all([
      put(fetchBackOfficeCiboSubscriptionsSuccess()),
      put(setBackOfficeCiboSubscriptions(payload.enterpriseId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to fetch subscriptions';
    yield put(fetchBackOfficeCiboSubscriptionsFailure(message));
    antdMessage.error('Failed to fetch subscriptions');
  }
}

export function* requestFetchCiboLocations(action: ReturnType<typeof fetchAllBackOfficeCiboLocations>) {
  const { payload } = action;
  const response: APIResponseParsed<CiboLocation[]> = yield call(ciboClient.fetchAllCiboLocations, payload);
  if (response.data) {
    yield all([
      put(fetchBackOfficeCiboLocationsSuccess()),
      put(setBackOfficeCiboLocations(payload.enterpriseId, response.data))
    ]);
  } else {
    const message = response.error.message || 'Failed to fetch pasture zones';
    yield put(fetchBackOfficeCiboLocationsFailure(message));
    antdMessage.error('Failed to fetch pasture zones');
  }
}

export function* requestAddCiboSubscription(action: ReturnType<typeof addBackOfficeCiboSubscription>) {
  const { payload } = action;
  const response: APIResponseParsed<CiboSubscription> = yield call(ciboClient.addCiboSubscription, payload);
  if (response.data) {
    yield all([
      put(addBackOfficeCiboSubscriptionSuccess()),
      put(fetchAllBackOfficeCiboSubscriptionsLatestFirst(payload.enterpriseId))
    ]);
    antdMessage.success('Subscription added successfully');
  } else {
    const message = response.error.message || 'Failed to add subscription';
    yield put(addBackOfficeCiboSubscriptionFailure(message));

    const minAddRegex = /The minimum number of locations for initial purchase is (\d+)/;
    const minAddMatch = message.match(minAddRegex);
    if (minAddMatch?.[1]) {
      const minAdd = parseInt(minAddMatch[1]);
      antdMessage.error(`Must add at least ${minAdd} pasture zones for initial purchase`);
    } else {
      antdMessage.error('Failed to add subscription');
    }
  }
}

export function* requestCancelCiboSubscription(action: ReturnType<typeof cancelBackOfficeCiboSubscription>) {
  const { payload } = action;
  const response: APIResponseParsed<CiboSubscription> = yield call(
    ciboClient.cancelCiboSubscription,
    payload.subscriptionId
  );
  if (response.data) {
    const updatedSubscription: CiboSubscription = response.data;
    yield all([
      put(cancelBackOfficeCiboSubscriptionSuccess()),
      put(closeBackOfficeCiboCancellationModal(payload.subscriptionId)),
      put(fetchAllBackOfficeCiboSubscriptionsLatestFirst(updatedSubscription.enterpriseId))
    ]);
    antdMessage.success('Subscription cancelled successfully');
  } else {
    const message = response.error.message || 'Failed to cancel subscription';
    yield put(cancelBackOfficeCiboSubscriptionFailure(message));

    const minPurchaseRegex = /Cannot cancel integration because it violates the minimum purchase rule of (\d+)/;
    const minPurchaseMatch = message.match(minPurchaseRegex);

    const placedZonesRegex = /Must remove (\d+) locations first/;
    const placedZonesMatch = message.match(placedZonesRegex);

    if (minPurchaseMatch?.[1]) {
      yield put(closeBackOfficeCiboCancellationModal(payload.subscriptionId));
      antdMessage.error(message.replace('integration', 'subscription').replace('locations', 'pasture zones'));
    } else if (placedZonesMatch?.[1]) {
      yield put(closeBackOfficeCiboCancellationModal(payload.subscriptionId));
      yield put(openBackOfficeCiboPlacedZonesModal(payload.subscriptionId, parseInt(placedZonesMatch[1])));
    } else {
      antdMessage.error('Failed to cancel subscription');
    }
  }
}

export function* requestUpdateCiboSubscriptionAutoRenew(
  action: ReturnType<typeof updateBackOfficeCiboSubscriptionRenew>
) {
  const { payload: { subscriptionId, isAutoRenewActive } } = action;
  const response: APIResponseParsed<CiboSubscription> = yield call(
    ciboClient.updateCiboSubscriptionAutoRenew,
    subscriptionId,
    isAutoRenewActive
  );
  if (response.data) {
    yield all([
      put(updateBackOfficeCiboSubscriptionRenewSuccess()),
      put(fetchAllBackOfficeCiboSubscriptionsLatestFirst(response.data.enterpriseId))
    ]);
    antdMessage.success('Auto-renew updated successfully');
  } else {
    const message = response.error.message || 'Failed to update auto-renew';
    yield put(updateBackOfficeCiboSubscriptionRenewFailure(message));
    antdMessage.error('Failed to update auto-renew');
  }
}

// ==============================
// WATCHER SAGAS
// ==============================
export function* watchFetchCiboSubscriptionsRequest() {
  yield takeLatest(ActionTypes.FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_REQUEST, requestFetchCiboSubscriptions);
}

export function* watchFetchCiboLocationsRequest() {
  yield takeLatest(ActionTypes.FETCH_BACK_OFFICE_CIBO_LOCATIONS_REQUEST, requestFetchCiboLocations);
}

export function* watchAddCiboSubscriptionRequest() {
  yield takeLatest(ActionTypes.ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST, requestAddCiboSubscription);
}

export function* watchCancelCiboSubscriptionRequest() {
  yield takeLatest(ActionTypes.CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST, requestCancelCiboSubscription);
}

export function* watchCancelCiboSubscriptionRenewalRequest() {
  yield takeLatest(
    ActionTypes.UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_REQUEST,
    requestUpdateCiboSubscriptionAutoRenew
  );
}

// ==============================
// EXPORTS
// ==============================
export default function* backOfficeCiboSubscriptionsSaga() {
  yield all([
    fork(watchFetchCiboSubscriptionsRequest),
    fork(watchFetchCiboLocationsRequest),
    fork(watchAddCiboSubscriptionRequest),
    fork(watchCancelCiboSubscriptionRequest),
    fork(watchCancelCiboSubscriptionRenewalRequest)
  ]);
}