import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  loadBackOfficeEnterprise,
  setBackOfficeEnterprise
} from 'store/modules/backOfficeEnterprise/actions';
import { EnterpriseId } from 'types/models/enterprise';

interface Props {
  enterpriseId?: EnterpriseId;
}

function BackOfficeEnterpriseRequest({ enterpriseId }: Props): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (enterpriseId) {
      dispatch(loadBackOfficeEnterprise(enterpriseId));
      return () => {
        dispatch(setBackOfficeEnterprise(null));
      };
    }
    // eslint complains otherwise
    return () => { };
  }, [enterpriseId, dispatch]);

  return null;
}

export default BackOfficeEnterpriseRequest;
