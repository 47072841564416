import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'types/models/enterprise';

import ActionTypes from './constants';
import { SamplePointsStatisticsState } from './types';

export const loadSamplePointsStatistics = (enterpriseId: EnterpriseId) =>
  action(ActionTypes.LOAD_SAMPLE_POINTS_STATISTICS_REQUEST, { enterpriseId });

export const loadSamplePointsStatisticsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_SAMPLE_POINTS_STATISTICS_SUCCESS, { response });

export const loadSamplePointsStatisticsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_SAMPLE_POINTS_STATISTICS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setSamplePointsStatistics = (
  enterpriseId: EnterpriseId,
  samplePointsStatisticsDictionary: SamplePointsStatisticsState['data']
) =>
  action(ActionTypes.SET_SAMPLE_POINTS_STATISTICS, {
    enterpriseId,
    samplePointsStatisticsDictionary
  });

export const updateSamplePointsStatistics = (
  samplePointsStatisticsDictionary: SamplePointsStatisticsState['data']
) =>
  action(ActionTypes.UPDATE_SAMPLE_POINTS_STATISTICS, {
    samplePointsStatisticsDictionary
  });
