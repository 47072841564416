import React, { useEffect } from 'react';
import { Detector } from 'react-detect-offline';
import { useDispatch } from 'react-redux';

import {
  setNetworkOffline,
  setNetworkOnline
} from 'store/modules/network/actions';

interface Props {
  online: boolean;
}

const NestedNetworkToRedux = ({ online }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (online) {
      dispatch(setNetworkOnline());
    } else {
      dispatch(setNetworkOffline());
    }
  }, [online, dispatch]);

  return null;
};

/**
 * Takes the network connection and stores it in redux so that it can be
 * utilised in the sagas.
 */
const NetworkToRedux = () => {
  return (
    <Detector render={(props: Props) => <NestedNetworkToRedux {...props} />} />
  );
};

export default NetworkToRedux;
