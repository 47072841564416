export enum DeviceStatus {
  ASSIGNED_TO_ENTERPRISE = 'AssignedToEnterprise',
  DECOMMISSIONED = 'Decommissioned',
  DELAYED = 'Delayed',
  DISCONNECTED = 'Disconnected',
  HIBERNATING = 'Hibernating',
  ONLINE = 'Online',
  TURNED_OFF = 'TurnedOff',
  UNASSIGNED_FROM_ENTERPRISE = 'UnassignedFromEnterprise',
  UNKNOWN = 'Unknown'
}

export enum DeviceStatusCause {
  BOOT = 'Boot',
  DECOMMISSIONED = 'Decommissioned',
  DEVICE_DISCONNECTED = 'DeviceDisconnected',
  FIRST_PACKET_RECEIVED = 'FirstPacketReceived',
  HIBERNATION_START = 'DeviceHibernationStart',
  HIBERNATION_STOP = 'DeviceHibernationEnd',
  MAX_TRANSMISSIONS_REACHED = 'MaxTransmissionsReached',
  OUTAGE = 'Outage',
  PACKET_RECEIVED = 'PacketReceived',
  TRANSMISSION_FAILURE = 'TransmissionFailure',
  TRANSMISSION_MISSED = 'TransmissionMissed',
  TURNED_OFF = 'TurnedOff',
  UNKNOWN = 'Unknown',
  UPDATE_FROM_API = 'UpdatedFromAPI'
}
