import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CountryAbbreviation } from 'types/models/country';

import {
  makeSelectCountryByAbbreviation,
  selectCountriesAsArray
} from './selectors';

export const useCountriesAsArray = () =>
  useSelector(selectCountriesAsArray, isEqual);

export const useCountry = (
  countryAbbreviation: CountryAbbreviation | undefined
) => {
  const selectCountryByAbbreviation = useMemo(
    () => makeSelectCountryByAbbreviation(countryAbbreviation),
    [countryAbbreviation]
  );
  return useSelector(selectCountryByAbbreviation, isEqual);
};
