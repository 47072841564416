import { action } from 'typesafe-actions';

import { UpdateCiboLocationDTO } from 'clients/ciboLocation';

import { CiboActionType } from './constants';
import {
  CiboImagerySet,
  CiboLocation,
  CiboLocationDataPoint,
  CiboLocationStatus,
  CiboSubscriptionSummary
} from './types';

// ================================================
// Fetch Cibo Subscriptions
// ================================================
export const fetchCiboSubscriptions = () =>
  action(CiboActionType.FETCH_CIBO_SUBSCRIPTIONS_REQUEST);

export const fetchCiboSubscriptionsSuccess = () => action(CiboActionType.FETCH_CIBO_SUBSCRIPTIONS_SUCCESS);

export const fetchCiboSubscriptionsFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_SUBSCRIPTIONS_FAILURE, { message }, undefined, true);

export const setCiboSubscriptionSummary = (subscriptionSummary: CiboSubscriptionSummary) =>
  action(CiboActionType.SET_CIBO_SUBSCRIPTION_SUMMARY, { subscriptionSummary });

// ================================================
// Fetch Cibo Locations
// ================================================
export const fetchCiboLocations = () =>
  action(CiboActionType.FETCH_CIBO_LOCATIONS_REQUEST);

export const fetchCiboLocationsSuccess = () => action(CiboActionType.FETCH_CIBO_LOCATIONS_SUCCESS);

export const fetchCiboLocationsFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_LOCATIONS_FAILURE, { message }, undefined, true);

export const setCiboLocations = (locations: CiboLocation[]) =>
  action(CiboActionType.SET_CIBO_LOCATIONS, { locations });

// ================================================
// Fetch Cibo Location
// ================================================
export const fetchCiboLocation = (locationId: number) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_REQUEST, { locationId });

export const fetchCiboLocationSuccess = () => action(CiboActionType.FETCH_CIBO_LOCATION_SUCCESS);

export const fetchCiboLocationFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_FAILURE, { message }, undefined, true);

export const setCiboLocation = (id: number, location: Partial<CiboLocation>) =>
  action(CiboActionType.SET_CIBO_LOCATION, { id, location });

export const unsetCiboLocation = (locationId: number) =>
  action(CiboActionType.UNSET_CIBO_LOCATION, { locationId });

// ================================================
// Add Cibo Location
// ================================================
export const addCiboLocation = (center: google.maps.LatLng) =>
  action(CiboActionType.ADD_CIBO_LOCATION_REQUEST, { lat: center.lat(), lng: center.lng() });

export const addCiboLocationSuccess = () => action(CiboActionType.ADD_CIBO_LOCATION_SUCCESS);

export const addCiboLocationFailure = (message: string) =>
  action(CiboActionType.ADD_CIBO_LOCATION_FAILURE, { message }, undefined, true);

// ================================================
// Edit Cibo Location
// ================================================
export const editCiboLocation = (locationId: number, data: UpdateCiboLocationDTO) =>
  action(CiboActionType.EDIT_CIBO_LOCATION_REQUEST, { locationId, data });

export const editCiboLocationSuccess = () => action(CiboActionType.EDIT_CIBO_LOCATION_SUCCESS);

export const editCiboLocationFailure = (message: string) =>
  action(CiboActionType.EDIT_CIBO_LOCATION_FAILURE, { message }, undefined, true);

// ================================================
// Delete Cibo Location
// ================================================
export const deleteCiboLocation = (locationId: number) =>
  action(CiboActionType.DELETE_CIBO_LOCATION_REQUEST, { locationId });

export const deleteCiboLocationSuccess = () => action(CiboActionType.DELETE_CIBO_LOCATION_SUCCESS);

export const deleteCiboLocationFailure = (message: string) =>
  action(CiboActionType.DELETE_CIBO_LOCATION_FAILURE, { message }, undefined, true);

// ================================================
// Fetch Cibo Locations' Imagery Set
// ================================================
export const fetchCiboImageries = () => action(CiboActionType.FETCH_CIBO_IMAGERIES_REQUEST);

export const fetchCiboImageriesSuccess = () => action(CiboActionType.FETCH_CIBO_IMAGERIES_SUCCESS);

export const fetchCiboImageriesFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_IMAGERIES_FAILURE, { message }, undefined, true);

export const setCiboImageries = (imagerySets: CiboImagerySet[]) =>
  action(CiboActionType.SET_CIBO_IMAGERIES, { imagerySets });

export const fetchCiboImagery = (locationId: number) =>
  action(CiboActionType.FETCH_CIBO_IMAGERY_REQUEST, { locationId });

export const fetchCiboImagerySuccess = () => action(CiboActionType.FETCH_CIBO_IMAGERY_SUCCESS);

export const fetchCiboImageryFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_IMAGERY_FAILURE, { message }, undefined, true);

export const setCiboImagery = (imagerySet: CiboImagerySet) =>
  action(CiboActionType.SET_CIBO_IMAGERY, { imagerySet });

// ================================================
// Get Cibo Location Status
// ================================================
export const fetchCiboLocationStatus = (locationId: number) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_STATUS_REQUEST, { locationId });

export const fetchCiboLocationStatusSuccess = () => action(CiboActionType.FETCH_CIBO_LOCATION_STATUS_SUCCESS);

export const fetchCiboLocationStatusFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_STATUS_FAILURE, { message }, undefined, true);

export const setCiboLocationStatus = (locationId: number, status: CiboLocationStatus) =>
  action(CiboActionType.SET_CIBO_LOCATION_STATUS, { locationId, status });

// ================================================
// Fetch Cibo Location Data Points
// ================================================
export const fetchCiboLocationDataPoints = (locationId: number, startDateMs: number, endDateMs: number) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_DATA_POINTS_REQUEST, { locationId, startDateMs, endDateMs });

export const fetchCiboLocationDataPointsSuccess = () => action(CiboActionType.FETCH_CIBO_LOCATION_DATA_POINTS_SUCCESS);

export const fetchCiboLocationDataPointsFailure = (message: string) =>
  action(CiboActionType.FETCH_CIBO_LOCATION_DATA_POINTS_FAILURE, { message }, undefined, true);

export const setCiboLocationDataPoints = (dataPoints: CiboLocationDataPoint[]) =>
  action(CiboActionType.SET_CIBO_LOCATION_DATA_POINTS, { dataPoints });
