import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from 'antd';
import React, { useEffect } from 'react';

import LoadingSpinner from 'components/composites/LoadingSpinner';

interface Props {
  children: JSX.Element;
}

/**
 * Wait for Auth0 SDK to initialise and handle any errors with the isLoading
 * and error states.
 */
const Auth0LoadingGate = ({ children }: Props) => {
  const { isLoading, error, handleRedirectCallback } = useAuth0();

  useEffect(() => {
    async function redirect() {
      try {
        const { appState } = await handleRedirectCallback();
        window.location.href = appState.target;
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
    redirect();
  }, [handleRedirectCallback]);

  if (error) {
    const { message } = error;
    if (message === 'Invalid state') {
      return <LoadingSpinner useLogo />;
    }
    return <Alert style={{ margin: 10 }} message={message} type="error" />;
  }

  if (isLoading) {
    return <LoadingSpinner useLogo />;
  }

  return children;
};

export default Auth0LoadingGate;
