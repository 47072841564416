import axios from 'axios';
import queryString from 'query-string';

import IPCamera, {
  IPCameraCapabilities,
  IPCameraEditableFields,
  IPCameraEvent,
  IPCameraMetadata,
  IPCameraPTZPresetEditableFields,
  IPCameraPhoto,
  IPCameraPreview,
  PTZControl,
  PTZPreset
} from 'types/models/ip-camera';
import { APIResponseParsed, PaginatedResponse } from 'types/response';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

// ================================================
// TYPES
// ================================================
export type FetchIPCameraPhotosParams = {
  page: number;
  pageSize: number;
  order: 'ASC' | 'DESC';
  startDate?: string;
  endDate?: string;
};

// ================================================
// REQUESTS
// ================================================
async function fetchIPCameras(
  enterpriseId: number,
  siteId?: number
): Promise<APIResponseParsed<IPCamera[]>> {
  const enterpriseIdFilter = `landwatchIntegration.enterpriseId::eq::${enterpriseId}`;
  const siteIdFilter = `siteId::eq::${siteId}`;

  const path = queryString.stringifyUrl({
    url: 'ip-camera',
    query: {
      filter: [enterpriseIdFilter, ...siteId ? [siteIdFilter] : []],
      join: 'preview'
    }
  });

  try {
    const response = await getRequest<IPCamera[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchIPCameraById(id: number): Promise<APIResponseParsed<IPCamera>> {
  const path = `ip-camera/${id}`;
  try {
    const response = await getRequest<IPCamera>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchIPCameraPhotos(
  ipCameraId: number,
  params: FetchIPCameraPhotosParams
): Promise<APIResponseParsed<PaginatedResponse<IPCameraPhoto>>> {
  const path = `ip-cameras/${ipCameraId}/images`;
  try {
    const response = await getRequest<PaginatedResponse<IPCameraPhoto>>(path, { params });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchIPCameraONVIFStateById(id: number): Promise<APIResponseParsed<IPCameraMetadata>> {
  const path = `ip-camera/${id}/onvif-state`;
  try {
    const response = await getRequest<IPCameraMetadata>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchIPCameraCapabilities(
  id: number,
  fields?: (keyof IPCameraCapabilities)[]
): Promise<APIResponseParsed<Partial<IPCameraCapabilities>>> {
  const path = `ip-camera/${id}/capabilities`;
  try {
    const response = await getRequest<Partial<IPCameraCapabilities>>(path, { params: { fields } });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function takeIPCameraPreview(id: number): Promise<APIResponseParsed<IPCameraPreview>> {
  const path = `ip-camera/${id}/take-preview`;
  try {
    const response = await postRequest<IPCameraPreview, undefined>(path, undefined);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function sendIPCameraControl(id: number, control: PTZControl): Promise<APIResponseParsed<'OK'>> {
  const path = `ip-camera/${id}/control`;
  try {
    await postRequest<unknown, PTZControl>(path, control);
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
};

async function fetchIPCameraEvents(
  ipCameraId: number,
  startDate: string,
  endDate: string
): Promise<APIResponseParsed<IPCameraEvent[]>> {
  const queryParams = queryString.stringify({
    startDate,
    endDate
  });

  const path = `ip-camera/${ipCameraId}/events?${queryParams}`;

  try {
    const response = await getRequest<IPCameraEvent[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function editIPCamera(id: number, values: IPCameraEditableFields): Promise<APIResponseParsed<IPCamera>> {
  const path = `ip-camera/${id}`;
  try {
    const response = await patchRequest<IPCamera, IPCameraEditableFields>(path, values);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function deleteIPCamera(id: number): Promise<APIResponseParsed<'OK'>> {
  const path = `ip-camera/${id}`;
  try {
    await deleteRequest<unknown>(path);
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function takeIPCameraSnapshot(ipCameraId: number): Promise<APIResponseParsed<IPCameraPhoto>> {
  const path = `ip-camera/${ipCameraId}/take-snapshot`;
  try {
    const response = await postRequest<IPCameraPhoto, undefined>(path, undefined);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchIPCameraPTZPresets(
  enterpriseId: number,
  ipCameraId: number
): Promise<APIResponseParsed<PTZPreset[]>> {
  const enterpriseIdFilter = `landwatchIntegration.enterpriseId::eq::${enterpriseId}`;

  const path = queryString.stringifyUrl({
    url: `ip-camera/${ipCameraId}/presets`,
    query: {
      filter: [enterpriseIdFilter]
    }
  });
  try {
    const response = await getRequest<PTZPreset[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function createIPCameraPTZPreset(ipCameraId: number): Promise<APIResponseParsed<PTZPreset>> {
  const path = `ip-camera/${ipCameraId}/presets/create`;
  try {
    const response = await postRequest<PTZPreset, undefined>(path, undefined);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function updateIPCameraPTZPreset(
  ipCameraId: number,
  presetId: number,
  values: IPCameraPTZPresetEditableFields
): Promise<APIResponseParsed<PTZPreset>> {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}`;
  try {
    const response = await patchRequest<PTZPreset, IPCameraPTZPresetEditableFields>(path, values);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function deleteIPCameraPTZPreset(ipCameraId: number, presetId: number): Promise<APIResponseParsed<'OK'>> {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}`;
  try {
    await deleteRequest<unknown>(path);
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function activateIPCameraPTZPreset(ipCameraId: number, presetId: number): Promise<APIResponseParsed<'OK'>> {
  const path = `ip-camera/${ipCameraId}/presets/${presetId}/activate`;
  try {
    await postRequest<unknown, undefined>(path, undefined);
    return { data: 'OK', error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

// Grouping these functions for a namespace export, rather than named exports,
// to avoid naming collisions with action creators
export default {
  activateIPCameraPTZPreset,
  createIPCameraPTZPreset,
  deleteIPCamera,
  deleteIPCameraPTZPreset,
  editIPCamera,
  fetchIPCameraById,
  fetchIPCameraCapabilities,
  fetchIPCameraEvents,
  fetchIPCameraONVIFStateById,
  fetchIPCameraPTZPresets,
  fetchIPCameraPhotos,
  fetchIPCameras,
  sendIPCameraControl,
  takeIPCameraPreview,
  takeIPCameraSnapshot,
  updateIPCameraPTZPreset
};