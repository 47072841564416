import React from 'react';

import BackOfficeUserCreateButton from 'components/buttons/BackOfficeUserCreateButton';
import BackOfficeUserCreateDrawer from 'components/drawers/BackOfficeUserCreateDrawer';
import BackOfficeUserEditDrawer from 'components/drawers/BackOfficeUserEditDrawer';
import BackOfficeSection from 'components/features/backOffice/BackOfficeSection';
import BackOfficeInvitationsRequest from 'components/requests/BackOfficeInvitationsRequest';
import BackOfficeInvitationsTable from 'components/tables/BackOfficeInvitationsTable';

export default function BackOfficeInvitations() {
  return (
    <BackOfficeSection
      actions={<BackOfficeUserCreateButton />}
      table={<BackOfficeInvitationsTable />}
      // filterSetName={FilterSetName.BACK_OFFICE_INVITATIONS}
      searchInputPlaceholder="Search by email"
    >
      <BackOfficeInvitationsRequest />
      <BackOfficeUserCreateDrawer />
      <BackOfficeUserEditDrawer />
      {/* <BackOfficeFilterDrawer
        filterSetName={FilterSetName.BACK_OFFICE_INVITATIONS}
      >
        <BackOfficeInvitationFilterForm />
      </BackOfficeFilterDrawer> */}
    </BackOfficeSection>
  );
}
