enum BackOfficeInvitationsActionType {
  LOAD_BACK_OFFICE_INVITATIONS_REQUEST = 'LOAD_BACK_OFFICE_INVITATIONS_REQUEST',
  LOAD_BACK_OFFICE_INVITATIONS_SUCCESS = 'LOAD_BACK_OFFICE_INVITATIONS_SUCCESS',
  LOAD_BACK_OFFICE_INVITATIONS_FAILURE = 'LOAD_BACK_OFFICE_INVITATIONS_FAILURE',

  LOAD_BACK_OFFICE_USER_INVITATIONS_REQUEST = 'LOAD_BACK_OFFICE_USER_INVITATIONS_REQUEST',
  LOAD_BACK_OFFICE_USER_INVITATIONS_SUCCESS = 'LOAD_BACK_OFFICE_USER_INVITATIONS_SUCCESS',
  LOAD_BACK_OFFICE_USER_INVITATIONS_FAILURE = 'LOAD_BACK_OFFICE_USER_INVITATIONS_FAILURE',

  ADD_BACK_OFFICE_INVITATION_REQUEST = 'ADD_BACK_OFFICE_INVITATION_REQUEST',
  ADD_BACK_OFFICE_INVITATION_SUCCESS = 'ADD_BACK_OFFICE_INVITATION_SUCCESS',
  ADD_BACK_OFFICE_INVITATION_FAILURE = 'ADD_BACK_OFFICE_INVITATION_FAILURE',

  REMOVE_BACK_OFFICE_INVITATION_REQUEST = 'REMOVE_BACK_OFFICE_INVITATION_REQUEST',
  REMOVE_BACK_OFFICE_INVITATION_SUCCESS = 'REMOVE_BACK_OFFICE_INVITATION_SUCCESS',
  REMOVE_BACK_OFFICE_INVITATION_FAILURE = 'REMOVE_BACK_OFFICE_INVITATION_FAILURE',

  RESEND_BACK_OFFICE_INVITATION_REQUEST = 'RESEND_BACK_OFFICE_INVITATION_REQUEST',
  RESEND_BACK_OFFICE_INVITATION_SUCCESS = 'RESEND_BACK_OFFICE_INVITATION_SUCCESS',
  RESEND_BACK_OFFICE_INVITATION_FAILURE = 'RESEND_BACK_OFFICE_INVITATION_FAILURE'
}

export default BackOfficeInvitationsActionType;
