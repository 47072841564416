import { ONE_SECOND_IN_MS } from 'constants/time';
import { IPCameraEvent } from 'types/models/ip-camera';

/**
 * Merge IP Camera events with overlap to avoid entry duplication in event list
 *
 * @param existingEvents IP Camera events that are already in the state
 * @param eventsToAppend New IP Camera that are to be appended to the existing events
 * @returns IP Camera events with the overlapping event merged and duration updated
 * @scope does not cover multiple overlapping events
 */
export const mergeOverlappedEventArray = (
  existingEvents: IPCameraEvent[],
  eventsToAppend: IPCameraEvent[]
): IPCameraEvent[] => {
  const existingEventsCopy: IPCameraEvent[] = [...existingEvents];
  if (eventsToAppend.length === 0) return existingEvents;

  const lastEvent: IPCameraEvent | undefined = existingEventsCopy.pop();
  if (!lastEvent) return eventsToAppend;

  const lastEventEndTimeInMs = new Date(lastEvent.time).getTime() + (lastEvent.duration * ONE_SECOND_IN_MS);

  const eventsToAppendFiltered = eventsToAppend.filter(event => {
    const eventStartInMs = new Date(event.time).getTime();
    if (eventStartInMs < lastEventEndTimeInMs) {
      const eventEndInMs = eventStartInMs + (event.duration * ONE_SECOND_IN_MS);
      lastEvent.duration = (eventEndInMs - new Date(lastEvent.time).getTime()) / ONE_SECOND_IN_MS;
      return false;
    } else {
      return true;
    }
  });

  return [
    ...existingEventsCopy,
    lastEvent,
    ...eventsToAppendFiltered
  ];
};