import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { AssetTypesState } from './types';

export const initialState = {} as AssetTypesState;

function authReducer(
  state: AssetTypesState = initialState,
  action: ApplicationActions
): AssetTypesState {
  switch (action.type) {
    case ActionTypes.SET_ASSET_TYPES:
      return action.payload;
    case ActionTypes.SET_ASSET_TYPE: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
