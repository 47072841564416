import axios from 'axios';
import get from 'lodash/get';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest, postRequest, putRequest } from 'utils/redux-saga-requests';

import {
  activateAccount,
  activateAccountFailure,
  activateAccountSuccess,
  deactivateAccount,
  editSiteFarmMappings,
  editSiteFarmMappingsFailure,
  editSiteFarmMappingsSuccess,
  loadFarms,
  loadFarmsFailure,
  loadFarmsSuccess
} from './actions';
import ActionTypes from './constants';

export function* requestActivateAccount(
  action: ReturnType<typeof activateAccount>
): SagaIterator {
  const {
    payload: {
      values: { enterpriseId, agriWebbCode, whiteLabel },
      onSuccess,
      onError
    }
  } = action;

  try {
    const response = yield call(
      postRequest,
      `integrations/agriwebb/account/enterprise/${enterpriseId}/activate`,
      {
        agriWebbCode,
        whiteLabel
      }
    );
    const { data } = response;
    yield all([put(activateAccountSuccess(data))]);
    onSuccess?.();
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong. :('
    );
    onError?.();
    yield put(activateAccountFailure(message, error));
  }
}

export function* watchActivateAccountRequest() {
  yield takeLatest(
    ActionTypes.ACTIVATE_ACCOUNT_REQUEST,
    requestActivateAccount
  );
}

export function* requestDeactivateAccount(
  action: ReturnType<typeof deactivateAccount>
): SagaIterator {
  const {
    payload: { enterpriseId, onSuccess, onError }
  } = action;

  try {
    yield call(
      putRequest,
      `integrations/agriwebb/account/enterprise/${enterpriseId}/deactivate`
    );
    onSuccess?.();
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong. :('
    );
    onError?.(message);
  }
}

export function* watchDeactivateAccountRequest() {
  yield takeLatest(
    ActionTypes.DEACTIVATE_ACCOUNT_REQUEST,
    requestDeactivateAccount
  );
}

export function* requestEditSiteFarmMappings(
  action: ReturnType<typeof editSiteFarmMappings>
): SagaIterator {
  const {
    payload: { values, onSuccess, onError }
  } = action;
  try {
    const response = yield call(
      postRequest,
      `integrations/agriwebb/enterprise/${values.enterpriseId}/site-mapping`,
      [...values.siteMapping]
    );
    const { data } = response;
    yield all([put(editSiteFarmMappingsSuccess(data))]);
    onSuccess?.();
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong. :('
    );
    yield put(editSiteFarmMappingsFailure(message, error));
    onError?.(message);
  }
}

export function* watchEditSiteFarmMappingRequest() {
  yield takeLatest(
    ActionTypes.EDIT_SITE_FARM_MAPPINGS_REQUEST,
    requestEditSiteFarmMappings
  );
}

export function* requestAgriWebbFarms(
  action: ReturnType<typeof loadFarms>
): SagaIterator {
  const {
    payload: { enterpriseId, onSuccess, onError }
  } = action;
  let agriWebbStatus;
  // 1. Check the enterprise is activated before fetching the farm list
  try {
    const {
      data: { enterpriseIntegration }
    } = yield call(
      getRequest,
      `/enterprise/${enterpriseId}?join=enterpriseIntegration`
    );
    agriWebbStatus = enterpriseIntegration?.agriWebbStatus;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadFarmsFailure(message, error));
    onError('Failed to activate the enterprise.');
  }
  // 2. Fetch farm list
  if (agriWebbStatus === 'enabled') {
    try {
      const { data: farms } = yield call(
        getRequest,
        `integrations/agriwebb/enterprise/${enterpriseId}/farm`,
        {},
        false
      );
      onSuccess(farms);
      yield put(loadFarmsSuccess(farms));
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      const message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
      yield put(loadFarmsFailure(message, error));
      onError(
        'Failed to load AgriWebb farms. Please restart the integration from AgriWebb.'
      );
    }
  } else {
    yield put(loadFarmsFailure('Failed to activate the enterprise.'));
  }
}

export function* watchLoadFarmsRequest() {
  yield takeLatest(ActionTypes.LOAD_FARMS_REQUEST, requestAgriWebbFarms);
}

export default function* agriWebbSaga() {
  yield all([
    fork(watchActivateAccountRequest),
    fork(watchDeactivateAccountRequest),
    fork(watchEditSiteFarmMappingRequest),
    fork(watchLoadFarmsRequest)
  ]);
}
