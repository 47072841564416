export const PAGE_SIZE = {
  CAMERA_MEDIA_LIST: 10,
  DEFAULT_LIST_IN_FORM: 5,
  DEFAULT_TABLE: 20,
  SAFETY_CHECK_IN_TABLE: 10,
  SUBSCRIPTION_TABLE: 5
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}