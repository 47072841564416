export const HEADER_HEIGHT_ON_MOBILE = 57;

// base styles
const MIN_WIDTH_TABLET = 480;
const MIN_WIDTH_DESKTOP = 1280;

// derived styles
export const MAX_WIDTH_MOBILE = MIN_WIDTH_TABLET - 1;
export const MAX_WIDTH_TABLET = MIN_WIDTH_DESKTOP - 1; // TODO: start applying this instead of the legacy below

// keeping the following until we have time for re-testing
// follow-up ticket: https://farmbot.atlassian.net/browse/FMBT-3897
const LEGACY_MIN_WIDTH_MOBILE_LANDSCAPE = 576;
const LEGACY_MIN_WIDTH_TABLET = 768;
const LEGACY_MIN_WIDTH_TABLET_LARGE = 992;

export const LEGACY_MAX_WIDTH_MOBILE_PORTRAIT = LEGACY_MIN_WIDTH_MOBILE_LANDSCAPE - 1;
export const LEGACY_MAX_WIDTH_MOBILE_LANDSCAPE = LEGACY_MIN_WIDTH_TABLET - 1;
export const LEGACY_MAX_WIDTH_TABLET_MEDIUM = LEGACY_MIN_WIDTH_TABLET_LARGE - 1;