import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Enterprise, { EnterpriseId } from 'types/models/enterprise';

import ActionTypes from './constants';
import { BackOfficeEnterpriseInput } from './types';

export const loadBackOfficeEnterprises = () =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISES_REQUEST);

export const loadBackOfficeEnterprisesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_ENTERPRISES_SUCCESS, { response });

export const loadBackOfficeEnterprisesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const addBackOfficeEnterprise = (
  values: BackOfficeEnterpriseInput,
  onSuccess: () => void
) =>
  action(ActionTypes.ADD_BACK_OFFICE_ENTERPRISE_REQUEST, { values, onSuccess });

export const addBackOfficeEnterpriseSuccess = (response: AxiosResponse) =>
  action(ActionTypes.ADD_BACK_OFFICE_ENTERPRISE_SUCCESS, { response });

export const addBackOfficeEnterpriseFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.ADD_BACK_OFFICE_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editBackOfficeEnterprise = (
  enterpriseId: EnterpriseId,
  values: Record<string, unknown>
) =>
  action(ActionTypes.EDIT_BACK_OFFICE_ENTERPRISE_REQUEST, {
    enterpriseId,
    values
  });

export const editBackOfficeEnterpriseSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_BACK_OFFICE_ENTERPRISE_SUCCESS, { response });

export const editBackOfficeEnterpriseFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.EDIT_BACK_OFFICE_ENTERPRISE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeEnterprises = (payload: {
  total: number;
  data: Record<string, Enterprise>;
  ids: EnterpriseId[];
}) => action(ActionTypes.SET_BACK_OFFICE_ENTERPRISES, payload);
