import { action } from 'typesafe-actions';

import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { ConnectedSamplePointLocale } from 'types/models/samplePointsStatistic';

import ControlPointActionType from './constants';
import {
  AutomaticControlType,
  MachineControlAction,
  MachineControlActionRequestResponse,
  MachineControlActionType
} from './types';

export const loadControlPointStatus = (samplePointId: SamplePointId) =>
  action(ControlPointActionType.LOAD_CONTROL_POINT_STATUS_REQUEST, {
    samplePointId
  });

export const loadControlPointStatusFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ControlPointActionType.LOAD_CONTROL_POINT_STATUS_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const sendMachineControlAction = (
  samplePointId: SamplePointId,
  machineControlAction: MachineControlAction,
  onSuccess: () => void,
  onError: (errorMsg: string) => void
) =>
  action(ControlPointActionType.SEND_MACHINE_CONTROL_ACTION_REQUEST, {
    samplePointId,
    machineControlAction,
    onSuccess,
    onError
  });

export const sendMachineControlActionSuccess = (
  samplePointId: SamplePointId,
  actionRequest: MachineControlActionType,
  { actionRequestId, controlPointId }: MachineControlActionRequestResponse
) =>
  action(ControlPointActionType.SEND_MACHINE_CONTROL_ACTION_SUCCESS, {
    samplePointId,
    actionRequest,
    actionRequestId,
    controlPointId
  });

export const sendMachineControlActionFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ControlPointActionType.SEND_MACHINE_CONTROL_ACTION_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const patchControlPoint = (
  samplePoint: SamplePoint,
  config: {
    controlType: AutomaticControlType;
    destinationSamplePoint?: ConnectedSamplePointLocale;
    sourceSamplePoint?: ConnectedSamplePointLocale;
    startTime: string | null;
    stopTime: string | null;
  },
  onSuccess: (samplePoint: SamplePoint, msg?: string) => void,
  onError: (errorMsg?: string) => void
) =>
  action(ControlPointActionType.PATCH_CONTROL_POINT_REQUEST, {
    samplePoint,
    config,
    onSuccess,
    onError
  });

export const patchControlPointSuccess = (samplePointId: SamplePointId) =>
  action(ControlPointActionType.PATCH_CONTROL_POINT_SUCCESS, {
    samplePointId
  });

export const patchControlPointFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ControlPointActionType.PATCH_CONTROL_POINT_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );

export const getControlPointActivityLog = (samplePointId: SamplePointId) =>
  action(ControlPointActionType.GET_CONTROL_POINT_ACTIVITY_LOG_REQUEST, {
    samplePointId
  });

export const getControlPointActivityLogSuccess = (
  samplePointId: SamplePointId,
  activityLog: any
) =>
  action(ControlPointActionType.GET_CONTROL_POINT_ACTIVITY_LOG_SUCCESS, {
    samplePointId,
    activityLog
  });

export const getControlPointActivityLogFailure = (
  samplePointId: SamplePointId,
  message: string,
  error?: any
) =>
  action(
    ControlPointActionType.GET_CONTROL_POINT_ACTIVITY_LOG_FAILURE,
    { samplePointId, message, error },
    undefined,
    true
  );
