import { Alert } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import { useBackOfficeDevice } from 'store/modules/backOfficeDevice/hooks';
import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import { DeviceId } from 'types/models/device';

const actions = ['LOAD_BACK_OFFICE_DEVICE'];

export const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  children: JSX.Element;
  deviceId: DeviceId | undefined;
}

function BackOfficeDeviceLoadingGate({
  children,
  deviceId
}: Props): JSX.Element {
  const backOfficeDevice = useBackOfficeDevice();
  const errorMessage = useSelector(selectErrorMessage);

  if (backOfficeDevice && backOfficeDevice.id === deviceId) {
    return children;
  }

  if (errorMessage) {
    return <Alert message={errorMessage} type="error" />;
  }

  return <LoadingSpinner />;
}

export default BackOfficeDeviceLoadingGate;
