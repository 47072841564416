import { Button, Col, Form, Row } from 'antd';
import isEqual from 'lodash/isEqual';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DefaultDrawer from 'components/composites/DefaultDrawer';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import {
  makeSelectRouterQueryStringFilterSet,
  selectRouterQueryStringDrawerFilter
} from 'store/modules/routerUtils/selectors';
import { FilterSetName } from 'store/modules/routerUtils/types';

interface Props {
  filterSetName: FilterSetName;
  children: React.ReactElement;
}

function BackOfficeFilterDrawer({
  filterSetName,
  children
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const drawerFilter = useSelector(selectRouterQueryStringDrawerFilter);

  const selectRouterQueryStringFilterSet = useMemo(
    () => makeSelectRouterQueryStringFilterSet(filterSetName),
    [filterSetName]
  );

  const filterSet = useSelector(selectRouterQueryStringFilterSet, isEqual);

  const [form] = Form.useForm();

  const visible = useMemo(
    () => drawerFilter === filterSetName,
    [drawerFilter, filterSetName]
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const handleClose = useCallback(() => {
    form.resetFields();

    dispatch(
      appendValuesToQueryString({
        drawerFilter: undefined
      })
    );
  }, [form, dispatch]);

  const handleSubmit = useCallback(
    (values) => {
      const newQueryStringParams: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(values)) {
        newQueryStringParams[`filter.${filterSetName}.${key}`] = value;
      }

      dispatch(
        appendValuesToQueryString({
          ...newQueryStringParams,
          currentPage: undefined
        })
      );
    },
    [dispatch, filterSetName]
  );

  const handleClick = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  }, [form, handleSubmit]);

  const handleFinish = useCallback(
    (values) => {
      handleSubmit(values);
    },
    [handleSubmit]
  );

  const handleClearFiltersClick = useCallback(() => {
    const fieldsValue = form.getFieldsValue();

    const nextFieldsValue = Object.keys(fieldsValue).reduce(
      (accumulator: Record<string, undefined>, currentValue: string) => {
        accumulator[currentValue] = undefined;
        return accumulator;
      },
      {}
    );

    form.setFieldsValue(nextFieldsValue);
    handleClick();
  }, [form, handleClick]);

  const childrenWithFormProps = useMemo(
    () =>
      React.cloneElement(children, {
        form,
        onFinish: handleFinish,
        initialValues: filterSet
      }),
    [children, filterSet, form, handleFinish]
  );

  return (
    <DefaultDrawer
      id={filterSetName}
      placement="left"
      onClose={handleClose}
      visible={visible}
      width={300}
      title="Filter"
      footer={
        <Row gutter={24}>
          <Col span={12}>
            <Button block onClick={handleClearFiltersClick}>
              CLEAR FILTERS
            </Button>
          </Col>
          <Col span={12}>
            <Button block onClick={handleClick} type="primary">
              APPLY FILTERS
            </Button>
          </Col>
        </Row>
      }
    >
      {childrenWithFormProps}
    </DefaultDrawer>
  );
}

export default memo(BackOfficeFilterDrawer);
