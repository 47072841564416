import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import get from 'lodash/get';
import React, { memo } from 'react';

interface Props extends Pick<FormItemProps, 'name'> {
  // TODO: FMBT-5349: Add generics to children
  /** It uses the captured form value to generate the child components in the form. */
  children?: (value: any) => React.ReactNode | null;
}

/**
 * `FieldValue` gets the latest form value by the given name path. The form value is passed to
 *  `children()` you provided to generate the child components. It's a better alternative to `Form.useWatch()`
 *  which sometimes provides dated value.
 */
const FieldValue: React.NamedExoticComponent<Props> = memo(
  ({ name, children }: Props) => {
    if (!name) {
      return null;
    }

    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          get(prevValues, name) !== get(currentValues, name)
        }
      >
        {({ getFieldValue }) => {
          const fieldValue = getFieldValue(name);

          if (children) {
            return children(fieldValue);
          }

          return fieldValue;
        }}
      </Form.Item>
    );
  }
);

export default FieldValue;
