import classNames from 'classnames';
import React, { ReactElement } from 'react';

import './styles.less';

interface Props {
  children: ReactElement;
  noPadding?: boolean;
  /**
   * Remove padding used for providing space for Intercom widget.
   * Some screens need to handle this separately
   */
  noIntercomPadding?: boolean;
  className?: string;
}

/**
 * A wrapper for content that needs a fixed page header.
 */
function PageHeaderPusher({
  children,
  noPadding,
  noIntercomPadding,
  className
}: Props): ReactElement {
  return (
    <div className={classNames('PageHeaderPusher', className)}>
      <div
        className={classNames('PageHeaderPusher-inner', {
          'no-padding': noPadding,
          'no-intercom-padding': noIntercomPadding
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default PageHeaderPusher;
