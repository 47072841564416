import Notification, { NotificationEventType } from 'types/models/notification';

/**
 * Primarily a wrapper for parseNotificationEventType that also removes
 * notifications with unknown event types.
 */
export const sanitiseNotifications = (notifications: any): Notification[] => notifications
  .map((notification) => {
    const { eventType } = notification;

    if (eventType === NotificationEventType.SAMPLE_POINT_EVENT || eventType === NotificationEventType.IP_CAMERA_EVENT) {
      return notification;
    }

    return null;
  })
  .filter((notification) => notification !== null);