import { DAM_PRECISION } from 'constants/samplePoint';
import { LevelDisplayUnit, LiquidLevelMeasurementDirection } from 'types/models/samplePoint';
import convertUnit from 'utils/convert-unit';
import { UnitLevelSm } from 'utils/get-unit-by-country';

export function getDamLevel(
  levelCm: number,
  maxDepthCm: number | undefined,
  liquidLevelMeasurementDirection: LiquidLevelMeasurementDirection | undefined,
  toUnit: LevelDisplayUnit | UnitLevelSm
): number | null {
  let level = levelCm;

  if (liquidLevelMeasurementDirection === 'topDown') {
    if (maxDepthCm === undefined) return null;
    if ((maxDepthCm - levelCm) < 0) {
      level = 0;
    } else {
      level = maxDepthCm - levelCm;
    }
  }

  return convertUnit(
    { value: level, unit: { symbol: 'cm' } },
    { symbol: toUnit },
    DAM_PRECISION
  );
};
