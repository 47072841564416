import Card, { CardProps } from 'antd/lib/card';
import classNames from 'classnames';
import React, { memo } from 'react';

import './styles.less';

interface Props
  extends Pick<
    CardProps,
    | 'id'
    | 'style'
    | 'bodyStyle'
    | 'children'
    | 'className'
    | 'title'
    | 'extra'
    | 'bordered'
    | 'onClick'
  > {
  disabled?: boolean;
  standardMaxWidth?: boolean;
}

/**
 * A wrapper around the antd Card with our custom config applied.
 */
function DefaultCard({
  id,
  style,
  bodyStyle,
  children,
  className,
  title,
  extra,
  bordered,
  onClick,
  disabled = false,
  standardMaxWidth = false
}: Props): JSX.Element {
  return (
    <Card
      id={id}
      data-testid={id}
      style={style}
      bodyStyle={bodyStyle}
      className={classNames(
        'DefaultCard',
        {
          clickable: !!onClick,
          disabled,
          'standard-max-width': standardMaxWidth
        },
        className
      )}
      title={title}
      extra={extra}
      bordered={bordered}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </Card>
  );
}

export default memo(DefaultCard);
