import { EventSamplePoint, EventSite } from 'types/models/event';
import SamplePoint from 'types/models/samplePoint';
import Site from 'types/models/site';

const convertSiteToEventSite = (site: Site): EventSite => {
  return {
    id: site.id,
    enterpriseId: site.enterpriseId,
    timezoneCode: site.timezoneCode
  };
};

export const convertSamplePointToEventSamplePoint = (samplePoint: SamplePoint, site: Site): EventSamplePoint => {
  return {
    id: samplePoint.id,
    sid: samplePoint.sid,
    name: samplePoint.name,
    siteId: samplePoint.siteId,
    deviceId: samplePoint.deviceId,
    site: convertSiteToEventSite(site)
  };
};