import classNames from 'classnames';
import mapKeys from 'lodash/mapKeys';

/**
 * @param base - Base class name.
 * @param variants - Variants of base class name. Prefixed with 'base'.
 * @param extra - Additional classnames not prefixed with 'base'.
 * Example:
 * Input: base = "foo", variants = { bar: true, baz: false }, extra: "fizz"
 * Output: "foo foo-bar fizz"
 */
export default function classNamesWithBase(
  base: string,
  variants: Record<string, boolean>,
  className?: string
) {
  return classNames(
    base,
    mapKeys(variants, (_value, key) => `${base}-${key}`),
    className
  );
}
