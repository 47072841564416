import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import selectBackOfficeEnterpriseSites from './selectors';

export function useBackOfficeEnterpriseSites() {
  const backOfficeEnterpriseSites = useSelector(
    selectBackOfficeEnterpriseSites,
    isEqual
  );

  return backOfficeEnterpriseSites;
}

export default useBackOfficeEnterpriseSites;
