import React from 'react';

import {
  AGRIWEBB_ORGANISATION_ID_KEY,
  AGRIWEBB_ORGANIZATION_PARAM
} from 'constants/agriwebb';
import createAgriWebbAuthorizeUrl from 'utils/agriwebb/create-agriwebb-authorize-url';

/**
 * As the 1st step of AgriWebb integration, AgriWebb calls this URL with
 * our organisation code. We then redirect to api.agriwebb.com with the
 * organisation code provided.
 */
export default function InstallAgriWebb() {
  const searchParams = new URLSearchParams(document.location.search);
  const organizationId = searchParams.get(
    AGRIWEBB_ORGANIZATION_PARAM
  ) as string;
  sessionStorage.setItem(AGRIWEBB_ORGANISATION_ID_KEY, organizationId);
  const redirectTo = createAgriWebbAuthorizeUrl(organizationId);

  window.location.href = redirectTo;

  return <>Redirecting...</>;
}
