import { Input } from 'antd';
import React from 'react';

import AnchorMailto from 'components/atoms/AnchorMailto';
import DefaultFormItem, {
  DefaultFormItemProps
} from 'components/composites/DefaultFormItem';

const normalize = (value: string) => value.trim().toLowerCase();
const formatReadOnlyValue = (email: string) => <AnchorMailto email={email} />;

type FormItemEmailProps = Pick<
  DefaultFormItemProps,
  'readOnly' | 'label' | 'hidden'
>;

export default function FormItemEmail({
  readOnly,
  label = 'Email',
  hidden
}: FormItemEmailProps) {
  return (
    <DefaultFormItem
      name="email"
      hidden={hidden}
      label={label}
      readOnly={readOnly}
      normalize={normalize}
      formatReadOnlyValue={formatReadOnlyValue}
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          message: 'Please enter an email.'
        },
        {
          // RFC 5322. https://emailregex.com/
          pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Please enter a valid email.'
        }
      ]}
    >
      <Input
        placeholder="Email"
        onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : '')}
      />
    </DefaultFormItem>
  );
}
