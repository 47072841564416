import Modal, { ModalProps } from 'antd/lib/modal';
import classNames from 'classnames';
import React, { memo } from 'react';

import './styles.less';

export interface DefaultModalProps
  extends Pick<
    ModalProps,
    | 'className'
    | 'closable'
    | 'title'
    | 'width'
  > {
  children?: React.ReactNode;
  confirmText?: ModalProps['okText'];
  dismissText?: ModalProps['cancelText'];
  hasStackedActions?: boolean;
  hideDismissButton?: boolean;
  isConfirmButtonDangerous?: boolean;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonLoading?: boolean;
  isOpen?: ModalProps['open'];
  onConfirm?: ModalProps['onOk'];
  onDismiss?: ModalProps['onCancel'];
}

function DefaultModal({
  children,
  className,
  closable = false,
  confirmText,
  dismissText,
  hasStackedActions,
  hideDismissButton,
  isConfirmButtonDangerous,
  isConfirmButtonDisabled,
  isConfirmButtonLoading = false,
  isOpen,
  onConfirm,
  onDismiss,
  title,
  width
}: DefaultModalProps): JSX.Element {
  return (
    <Modal
      className={classNames(
        'DefaultModal',
        { 'DefaultModal-max-content': !width },
        { 'DefaultModal-stacked-actions': hasStackedActions },
        className
      )}
      title={title}
      open={isOpen}
      centered
      closable={closable}
      okText={confirmText}
      cancelText={dismissText || 'Cancel'}
      width={width}
      onOk={onConfirm}
      onCancel={onDismiss}
      okButtonProps={{
        className: 'DefaultButton',
        loading: isConfirmButtonLoading,
        disabled: isConfirmButtonDisabled,
        danger: isConfirmButtonDangerous
      }}
      cancelButtonProps={{
        className: classNames(
          'DefaultButton modal-dismiss-action',
          { 'display-none': hideDismissButton }
        )
      }}
    >
      {children}
    </Modal>
  );
}

export default memo(DefaultModal);
