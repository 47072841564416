import TagManager, { DataLayerArgs } from 'react-gtm-module';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { makeSelectAssetTypeById } from 'store/modules/assetTypes/selectors';
import { makeSelectDevice } from 'store/modules/devices/selectors';
import { selectCurrentEnterprise } from 'store/modules/enterprise/selectors';
import LocalUserSettingsActionType from 'store/modules/localUserSettings/constants';
import { selectMyUser } from 'store/modules/myUser/selectors';
import { selectSamplePointsState } from 'store/modules/samplePoints/selectors';
import { SamplePointsState } from 'store/modules/samplePoints/types';
import { AssetTypeCode } from 'types/models/asset-type';
import Device from 'types/models/device';
import Enterprise from 'types/models/enterprise';
import { Level } from 'types/models/event';
import SamplePoint from 'types/models/samplePoint';
import User from 'types/models/user';

import trackEvent from './actions';
import ActionTypes from './constants';
import { EventType, TrackingEvent } from './types';
import { setDefaultSite } from '../localUserSettings/actions';

type TrackingData = [DataLayerArgs | undefined];

type DataLayer = Record<string, string | number | boolean | undefined>;

function* getSensorType(assetTypeId: AssetTypeCode) {
  // Forcibly return 'Battery' for for Internal Sensor type
  // https://farmbot.atlassian.net/browse/FMBT-5399
  if (assetTypeId === AssetTypeCode.BATTERY) return 'Battery';

  const { name } = yield select(makeSelectAssetTypeById(assetTypeId));

  return name;
}

/**
 * Due to restrictions on the minimum length of ids in Segment, we will use
 * sids over ids wherever possible.
 * This is done in the tracking saga, so code dispatching tracking actions
 * will not need to know about this implementation detail.
 */
function* buildTrackingDataObjects({ type: eventType, data }: TrackingEvent) {
  if (eventType === EventType.USER_SIGNED_UP) {
    return [
      {
        dataLayer: {
          ...data,
          event: eventType
        }
      },
      undefined
    ];
  }
  const {
    sid: userId,
    firstName,
    lastName,
    email,
    username,
    createdAt,
    role,
    isSuperAdmin
  }: User = yield select(selectMyUser);
  const enterprise: Enterprise | undefined = yield select(
    selectCurrentEnterprise
  );
  const companyId = enterprise?.sid;
  const company = enterprise?.name;
  const roleType = isSuperAdmin ? 'superAdmin' : role;

  const dataLayer: DataLayer = {
    event: eventType,
    userId,
    firstName,
    lastName,
    email,
    username,
    createdAt,
    roleType,
    companyId,
    company,
    country: enterprise?.billingAddress?.country,
    status: data.status
  };

  switch (eventType) {
    case EventType.SET_USER:
      return [{ dataLayer }, { userId }];
    case EventType.SET_ENTERPRISE:
      return [
        {
          dataLayer: {
            ...data,
            event: eventType,
            id: enterprise?.sid,
            country: enterprise?.billingAddress?.country
          }
        },
        undefined
      ];
    case EventType.SET_SITE:
      return [
        {
          dataLayer: {
            ...data,
            event: eventType
          }
        },
        undefined
      ];
    case EventType.NOTIFICATION_ACKNOWLEDGED: {
      const { eventLevel, eventMessage } = data;
      dataLayer.alertType = eventMessage;
      dataLayer.alertSeverity = Level[eventLevel as Level];
      return [{ dataLayer }, undefined];
    }
    case EventType.SENSOR_VIEWED: {
      dataLayer.sensorType = yield getSensorType(data.assetTypeId);
      dataLayer.samplePointId = data.samplePointId;
      dataLayer.sensorLocation = data.sensorLocation;
      dataLayer.entryPoint = data.entryPoint;
      dataLayer.action = data.action;
      dataLayer.actionAttributes = data.actionAttributes;
      return [{ dataLayer }, undefined];
    }
    case EventType.SENSOR_SETTINGS_CLICKED:
    case EventType.SENSOR_ACTION_REQUESTED:
    case EventType.SENSOR_ACTION_AUTOMATED: {
      dataLayer.sensorType = yield getSensorType(data.assetTypeId);
      dataLayer.samplePointId = data.samplePointId;
      dataLayer.sensorLocation = data.sensorLocation;
      dataLayer.entryPoint = data.entryPoint;
      dataLayer.action = data.action;
      dataLayer.actionAttributes = data.actionAttributes;
      dataLayer.buttonName = data.buttonName;
      return [{ dataLayer }, undefined];
    }
    // Add monitorModel, monitorBackhaul, fallthrough
    // eslint-disable-next-line no-fallthrough
    case EventType.SENSOR_SET_UP: {
      const { samplePointId } = data;
      const samplePoints: SamplePointsState = yield select(
        selectSamplePointsState
      );
      const device: Device = yield select(
        makeSelectDevice(samplePoints[samplePointId].deviceId)
      );
      dataLayer.monitorModel = device.model;
      dataLayer.monitorBackhaul = device.backhaulDesc;
    }
    // Add sensorType, fallthrough
    case EventType.SENSOR_EXPORTED:
    case EventType.SENSOR_PRINTED: {
      dataLayer.sensorType = yield getSensorType(data.assetTypeId);
      return [{ dataLayer }, undefined];
    }
    case EventType.USER_JOINED_ENTERPRISE:
      return [
        {
          dataLayer: {
            ...data,
            event: eventType,
            userId: dataLayer.userId,
            country: dataLayer.country
          }
        },
        undefined
      ];
    case EventType.USER_INVITED:
      return [
        {
          dataLayer: {
            ...data,
            event: eventType,
            inviterId: dataLayer.userId,
            inviterEmail: dataLayer.email,
            country: dataLayer.country
          }
        },
        undefined
      ];
    // Add rest of the common props.
    case EventType.USER_REMOVED:
    case EventType.SITE_CREATED:
    case EventType.SITE_REMOVED:
    case EventType.LOG_OUT:
      return [{ dataLayer }, undefined];
    default:
      return [undefined, undefined];
  }
}

export function* sendTrackingEvent({
  payload: { event }
}: ReturnType<typeof trackEvent>) {
  const [tagManagerData]: TrackingData = yield call(
    buildTrackingDataObjects,
    event
  );

  if (tagManagerData) {
    yield call(TagManager.dataLayer, tagManagerData);
  }
}

function* trackSiteSwitch({
  payload: currentSite
}: ReturnType<typeof setDefaultSite>) {
  const enterprise: Enterprise | undefined = yield select(
    selectCurrentEnterprise
  );
  const samplePoints: SamplePointsState = yield select(selectSamplePointsState);
  yield put(
    trackEvent({
      type: EventType.SET_SITE,
      data: {
        groupType: 'site',
        groupValue: `${enterprise?.name} - ${currentSite.name}`,
        name: currentSite.name,
        id: currentSite.sid,
        numberOfSensors: Object.values(samplePoints).filter(
          (sp: SamplePoint) => sp.siteId === currentSite.id
        )?.length,
        siteSize: currentSite.siteSize
      }
    })
  );
}

function* watchTrackingEvent() {
  yield takeEvery(ActionTypes.TRACK_EVENT, sendTrackingEvent);
}

function* watchSiteSwitch() {
  yield takeLatest(
    LocalUserSettingsActionType.SET_LOCAL_USER_SETTINGS_DEFAULT_SITE,
    trackSiteSwitch
  );
}

export default function* trackingSaga() {
  yield all([fork(watchTrackingEvent), fork(watchSiteSwitch)]);
}
