// Adapted from https://attacomsian.com/blog/javascript-download-file
export default function downloadFile(
  fileName: string,
  data: BlobPart,
  mimeType = ''
) {
  // Create a Blob object
  const blob = new Blob([data], { type: mimeType });

  // Create an object URL
  const url = URL.createObjectURL(blob);

  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);

  // Release the object URL
  URL.revokeObjectURL(url);
}
