import moment from 'moment-timezone';
import { delay, put } from 'redux-saga/effects';

import { secondsToMilliseconds } from 'utils/unit-conversions';

import setTime from './actions';

export default function* timeSaga() {
  while (true) {
    const time = new Date();
    const seconds = time.getSeconds();
    const unixTime = moment().unix();
    // Offset from closest minute in seconds
    const offset = secondsToMilliseconds(seconds);

    yield put(setTime(unixTime));
    // Wait until the next minute
    yield delay(60000 - offset);
  }
}
