enum AgriWebbActionType {
  ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE',
  ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS',
  DEACTIVATE_ACCOUNT_REQUEST = 'DEACTIVATE_ACCOUNT_REQUEST',
  EDIT_SITE_FARM_MAPPINGS_REQUEST = 'EDIT_SITE_FARM_MAPPINGS_REQUEST',
  EDIT_SITE_FARM_MAPPINGS_SUCCESS = 'EDIT_SITE_FARM_MAPPINGS_SUCCESS',
  EDIT_SITE_FARM_MAPPINGS_FAILURE = 'EDIT_SITE_FARM_MAPPINGS_FAILURE',
  LOAD_FARMS_REQUEST = 'LOAD_FARMS_REQUEST',
  LOAD_FARMS_SUCCESS = 'LOAD_FARMS_SUCCESS',
  LOAD_FARMS_FAILURE = 'LOAD_FARMS_FAILURE'
}

export default AgriWebbActionType;
