/**
 * Use a separate reducer to store all errorMessages instead of polluting all of
 * the other reducers.
 * check out {@link https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6}
 */

import get from 'lodash/get';

import { ApplicationActions } from 'store/types';

import ActionType from './constants';
import { ErrorState } from './types';

export const initialState = {};

function errorReducer(
  state: ErrorState = initialState,
  action: ApplicationActions
): ErrorState {
  const { type } = action;

  switch (type) {
    case ActionType.CLEAR_ERRORS: {
      if (action.payload?.actions?.length) {
        const clearedErrors = action.payload.actions.reduce(
          (acc, curr) => ({ ...acc, [curr.replace('_REQUEST', '')]: '' }),
          {}
        );
        return {
          ...state,
          ...clearedErrors
        };
      }
      return state;
    }
    default: {
      // To bypassing the type checker
      const payload = get(action, 'payload');

      const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

      // If it's not a *_REQUEST / *_FAILURE action, ignore it.
      if (!matches) return state;

      const [, requestName, requestState] = matches;
      // TODO: clean this up type-wise
      // @ts-ignore
      const message = payload?.message;

      const formattedMessage = Array.isArray(message)
        ? message.join(', ')
        : message;

      return {
        ...state,
        // Store errorMessage when receiving *_FAILURE.
        // Clear errorMessage when receiving *_REQUEST.
        [requestName]: requestState === 'FAILURE' ? formattedMessage : ''
      };
    }
  }
}

export default errorReducer;
