export enum FilterSetName {
  BACK_OFFICE_ENTERPRISES = 'backOfficeEnterprises',
  BACK_OFFICE_DEVICES = 'backOfficeDevices',
  BACK_OFFICE_USERS = 'backOfficeUsers',
  BACK_OFFICE_INVITATIONS = 'backOfficeInvitations'
}

export interface RouterUtilsState {
  storedSearches: Record<string, string>;
}
