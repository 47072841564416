import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import get from 'lodash/get';
import React, { memo } from 'react';

interface Props {
  /** `names` accept both single and multiple name paths, e.g., ["path1", "path1.1"]
   * and [["path1", "path1.1"],["path2", "path2.2"]] */
  names?: NamePath[];
  // TODO: FMBT-5349: Add generics to children
  /** It uses the captured form values to generates the child components in the form. */
  children: (values: any) => React.ReactElement | null;
}

/**
 * `FieldsValue` gets the latest form value(s) by the given name path(s). The form values are passed to
 *  `children()` you provided to generate the child components. It's a better alternative to `Form.useWatch()`
 *  which sometimes provides dated value.
 */
function FieldsValue({ names, children }: Props) {
  if (!names) {
    return null;
  }

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        names.some((name) => get(prevValues, name) !== get(currentValues, name))
      }
    >
      {({ getFieldsValue }) => {
        const fieldsValue = getFieldsValue(names);

        return children(fieldsValue);
      }}
    </Form.Item>
  );
}

export default memo(FieldsValue);
