/**
 * Selectors for accessing router state
 */
import { createMatchSelector } from 'connected-react-router';
import queryString from 'query-string';

import { ApplicationRootState } from 'store/types';
import createDeepEqualSelector from 'utils/create-deep-equal-selector';
import getPhoneNumberSearchablePart from 'utils/phone-number/get-phone-number-searchable-part';
import splitWithRemainder from 'utils/split-with-remainder';

import { FilterSetName } from './types';

const FILTER_NULL = 'null';
const FILTER_NOT_NULL = 'notnull';

const selectRouter = (state: ApplicationRootState) => state.router;
const selectRouterUtils = (state: ApplicationRootState) => state.routerUtils;

const selectPathname = createDeepEqualSelector(
  selectRouter,
  (router) => router.location.pathname
);

const selectMatch = createDeepEqualSelector(selectPathname, (pathname) =>
  createMatchSelector(pathname)
);

const selectRouterQueryString = createDeepEqualSelector(
  selectRouter,
  (router) => {
    const {
      currentPage,
      modal,

      selectedAsset,
      selectedTrigger,
      sortField,
      sortOrder,
      unitOfTime,
      numberOfDays,
      endDate,
      zoom,
      lat,
      lng,
      dateRangeType,
      focus,

      drawer,
      drawerIndex,
      drawerFilter,

      selectedDevice,
      selectedUser,
      selectedEnterprise,
      invitationEmail,
      search,
      enterpriseStatusFilter,
      ...remainingParameters
    } = queryString.parse(router.location.search, { arrayFormat: 'bracket' });

    // Manually convert parameters to values we expect.
    return {
      ...remainingParameters,
      currentPage: Number(currentPage) || undefined,
      modal,
      selectedAsset: Number(selectedAsset) || undefined,
      selectedTrigger: Number(selectedTrigger) || undefined,
      sortField,
      sortOrder,
      unitOfTime: unitOfTime ? `${unitOfTime}` : undefined,
      numberOfDays: Number(numberOfDays) || undefined,
      endDate: Number(endDate) || undefined,
      zoom: Number(zoom) || undefined,
      lat: Number(lat) || undefined,
      lng: Number(lng) || undefined,
      dateRangeType: dateRangeType ? `${dateRangeType}` : undefined,
      focus: focus ? `${focus}` : undefined,
      drawer: drawer ? `${drawer}` : undefined,
      drawerIndex: drawerIndex ? `${drawerIndex}` : undefined,
      drawerFilter: drawerFilter ? `${drawerFilter}` : undefined,

      selectedDevice: Number(selectedDevice) || undefined,
      selectedUser: Number(selectedUser) || undefined,
      selectedEnterprise: Number(selectedEnterprise) || undefined,
      invitationEmail: invitationEmail ? `${invitationEmail}` : undefined,

      search: search ? `${search}`.trim() : undefined,
      enterpriseStatusFilter: Array.isArray(enterpriseStatusFilter)
        ? enterpriseStatusFilter.map(Number)
        : undefined
    };
  }
);

const makeSelectRouterQueryStringValue = (
  param:
    | 'currentPage'
    | 'modal'
    | 'selectedDevice'
    | 'selectedUser'
    | 'selectedAsset'
    | 'selectedTrigger'
    | 'sortField'
    | 'sortOrder'
) =>
  createDeepEqualSelector(
    selectRouterQueryString,
    (routerQueryString) => routerQueryString[param]
  );

const makeSelectRouterQueryStringFilterSet = (filterSetName: FilterSetName) =>
  createDeepEqualSelector(selectRouter, (router) => {
    const values = queryString.parse(router.location.search, {
      arrayFormat: 'bracket'
    });
    const result: Record<string, string[]> = {};
    const keys = Object.keys(values);

    keys.forEach((key) => {
      const subKeys = splitWithRemainder(key, '.', 2);

      if (
        subKeys.length &&
        subKeys[0] === 'filter' &&
        subKeys[1] === filterSetName
      ) {
        const parameterName = subKeys[2];
        const value = values[key];

        if (value) {
          result[parameterName] = Array.isArray(value) ? value : [value];
        }
      }
    });

    return result;
  });

const selectRouterQueryStringSortField = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.sortField
);

const selectRouterQueryStringSortOrder = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.sortOrder
);

const selectRouterQueryStringCurrentPage = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.currentPage
);

const selectRouterQueryStringDateRangeType = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.dateRangeType
);

const selectRouterQueryStringFocus = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.focus
);

const selectRouterQueryStringEndDate = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.endDate
);

const selectRouterQueryStringZoom = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.zoom
);

const selectRouterQueryStringLat = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.lat
);

const selectRouterQueryStringLng = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.lng
);

const selectRouterQueryStringDrawer = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.drawer
);

const selectRouterQueryStringDrawerIndex = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.drawerIndex
);

const selectRouterQueryStringDrawerFilter = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.drawerFilter
);

const selectRouterQueryStringSelectedEnterprise = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.selectedEnterprise
);

const selectRouterQueryStringSelectedDevice = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.selectedDevice
);

const selectRouterQueryStringSelectedUser = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.selectedUser
);

const selectRouterQueryStringInvitationEmail = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.invitationEmail
);

const selectRouterQueryStringSearch = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.search
);

const selectRouterQueryStringEnterpriseStatusFilter = createDeepEqualSelector(
  selectRouterQueryString,
  (routerQueryString) => routerQueryString.enterpriseStatusFilter
);

const selectStoredSearches = createDeepEqualSelector(
  selectRouterUtils,
  (routerUtils) => routerUtils.storedSearches
);

const makeSelectStoredSearch = (pathname: string) =>
  createDeepEqualSelector(
    selectStoredSearches,
    (storedSearches) => storedSearches[pathname]
  );

const selectBackOfficeEnterprisesRequestParameters = createDeepEqualSelector(
  [
    selectRouterQueryString,
    makeSelectRouterQueryStringFilterSet(FilterSetName.BACK_OFFICE_ENTERPRISES)
  ],
  ({ currentPage, search, sortField, sortOrder }, filterSet) => {
    const filterSetKeys = Object.keys(filterSet);
    return {
      per_page: 20,
      page: currentPage || 1,
      sort: [
        `${sortField || 'name'},${sortOrder === 'descend' ? 'DESC' : 'ASC'}`,
        ...(sortField !== 'id' ? ['id,ASC'] : [])
      ],
      ...(search || filterSetKeys?.length
        ? {
          s: {
            $and: [
              ...(search
                ? [
                  {
                    $or: [{ name: { $contL: search } }]
                  }
                ]
                : []),
              ...(filterSetKeys?.length
                ? filterSetKeys.map((filterSetKey) => ({
                  [filterSetKey]: { $in: filterSet?.[filterSetKey] }
                }))
                : [])
            ]
          }
        }
        : {})
    };
  }
);

const selectBackOfficeUsersRequestParameters = createDeepEqualSelector(
  [
    selectRouterQueryString,
    makeSelectRouterQueryStringFilterSet(FilterSetName.BACK_OFFICE_USERS)
  ],
  ({ currentPage, search, sortField, sortOrder }, filterSet) => {
    const keywords = getPhoneNumberSearchablePart(search); // If it's not
    // a phone number, the string won't change.
    const filterSetKeys = Object.keys(filterSet);
    return {
      per_page: 20,
      page: currentPage || 1,
      sort: [
        `${sortField || 'firstName'},${sortOrder === 'descend' ? 'DESC' : 'ASC'
        }`,
        ...(sortField !== 'id' ? ['id,ASC'] : [])
      ],
      join: ['memberships'],
      ...(keywords || filterSetKeys?.length
        ? {
          s: {
            $and: [
              ...(keywords
                ? // Split search by spaces and search for each part
                // individually. This allows a user to enter a full name eg
                // 'John Citizen' and return results.
                keywords
                  .split(' ')
                  .filter((s) => !!s)
                  .map((keyword) => ({
                    $or: [
                      { firstName: { $contL: keyword } },
                      { lastName: { $contL: keyword } },
                      { email: { $contL: keyword } },
                      { username: { $contL: keyword } },
                      { mobileNumber: { $contL: keyword } }
                    ]
                  }))
                : []),
              ...(filterSetKeys?.length
                ? filterSetKeys.map((filterSetKey) => ({
                  [filterSetKey]: { $in: filterSet?.[filterSetKey] }
                }))
                : [])
            ]
          }
        }
        : {})
    };
  }
);

const selectBackOfficeDevicesRequestParameters = createDeepEqualSelector(
  [
    selectRouterQueryString,
    makeSelectRouterQueryStringFilterSet(FilterSetName.BACK_OFFICE_DEVICES)
  ],
  ({ currentPage, search, sortField, sortOrder }, filterSet) => {
    const filterSetKeys = Object.keys(filterSet);
    return {
      per_page: 20,
      page: currentPage || 1,
      sort: sortField
        ? [`${sortField},${sortOrder === 'descend' ? 'DESC' : 'ASC'}`]
        : [],
      ...(search || filterSetKeys?.length
        ? {
          s: {
            $and: [
              ...(search
                ? [
                  {
                    $or: [
                      { name: { $contL: search } },
                      { serialNumber: { $contL: search } }
                    ]
                  }
                ]
                : []),
              ...(filterSetKeys?.length
                ? filterSetKeys.map((filterSetKey) => {
                  const values = filterSet?.[filterSetKey];
                  const hasNull = values.includes(FILTER_NULL);
                  const hasNotNull = values.includes(FILTER_NOT_NULL);
                  // If the only filter condition is 'null'/'notnull',
                  // return empty object. Else, return object with all
                  // filter values except 'null'/'notnull'.
                  const conditions =
                    (hasNull || hasNotNull) && values.length === 1
                      ? {}
                      : {
                        $in:
                          hasNull || hasNotNull
                            ? values.filter(
                              (value) =>
                                value !== FILTER_NULL &&
                                value !== FILTER_NOT_NULL
                            )
                            : values
                      };
                  return {
                    [filterSetKey]:
                      // Don't support filtering by both 'null' & 'not null'
                      // simultaneously.
                      hasNull || hasNotNull
                        ? {
                          [hasNull ? '$isnull' : '$notnull']: true,
                          ...conditions
                        }
                        : conditions
                  };
                })
                : [])
            ]
          }
        }
        : {})
    };
  }
);

const selectBackOfficeInvitationsRequestParameters = createDeepEqualSelector(
  [
    selectRouterQueryString,
    makeSelectRouterQueryStringFilterSet(FilterSetName.BACK_OFFICE_INVITATIONS)
  ],
  ({ currentPage, search, sortField, sortOrder }, filterSet) => {
    const filterSetKeys = Object.keys(filterSet);

    // TODO: we should not have any
    const sort: any[] = [];
    const defaultSortParam = 'lastInviteDate,DESC';

    if (sortField) {
      if (sortField === 'lastInviteDate') {
        sort.push(`lastInviteDate,${sortOrder === 'descend' ? 'DESC' : 'ASC'}`);
      } else {
        sort.push(
          `${sortField},${sortOrder === 'descend' ? 'DESC' : 'ASC'}`,
          defaultSortParam
        );
      }
    } else {
      sort.push(defaultSortParam);
    }

    return {
      per_page: 20,
      page: currentPage || 1,
      sort,
      ...(search || filterSetKeys.length
        ? {
          s: {
            $and: [
              ...(search
                ? [
                  {
                    $or: [{ email: { $contL: search } }]
                  }
                ]
                : []),
              ...(filterSetKeys?.length
                ? filterSetKeys.map((filterSetKey) => ({
                  [filterSetKey]: { $in: filterSet?.[filterSetKey] }
                }))
                : [])
            ]
          }
        }
        : {})
    };
  }
);

export {
  FILTER_NULL,
  FILTER_NOT_NULL,
  selectPathname,
  selectMatch,
  selectRouter,
  selectRouterQueryString,
  makeSelectRouterQueryStringFilterSet,
  makeSelectRouterQueryStringValue,
  selectRouterQueryStringSortField,
  selectRouterQueryStringSortOrder,
  selectRouterQueryStringCurrentPage,
  selectRouterQueryStringDateRangeType,
  selectRouterQueryStringFocus,
  selectRouterQueryStringEndDate,
  selectRouterQueryStringZoom,
  selectRouterQueryStringLat,
  selectRouterQueryStringLng,
  selectRouterQueryStringDrawer,
  selectRouterQueryStringDrawerIndex,
  selectRouterQueryStringDrawerFilter,
  selectRouterQueryStringSelectedEnterprise,
  selectRouterQueryStringSelectedDevice,
  selectRouterQueryStringSelectedUser,
  selectRouterQueryStringInvitationEmail,
  selectRouterQueryStringSearch,
  selectRouterQueryStringEnterpriseStatusFilter,
  selectStoredSearches,
  makeSelectStoredSearch,
  selectBackOfficeEnterprisesRequestParameters,
  selectBackOfficeUsersRequestParameters,
  selectBackOfficeDevicesRequestParameters,
  selectBackOfficeInvitationsRequestParameters
};
