import { Button, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

import FarmbotLogoHorizontal from 'components/atoms/FarmbotLogoHorizontal';
import LogOutButton from 'components/buttons/LogOutButton';
import DefaultDrawer from 'components/composites/DefaultDrawer';
import FarmbotIcon from 'components/icons/FarmbotIcon';
import MainNavigationLogoWithPusher from 'components/navigation/MainNavigationLogoWithPusher';
import MainNavigationMenu from 'components/navigation/MainNavigationMenu';
import NavLinkWithStoredSearch from 'components/navigation/NavLinkWithStoredSearch';
import { HEADER_HEIGHT_ON_MOBILE, LEGACY_MAX_WIDTH_MOBILE_PORTRAIT } from 'style/constants';

function BackOfficeNavigation(): JSX.Element | null {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const hideDrawer = () => {
    setVisible(false);
  };
  const isMobile = useMediaQuery({ maxWidth: LEGACY_MAX_WIDTH_MOBILE_PORTRAIT });

  const { pathname } = useLocation();

  const selectedKey = pathname.split('/').slice(0, 3).join('/');

  const menuItems = [
    {
      label: 'Enterprises',
      route: '/back-office/enterprises'
    },
    {
      label: 'Devices',
      route: '/back-office/devices'
    },
    {
      label: 'Device Map',
      route: '/back-office/device-map'
    },
    {
      label: 'Users',
      route: '/back-office/users'
    },
    {
      label: 'Invitations',
      route: '/back-office/invitations'
    }
  ];

  return (
    <>
      {/* TODO: update this file to use DefaultPageHeader instead of
      Layout.Header, because DefaultPageHeader and PageHeaderPusher are
      synchronizing the height and the top, while this one is not */}
      <Layout.Header
        style={{
          background: 'rgb(255, 255, 255)',
          borderBottom: '1px solid rgb(236, 239, 241)',
          position: 'relative',
          height: isMobile ? HEADER_HEIGHT_ON_MOBILE : undefined
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {isMobile && (
            <>
              <Button
                onClick={showDrawer}
                type="text"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <FiMenu
                  size="20"
                  style={{
                    verticalAlign: 'middle',
                    position: 'relative',
                    top: -1
                  }}
                />
              </Button>
              <Link
                to="/back-office"
                style={{ marginLeft: 5, marginRight: 10 }}
              >
                <FarmbotIcon />
              </Link>
            </>
          )}
          {!isMobile && (
            <>
              <Link
                to="/back-office"
                style={{ marginLeft: 5, marginRight: 10 }}
              >
                <FarmbotLogoHorizontal />
              </Link>
              <Menu
                mode="horizontal"
                selectedKeys={[selectedKey]}
                style={{
                  lineHeight: '57px',
                  marginTop: '5px'
                }}
                onClick={hideDrawer}
              >
                {menuItems.map(({ route, label }) => (
                  <Menu.Item key={route}>
                    <NavLinkWithStoredSearch to={route}>
                      {label}
                    </NavLinkWithStoredSearch>
                  </Menu.Item>
                ))}
              </Menu>
            </>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <LogOutButton />
          </div>
        </div>
      </Layout.Header>
      <DefaultDrawer
        id="back-office-navigation-drawer"
        placement="left"
        closable={false}
        onClose={hideDrawer}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <MainNavigationLogoWithPusher>
          <MainNavigationMenu selectedKeys={[selectedKey]} onClick={hideDrawer}>
            {menuItems.map(({ route, label }) => (
              <Menu.Item key={route}>
                <NavLinkWithStoredSearch to={route}>
                  {label}
                </NavLinkWithStoredSearch>
              </Menu.Item>
            ))}
          </MainNavigationMenu>
        </MainNavigationLogoWithPusher>
      </DefaultDrawer>
    </>
  );
}

export default BackOfficeNavigation;
