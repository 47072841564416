import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import { shiftDateToTimezoneKeepDisplay } from 'utils/Date/shift-date-to-timezone-keep-display';

/**
 * Constructs the URL to fetch samples for CSV Export based on the provided sample point ID and date range
 * @param samplePointId - The ID of the sample point
 * @param samplePointTimezone - The timezone of the sample point
 * @param dateRange - The date range
 * @returns The constructed sample URL
 */
export const constructSampleUrlForCSVExport = (
  samplePointId: SamplePointId,
  samplePointTimezone: NonNullable<SamplePoint['siteTimezoneCode']>,
  dateRange: DateRange
) => {
  const startDateInSiteTimezone = shiftDateToTimezoneKeepDisplay(dateRange[0], samplePointTimezone);

  // If we don't get the next sample outside of the given time period,
  // the last date will always be under-reporting.
  const endDateInSiteTimezone = shiftDateToTimezoneKeepDisplay(dateRange[1].clone().add(1, 'day'), samplePointTimezone);

  const startDateURISafe = encodeURIComponent(startDateInSiteTimezone.format());
  const endDateURISafe = encodeURIComponent(endDateInSiteTimezone.format());

  return `samplepoint/${samplePointId}/sample?startDate=${startDateURISafe}&endDate=${endDateURISafe}`;
};