import { Tag } from 'antd';
import classNames from 'classnames';
import React from 'react';

import LearnMoreTooltip from 'components/atoms/LearnMoreTooltip';
import {
  DELAY_HELP_ARTICLE_LINK_FARMBOT,
  DELAY_HELP_ARTICLE_LINK_RANCHBOT,
  HIBERNATION_HELP_ARTICLE_LINK_FARMBOT,
  HIBERNATION_HELP_ARTICLE_LINK_RANCHBOT
} from 'constants/links';
import { DeviceStatus } from 'types/device.enum';
import { isRanchbot } from 'utils/is-x-bot';

import './styles.less';

interface StatusTagProps {
  status?: DeviceStatus;
  showTooltip?: boolean;
  showOnlineStatus?: boolean;
  asPlainText?: boolean;
  className?: string;
}

interface TagViewProps extends Pick<StatusTagProps, 'asPlainText' | 'className'> {
  text: string,
  variant?: string,
}

const TagView = ({ text, variant, asPlainText, className }: TagViewProps) => (
  asPlainText
    ? <div className={className}>{text}</div>
    : <Tag className={classNames('StatusTag', variant, className)}>{text}</Tag>
);

interface TagWithTooltipProps extends Pick<StatusTagProps, 'asPlainText' | 'className'> { }

const DelayTagWithTooltip = ({
  asPlainText,
  className
}: TagWithTooltipProps) => (
  <LearnMoreTooltip
    title="When a monitor is in DELAY status, it means we have not heard from
it in some time and it may need to be checked."
    link={
      isRanchbot()
        ? DELAY_HELP_ARTICLE_LINK_RANCHBOT
        : DELAY_HELP_ARTICLE_LINK_FARMBOT
    }
    showInfoIcon={false}
  >
    <TagView
      text="DELAY"
      variant="delayed"
      asPlainText={asPlainText}
      className={className}
    />
  </LearnMoreTooltip>
);

const HibernatingTagWithTooltip = ({
  asPlainText,
  className
}: TagWithTooltipProps) => (
  <LearnMoreTooltip
    title="When a monitor is in Hibernation status, it means it is in a power-saving mode and will not transmit."
    link={
      isRanchbot()
        ? HIBERNATION_HELP_ARTICLE_LINK_RANCHBOT
        : HIBERNATION_HELP_ARTICLE_LINK_FARMBOT
    }
    showInfoIcon={false}
  >
    <TagView
      text="HIBERNATION"
      variant="grey"
      asPlainText={asPlainText}
      className={className}
    />
  </LearnMoreTooltip>
);

/**
 * Show a tag with the device status
 * @param status Device status
 * @param showTooltip Defaults to false
 * @param showOnlineStatus Defaults to false
 * @param asPlainText Useful for compact containers, e.g. mobile header
 * @param className
 * @returns
 */
const StatusTag = ({
  status,
  showTooltip = false,
  showOnlineStatus = false,
  asPlainText,
  className
}: StatusTagProps) => {
  switch (status) {
    case DeviceStatus.ONLINE:
      return showOnlineStatus
        ? (
          <TagView
            text="ONLINE"
            asPlainText={asPlainText}
            className={className}
          />
        ) : null;
    case DeviceStatus.DELAYED:
      return showTooltip
        ? (
          <DelayTagWithTooltip
            asPlainText={asPlainText}
            className={className}
          />
        ) : (
          <TagView
            text="DELAY"
            variant="delayed"
            asPlainText={asPlainText}
            className={className}
          />
        );
    case DeviceStatus.HIBERNATING:
      return showTooltip
        ? (
          <HibernatingTagWithTooltip
            asPlainText={asPlainText}
            className={className}
          />
        ) : (
          <TagView
            text="HIBERNATION"
            variant="grey"
            asPlainText={asPlainText}
            className={className}
          />
        );
    case DeviceStatus.DECOMMISSIONED:
      return (
        <TagView
          text="DECOMMISSIONED"
          asPlainText={asPlainText}
          className={className}
        />
      );
    case DeviceStatus.TURNED_OFF:
      /**
       * For UI purposes (at this point in time—before Notification Centre)
       * system TURNED-OFF is visually "MUTED"
       */
      return (
        <TagView
          text="MUTED"
          variant="grey"
          asPlainText={asPlainText}
          className={className}
        />
      );
    case DeviceStatus.UNASSIGNED_FROM_ENTERPRISE:
      return (
        <TagView
          text="UNASSIGNED"
          variant="grey"
          asPlainText={asPlainText}
          className={className}
        />
      );
    case DeviceStatus.ASSIGNED_TO_ENTERPRISE:
      return (
        <TagView
          text="ASSIGNED"
          asPlainText={asPlainText}
          className={className}
        />
      );
    case DeviceStatus.UNKNOWN:
    default:
      return (
        <TagView
          text="UNKNOWN"
          variant="grey"
          asPlainText={asPlainText}
          className={className}
        />
      );
  }
};

export default StatusTag;
