import EventEmitter from 'events';

/**
 * Unused for now but will leave here for future use.
 *
 * This is a simple event emitter for the whole application that can be used for triggering actions
 * app-wide that may not be directly associated with states. For example, closing all overlays because
 * of a certain event.
 */

// ==============================
// TYPES
// ==============================
export enum ApplicationEvents {
  CLOSE_OVERLAYS = 'closeOverlays'
}

// ==============================
// EVENT EMITTER
// ==============================
export class ApplicationEventEmitter {
  private eventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  emit(event: ApplicationEvents, data?: any) {
    this.eventEmitter.emit(event, data);
  }

  on(event: ApplicationEvents, listener: (...args: any[]) => void) {
    this.eventEmitter.on(event, listener);
  }

  off(event: ApplicationEvents, listener: (...args: any[]) => void) {
    this.eventEmitter.off(event, listener);
  }
}
