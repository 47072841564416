import moment from 'moment-timezone';

import { DATE_TIME_FORMAT } from 'constants/time';

export const commonDateTimeWithTimezone = (
  date: number | string | Date,
  timezoneCode: string
) => {
  return moment(date).tz(timezoneCode).format(DATE_TIME_FORMAT.DEFAULT);
};

export const commonDateWithBrowserTimezone = (date: number | string | Date) => {
  const localtz = moment.tz.guess();
  return commonDateTimeWithTimezone(date, localtz);
};
