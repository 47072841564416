import { Col } from 'antd';
import React from 'react';

interface Props {
  children: React.ReactNode;
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
}

/***
 * Full/half width <Col>
 */
function FormItemCol({
  children,
  isFullSpanOnMobileHalfOnDesktop = false
}: Props): JSX.Element | null {
  const largeScreenColumnSpan = isFullSpanOnMobileHalfOnDesktop ? 12 : 24;

  return (
    <Col
      xs={24}
      sm={24}
      md={largeScreenColumnSpan}
      lg={largeScreenColumnSpan}
      xl={largeScreenColumnSpan}
    >
      {children}
    </Col>
  );
}

export default FormItemCol;
