import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import selectBackOfficeEnterprise from './selectors';

export function useBackOfficeEnterprise() {
  const backOfficeEnterprise = useSelector(selectBackOfficeEnterprise, isEqual);

  return backOfficeEnterprise;
}

export default useBackOfficeEnterprise;
