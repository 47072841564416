import { action } from 'typesafe-actions';

import { EnterpriseId } from 'types/models/enterprise';
import { SamplePointId } from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';

import ActionTypes from './constants';

// ================================================
// Download Devices and Sample Points CSV
// ================================================
export const downloadDevicesAndSamplePointsCSV = (
  enterpriseId: EnterpriseId,
  enterpriseName: string
) =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST, {
    enterpriseId,
    enterpriseName
  });

export const downloadDevicesAndSamplePointsCSVSuccess = () =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_SUCCESS);

export const downloadDevicesAndSamplePointsCSVFailure = () =>
  action(ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_FAILURE);

// ================================================
// Fetch Samples CSV Content
// ================================================
export const fetchSamplesCsvContent = (
  assetTypeName: string,
  dateRange: DateRange,
  samplePointId: SamplePointId,
  samplePointName: string,
  setLoading: (loading: boolean) => void
) =>
  action(ActionTypes.FETCH_SAMPLES_CSV_CONTENT_REQUEST, {
    assetTypeName,
    dateRange,
    samplePointId,
    samplePointName,
    setLoading
  });

export const fetchSamplesCsvContentSuccess = () => action(ActionTypes.FETCH_SAMPLES_CSV_CONTENT_SUCCESS);

export const fetchSamplesCsvContentFailure = () => action(ActionTypes.FETCH_SAMPLES_CSV_CONTENT_FAILURE);
