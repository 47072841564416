// This request is created because we can't join invitations (deletedAt is null) in GET /user/:id.
// https://github.com/nestjsx/crud/issues/433

import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { loadBackOfficeUserInvitations } from 'store/modules/backOfficeInvitations/actions';
import { makeSelectInvitationsByEmail } from 'store/modules/backOfficeInvitations/selectors';

interface Props {
  email?: string;
}

export default function BackOfficeUserInvitationsRequest({ email }: Props) {
  const dispatch = useDispatch();
  const invitations = useSelector(
    makeSelectInvitationsByEmail(email),
    shallowEqual
  );

  useEffect(() => {
    // If no invitations means we're in Users tab, we need to load this user's
    // invitations.
    // If has invitations means we're in Invitations tab, no need to double
    // fetch invitations for this user. Otherwise the invitation reducer will be
    // overwritten by this user's invitations only.
    if (email && !invitations.length) {
      dispatch(loadBackOfficeUserInvitations(email));
    }
  }, [dispatch, email, invitations.length]);

  return null;
}
