/**
 * Create the store
 */
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import localForage from 'localforage';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import rollbarMiddleware from './middleware/rollbar';
import migrations from './migrations';
import localUserSettingsReducer from './modules/localUserSettings/reducer';
import createRootReducer from './reducers';
import rootSaga from './sagas';
import type { ApplicationRootState } from './types';

export const reduxPersistWhitelist: (keyof ApplicationRootState)[] = [
  'appScaffold',
  'assetTypes',
  'countries',
  'enterprise',
  'devices',
  'myUser',
  'samplePoints',
  'samplePointsStatistics',
  'recentNotifications',
  'sites',
  'states',
  'triggers',
  'users'
];

localForage.setDriver([localForage.INDEXEDDB, localForage.LOCALSTORAGE]);

const localUserSettingsPersistConfig = {
  key: 'localUserSettings',
  storage: localForage,
  blacklist: ['comparisonSettings'] // do not persist comparisonSettings upon page reloads
};

const reducerOverrides = {
  localUserSettings: persistReducer(localUserSettingsPersistConfig, localUserSettingsReducer)
};

const persistedReducer = (history: History) =>
  persistReducer(
    {
      version: 18,
      key: 'root',
      storage: localForage,
      debug: process.env.REACT_APP_ENV !== 'production',
      whitelist: reduxPersistWhitelist,
      migrate: createMigrate(migrations, { debug: false })
    },
    createRootReducer(history, reducerOverrides)
  );

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(history: History) {
  // Create the store with two middleware
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    rollbarMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    persistedReducer(history),
    composeWithDevTools(...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { persistor, store };
}
