import { AxiosError } from 'axios';
import { action } from 'typesafe-actions';

import ActionTypes from './constants';
import { SearchTypes } from './types';

export const search = (searchType: SearchTypes, searchTerm: string) =>
  action(ActionTypes.SEARCH_REQUEST, { searchType, searchTerm });

export const searchSuccess = <T>(searchType: SearchTypes, searchResult: T[]) =>
  action(ActionTypes.SEARCH_SUCCESS, { searchType, searchResult });

export const searchFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.SEARCH_FAILURE, { message, error }, undefined, true);
