import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { NotificationPolicyState } from './types';

export const initialState: NotificationPolicyState = {
  siteId: null,
  devicePolicies: [],
  externalPolicies: []
};

function notificationPolicyReducer(
  state: NotificationPolicyState = initialState,
  action: ApplicationActions
): NotificationPolicyState {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATION_POLICIES: {
      const { payload: { siteId, data } } = action;
      const newState = {
        ...state,
        siteId,
        devicePolicies: data
      };

      // Clear out external policies if the siteId has changed
      if (siteId !== state.siteId) {
        newState.externalPolicies = [];
      }

      return newState;
    }
    case ActionTypes.SET_EXTERNAL_DEVICE_NOTIFICATION_POLICIES: {
      const { payload: { siteId, data } } = action;
      const newState = {
        ...state,
        siteId,
        externalPolicies: data
      };

      // Clear out device policies if the siteId has changed
      if (siteId !== state.siteId) {
        newState.devicePolicies = [];
      }

      return newState;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default notificationPolicyReducer;
