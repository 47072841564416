import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { CountryAbbreviation } from 'types/models/country';

const selectCountries = (state: ApplicationRootState) => state.countries;

const selectCountriesAsArray = createSelector(selectCountries, (countries) => {
  const alphabeticOrderCountries = orderBy(Object.values(countries), 'name');
  // Move AU, US & CA to the front of the country list
  const au = alphabeticOrderCountries.find((c) => c.shortcode === 'AU');
  const us = alphabeticOrderCountries.find((c) => c.shortcode === 'US');
  const ca = alphabeticOrderCountries.find((c) => c.shortcode === 'CA');
  let sortedCountries = alphabeticOrderCountries;
  if (au && us && ca) {
    sortedCountries = alphabeticOrderCountries.filter(
      (c) =>
        c.shortcode !== 'US' && c.shortcode !== 'AU' && c.shortcode !== 'CA'
    );
    sortedCountries.unshift(ca);
    sortedCountries.unshift(us);
    sortedCountries.unshift(au);
  }
  return sortedCountries;
});

const makeSelectCountryByAbbreviation = (
  countryAbbreviation: CountryAbbreviation | undefined
) =>
  createSelector(selectCountries, (countries) =>
    countryAbbreviation ? countries[countryAbbreviation] : undefined
  );

export {
  selectCountries,
  selectCountriesAsArray,
  makeSelectCountryByAbbreviation
};
