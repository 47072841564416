import React from 'react';
import Helmet from 'react-helmet';

import getWhiteLabelConfig from 'utils/get-white-label-config';

interface Props {
  section?: string;
}

/**
 * Returns a helmet containing a title, meta and link according to the section
 * and domain.
 */
function HelmetWrapper(props: Props): JSX.Element {
  const { title, description, faviconName } = getWhiteLabelConfig();
  const { section } = props;
  const first = title;
  const second = section ? ` - ${section}` : ''; // solves case where you simply want "Xbot"
  const helmetTitle = first + second;
  return (
    <Helmet>
      <title>{helmetTitle}</title>
      <meta name={`${title}`} content={`${description}`} />
      <link
        rel="icon"
        type="image/png"
        href={`${process.env.PUBLIC_URL}/${faviconName}`}
        sizes="16x16"
      />
    </Helmet>
  );
}

export default HelmetWrapper;
