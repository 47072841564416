enum ActionTypes {
  LOAD_MY_USER_REQUEST = 'LOAD_MY_USER_REQUEST',
  LOAD_MY_USER_SUCCESS = 'LOAD_MY_USER_SUCCESS',
  LOAD_MY_USER_FAILURE = 'LOAD_MY_USER_FAILURE',
  EDIT_MY_USER_REQUEST = 'EDIT_MY_USER_REQUEST',
  EDIT_MY_USER_SUCCESS = 'EDIT_MY_USER_SUCCESS',
  EDIT_MY_USER_FAILURE = 'EDIT_MY_USER_FAILURE',
  MERGE_MY_USER = 'MERGE_MY_USER'
}

export default ActionTypes;
