import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { BackOfficeUserState } from './types';

export const initialState: BackOfficeUserState = null;

function backOfficeUserReducer(
  state: BackOfficeUserState = initialState,
  action: ApplicationActions
): BackOfficeUserState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_USER: {
      const {
        payload: { user }
      } = action;

      return user;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeUserReducer;
