import { SamplePointId } from 'types/models/samplePoint';
import { ConditionType, EventLevel, ValueType } from 'types/trigger.enum';

/**
 * Create the special key which is unique to each trigger.
 * e.g., 9234.2.1.4 means the trigger is for sample point 9234, event level 2, value type 1, condition type 4.
 * The special key makes it easier for UI to locate which trigger to display.
 */
export default function makeTriggerSpecialKey(
  samplePointId: SamplePointId,
  eventLevel: EventLevel,
  valueType: ValueType,
  conditionType: ConditionType
) {
  return [samplePointId, eventLevel, valueType, conditionType].join('.');
}
