import { Select, Space, Typography } from 'antd';
import lodashRange from 'lodash/range';
import React, { useMemo } from 'react';

import DefaultButton from 'components/atoms/DefaultButton';

interface AddSubscriptionFormProps {
  label: string;
  min: number;
  max: number;
  defaultValue: number;
  onAdd: (value: number) => void;
  isAdding: boolean;
}

function AddSubscriptionForm({ label, min, max, defaultValue, onAdd, isAdding }: AddSubscriptionFormProps) {
  const [value, setValue] = React.useState(defaultValue);

  const options = useMemo(() => lodashRange(min, max + 1).map(number => ({ label: number, value: number })), [max, min]);

  return (
    <>
      <Typography>{label}</Typography>
      <Space size={16}>
        <Select
          options={options}
          value={value}
          onChange={(newValue) => (newValue ? setValue(newValue) : undefined)}
        />
        <DefaultButton type="primary" onClick={() => onAdd(value)} loading={isAdding}>Add</DefaultButton>
      </Space>
    </>
  );
}

export default AddSubscriptionForm;