import {
  AGRIWEBB_MARKETPLACE_FARMBOT_DEV_URL,
  AGRIWEBB_MARKETPLACE_FARMBOT_PROD_URL,
  AGRIWEBB_MARKETPLACE_FARMBOT_STAGING_URL,
  AGRIWEBB_MARKETPLACE_RANCHBOT_DEV_URL,
  AGRIWEBB_MARKETPLACE_RANCHBOT_PROD_URL,
  AGRIWEBB_MARKETPLACE_RANCHBOT_STAGING_URL,
  AGRIWEBB_ORGANISATION_ID_KEY
} from 'constants/agriwebb';
import { WhiteLabelTitle } from 'types/models/white-label';
import getWhiteLabelConfig from 'utils/get-white-label-config';

const getAgriWebbMarketplaceCardUrl = () => {
  const { title: whiteLabel } = getWhiteLabelConfig();
  const orgId = sessionStorage.getItem(AGRIWEBB_ORGANISATION_ID_KEY);

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return whiteLabel === WhiteLabelTitle.RANCHBOT
        ? `${AGRIWEBB_MARKETPLACE_RANCHBOT_PROD_URL}/${orgId}`
        : `${AGRIWEBB_MARKETPLACE_FARMBOT_PROD_URL}/${orgId}`;
    case 'staging':
      return whiteLabel === WhiteLabelTitle.RANCHBOT
        ? `${AGRIWEBB_MARKETPLACE_RANCHBOT_STAGING_URL}/${orgId}`
        : `${AGRIWEBB_MARKETPLACE_FARMBOT_STAGING_URL}/${orgId}`;
    case 'development':
    case 'local':
    default:
      return whiteLabel === WhiteLabelTitle.RANCHBOT
        ? `${AGRIWEBB_MARKETPLACE_RANCHBOT_DEV_URL}/${orgId}`
        : `${AGRIWEBB_MARKETPLACE_FARMBOT_DEV_URL}/${orgId}`;
  }
};

export default getAgriWebbMarketplaceCardUrl;
