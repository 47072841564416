import keyBy from 'lodash/keyBy';

export const mergeGroupedCollectionsByKey = (
  baseCollection: Record<number, any[]>,
  updatedCollection: Record<number, any[]>,
  key: string
): Record<number, any[]> => {
  const result = { ...baseCollection };

  Object.keys(updatedCollection).forEach((updatedCollectionKeyString) => {
    const updatedCollectionKey = Number(updatedCollectionKeyString);
    const updatedCollectionValue = updatedCollection[updatedCollectionKey];
    const baseCollectionValue = baseCollection[updatedCollectionKey];

    if (baseCollectionValue) {
      result[updatedCollectionKey] = Object.values(keyBy([...baseCollectionValue, ...updatedCollectionValue], key));
    } else {
      result[updatedCollectionKey] = updatedCollectionValue;
    }
  });

  return result;
};