import { ApplicationActions } from 'store/types';
import { upsertSafeChildProperties } from 'utils/Object/properties/upsert-child-property';

import ActionTypes from './constants';
import { MyPreferencesState } from './types';

export const initialState: MyPreferencesState = {};

const myPreferencesReducer = (
  state: MyPreferencesState = initialState,
  action: ApplicationActions
): MyPreferencesState => {
  switch (action.type) {
    case ActionTypes.SET_MY_PREFERENCES:
      return action.payload.reduce((acc, { entityKind, entityId, preference }) =>
        upsertSafeChildProperties(acc, [entityKind, entityId], preference),
        state);
    case ActionTypes.UNSET_MY_PREFERENCE:
      delete state[action.payload.entityKind]?.[action.payload.entityId];
      return { ...state };
    default:
      return state;
  }
};

export default myPreferencesReducer;