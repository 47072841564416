import { AxiosError, AxiosResponse } from 'axios';
import { Bounds } from 'google-map-react';
import { action } from 'typesafe-actions';

import BackOfficeDeviceMapPoint from 'types/models/back-office-device-map-point';

import ActionTypes from './constants';

export const loadBackOfficeDeviceMapPoints = (bounds: Bounds, zoom: number) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_MAP_POINTS_REQUEST, { ...bounds, zoom });

export const loadBackOfficeDeviceMapPointsSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_MAP_POINTS_SUCCESS, { response });

export const loadBackOfficeDeviceMapPointsFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_MAP_POINTS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeDeviceMapPoints = (payload: {
  data: BackOfficeDeviceMapPoint[];
}) => {
  return action(ActionTypes.SET_BACK_OFFICE_DEVICE_MAP_POINTS, payload);
};
