enum ActionTypes {
  LOAD_ENTERPRISE_REQUEST = 'LOAD_ENTERPRISE_REQUEST',
  LOAD_ENTERPRISE_SUCCESS = 'LOAD_ENTERPRISE_SUCCESS',
  LOAD_ENTERPRISE_FAILURE = 'LOAD_ENTERPRISE_FAILURE',

  EDIT_ENTERPRISE_REQUEST = 'EDIT_ENTERPRISE_REQUEST',
  EDIT_ENTERPRISE_SUCCESS = 'EDIT_ENTERPRISE_SUCCESS',
  EDIT_ENTERPRISE_FAILURE = 'EDIT_ENTERPRISE_FAILURE',

  SET_ENTERPRISE = 'SET_ENTERPRISE'
}

export default ActionTypes;
