import { action } from 'typesafe-actions';

import { UserPermission } from 'types/models/user-permission';
import { EntityKind } from 'types/models/user-preference';

import { UserPermissionActionTypes } from './constants';

// ================================================
// Fetch User Permissions
// ================================================
export const fetchUserPermissions = (entityKind?: EntityKind, entityId?: number) =>
  action(UserPermissionActionTypes.FETCH_USER_PERMISSIONS_REQUEST, { entityKind, entityId });

export const fetchUserPermissionsSuccess = () => action(UserPermissionActionTypes.FETCH_USER_PERMISSIONS_SUCCESS);

export const fetchUserPermissionsFailure = (message: string) =>
  action(UserPermissionActionTypes.FETCH_USER_PERMISSIONS_FAILURE, { message }, undefined, true);

export const setUserPermissions = (userPermissions: UserPermission[]) =>
  action(UserPermissionActionTypes.SET_USER_PERMISSIONS, userPermissions);