enum ActionTypes {
  ADD_SITE_REQUEST = 'ADD_SITE_REQUEST',
  ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS',
  ADD_SITE_FAILURE = 'ADD_SITE_FAILURE',

  EDIT_SITE_REQUEST = 'EDIT_SITE_REQUEST',
  EDIT_SITE_SUCCESS = 'EDIT_SITE_SUCCESS',
  EDIT_SITE_FAILURE = 'EDIT_SITE_FAILURE',

  DELETE_SITE_REQUEST = 'DELETE_SITE_REQUEST',
  DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS',
  DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE',

  EDIT_SITE_PREFERENCE_REQUEST = 'EDIT_SITE_PREFERENCE_REQUEST',
  EDIT_SITE_PREFERENCE_SUCCESS = 'EDIT_SITE_PREFERENCE_SUCCESS',
  EDIT_SITE_PREFERENCE_FAILURE = 'EDIT_SITE_PREFERENCE_FAILURE',

  SET_SITES = 'SET_SITES',
  SET_SITE = 'SET_SITE',
  UNSET_SITE = 'UNSET_SITE',

  LOAD_SITES_REQUEST = 'LOAD_SITES_REQUEST',
  LOAD_SITES_SUCCESS = 'LOAD_SITES_SUCCESS',
  LOAD_SITES_FAILURE = 'LOAD_SITES_FAILURE'
}

export default ActionTypes;
