import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { LEGACY_MAX_WIDTH_TABLET_MEDIUM } from 'style/constants';

import './styles.less';

interface Props extends ButtonProps {
  noBorder?: boolean;
  flex?: boolean;
  mobileChildren?: string;
}

/**
 * A wrapper around the antd Button with our custom config applied.
 * To be used as a button in the extra prop of DefaultPageHeader.
 */
function DefaultPageHeaderButton({
  onClick,
  children,
  noBorder,
  flex,
  className,
  mobileChildren,
  ...rest
}: Props): JSX.Element | null {
  const isTabletOrMobile = useMediaQuery({ maxWidth: LEGACY_MAX_WIDTH_TABLET_MEDIUM });

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      size="small"
      className={
        classNames('DefaultPageHeaderButton',
          { 'no-border': noBorder, flex },
          className)
      }
      onClick={onClick}
    >
      {isTabletOrMobile && mobileChildren ? mobileChildren : children}
    </Button>
  );
}

export default memo(DefaultPageHeaderButton);
