enum ActionTypes {
  SET_DEVICES = 'SET_DEVICES',
  SET_DEVICE = 'SET_DEVICE',
  UPDATE_DEVICE = 'UPDATE_DEVICE',

  EDIT_DEVICE_REQUEST = 'EDIT_DEVICE_REQUEST',
  EDIT_DEVICE_SUCCESS = 'EDIT_DEVICE_SUCCESS',
  EDIT_DEVICE_FAILURE = 'EDIT_DEVICE_FAILURE'
}

export default ActionTypes;
