/**
 * Request all the sample points for the current enterprise.
 */

import { message as antdMessage } from 'antd';
import keyBy from 'lodash/keyBy';
import { REHYDRATE } from 'redux-persist';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { notificationClient } from 'clients';
import NetworkActionTypes from 'store/modules/network/constants';
import { selectNetworkOnline } from 'store/modules/network/selectors';
import { selectSamplePointsState } from 'store/modules/samplePoints/selectors';
import { SamplePointsState } from 'store/modules/samplePoints/types';
import trackEvent from 'store/modules/tracking/actions';
import { EventType, TrackingEvent } from 'store/modules/tracking/types';
import { AssetTypeCode } from 'types/models/asset-type';
import { IPCameraEvent, SamplePointEvent } from 'types/models/event';
import Notification, { NotificationEventType, NotificationId } from 'types/models/notification';
import { APIResponseParsed } from 'types/response';
import { getNotificationEventType } from 'utils/Notification/notification-event-type';
import { sanitiseNotifications } from 'utils/Notification/sanitise-notifications';

import {
  acknowledgeRecentNotificationFailure,
  acknowledgeRecentNotificationSuccess,
  loadRecentNotifications,
  loadRecentNotificationsFailure,
  loadRecentNotificationsSuccess,
  removePendingAcknowledgement,
  setRecentNotification,
  setRecentNotifications
} from './actions';
import ActionTypes from './constants';
import {
  selectRecentNotifications,
  selectRecentNotificationsPendingStatusChanges
} from './selectors';

// ==============================
// PURE FUNCTIONS
// ==============================
const getTrackingDataWhenAcknowledgingNotification = (
  pendingNotification: Notification,
  samplePointsState: SamplePointsState,
  isSuccess: boolean
): TrackingEvent | undefined => {
  const event = pendingNotification.event;
  const eventType = getNotificationEventType(event);
  switch (eventType) {
    case NotificationEventType.SAMPLE_POINT_EVENT: {
      const samplePointEvent = event as SamplePointEvent;
      const assetTypeId: AssetTypeCode = samplePointsState[samplePointEvent.samplePoint.id]?.assetTypeId
        || AssetTypeCode.BATTERY; // Battery sample point is not represented in the store
      return {
        type: EventType.NOTIFICATION_ACKNOWLEDGED,
        data: {
          status: isSuccess,
          samplePointId: samplePointEvent.samplePoint.id,
          assetTypeId,
          eventLevel: samplePointEvent.level,
          eventMessage: samplePointEvent.message
        }
      };
    }
    case NotificationEventType.IP_CAMERA_EVENT: {
      const ipCameraEvent = event as IPCameraEvent;
      return {
        type: EventType.THIRD_PARTY_NOTIFICATION_ACKNOWLEDGED,
        data: {
          status: isSuccess,
          // @ts-ignore TODO fix 'ipCameraId' does not exist on type TrackingEvent
          ipCameraId: ipCameraEvent.ipCamera.id,
          assetTypeId: AssetTypeCode.SECURITY_CAMERA,
          eventLevel: ipCameraEvent.level,
          eventMessage: ipCameraEvent.message
        }
      };
    }
    default:
      return undefined;
  }
};

// ==============================
// SAGAS
// ==============================
function* requestRecentNotifications(
  action: ReturnType<typeof loadRecentNotifications>
): SagaIterator {
  const {
    payload: { enterpriseId }
  } = action;
  const response: APIResponseParsed<Notification[]> = yield call(notificationClient.fetchNotifications, enterpriseId);
  if (response.data) {
    const { data } = response;
    const notifications: Notification[] = sanitiseNotifications(data);

    yield all([
      put(setRecentNotifications(enterpriseId, keyBy(notifications, 'id'))),
      put(loadRecentNotificationsSuccess())
    ]);
  } else {
    const errorMessage = response.error.message || 'Sorry, something went wrong.';
    yield put(loadRecentNotificationsFailure(errorMessage));
  }
}

function* acknowledgeRecentNotifications() {
  const networkOnline: ReturnType<typeof selectNetworkOnline> = yield select(selectNetworkOnline);
  if (!networkOnline) return;

  const notifications: ReturnType<typeof selectRecentNotifications> = yield select(
    selectRecentNotifications
  );
  const samplePointsState: SamplePointsState = yield select(
    selectSamplePointsState
  );

  const pendingStatusChanges: ReturnType<
    typeof selectRecentNotificationsPendingStatusChanges
  > = yield select(selectRecentNotificationsPendingStatusChanges);

  const pendingNotificationIds = Object.keys(pendingStatusChanges);

  for (let i = 0; i < pendingNotificationIds.length; i += 1) {
    const pendingNotificationId: NotificationId = Number(pendingNotificationIds[i]);
    const newStatus = pendingStatusChanges[pendingNotificationId];
    const response: APIResponseParsed<Notification> = yield call(
      notificationClient.patchNotification,
      pendingNotificationId,
      newStatus
    );
    if (response.data) {
      yield all([
        put(removePendingAcknowledgement(pendingNotificationId)),
        put(setRecentNotification(response.data)),
        put(acknowledgeRecentNotificationSuccess())
      ]);
      const trackingData = getTrackingDataWhenAcknowledgingNotification(
        notifications[pendingNotificationId],
        samplePointsState,
        true
      );
      if (trackingData) {
        yield put(trackEvent(trackingData));
      }
    } else {
      // Suppress error handling in the event of a Network Error.
      if (response.error.isNetworkError) return;

      const errorMessage = response.error.message || 'Sorry, something went wrong.';
      antdMessage.error('Failed to acknowledge the notification. Please try again.');

      yield all([
        put(removePendingAcknowledgement(pendingNotificationId)),
        acknowledgeRecentNotificationFailure(errorMessage)
      ]);
      const trackingData = getTrackingDataWhenAcknowledgingNotification(
        notifications[pendingNotificationId],
        samplePointsState,
        false
      );
      if (trackingData) {
        yield put(trackEvent(trackingData));
      }
    }
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchLoadRecentNotificationsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_RECENT_NOTIFICATIONS_REQUEST,
    requestRecentNotifications
  );
}

function* watchSetPendingAcknowledgement() {
  yield takeLatest(
    [
      REHYDRATE,
      NetworkActionTypes.SET_NETWORK_ONLINE,
      ActionTypes.SET_PENDING_ACKNOWLEDGEMENT
    ],
    acknowledgeRecentNotifications
  );
}

// ==============================
// EXPORT
// ==============================
export default function* recentNotificationsSaga() {
  yield all([
    fork(watchLoadRecentNotificationsRequest),
    fork(watchSetPendingAcknowledgement)
  ]);
}
