import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint from 'types/models/samplePoint';

export function spHasMultipleUnitTriggers(samplePoint: SamplePoint): boolean {
  const spIsAdvancedDam = samplePoint?.config?.enabledVolumeMapping;
  return spIsAdvancedDam
    || [
      AssetTypeCode.WATER_TANK,
      AssetTypeCode.FUEL_TANK,
      AssetTypeCode.LIQUID_FERTILISER_TANK
    ].includes(samplePoint.assetTypeId);
}