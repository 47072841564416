import axios from 'axios';

import { SamplePointId } from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import { APIResponseParsed } from 'types/response';
import { getRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

// ==============================
// TYPES
// ==============================
type FetchSampleCsvParams = {
  samplePointId: SamplePointId;
  dateRange: DateRange;
};

// ==============================
// REQUESTS
// ==============================
async function fetchSamplesCsvContent(
  params: FetchSampleCsvParams
): Promise<APIResponseParsed<BlobPart>> {
  try {
    const { samplePointId, dateRange } = params;
    const response = await getRequest<BlobPart>(`/samplepoint/${samplePointId}/export`, {
      params: {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
        format: 'csv'
      }
    });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

export default { fetchSamplesCsvContent };
