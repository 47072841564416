import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import State, { StateAbbreviation } from 'types/models/state';

import ActionTypes from './constants';

export const setStates = (states: Record<StateAbbreviation, State>) =>
  action(ActionTypes.SET_STATES, states);

export const setState = (state: State) => action(ActionTypes.SET_STATE, state);

export const loadStates = () => action(ActionTypes.LOAD_STATES_REQUEST);

export const loadStatesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_STATES_SUCCESS, { response });

export const loadStatesFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.LOAD_STATES_FAILURE, { message, error }, undefined, true);
