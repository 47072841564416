import { message as antdMessage } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadFarms } from 'store/modules/agriWebb/actions';
import { AgriWebbFarm } from 'store/modules/agriWebb/types';
import { loadSites } from 'store/modules/sites/actions';
import { EnterpriseId } from 'types/models/enterprise';

interface Props {
  enterpriseId: EnterpriseId;
  onSuccess: (farms: AgriWebbFarm[]) => void;
}

export default function AgriWebbFarmsRequest({
  enterpriseId,
  onSuccess
}: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (enterpriseId) {
      dispatch(
        loadFarms(+enterpriseId, onSuccess, (msg) => {
          antdMessage.error(msg);
        })
      );
      dispatch(loadSites(+enterpriseId));
    }
  }, [dispatch, enterpriseId, onSuccess]);

  return null;
}
