import { Form, Input, Select, Typography } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import parsePhoneNumber from 'libphonenumber-js';
import React, { FocusEventHandler, useContext, useMemo } from 'react';

import DefaultFormItem from 'components/composites/DefaultFormItem';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import FieldValue from 'components/forms/FieldValue';
import CountriesRequest from 'components/requests/CountriesRequest';
import { DEFAULT_COUNTRY_ABBR_FARMBOT } from 'constants/index';
import { useCountriesAsArray } from 'store/modules/countries/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import phoneNumberValidator, {
  isValidCountryShortCode
} from 'utils/phone-number/phone-number-validator';

import { FormContext } from '../../composites/DefaultForm';

interface Props extends Pick<FormItemProps, 'name' | 'label' | 'rules'> {
  prefixName: string;
  prefixValue?: string;
  readOnly?: boolean;
  hasInitialValues?: boolean;
}

function FormItemInputPhoneNumber({
  name: phoneNumberFieldNamePath,
  label,
  rules,
  prefixName,
  prefixValue,
  readOnly,
  hasInitialValues
}: Props) {
  const { formInstance } = useContext(FormContext);
  // CAN and USA share the same label and code.
  const currentCountry =
    useEnterpriseCountry().replace('CAN', 'USA') ??
    DEFAULT_COUNTRY_ABBR_FARMBOT;

  const countriesAsArray = useCountriesAsArray().filter(
    // CAN and USA share the same label and code. We merge CAN into USA here.
    // PRI & DOM have multiple codes. We don't handle it for now.
    // See more https://countrycode.org/dominicanrepublic
    (c) => !['CAN', 'DOM', 'PRI'].includes(c.abbreviation)
  );

  const countryShortCodes = useMemo(
    () => countriesAsArray.map((country) => country.shortcode),
    [countriesAsArray]
  );

  const countryShortCode = useMemo(() => {
    let shortCode = countriesAsArray.find(
      (country) => country.abbreviation === currentCountry
    )?.shortcode;
    // If country code is manually selected
    if (prefixValue) {
      shortCode = countriesAsArray.find(
        (country) => country.phonePrefix === prefixValue
      )?.shortcode;
    }

    return isValidCountryShortCode(shortCode) ? shortCode : undefined;
  }, [countriesAsArray, prefixValue, currentCountry]);

  const countriesOptions = useMemo(() => {
    let options = countriesAsArray
      .map(({ shortcode, phonePrefix, abbreviation }) => ({
        label: `${shortcode} ${phonePrefix}`,
        value: phonePrefix,
        abbreviation
      }))
      .sort((a, b) => +a.value.replace('-', '.') - +b.value.replace('-', '.'));
    // CAN and USA share the same label and code.
    const us = options.find((o) => o.abbreviation === 'USA');
    if (us) {
      us.label = '+1';
    }
    const au = options.find((o) => o.abbreviation === 'AUS');
    options = options.filter((o) => o.abbreviation !== 'AUS');
    if (au) {
      options.unshift(au);
    }
    return options;
  }, [countriesAsArray]);

  // Runs on blur (lost focus) to format entered phone number.
  const onBlurHandler: FocusEventHandler<HTMLInputElement> = () => {
    const value = formInstance?.getFieldValue(
      phoneNumberFieldNamePath as string
    );

    if (!value) {
      return;
    }

    const parsed = parsePhoneNumber(value, countryShortCode);

    if (!parsed || !parsed.isValid()) {
      return;
    }

    const { country } = parsed;

    if (country && countryShortCodes.includes(country)) {
      const newCountryCodeValue = countriesAsArray.find(
        (c) => c.shortcode === country
      )?.phonePrefix;
      formInstance?.setFieldsValue({ [prefixName]: newCountryCodeValue });

      formInstance?.setFieldsValue({
        [phoneNumberFieldNamePath as string]: parsed.nationalNumber
      });
    }
  };

  if (readOnly) {
    return (
      <Form.Item label={<Typography.Text strong>{label}</Typography.Text>}>
        <FieldValue name={prefixName} />{' '}
        <FieldValue name={phoneNumberFieldNamePath} />
      </Form.Item>
    );
  }

  if (countriesAsArray.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <DefaultFormItem
      name={phoneNumberFieldNamePath}
      label={label}
      rules={[
        ...(rules || []),
        {
          validator: (_, val) =>
            phoneNumberValidator(val, countryShortCode, countryShortCodes),
          message: 'Please enter a valid phone number'
        }
      ]}
    >
      <Input
        onBlur={onBlurHandler}
        addonBefore={
          <FieldValue name={phoneNumberFieldNamePath}>
            {(value) => (
              <DefaultFormItem
                name={prefixName}
                rules={[
                  {
                    required: !!value,
                    message: 'Please select a phone number country code'
                  }
                ]}
                noStyle
                initialValue={
                  hasInitialValues
                    ? undefined
                    : countriesOptions.find(
                      (o) => o.abbreviation === currentCountry
                    )?.value
                }
              >
                <Select
                  onBlur={onBlurHandler}
                  style={{ width: 100 }}
                  options={countriesOptions}
                />
              </DefaultFormItem>
            )}
          </FieldValue>
        }
        placeholder="Phone Number"
      />
    </DefaultFormItem>
  );
}

function FormItemInputPhoneNumberOuter({
  prefixName,
  hasInitialValues,
  ...rest
}: Omit<Props, 'prefixValue'>) {
  return (
    <>
      <CountriesRequest />
      <FieldValue name={prefixName}>
        {(prefixValue) => (
          <FormItemInputPhoneNumber
            prefixValue={prefixValue as string}
            prefixName={prefixName}
            hasInitialValues={hasInitialValues}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
        )}
      </FieldValue>
    </>
  );
}

export default FormItemInputPhoneNumberOuter;
