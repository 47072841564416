import React, { memo } from 'react';

import { Industry } from 'types/models/enterprise';

interface Props {
  industry?: Industry | undefined | null;
}

function EnterpriseIndustryText({ industry }: Props): JSX.Element | null {
  switch (industry) {
    case Industry.AGRICULTURE:
      return <>Agriculture</>;
    case Industry.MINING:
      return <>Mining</>;
    default:
      return null;
  }
}

export default memo(EnterpriseIndustryText);
