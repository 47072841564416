export enum WhiteLabelTitle {
  FARMBOT = 'Farmbot',
  RANCHBOT = 'Ranchbot',
  MINEBOT = 'Minebot'
}

export default interface WhiteLabelConfig {
  title: WhiteLabelTitle;
  description: string;
  faviconName: string;
  auth0ClientId: string;
}
