import { action } from 'typesafe-actions';

import { FetchIPCameraPhotosParams } from 'clients/ipCamera';
import { EnterpriseId } from 'types/models/enterprise';
import IPCamera, {
  IPCameraCapabilities,
  IPCameraEditableFields,
  IPCameraEvent,
  IPCameraMetadata,
  IPCameraPTZPresetEditableFields,
  IPCameraPhoto,
  IPCameraPreview,
  PTZControl,
  PTZPreset
} from 'types/models/ip-camera';
import { SiteId } from 'types/models/site';

import ActionTypes from './constants';
import { IPCameraPhotosState, IPCamerasState } from './types';

// ================================================
// IP CAMERA LIST
// ================================================
export const fetchIPCameras = (enterpriseId: EnterpriseId, siteId?: SiteId) =>
  action(ActionTypes.FETCH_IP_CAMERAS_REQUEST, { enterpriseId, siteId });

export const fetchIPCamerasSuccess = (ipCameras: IPCamerasState) =>
  action(ActionTypes.FETCH_IP_CAMERAS_SUCCESS, ipCameras);

export const fetchIPCamerasFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERAS_FAILURE, { message });

// ================================================
// IP CAMERA DETAILS
// ================================================
export const fetchCameraById = (id: number) =>
  action(ActionTypes.FETCH_CAMERA_BY_ID_REQUEST, { id });

export const fetchCameraByIdSuccess = (ipCamera: IPCamera) =>
  action(ActionTypes.FETCH_CAMERA_BY_ID_SUCCESS, ipCamera);

// ================================================
// IP CAMERA PREVIEW
// ================================================
export const takeIPCameraPreview = (ipCameraId: number) =>
  action(ActionTypes.TAKE_IP_CAMERA_PREVIEW_REQUEST, { ipCameraId });

export const takeIPCameraPreviewSuccess = () => action(ActionTypes.TAKE_IP_CAMERA_PREVIEW_SUCCESS);

export const takeIPCameraPreviewFailure = (message: string) =>
  action(ActionTypes.TAKE_IP_CAMERA_PREVIEW_FAILURE, { message }, undefined, true);

export const setIPCameraPreview = (ipCameraId: number, preview: IPCameraPreview) =>
  action(ActionTypes.SET_IP_CAMERA_PREVIEW, { ipCameraId, preview });

export const setIPCameraPreviewError = (ipCameraId: number, error: string) =>
  action(ActionTypes.SET_IP_CAMERA_PREVIEW_ERROR, { ipCameraId, error });

// ================================================
// IP CAMERA STATE (e.g. online, ptz capabilities)
// ================================================
export const fetchIPCameraState = (ipCameraId: number) =>
  action(ActionTypes.FETCH_IP_CAMERA_STATE_REQUEST, { ipCameraId });

export const fetchIPCameraStateSuccess = () => action(ActionTypes.FETCH_IP_CAMERA_STATE_SUCCESS);

export const fetchIPCameraStateFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERA_STATE_FAILURE, { message }, undefined, true);

export const setIPCameraState = (ipCameraId: number, metadata: IPCameraMetadata) =>
  action(ActionTypes.SET_IP_CAMERA_STATE, { ipCameraId, metadata });

export const fetchIPCameraCapabilities = (ipCameraId: number, capabilityFields?: (keyof IPCameraCapabilities)[]) =>
  action(ActionTypes.FETCH_IP_CAMERA_CAPABILITIES_REQUEST, { ipCameraId, capabilityFields });

export const fetchIPCameraCapabilitiesSuccess = () => action(ActionTypes.FETCH_IP_CAMERA_CAPABILITIES_SUCCESS);

export const fetchIPCameraCapabilitiesFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERA_CAPABILITIES_FAILURE, { message }, undefined, true);

export const setIPCameraCapabilities = (ipCameraId: number, partialCapabilities: Partial<IPCameraCapabilities>) =>
  action(ActionTypes.SET_IP_CAMERA_CAPABILITIES, { ipCameraId, partialCapabilities });

// ================================================
// IP CAMERA PHOTOS
// ================================================
export const fetchIPCameraPhotos = (ipCameraId: number, params: FetchIPCameraPhotosParams) =>
  action(ActionTypes.FETCH_IP_CAMERA_PHOTOS_REQUEST, { ipCameraId, params });

export const fetchIPCameraPhotosSuccess = () => action(ActionTypes.FETCH_IP_CAMERA_PHOTOS_SUCCESS);

export const fetchIPCameraPhotosFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERA_PHOTOS_FAILURE, { message }, undefined, true);

export const setIPCameraPhotos = (ipCameraId: number, photoList: IPCameraPhotosState) =>
  action(ActionTypes.SET_IP_CAMERA_PHOTOS, { ipCameraId, photoList });

export const takeIPCameraPhoto = (ipCameraId: number) =>
  action(ActionTypes.TAKE_IP_CAMERA_PHOTO_REQUEST, { ipCameraId });

export const takeIPCameraPhotoSuccess = () => action(ActionTypes.TAKE_IP_CAMERA_PHOTO_SUCCESS);

export const takeIPCameraPhotoFailure = (message: string) =>
  action(ActionTypes.TAKE_IP_CAMERA_PHOTO_FAILURE, { message }, undefined, true);

export const setIPCameraUnseenPhoto = (ipCameraId: number, newPhoto: IPCameraPhoto) =>
  action(ActionTypes.SET_IP_CAMERA_UNSEEN_PHOTO, { ipCameraId, newPhoto });

export const resetIPCameraUnseenPhoto = (ipCameraId: number) =>
  action(ActionTypes.SET_IP_CAMERA_UNSEEN_PHOTO, { ipCameraId, newPhoto: undefined });

// ================================================
// IP CAMERA PTZ
// ================================================
export const sendCameraPTZControlRequest = (id: number, control: PTZControl) =>
  action(ActionTypes.SEND_CAMERA_PTZ_CONTROL_REQUEST, { id, control });

// ================================================
// IP CAMERA EVENTS
// ================================================
export const fetchIPCameraEvents = (ipCameraId: number, startDate: string, endDate: string, isAppend: boolean) =>
  action(ActionTypes.FETCH_IP_CAMERA_EVENTS_REQUEST, { ipCameraId, startDate, endDate, isAppend });

export const fetchIPCameraEventsSuccess = () => action(ActionTypes.FETCH_IP_CAMERA_EVENTS_SUCCESS);

export const fetchIPCameraEventsFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERA_EVENTS_FAILURE, { message }, undefined, true);

export const setIPCameraEvents = (ipCameraId: number, events: IPCameraEvent[]) =>
  action(ActionTypes.SET_IP_CAMERA_EVENTS, { ipCameraId, events });

export const resetIPCameraEvents = (ipCameraId: number) =>
  action(ActionTypes.SET_IP_CAMERA_EVENTS, { ipCameraId, events: undefined });

// ================================================
// IP CAMERA EDIT
// ================================================
export const editIPCamera = (id: number, values: IPCameraEditableFields) =>
  action(ActionTypes.EDIT_IP_CAMERA_REQUEST, { id, values });

export const editIPCameraSuccess = () => action(ActionTypes.EDIT_IP_CAMERA_SUCCESS);

export const editIPCameraFailure = (message: string) =>
  action(ActionTypes.EDIT_IP_CAMERA_FAILURE, { message }, undefined, true);

// ================================================
// IP CAMERA REMOVAL
// ================================================
export const removeIPCamera = (id: number) =>
  action(ActionTypes.REMOVE_IP_CAMERA_REQUEST, { id });

export const removeIPCameraSuccess = () => action(ActionTypes.REMOVE_IP_CAMERA_SUCCESS);

export const removeIPCameraFailure = (message: string) =>
  action(ActionTypes.REMOVE_IP_CAMERA_FAILURE, { message }, undefined, true);

export const removeFromIPCameraList = (id: number) =>
  action(ActionTypes.REMOVE_FROM_IP_CAMERA_LIST, { id });

// ================================================
// IP CAMERA PTZ PRESETS
// ================================================
export const fetchIPCameraPTZPresetsRequest = (ipCameraId: number) =>
  action(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST, { ipCameraId });

export const fetchIPCameraPTZPresetsSuccess = () =>
  action(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_SUCCESS);

export const fetchIPCameraPTZPresetsFailure = (message: string) =>
  action(ActionTypes.FETCH_IP_CAMERA_PTZ_PRESETS_FAILURE, { message }, undefined, true);

export const createIPCameraPTZPresetRequest = (ipCameraId: number) =>
  action(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId });

export const createIPCameraPTZPresetSuccess = () =>
  action(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_SUCCESS);

export const createIPCameraPTZPresetFailure = (message: string) =>
  action(ActionTypes.CREATE_IP_CAMERA_PTZ_PRESET_FAILURE, { message }, undefined, true);

export const activateIPCameraPTZPresetRequest = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId });

export const activateIPCameraPTZPresetSuccess = () =>
  action(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_SUCCESS);

export const activateIPCameraPTZPresetFailure = (message: string) =>
  action(ActionTypes.ACTIVATE_IP_CAMERA_PTZ_PRESET_FAILURE, { message }, undefined, true);

export const updateIPCameraPTZPresetRequest = (
  ipCameraId: number,
  presetId: number,
  values: IPCameraPTZPresetEditableFields
) =>
  action(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId, values });

export const updateIPCameraPTZPresetSuccess = () =>
  action(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_SUCCESS);

export const updateIPCameraPTZPresetFailure = (message: string) =>
  action(ActionTypes.UPDATE_IP_CAMERA_PTZ_PRESET_FAILURE, { message }, undefined, true);

export const deleteIPCameraPTZPresetRequest = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_REQUEST, { ipCameraId, presetId });

export const deleteIPCameraPTZPresetSuccess = () =>
  action(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_SUCCESS);

export const deleteIPCameraPTZPresetFailure = (message: string) =>
  action(ActionTypes.DELETE_IP_CAMERA_PTZ_PRESET_FAILURE, { message }, undefined, true);

export const setIPCameraPTZPresets = (ipCameraId, presets: PTZPreset[]) =>
  action(ActionTypes.SET_IP_CAMERA_PTZ_PRESETS, { ipCameraId, presets });

export const setIPCameraPTZPreset = (ipCameraId, preset: PTZPreset) =>
  action(ActionTypes.SET_IP_CAMERA_PTZ_PRESET, { ipCameraId, preset });

export const removeIPCameraPTZPreset = (ipCameraId: number, presetId: number) =>
  action(ActionTypes.REMOVE_IP_CAMERA_PTZ_PRESET, { ipCameraId, presetId });