import keyBy from 'lodash/keyBy';
import { AnyAction } from 'redux';

import { LegalPolicyActionType } from './constants';
import { LegalPolicyState } from './types';

const initialState: LegalPolicyState = {
  documents: {},
  userAcceptance: {},
  lastChecked: 0
};

const legalPolicyReducer = (state = initialState, action: AnyAction): LegalPolicyState => {
  switch (action.type) {
    case LegalPolicyActionType.SET_LEGAL_POLICY_DOCUMENTS: {
      const { documents } = action.payload;
      return {
        ...state,
        documents: keyBy(documents, 'id')
      };
    }
    case LegalPolicyActionType.SET_LEGAL_POLICY_USER_ACCEPTANCE:
      return {
        ...state,
        userAcceptance: {
          ...state.userAcceptance,
          [action.payload.userId]: action.payload.accepted
        }
      };
    case LegalPolicyActionType.SET_LAST_CHECKED: {
      return {
        ...state,
        lastChecked: action.payload.lastChecked
      };
    }
    default:
      return state;
  }
};

export default legalPolicyReducer;