import { Typography } from 'antd';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

interface NewTabLinkProps {
  className?: string;
  disabled?: boolean;
  href: string;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  text?: React.ReactNode;
}

/**
 * A wrapper of Typography.Link that ensures we do not forget the FiExternalLink icon.
 *
 * Note: Unlike IconLabel that puts icon in front of (or beneath) the label, this component puts the icon after the text.
 * */
function NewTabLink({ className, disabled, href, iconSize, iconStyle, text }: NewTabLinkProps) {
  const iconInternalStyle = text
    ? { verticalAlign: 'text-bottom', marginLeft: 4 }
    : { verticalAlign: 'middle' };

  return (
    <Typography.Link className={className} disabled={disabled} href={href} target="_blank">
      {text}
      <FiExternalLink size={iconSize || 18} style={{ ...iconInternalStyle, ...iconStyle }} />
    </Typography.Link>
  );
}

export default NewTabLink;