import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import User, { UserId } from 'types/models/user';

import ActionTypes from './constants';

export const loadBackOfficeUser = (userId: UserId) =>
  action(ActionTypes.LOAD_BACK_OFFICE_USER_REQUEST, { userId });

export const loadBackOfficeUserSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_USER_SUCCESS, { response });

export const loadBackOfficeUserFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_USER_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeUser = (user: User) =>
  action(ActionTypes.SET_BACK_OFFICE_USER, { user });
