export enum LegalPolicyDocumentType {
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  DATA_USE_POLICY = 'data-use-policy',
  TERMS_OF_SERVICE = 'terms-of-service'
}

export const LegalPolicyDocumentLabels: Record<LegalPolicyDocumentType, string> = {
  [LegalPolicyDocumentType.TERMS_AND_CONDITIONS]: 'Terms and Conditions',
  [LegalPolicyDocumentType.PRIVACY_POLICY]: 'Privacy Policy',
  [LegalPolicyDocumentType.DATA_USE_POLICY]: 'Data Use Policy',
  [LegalPolicyDocumentType.TERMS_OF_SERVICE]: 'LEGAL_BRAND Terms of Service'
};