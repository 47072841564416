import { BackOfficeEnterpriseInput, CreateEnterpriseDTO } from './types';

export default function parserBackOfficeEnterpriseToEnterprise(
  backOfficeEnterprise: BackOfficeEnterpriseInput
): CreateEnterpriseDTO {
  return {
    name: backOfficeEnterprise.name,
    subdomain: backOfficeEnterprise.subdomain,
    industry: backOfficeEnterprise.industry,
    status: backOfficeEnterprise.status,
    note: backOfficeEnterprise.note,
    ownerEmail: backOfficeEnterprise.email,
    billingAddress: {
      id: backOfficeEnterprise.billingAddressId,
      ...backOfficeEnterprise.billingAddress
    },
    timezoneCode: backOfficeEnterprise.timezoneCode
  };
}
