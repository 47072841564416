import { IPCameraEvent, SamplePointEvent } from 'types/models/event';
import Notification, { NotificationEventType } from 'types/models/notification';

export const getNotificationEventType = (event: SamplePointEvent | IPCameraEvent) => {
  // Protections from TypeScript are not available at runtime
  if (!event) return false;

  if ('samplePoint' in event) {
    return NotificationEventType.SAMPLE_POINT_EVENT;
  } else if ('ipCamera' in event) {
    return NotificationEventType.IP_CAMERA_EVENT;
  } else {
    return false;
  }
};

export const getSamplePointEventFromNotification = (
  notification: Notification | null | undefined
): SamplePointEvent | null => {
  if (!notification) return null;

  if (notification.eventType === NotificationEventType.SAMPLE_POINT_EVENT) {
    return notification.event as SamplePointEvent;
  }

  return null;
};