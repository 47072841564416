import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest } from 'utils/redux-saga-requests';

import { loadStatesFailure, loadStatesSuccess, setStates } from './actions';
import ActionTypes from './constants';

export function* requestStates(): SagaIterator {
  try {
    const response = yield call(getRequest, 'address/states');
    const states = response.data;
    const statesData = keyBy(states, 'id');
    yield all([put(loadStatesSuccess(response)), put(setStates(statesData))]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadStatesFailure(message, error));
  }
}

export function* watchStatesRequest() {
  yield takeLatest(ActionTypes.LOAD_STATES_REQUEST, requestStates);
}

export default function* statesSaga() {
  yield all([fork(watchStatesRequest)]);
}
