import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadBackOfficeDevice } from 'store/modules/backOfficeDevice/actions';
import { DeviceId } from 'types/models/device';

interface Props {
  deviceId?: DeviceId;
}

function BackOfficeDeviceRequest({ deviceId }: Props): null {
  const dispatch = useDispatch();

  useEffect(() => {
    if (deviceId) {
      dispatch(loadBackOfficeDevice(deviceId));
    }
  }, [deviceId, dispatch]);

  return null;
}

export default BackOfficeDeviceRequest;
