import { NESWBounds } from 'google-map-react';
import cloneDeep from 'lodash/cloneDeep';

/**
  * fitBounds breaks if ne/sw's lat or lng is equal
  * {@link https://github.com/google-map-react/google-map-react/issues/207#issuecomment-400579621}
  */
export const getSafeBounds = (bounds: NESWBounds): NESWBounds => {
  const safeBounds = cloneDeep(bounds);

  if (bounds.ne.lat === bounds.sw.lat) {
    safeBounds.ne.lat += 0.0001;
    safeBounds.sw.lat -= 0.0001;
  }

  if (bounds.ne.lng === bounds.sw.lng) {
    safeBounds.ne.lng += 0.0001;
    safeBounds.sw.lng -= 0.0001;
  }

  return safeBounds;
};