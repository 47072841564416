import SamplePoint, { SamplePointId } from 'types/models/samplePoint';

export interface CameraImageType {
  id: number;
  url: string | null;
  size: number;
  status: CameraImageStatus;
  statusCode: RawCameraImageStatusCode;
  samplePointId: SamplePointId;
  samplePointSid: string; // uuid
  attributes:
  | (Omit<CameraSettings, 'imageQuality'> & { imageSize: CameraImageQuality })
  | null;
  createdBy: {
    firstName: string;
    lastName: string;
  } | null;
  updatedAt: number;
  createdAt: number;
}

export type CameraDate = Pick<CameraImageType, 'createdAt'>;

export enum RawCameraImageStatusCode {
  MS_POSTED_TO_GATEWAY = 2,
  MS_POSTED_TO_GATEWAY_FAILED = 42,
  MS_REQ_DELIVERED_TO_MONITOR = 3,
  MS_BLOCK_ASSEMBLY_IN_PROGRESS = 4,
  MS_PAYLOAD_DELIVERED = 5,
  MS_PAYLOAD_INCOMPLETE = 6,
  MS_DELIVERY_FAILED = 7,
  MS_EVENT_RECEIVED_SUCCESS = 8,
  MS_COMMS_ERROR = 9,
  MS_MONITOR_CAMERA_ACTIVE = 12,
  MS_MONITOR_CAMERA_FAILED_TO_RESPOND = 14,
  MS_MONITOR_CAMERA_FAILED_WHILE_COMPOSING_IMAGE = 15,
  MS_MONITOR_CAMERA_FAILED_DURING_TRANSFER = 16,
  MS_MONITOR_CAMERA_FAILED_MAX_TIMEOUT = 17,
  MS_MONITOR_CAMERA_SD_CARD_MOUNT_ERROR = 18,
  MS_MONITOR_CAMERA_SD_CARD_READ_ERROR = 19,
  MS_MONITOR_CAMERA_SD_CARD_MK_NEW_FILE_ERROR = 20,
  MS_MONITOR_CAMERA_SD_CARD_WRITE_ERROR = 21,
  MS_MONITOR_CAMERA_NACK_ABORT = 22,
  MS_MONITOR_CAMERA_FAIL_DURING_IMAGE_CAPTURE = 23,
  MS_MONITOR_CAMERA_FAIL_MODULE_CAMERA_ERR = 24,
  MS_MONITOR_CAMERA_FAIL_CRC_ERROR_ON = 25
}

export enum CameraImageStatus {
  READY = 'ready',
  ERROR = 'error',
  REQUESTED = 'requested',
  PROCESSING = 'processing'
}

export enum CameraStatus {
  SETUP = 'Setup',
  STANDBY = 'Standby',
  PROCESSING = 'Processing',
  DONE = 'Done',
  ERROR = 'Error'
}

// The size (in KB) of the base data package for camera.
// If the value of samplePoint.config.maxKb is greater than this, an add-on data
// pack has been purchased, with a value of maxKb - CAMERA_DATA_PLAN_BASE_KB.
export const CAMERA_DATA_PLAN_BASE_KB = 1281;

type CameraZoom = '1x' | '2x' | '4x' | '8x' | '12x';
type CameraImageQuality = 'low' | 'standard' | 'high';
type CameraImageColor = 'black-and-white' | 'colour';

export interface CameraSettings {
  zoom: CameraZoom;
  imageQuality: CameraImageQuality;
  imageColour: CameraImageColor;
}

export type CameraSettingsKeys = keyof CameraSettings;

export interface CameraListItem {
  samplePoint: SamplePoint;
  image?: CameraImageType;
  status?: CameraStatus;
}
