import keyBy from 'lodash/keyBy';

import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';
import { upsertSafeChildProperties } from 'utils/Object/properties/upsert-child-property';

import { CiboActionType } from './constants';
import { CiboLocationsState } from './types';

export const initialState: CiboLocationsState = {
  subscriptionSummary: {
    totalPurchasedLocations: 0,
    hasActiveSubscriptions: false
  },
  locations: {},
  locationDataPoints: []
};

function ciboLocationsReducer(
  state: CiboLocationsState = initialState,
  action: ApplicationActions
): CiboLocationsState {
  switch (action.type) {
    case CiboActionType.SET_CIBO_SUBSCRIPTION_SUMMARY: {
      const {
        payload: { subscriptionSummary }
      } = action;

      return {
        ...state,
        subscriptionSummary
      };
    }
    case CiboActionType.SET_CIBO_LOCATION: {
      const {
        payload: { id, location }
      } = action;

      return upsertSafeChildProperties(state, ['locations', id], location);
    }
    case CiboActionType.SET_CIBO_LOCATIONS: {
      const {
        payload: { locations }
      } = action;

      return upsertSafeChildProperties(state, ['locations'], keyBy(locations, 'id'));
    }
    case CiboActionType.UNSET_CIBO_LOCATION: {
      const {
        payload: { locationId }
      } = action;

      return upsertSafeChildProperties(state, ['locations', locationId], undefined);

    }
    case CiboActionType.SET_CIBO_IMAGERIES: {
      const {
        payload: { imagerySets }
      } = action;
      const locations = Object.values(state.locations).reduce(
        (acc, location) => ({
          ...acc,
          [location.id]: {
            ...location,
            imagerySet: imagerySets.find(
              ({ locationId }) => locationId === location.id
            )
          }
        }),
        {}
      );

      return {
        ...state,
        locations
      };
    }
    case CiboActionType.SET_CIBO_IMAGERY: {
      const {
        payload: { imagerySet }
      } = action;

      return upsertSafeChildProperties(
        state,
        ['locations', imagerySet.locationId, 'imagerySet'],
        imagerySet
      );
    }
    case CiboActionType.SET_CIBO_LOCATION_STATUS: {
      const {
        payload: { locationId, status }
      } = action;

      return upsertSafeChildProperties(state, ['locations', locationId, 'status'], status);
    }
    case CiboActionType.SET_CIBO_LOCATION_DATA_POINTS: {
      const {
        payload: { dataPoints }
      } = action;

      return {
        ...state,
        locationDataPoints: dataPoints
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default ciboLocationsReducer;
