import { Col, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import {
  selectBackOfficeDeviceDataSources,
  selectBackOfficeDeviceFilters
} from 'store/modules/backOfficeDevices/selectors';
import { BackOfficeDeviceCameraFilterValues } from 'store/modules/backOfficeDevices/types';
import { loadBackOfficeEnterpriseSites } from 'store/modules/backOfficeEnterpriseSites/actions';
import useBackOfficeEnterpriseSites from 'store/modules/backOfficeEnterpriseSites/hooks';
import {
  FILTER_NOT_NULL,
  FILTER_NULL
} from 'store/modules/routerUtils/selectors';
import { SearchTypes } from 'store/modules/search/types';
import { BackhaulType } from 'types/models/device';
import { EnterpriseId } from 'types/models/enterprise';
import useSearch from 'utils/use-search';

interface Props {
  form?: FormInstance;
  initialValues?: any;
  onFinish?: (values: Record<string, unknown>) => void;
}

function BackOfficeDeviceFilterForm({
  initialValues,
  onFinish,
  form
}: Props): JSX.Element {
  const filters = useSelector(selectBackOfficeDeviceFilters);
  const dataSources = useSelector(selectBackOfficeDeviceDataSources);

  const [enterpriseId, setEnterpriseId] = useState<
    EnterpriseId | undefined | null
  >(
    initialValues?.enterpriseId?.[0] === FILTER_NULL
      ? null
      : initialValues?.enterpriseId?.[0]
  );

  const dispatch = useDispatch();

  // Loads sites for given enterpriseId.
  useEffect(() => {
    if (enterpriseId) {
      dispatch(loadBackOfficeEnterpriseSites(enterpriseId));
    }
  }, [enterpriseId, dispatch]);

  const [enterpriseSearchTerm, setEnterpriseSearchTerm] = useState<
    string | undefined
  >(enterpriseId ? enterpriseId.toString() : (enterpriseId as undefined));

  const enterpriseSearchResults = useSearch(
    SearchTypes.UserEnterpriseMembershipSearch,
    enterpriseSearchTerm
  );
  const enterpriseOptions = useMemo(
    () => [
      ...(enterpriseSearchTerm
        ? []
        : [{ label: 'No Enterprise', value: FILTER_NULL }]),
      ...(enterpriseSearchResults || []).map(({ name, id }) => ({
        label: name,
        value: id.toString()
      }))
    ],
    [enterpriseSearchTerm, enterpriseSearchResults]
  );

  const sites = useBackOfficeEnterpriseSites();

  const siteOptions = useMemo(
    () => [
      { label: 'No Site', value: FILTER_NULL },
      ...sites.map((site) => ({
        label: site.name,
        value: site.id.toString()
      }))
    ],
    [sites]
  );

  const filterDropdowns = useMemo(() => {
    if (!filters || !dataSources) {
      return null;
    }

    return (
      <Col span={24}>
        {Object.entries(filters).map(([key, values]) => (
          <DefaultFormItem name={key} key={key}>
            <Select
              mode="multiple"
              options={[
                // Add 'Not Null' as an option.
                {
                  label: `Non-empty ${startCase(key)}`,
                  value: FILTER_NOT_NULL
                },
                ...values
              ]
                // Map values to structure required by Select.
                // 'Null' value is mapped to an appropriate labelled option.
                .map(
                  ({
                    label,
                    value
                  }: {
                    label?: string;
                    value: BackhaulType | string | null;
                  }) => ({
                    label: label || value || `No ${startCase(key)}`,
                    value: value || FILTER_NULL
                  })
                )
                // Sort options, so that 'Null' & 'Not Null' appear first.
                .sort((a, b) => {
                  if (a.value === FILTER_NULL) {
                    return -1;
                  }
                  if (b.value === FILTER_NULL) {
                    return 1;
                  }
                  return 0;
                })}
              placeholder={startCase(key)}
            />
          </DefaultFormItem>
        ))}
        <DefaultFormItem name="dataSourceId">
          <Select
            mode="multiple"
            placeholder="Data Source"
            options={Object.entries(dataSources).map(
              ([key, { name, environment }]) => ({
                label: `${name} ${environment}`,
                value: key
              })
            )}
          />
        </DefaultFormItem>
      </Col>
    );
  }, [filters, dataSources]);

  return (
    <DefaultForm
      form={form}
      layout="vertical"
      name="backOfficeDevice"
      autoComplete="off"
      initialValues={initialValues}
      onFinish={onFinish}
      requiredMark="optional"
      closeDrawerOnSubmit
    >
      {filterDropdowns}
      <DefaultFormItem name="enterpriseId">
        <Select
          filterOption={false}
          showSearch
          onFocus={() => setEnterpriseSearchTerm(undefined)}
          onBlur={() => setEnterpriseSearchTerm(undefined)}
          onSearch={setEnterpriseSearchTerm}
          options={enterpriseOptions}
          placeholder="Enterprise"
          onSelect={(value) => {
            if (value === FILTER_NULL) {
              setEnterpriseId(null);
            } else {
              setEnterpriseId(Number(value));
            }
            // Clear site field when an enterprise is selected, so the device is
            // not in an inconsistent state.
            form?.setFieldsValue({ siteId: undefined });
            setEnterpriseSearchTerm(undefined);
          }}
        />
      </DefaultFormItem>
      <DefaultFormItem name="siteId">
        <Select
          placeholder="Site"
          options={siteOptions}
          disabled={!enterpriseId}
        />
      </DefaultFormItem>
      <DefaultFormItem name="camera">
        <Select
          placeholder="Camera"
          options={[
            {
              label: 'With camera',
              value: BackOfficeDeviceCameraFilterValues.WITH_CAMERA
            },
            {
              label: 'Without camera',
              value: BackOfficeDeviceCameraFilterValues.WITHOUT_CAMERA
            }
          ]}
        />
      </DefaultFormItem>
    </DefaultForm>
  );
}

export default memo(BackOfficeDeviceFilterForm, isEqual);
