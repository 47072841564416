import { Input, Tooltip } from 'antd';
import type { InputRef } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { selectRouterQueryStringSearch } from 'store/modules/routerUtils/selectors';

interface Props {
  className?: string;
  placeholder?: string | undefined;
  autoFocusOnLoad?: boolean;
  size?: SizeType;
  tooltipContent?: string | JSX.Element;
}

/** A search bar that shares its inner value via URL query params */
function SearchInput({
  className,
  placeholder,
  autoFocusOnLoad,
  size = 'small',
  tooltipContent
}: Props): JSX.Element {
  const search = useSelector(selectRouterQueryStringSearch);
  const inputRef = useRef<InputRef>(null);

  const dispatch = useDispatch();

  const [val, setVal] = useState(search);
  const [tooltipTitle, setTooltipTitle] = useState(tooltipContent);

  useEffectOnce(() => {
    if (autoFocusOnLoad) {
      inputRef.current?.focus({
        cursor: 'start'
      });
    }
  });

  const debouncedFunc = useMemo(
    () =>
      debounce((searchTerm?: string) => {
        dispatch(
          appendValuesToQueryString({
            search: searchTerm || undefined,
            currentPage: undefined
          })
        );
      }, 300),
    [dispatch]
  );

  useUpdateEffect(() => {
    debouncedFunc(val);
    if (val) {
      setTooltipTitle(undefined);
    }
  }, [val, debouncedFunc]);

  return (
    <Tooltip title={tooltipTitle}>
      <Input
        className={className}
        allowClear
        size={size}
        value={val}
        placeholder={placeholder}
        onChange={({ currentTarget }) => {
          setVal(currentTarget.value);
        }}
        ref={inputRef}
        prefix={<FiSearch />}
      />
    </Tooltip>
  );
}

export default SearchInput;
