import { useDispatch } from 'react-redux';

import useAssetType from 'store/modules/assetTypes/hooks';
import { fetchSamplesCsvContent } from 'store/modules/exports/actions';
import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import { isTroughDirect } from 'utils/SamplePoints/trough';

import useExportBoreCsv from './use-export-bore-csv';
import useExportDamCsv from './use-export-dam-csv';
import useExportLinePressureCsv from './use-export-line-pressure-csv';
import useExportPipeCsv from './use-export-pipe-csv';
import useExportRainGaugeCsv from './use-export-rain-gauge-csv';
import useExportSafetyCheckInCsv from './use-export-safety-check-in-csv';
import useExportTroughDirectCsv from './use-export-trough-direct';
import useExportWaterTankCsv from './use-export-water-tank-csv';

export default function useExportCsv(
  assetType: AssetTypeCode,
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  // TODO: Remove it. This onFinish is actually onDismiss of the modal. We're not using it at all.
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const assetTypeName = useAssetType(assetType).name;
  // We are going to gradually replace all the following exportXXXCsv functions with downloadCsvFromBackend(), which
  // should support all the asset types.
  const downloadCsvFromBackend = async () => {
    dispatch(fetchSamplesCsvContent(
      assetTypeName,
      dateRange,
      samplePoint.id,
      samplePoint.name ?? '',
      setLoading
    ));
  };

  const exportBoreCsv = useExportBoreCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportDamCsv = useExportDamCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportLinePressureCsv = useExportLinePressureCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportSafetyCheckInCsv = useExportSafetyCheckInCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportRainGaugeCsv = useExportRainGaugeCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportWaterTankCsv = useExportWaterTankCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportFlowCsv = useExportPipeCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );
  const exportTroughDirectCsv = useExportTroughDirectCsv(
    dateRange,
    samplePoint,
    setLoading,
    onFinish
  );

  switch (assetType) {
    case AssetTypeCode.BORE:
      return exportBoreCsv;
    case AssetTypeCode.DAM:
      return exportDamCsv;
    case AssetTypeCode.LINE_PRESSURE:
      return exportLinePressureCsv;
    case AssetTypeCode.RAIN_GAUGE:
      return exportRainGaugeCsv;
    case AssetTypeCode.SAFETY_CHECK_IN:
      return exportSafetyCheckInCsv;
    case AssetTypeCode.WATER_TANK:
      return exportWaterTankCsv;
    case AssetTypeCode.PIPE:
      return exportFlowCsv;
    case AssetTypeCode.FUEL_TANK:
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
      return downloadCsvFromBackend;
    case AssetTypeCode.TROUGH:
      return isTroughDirect(samplePoint)
        ? exportTroughDirectCsv
        : downloadCsvFromBackend;
    default:
      throw new Error(`Unsupported csv export asset type: ${assetType}`);
  }
}
