import React from 'react';

import classNamesWithBase from 'utils/classnames-with-base';

import { ReactComponent as SafetyCheckInIcon } from './safety-check-in.svg';

import './styles.less';

interface Props {
  className?: string;
  coloured?: boolean;
}

function FarmbotSafetyCheckInIcon({ className, coloured = false }: Props) {
  return (
    <SafetyCheckInIcon
      className={classNamesWithBase(
        'FarmbotSafetyCheckInIcon',
        {
          red: coloured
        },
        className
      )}
    />
  );
}

export default FarmbotSafetyCheckInIcon;
