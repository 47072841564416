import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';

import CiboSubscriptionListPage from 'components/features/backOffice/CiboSubscriptionListPage';
import BackOfficeEnterpriseLoadingGate from 'components/loadingGates/BackOfficeEnterpriseLoadingGate';
import BackOfficeEnterpriseRequest from 'components/requests/BackOfficeEnterpriseRequest';
import {
  addBackOfficeCiboSubscription,
  cancelBackOfficeCiboSubscription,
  fetchAllBackOfficeCiboLocations,
  fetchAllBackOfficeCiboSubscriptionsLatestFirst,
  updateBackOfficeCiboSubscriptionRenew
} from 'store/modules/backOfficeCiboSubscriptions/actions';
import ActionTypes from 'store/modules/backOfficeCiboSubscriptions/constants';
import { useCiboLocations, useCiboSubscriptions } from 'store/modules/backOfficeCiboSubscriptions/hooks';
import { useIsLoading } from 'store/modules/loading/hooks';

function CiboSubscriptionListPageContainer() {
  const { enterpriseId: enterpriseIdString } = useParams();
  const enterpriseId = Number(enterpriseIdString);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBackOfficeCiboSubscriptionsLatestFirst(enterpriseId));
    dispatch(fetchAllBackOfficeCiboLocations(enterpriseId));
  }, [dispatch, enterpriseId]);

  const subscriptions = useCiboSubscriptions(enterpriseId);
  const locations = useCiboLocations(enterpriseId);

  const handleSubscriptionAdd = (numberOfLocations: number) => {
    dispatch(addBackOfficeCiboSubscription({ enterpriseId, numberOfLocations }));
  };
  const handleSubscriptionCancel = (subscriptionId: number) => {
    dispatch(cancelBackOfficeCiboSubscription(subscriptionId));
  };
  const handleAutoRenewUpdate = (subscriptionId: number, isAutoRenewActive: boolean) => {
    dispatch(updateBackOfficeCiboSubscriptionRenew(subscriptionId, isAutoRenewActive));
  };

  const isFetchingSubscriptions = useIsLoading(ActionTypes.FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_REQUEST);
  const isAddingSubscription = useIsLoading(ActionTypes.ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST);
  const isCancellingSubscription = useIsLoading(ActionTypes.CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST);

  return (
    <>
      {/* In case user enters this page directly without the enterprise being loaded yet */}
      <BackOfficeEnterpriseRequest enterpriseId={enterpriseId} />
      <BackOfficeEnterpriseLoadingGate enterpriseId={enterpriseId}>
        <CiboSubscriptionListPage
          enterpriseId={enterpriseId}
          isFetchingSubscriptions={isFetchingSubscriptions}
          subscriptions={subscriptions}
          locations={locations}
          onSubscriptionAdd={handleSubscriptionAdd}
          isAddingSubscription={isAddingSubscription}
          onSubscriptionCancel={handleSubscriptionCancel}
          isCancellingSubscription={isCancellingSubscription}
          onAutoRenewUpdate={handleAutoRenewUpdate}
        />
      </BackOfficeEnterpriseLoadingGate>
    </>
  );
}

export default CiboSubscriptionListPageContainer;