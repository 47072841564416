enum ActionTypes {
  TAKE_PHOTO_REQUEST = 'TAKE_PHOTO_REQUEST',
  TAKE_PHOTO_SUCCESS = 'TAKE_PHOTO_SUCCESS',
  TAKE_PHOTO_FAILURE = 'TAKE_PHOTO_FAILURE',

  LOAD_CAMERA_IMAGES_REQUEST = 'LOAD_CAMERA_IMAGES_REQUEST',
  LOAD_CAMERA_IMAGES_SUCCESS = 'LOAD_CAMERA_IMAGES_SUCCESS',
  LOAD_CAMERA_IMAGES_FAILURE = 'LOAD_CAMERA_IMAGES_FAILURE',

  LOAD_CAMERA_IMAGE_DATES_REQUEST = 'LOAD_CAMERA_IMAGE_DATES_REQUEST',
  LOAD_CAMERA_IMAGE_DATES_SUCCESS = 'LOAD_CAMERA_IMAGE_DATES_SUCCESS',
  LOAD_CAMERA_IMAGE_DATES_FAILURE = 'LOAD_CAMERA_IMAGE_DATES_FAILURE',

  LOAD_CAMERA_STATUS_REQUEST = 'LOAD_CAMERA_STATUS_REQUEST',
  LOAD_CAMERA_STATUS_SUCCESS = 'LOAD_CAMERA_STATUS_SUCCESS',
  LOAD_CAMERA_STATUS_FAILURE = 'LOAD_CAMERA_STATUS_FAILURE',

  REVOKE_CAMERA_IMAGES_PAGE = 'REVOKE_CAMERA_IMAGES_PAGE',

  GET_CAMERA_SCHEDULES_REQUEST = 'GET_CAMERA_SCHEDULES_REQUEST',
  GET_CAMERA_SCHEDULES_SUCCESS = 'GET_CAMERA_SCHEDULES_SUCCESS',
  GET_CAMERA_SCHEDULES_FAILURE = 'GET_CAMERA_SCHEDULES_FAILURE',

  ADD_CAMERA_SCHEDULE_REQUEST = 'ADD_CAMERA_SCHEDULE_REQUEST',
  ADD_CAMERA_SCHEDULE_SUCCESS = 'ADD_CAMERA_SCHEDULE_SUCCESS',
  ADD_CAMERA_SCHEDULE_FAILURE = 'ADD_CAMERA_SCHEDULE_FAILURE',

  DELETE_CAMERA_SCHEDULE_REQUEST = 'DELETE_CAMERA_SCHEDULE_REQUEST',
  DELETE_CAMERA_SCHEDULE_SUCCESS = 'DELETE_CAMERA_SCHEDULE_SUCCESS',
  DELETE_CAMERA_SCHEDULE_FAILURE = 'DELETE_CAMERA_SCHEDULE_FAILURE'
}

export default ActionTypes;
