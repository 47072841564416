import { LegalBrand } from 'types/legal-brand.enum';
import { WhiteLabelTitle } from 'types/models/white-label';
import getWhiteLabelConfig from 'utils/get-white-label-config';

export const getLegalBrand = (): LegalBrand => {
  const { title: whiteLabel } = getWhiteLabelConfig();

  switch (whiteLabel) {
    case WhiteLabelTitle.FARMBOT:
      return LegalBrand.FARMBOT;
    case WhiteLabelTitle.RANCHBOT:
      return LegalBrand.RANCHBOT;
    default:
      return LegalBrand.FARMBOT;
  };
};