import roundNumber from 'utils/round-number';

interface Options {
  suffix?: string;
  noSuffixSpace?: boolean;
  decimalPlaces?: number;
}

/**
 * A function that helps achieve consistent formatting when displaying quantity
 * on the UI.
 *
 * Default to 1 decimal place.
 */
function formatQuantity(value?: number | string, options?: Options): string {
  const suffix = options?.suffix;
  const decimalPlaces = options?.decimalPlaces;

  const numberValue = Number(
    // Turn '1,234,567.89' into '1234567.89' to allow correct parsing.
    typeof value === 'string' ? value.replace(/,/g, '') : value
  );

  if (!numberValue && numberValue !== 0) {
    return '';
  }

  const space = options?.noSuffixSpace || suffix === '%' ? '' : ' ';

  return `${roundNumber(numberValue, { decimalPlaces }).toLocaleString()}${
    suffix ? `${space}${suffix}` : ''
  }`;
}

export default formatQuantity;
