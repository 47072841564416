import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React, { memo } from 'react';

import './styles.less';

const LearnMoreTooltip = ({
  title,
  link,
  children,
  showInfoIcon = true
}: {
  title: string;
  link?: string;
  children: string | JSX.Element;
  showInfoIcon?: boolean;
}) => (
  <Tooltip
    title={
      <>
        {title}{' '}
        {link && <Typography.Link
          href={link}
          target="_blank"
          className="LearnMoreTooltip-link"
        >
          Learn More
        </Typography.Link>}
      </>
    }
  >
    {children}
    {showInfoIcon && <InfoCircleOutlined className="LearnMoreTooltip-icon" />}
  </Tooltip>
);

export default memo(LearnMoreTooltip);
