import countBy from 'lodash/countBy';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';

import rollbarLogger from 'config/rollbar';
import {
  makeSelectSamplePointsByAssetTypeIdForDefaultSite
} from 'store/modules/samplePoints/selectors';
import { ApplicationRootState } from 'store/types';
import { AssetTypeCode } from 'types/models/asset-type';
import { EventId, EventSamplePoint, Level, SamplePointEvent } from 'types/models/event';
import Notification, {
  NotificationEventType,
  NotificationStatus
} from 'types/models/notification';
import SamplePoint, { MergedSamplePoint, SamplePointId } from 'types/models/samplePoint';
import { convertSamplePointToEventSamplePoint } from 'utils/Notification/convert-sample-point-to-event-sample-point';
import { getEventSamplePointFromNotification } from 'utils/Notification/get-event-sample-point-from-notification';
import { getNotificationSiteId } from 'utils/Notification/get-notification-site';
import { getNotificationEventType } from 'utils/Notification/notification-event-type';

import { RecentNotificationsState } from './types';
import { selectDefaultSite } from '../localUserSettings/selectors';
import { selectSites } from '../sites/selectors';

const selectRecentNotificationsState = (state: ApplicationRootState) =>
  state.recentNotifications;

const selectRecentNotificationsEnterpriseId = createSelector(
  selectRecentNotificationsState,
  (recentNotificationsState) => recentNotificationsState.enterpriseId as number
);

const selectRecentNotificationsPendingStatusChanges = createSelector(
  selectRecentNotificationsState,
  (recentNotificationsState) =>
    recentNotificationsState.pendingStatusChanges || {}
);

const selectRecentNotifications = createSelector(
  [
    selectRecentNotificationsState,
    selectSites,
    makeSelectSamplePointsByAssetTypeIdForDefaultSite(AssetTypeCode.SAFETY_CHECK_IN),
    selectRecentNotificationsPendingStatusChanges
  ],
  (
    recentNotificationsState,
    sites,
    safetyCheckSamplePoints,
    recentNotificationsPendingAcknowledgements
  ) => {
    try {
      const validNotifications: Notification[] = Object
        .values(recentNotificationsState.data)
        .filter((n: Notification) => !!n.event); // Filter out cached notifications that do not follow our expected type

      const remapStatusAndSamplePoint = (notification: Notification): Notification => {
        const pendingAcknowledgement = recentNotificationsPendingAcknowledgements[notification.id];
        const status = pendingAcknowledgement ? NotificationStatus.ACKNOWLEDGED : notification.status;

        const event = { ...notification.event };
        const isSamplePointEvent = getNotificationEventType(event) === NotificationEventType.SAMPLE_POINT_EVENT;

        // If event level SOS and there's a matching safety check sample point,
        // replace the notification's sample point with the SOS sample point
        if (isSamplePointEvent && event.level === Level.SOS) {
          const notificationSamplePoint: EventSamplePoint = getEventSamplePointFromNotification(notification)!;

          const matchingSafetyCheck: SamplePoint | undefined = (safetyCheckSamplePoints as MergedSamplePoint[])
            .find((sp) => sp._hidden?.id === notificationSamplePoint.id);

          if (matchingSafetyCheck) {
            const samplePointToReplaceWith: EventSamplePoint = convertSamplePointToEventSamplePoint(
              matchingSafetyCheck,
              sites[matchingSafetyCheck.siteId]
            );
            (event as SamplePointEvent).samplePoint = samplePointToReplaceWith;
          }
        }

        return {
          ...notification,
          status,
          event
        };
      };

      const notificationsKeyedById: RecentNotificationsState['data'] = keyBy(
        validNotifications.map(remapStatusAndSamplePoint),
        'id'
      );
      return notificationsKeyedById;
    } catch (error) {
      rollbarLogger.error(
        'Notifications error',
        error as Record<string, unknown>,
        recentNotificationsState.data
      );
      return recentNotificationsState.data;
    }
  }
);

const selectRecentNotificationsForDefaultSite = createSelector(
  [selectRecentNotifications, selectDefaultSite],
  (recentNotifications, defaultSite) =>
    pickBy(
      recentNotifications,
      (notification) => getNotificationSiteId(notification) === defaultSite?.id
    )
);

const selectRecentNotificationsAsArray = createSelector(
  selectRecentNotifications,
  (recentNotifications) => Object.values(recentNotifications)
);

const selectRecentNotificationsForDefaultSiteAsArray = createSelector(
  selectRecentNotificationsForDefaultSite,
  (recentNotificationsForDefaultSite) =>
    Object.values(recentNotificationsForDefaultSite)
);

const selectRecentNotificationsAsArrayOrderedByEventDate = createSelector(
  selectRecentNotificationsAsArray,
  (recentNotificationsAsArray) =>
    orderBy(recentNotificationsAsArray, 'event.date', 'desc')
);

const selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate =
  createSelector(
    selectRecentNotificationsForDefaultSiteAsArray,
    (recentNotificationsForDefaultSiteAsArray) =>
      orderBy(recentNotificationsForDefaultSiteAsArray, 'event.date', 'desc')
  );

const selectRecentUnacknowledgedNotificationsAsArray = createSelector(
  selectRecentNotificationsAsArrayOrderedByEventDate,
  (recentNotificationsAsArrayOrderedByEventDate) =>
    recentNotificationsAsArrayOrderedByEventDate.filter(
      ({ status }) =>
        status === NotificationStatus.SENT
    )
);

const selectRecentUnacknowledgedNotificationsForDefaultSiteAsArray =
  createSelector(
    selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate,
    (recentNotificationsAsArrayOrderedByEventDate) =>
      recentNotificationsAsArrayOrderedByEventDate.filter(
        ({ status }) =>
          status === NotificationStatus.SENT
      )
  );

const selectRecentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId =
  createSelector(
    selectRecentUnacknowledgedNotificationsAsArray,
    (recentUnacknowledgedNotificationsAsArray) =>
      groupBy(
        recentUnacknowledgedNotificationsAsArray,
        'event.trigger.samplePointId'
      )
  );

const makeSelectRecentUnacknowledgedNotificationsByEventTriggerSamplePointId = (
  samplePointId: SamplePointId
) =>
  createSelector(
    selectRecentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId,
    (recentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId) =>
      recentUnacknowledgedNotificationsGroupedByEventTriggerSamplePointId[samplePointId]
  );

const makeSelectRecentNotificationByEventId = (eventId: EventId) =>
  createSelector(
    selectRecentNotificationsAsArrayOrderedByEventDate,
    (recentNotifications) => {
      return recentNotifications.find((n) => n.eventId === eventId);
    }
  );

const selectRecentUnacknowledgedNotificationsCount = createSelector(
  selectRecentUnacknowledgedNotificationsAsArray,
  (recentUnacknowledgedNotificationsAsArray) => {
    return countBy(
      recentUnacknowledgedNotificationsAsArray,
      (n) => getNotificationSiteId(n)
    );
  }
);

export {
  makeSelectRecentNotificationByEventId,
  makeSelectRecentUnacknowledgedNotificationsByEventTriggerSamplePointId,
  selectRecentNotifications,
  selectRecentNotificationsAsArray,
  selectRecentNotificationsEnterpriseId,
  selectRecentNotificationsForDefaultSiteAsArrayOrderedByEventDate,
  selectRecentNotificationsPendingStatusChanges,
  selectRecentNotificationsState,
  selectRecentUnacknowledgedNotificationsAsArray,
  selectRecentUnacknowledgedNotificationsCount,
  selectRecentUnacknowledgedNotificationsForDefaultSiteAsArray
};
