import { IPCameraStreamId } from 'types/models/ip-camera';

export interface IPCameraPreference {
  streamId: IPCameraStreamId;
}

export interface SamplePointPreference {
  graphYAxisBeginsAtZero?: boolean;
}

export enum EntityKind {
  IP_CAMERA = 'ip-camera',
  SAMPLE_POINT = 'sample-point'
}

export type EntityId = string;

export type PreferenceValue = IPCameraPreference | SamplePointPreference;

export interface UserPreference {
  id: number;
  userId: number;
  entityKind: EntityKind;
  entityId: EntityId;
  preference: PreferenceValue;
}
