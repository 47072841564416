import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { DeviceId } from 'types/models/device';

import ActionTypes from './constants';
import { BackOfficeDevice } from './types';

export const loadBackOfficeDevice = (deviceId: DeviceId) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_REQUEST, { deviceId });

export const loadBackOfficeDeviceSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_BACK_OFFICE_DEVICE_SUCCESS, { response });

export const loadBackOfficeDeviceFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setBackOfficeDevice = (device: BackOfficeDevice) =>
  action(ActionTypes.SET_BACK_OFFICE_DEVICE, { device });
