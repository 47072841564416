import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import agriWebbSaga from './modules/agriWebb/saga';
import appScaffold from './modules/appScaffold/saga';
import backOfficeCiboSubscriptionsSaga from './modules/backOfficeCiboSubscriptions/saga';
import backOfficeDeviceSaga from './modules/backOfficeDevice/saga';
import backOfficeDeviceMapPointsSaga from './modules/backOfficeDeviceMapPoints/saga';
import backOfficeDevicesSaga from './modules/backOfficeDevices/saga';
import backOfficeEnterpriseSaga from './modules/backOfficeEnterprise/saga';
import backOfficeEnterprisesSaga from './modules/backOfficeEnterprises/saga';
import backOfficeEnterpriseSitesSaga from './modules/backOfficeEnterpriseSites/saga';
import backOfficeInvitationsSaga from './modules/backOfficeInvitations/saga';
import backOfficeUserSaga from './modules/backOfficeUser/saga';
import backOfficeUsersSaga from './modules/backOfficeUsers/saga';
import camerasSaga from './modules/cameras/saga';
import ciboLocationsSaga from './modules/ciboLocations/saga';
import controlPointsSaga from './modules/controlPoints/saga';
import countriesSaga from './modules/countries/saga';
import devicesSaga from './modules/devices/saga';
import enterpriseSaga from './modules/enterprise/saga';
import enterpriseScaffoldSaga from './modules/enterpriseScaffold/saga';
import ephemeralRequestSaga from './modules/ephemeralRequest/saga';
import exportsSaga from './modules/exports/saga';
import externalDevicesSaga from './modules/integration/externalDevices/saga';
import ipCamerasSaga from './modules/integration/ipCameras/saga';
import invitationSaga from './modules/invitations/saga';
import legalPolicySaga from './modules/legalPolicy/saga';
import myPreferencesSaga from './modules/myPreferences/saga';
import myUserSaga from './modules/myUser/saga';
import notificationPolicySaga from './modules/notificationPolicy/saga';
import recentNotificationsSaga from './modules/recentNotifications/saga';
import routerUtilsSaga from './modules/routerUtils/saga';
import samplePointSaga from './modules/samplePoints/saga';
import samplePointsStatisticsSaga from './modules/samplePointsStatistics/saga';
import samplesSaga from './modules/samples/saga';
import searchSaga from './modules/search/saga';
import sitesSaga from './modules/sites/saga';
import statesSaga from './modules/states/saga';
import timeSaga from './modules/time/saga';
import trackingSaga from './modules/tracking/saga';
import triggersSaga from './modules/triggers/saga';
import userPermissionsSaga from './modules/userPermissions/saga';
import usersSaga from './modules/users/saga';
import volumeMappingsSaga from './modules/volumeMappings/saga';

function* rootSaga(): SagaIterator {
  yield all([
    fork(agriWebbSaga),
    fork(appScaffold),
    fork(backOfficeCiboSubscriptionsSaga),
    fork(backOfficeDeviceMapPointsSaga),
    fork(backOfficeDeviceSaga),
    fork(backOfficeDevicesSaga),
    fork(backOfficeEnterpriseSaga),
    fork(backOfficeEnterpriseSitesSaga),
    fork(backOfficeEnterprisesSaga),
    fork(backOfficeInvitationsSaga),
    fork(backOfficeUserSaga),
    fork(backOfficeUsersSaga),
    fork(camerasSaga),
    fork(ciboLocationsSaga),
    fork(controlPointsSaga),
    fork(countriesSaga),
    fork(devicesSaga),
    fork(enterpriseSaga),
    fork(enterpriseScaffoldSaga),
    fork(ephemeralRequestSaga),
    fork(exportsSaga),
    fork(externalDevicesSaga),
    fork(invitationSaga),
    fork(ipCamerasSaga),
    fork(legalPolicySaga),
    fork(myPreferencesSaga),
    fork(myUserSaga),
    fork(notificationPolicySaga),
    fork(recentNotificationsSaga),
    fork(routerUtilsSaga),
    fork(samplePointSaga),
    fork(samplePointsStatisticsSaga),
    fork(samplesSaga),
    fork(searchSaga),
    fork(sitesSaga),
    fork(statesSaga),
    fork(timeSaga),
    fork(trackingSaga),
    fork(triggersSaga),
    fork(userPermissionsSaga),
    fork(usersSaga),
    fork(volumeMappingsSaga)
  ]);
}

export default rootSaga;
