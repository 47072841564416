import { message as antdMessage } from 'antd';
import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { setBackOfficeEnterprise } from 'store/modules/backOfficeEnterprise/actions';
import { selectBackOfficeEnterprisesRequestParameters } from 'store/modules/routerUtils/selectors';
import Enterprise from 'types/models/enterprise';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import {
  getRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';

import {
  addBackOfficeEnterprise,
  addBackOfficeEnterpriseFailure,
  addBackOfficeEnterpriseSuccess,
  editBackOfficeEnterprise,
  editBackOfficeEnterpriseFailure,
  editBackOfficeEnterpriseSuccess,
  loadBackOfficeEnterprises,
  loadBackOfficeEnterprisesFailure,
  loadBackOfficeEnterprisesSuccess,
  setBackOfficeEnterprises
} from './actions';
import ActionTypes from './constants';
import parserBackOfficeEnterpriseToEnterprise from './sagaHelper';
import { BackOfficeEnterprise } from './types';
import { appendValuesToQueryString } from '../routerUtils/actions';

export function* requestBackOfficeEnterprises(): SagaIterator {
  const parameters = yield select(selectBackOfficeEnterprisesRequestParameters);

  try {
    const { data } = yield call(getRequest, 'enterprise', {
      params: parameters
    });

    yield all([
      put(loadBackOfficeEnterprisesSuccess(data)),
      put(
        setBackOfficeEnterprises({
          total: data.total,
          data: keyBy(
            data.data.map(
              (enterprise: Enterprise): Enterprise =>
                parseBackendPhoneNumber(
                  enterprise,
                  'mobileNumber',
                  'mobileNumberCountryCode',
                  'mobileNumberFormatted'
                )
            ),
            'id'
          ),
          ids: data.data.map((enterprise: Enterprise) => enterprise.id)
        })
      )
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }
    yield put(loadBackOfficeEnterprisesFailure(message, error));
  }
}

export function* watchloadBackOfficeEnterprisesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISES_REQUEST,
    requestBackOfficeEnterprises
  );
}

export function* requestAddBackOfficeEnterprise(
  action: ReturnType<typeof addBackOfficeEnterprise>
) {
  const {
    payload: { values, onSuccess }
  } = action;
  const createEnterpriseDTO = parserBackOfficeEnterpriseToEnterprise(values);

  try {
    const { data } = yield call(postRequest, 'enterprise/v1', {
      ...createEnterpriseDTO
    });
    onSuccess();
    yield all([
      put(loadBackOfficeEnterprises()),
      put(addBackOfficeEnterpriseSuccess(data)),
      // Close enterprise drawer
      put(
        appendValuesToQueryString({
          selectedEnterprise: undefined
        })
      )
    ]);

    antdMessage.success('Enterprise added');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to add enterprise');
    yield put(addBackOfficeEnterpriseFailure(message, error));
  }
}

export function* watchAddBackOfficeEnterprisesRequest() {
  yield takeLatest(
    ActionTypes.ADD_BACK_OFFICE_ENTERPRISE_REQUEST,
    requestAddBackOfficeEnterprise
  );
}

export function* requestEditBackOfficeEnterprise(
  action: ReturnType<typeof editBackOfficeEnterprise>
) {
  const {
    payload: { enterpriseId, values }
  } = action;

  try {
    const enterprise: Partial<BackOfficeEnterprise> = values;
    const { data } = yield call(patchRequest, `enterprise/${enterpriseId}`, {
      ...enterprise,
      billingAddress: {
        id: values.billingAddressId,
        ...enterprise.billingAddress
      }
    });
    yield put(setBackOfficeEnterprise(data));
    yield all([
      put(loadBackOfficeEnterprises()),
      put(editBackOfficeEnterpriseSuccess(data)),
      put(
        appendValuesToQueryString({
          selectedEnterprise: undefined
        })
      )
    ]);

    antdMessage.success('Enterprise edited');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    antdMessage.error('Failed to edit enterprise');
    yield put(editBackOfficeEnterpriseFailure(message, error));
  }
}

export function* watchEditBackOfficeEnterprisesRequest() {
  yield takeLatest(
    ActionTypes.EDIT_BACK_OFFICE_ENTERPRISE_REQUEST,
    requestEditBackOfficeEnterprise
  );
}

export default function* backOfficeEnterprisesSaga() {
  yield all([
    fork(watchloadBackOfficeEnterprisesRequest),
    fork(watchAddBackOfficeEnterprisesRequest),
    fork(watchEditBackOfficeEnterprisesRequest)
  ]);
}
