import { message as antdMessage } from 'antd';
import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { SagaIterator } from 'redux-saga';
import { all, fork, put, takeLatest } from 'redux-saga/effects';

import { fetchExternalDevices, fetchExternalDevicesFailure, fetchExternalDevicesSuccess } from './actions';
import ActionTypes from './constants';
import { fetchIPCameras } from '../ipCameras/actions';

// ==============================
// SAGAS
// ==============================
function* requestFetchExternalDevices(action: ReturnType<typeof fetchExternalDevices>): SagaIterator {
  const { enterpriseId, siteId } = action.payload;

  try {
    // Trigger fetch requests for devices considered as "external", let their own sagas handle the rest
    yield all([
      put(fetchIPCameras(enterpriseId, siteId))
      // ... add other external devices here
    ]);

    yield put(fetchExternalDevicesSuccess({} as AxiosResponse));
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong retrieving external devices.'
    );

    antdMessage.error('Error loading all external devices');
    yield put(fetchExternalDevicesFailure(message, error));
  }
}

// ==============================
// REGISTRATION
// ==============================
function* watchFetchExternalDevicesRequest() {
  yield takeLatest(ActionTypes.FETCH_EXTERNAL_DEVICES_REQUEST, requestFetchExternalDevices);
}

// ==============================
// EXPORT
// ==============================
export default function* externalDevicesSaga() {
  yield all([fork(watchFetchExternalDevicesRequest)]);
}