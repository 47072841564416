import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { makeSelectBackOfficeDeviceCameraSamplePoint } from 'store/modules/backOfficeDevices/selectors';
import { DeviceId } from 'types/models/device';

import selectBackOfficeDevice from './selectors';

export function useBackOfficeDevice() {
  const backOfficeDevice = useSelector(selectBackOfficeDevice, isEqual);

  return backOfficeDevice;
}

export function useBackOfficeDeviceCameraSamplePoint(deviceId?: DeviceId) {
  const selectBackOfficeDeviceCameraSamplePoint = useMemo(
    () => makeSelectBackOfficeDeviceCameraSamplePoint(deviceId),
    [deviceId]
  );

  return useSelector(selectBackOfficeDeviceCameraSamplePoint, shallowEqual);
}

export default useBackOfficeDevice;
