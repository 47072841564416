import React from 'react';

import DefaultCheckbox from 'components/atoms/DefaultCheckbox';

type AutoRenewCheckboxProps = {
  checked: boolean;
  disabled: boolean;
  onAutoRenewUpdate: (subscriptionId: number, isAutoRenewActive: boolean) => void;
  subscriptionId: number;
};

export function AutoRenewCheckbox({
  checked,
  disabled,
  onAutoRenewUpdate,
  subscriptionId
}: AutoRenewCheckboxProps) {
  return (
    <DefaultCheckbox
      disabled={disabled}
      checked={checked}
      onChange={() => { onAutoRenewUpdate(subscriptionId, !checked); }}
    />
  );
}
