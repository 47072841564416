import { Typography } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal';
import classNames from 'classnames';
import React, { memo } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import './styles.less';

export interface WarningModalProps
  extends Pick<
    ModalProps,
    | 'title'
    | 'visible'
    | 'okText'
    | 'cancelText'
    | 'onOk'
    | 'onCancel'
    | 'className'
  > {
  children?: React.ReactNode;
}

/**
 * A modal with a warning icon and red buttons.
 */
const WarningModal: React.NamedExoticComponent<WarningModalProps> = memo(
  ({
    title,
    visible,
    okText,
    cancelText,
    onOk,
    onCancel,
    className,
    children
  }: WarningModalProps) => {
    return (
      <Modal
        className={classNames('WarningModal', className)}
        title={
          <>
            <FiAlertTriangle className="WarningModal-icon" />
            <Typography.Text className="WarningModal">{title}</Typography.Text>
          </>
        }
        visible={visible}
        centered
        closable={false}
        okText={okText}
        cancelText={cancelText}
        onOk={onOk}
        onCancel={onCancel}
        okButtonProps={{
          className: 'WarningModal-button WarningModal-button-ok'
        }}
        cancelButtonProps={{
          className: 'WarningModal-button WarningModal-button-cancel'
        }}
      >
        {children}
      </Modal>
    );
  }
);

export default WarningModal;
