import Sample from 'types/models/sample';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';
import { millisecondsToSeconds } from 'utils/unit-conversions';

/**
 * Calculate the current sample value based on a past sample.
 * @returns The current sample value in centimetre or inch.
 */
function getWaterLevelSampleForecast(
  sample?: Sample,
  /** in seconds */
  forecastDate?: number | null,
  tankHeight?: number | undefined | null,
  gradient?: number,
  country = 'AUS'
) {
  if (!sample) {
    return undefined;
  }
  const waterLevel = convertMetricToImperial(
    UnitType.LEVEL_SM,
    country,
    sample.rwValue
  );
  if (!waterLevel) {
    return undefined;
  }

  if (!gradient || !forecastDate) {
    return waterLevel;
  }

  const minutesToPresent =
    (forecastDate - millisecondsToSeconds(sample.date)) / 60;

  const change = minutesToPresent * gradient;
  const forecastedValue = waterLevel + change;
  if (tankHeight && forecastedValue > tankHeight) {
    return tankHeight;
  }

  if (forecastedValue < 0) {
    return 0;
  }

  return forecastedValue;
}

export default getWaterLevelSampleForecast;
