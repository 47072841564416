import { AssetTypeId } from 'types/models/asset-type';
import { SamplePointId } from 'types/models/samplePoint';
import SamplePointStatistic, { ConnectedSamplePoint } from 'types/models/samplePointsStatistic';

export type ControlPointId = SamplePointId;

/**
 * It's the sample point with additional machine control properties.
 *  We borrowed the name 'control point' from IoT layer tho they're different
 *  things.
 */
export interface ControlPoint {
  assetTypeId: AssetTypeId;
  config: {
    automation: AutomaticControlType;
  };
  connectedToSamplePointSid: string | null;
  id: ControlPointId;
  isConfigured: boolean;
  name: string;
  /** The `status` from BE.
   * We use underscore here to avoid conflict naming in ControlPoint's extended
   * interfaces.
   * (Modify<> doesn't work well with generics) */
  _status: MachineControlStatus;
  /** The `statusCode` from BE.
   * We use underscore here to avoid conflict naming in ControlPoint's extended
   * interfaces. */
  _statusCode: MachineControlStatusCode;
  statusText: string;
  activityLog?: ActivityEvent[];
}

export enum MachineControlStatus {
  ON = 'on',
  OFF = 'off',
  ERROR = 'error',
  TURNING_ON = 'turning-on',
  TURNING_OFF = 'turning-off'
}

/**
 * We repurpose comm status code as machine status code in FE.
 * Camera (src/models/camera.ts) has more status codes.
 */
export enum MachineControlStatusCode {
  MS_UNKNOWN = 0,
  MS_POSTED_TO_GATEWAY = 2,
  MS_REQ_DELIVERED_TO_MONITOR = 3,
  MS_BLOCK_ASSEMBLY_IN_PROGRESS = 4,
  MS_PAYLOAD_DELIVERED = 5,
  MS_PAYLOAD_INCOMPLETE = 6,
  MS_DELIVERY_FAILED = 7,
  MS_EVENT_RECEIVED_SUCCESS = 8,
  MS_COMMS_ERROR = 9,
  MS_POSTED_TO_GATEWAY_FAILED = 42,
  MS_PRIMARY_MC_ON = 100,
  MS_PRIMARY_MC_FAILED = 101,
  MS_SECONDARY_MC_TURNING_ON = 102,
  MS_SECONDARY_MC_ON = 103,
  MS_SECONDARY_MC_FAILED = 104,
  MS_MC_OFF = 105,
  MS_MC_TURNING_OFF = 106,
  MS_CHANGE_CONFIGURATION_SUCCESS = 200,
  MS_CHANGE_CONFIGURATION_REQUESTED_FAILED = 201,
  MS_ACTION_REQUESTED = 210,
  MS_ACTION_REQUESTED_FAILED = 211
}

export enum AutomaticControlType {
  MANUAL,
  AUTO,
  AUTO_PAUSED
}

export enum MachineControlActionType {
  OFF,
  ON
}

export enum MachineControlControlType {
  SIMPLE = 1,
  HIGH_LOW = 2,
  ALERT_EVENT_DRIVEN = 3
}

export type MachineControlAction = {
  actionRequest: MachineControlActionType;
  controlType?: MachineControlControlType;
};

export interface RawMachineControlStatus extends SamplePointStatistic {
  config: {
    automation: AutomaticControlType;
  };
  destinationSamplePoint: ConnectedSamplePoint | null;
  sourceSamplePoint: ConnectedSamplePoint | null;
  /** format HH:mm */
  startRunningWindow: string | null,
  /** format HH:mm */
  stopRunningWindow: string | null,
  id: SamplePointId;
  isConfigured: boolean;
  name: string;
  status: MachineControlStatus;
  statusCode: MachineControlStatusCode;
}

export type ControlPointsState = Record<SamplePointId, ControlPoint>;

export interface MachineControlActionRequestResponse {
  actionRequestId: number;
  /** The id of the control point in IoT layer */
  controlPointId: number;
}

export interface ActivityEvent {
  type: string;
  level: string;
  statusCode: MachineControlStatusCode;
  description: string;
  source: string;
  reason: string;
  date: number;
}
