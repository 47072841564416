import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put, select } from 'redux-saga/effects';

import { EM_DASH } from 'constants/index';
import { AssetTypeCode } from 'types/models/asset-type';
import { EnterpriseId } from 'types/models/enterprise';
import Sample, { MachineControlSample } from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { getRequest } from 'utils/redux-saga-requests';

import { parseSamples } from './utils';
import {
  selectCurrentEnterpriseCountry,
  selectCurrentEnterpriseId
} from '../../enterprise/selectors';
import { makeSelectSamplePointById } from '../../samplePoints/selectors';
import {
  loadMachineControlSamples,
  loadMachineControlSamplesFailure,
  loadMachineControlSamplesSuccess, setControlPointSamples,
  setSamplePointSamples
} from '../actions';

interface MachineControlRawSample {
  id: number;
  currentStatus: MachineControlSample['currentStatus'];
  requestedStatus: MachineControlSample['requestedStatus'];
  statusCode: number;
  date: number;
}

export function* requestMachineControlSamples(
  action: ReturnType<typeof loadMachineControlSamples>
) {
  const {
    payload: {
      machineControlId, startDateMs, endDateMs, siteTimezoneCode, connectedSamplePointIds
    }
  } = action;
  const enterpriseId: EnterpriseId = yield select(selectCurrentEnterpriseId);
  try {
    const startDateInSiteTimezone = moment(startDateMs).tz(siteTimezoneCode);
    const endDateInSiteTimezone = moment(endDateMs).tz(siteTimezoneCode);

    let { data: machineControlSamples }: { data: MachineControlRawSample[]; } = yield call(
      getRequest,
      `enterprise/${enterpriseId}/machine-control/${machineControlId}/sample`,
      {
        params: {
          startDate: startDateInSiteTimezone.format(),
          endDate: endDateInSiteTimezone.format()
        }
      }
    );
    machineControlSamples = machineControlSamples?.sort(
      (a: MachineControlRawSample, b: MachineControlRawSample) => b.date - a.date
    );

    const country: string = yield select(selectCurrentEnterpriseCountry);
    const connectedSamplePointsSamples: Sample[] = [];
    const destinationSamplePointId = connectedSamplePointIds.destination;
    const sourceSamplePointId = connectedSamplePointIds.source;
    const dualSampleCalls = [destinationSamplePointId, sourceSamplePointId]
      .map(id => id && call(getRequest, `samplepoint/${id}/sample`, {
        params: {
          startDate: startDateInSiteTimezone.format(),
          endDate: endDateInSiteTimezone.format()
        }
      }));
    const [destinationResponse, sourceResponse] = yield all(dualSampleCalls);
    const destinationSamples: Sample[] | undefined = destinationResponse?.data;
    const sourceSamples: Sample[] | undefined = sourceResponse?.data;

    const connectedDestinationSP: SamplePoint | undefined = yield destinationSamplePointId && select(
      makeSelectSamplePointById(destinationSamplePointId)
    );
    const connectedSourceSP: SamplePoint | undefined = yield sourceSamplePointId && select(
      makeSelectSamplePointById(sourceSamplePointId)
    );

    const connectedSamplePointData = [
      { samplePoint: connectedDestinationSP, samples: destinationSamples },
      { samplePoint: connectedSourceSP, samples: sourceSamples }
    ];
    for (const { samplePoint, samples } of connectedSamplePointData) {
      if (samplePoint && samples) {
        // Associate machine statuses with liquid level samples to display both in the tooltip.
        const associatedSamples: Sample[] = samples?.map((sample: Sample) => ({
          ...sample,
          machineStatus: (() => {
            for (const machineControlSample of machineControlSamples) {
              if (sample.date >= machineControlSample.date) {
                if (!machineControlSample.currentStatus ||
                  !['off', 'on'].includes(machineControlSample.currentStatus)) {
                  return EM_DASH;
                }
                return machineControlSample.currentStatus as 'off' | 'on';
              }
            }
            return EM_DASH;
          })()
        }));

        const associatedSamplesParsed = parseSamples({
          samples: associatedSamples,
          samplePoint: {
            id: samplePoint.id,
            assetTypeId: samplePoint.assetTypeId
          } as SamplePoint,
          country
        });
        connectedSamplePointsSamples.push(...associatedSamplesParsed);
      }
    }
    yield put(
      setSamplePointSamples(keyBy(connectedSamplePointsSamples, 'id'))
    );

    yield all([
      put(loadMachineControlSamplesSuccess(connectedSamplePointsSamples)),
      put(setControlPointSamples(keyBy(
        machineControlSamples.map((s) => ({
          ...s,
          dataType: AssetTypeCode.MACHINE_CONTROL,
          controlPointId: machineControlId
        })),
        'id')))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadMachineControlSamplesFailure(message, error));
  }
}
