import { Button, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import ActionTypes from 'store/modules/countries/constants';
import { useCountriesAsArray } from 'store/modules/countries/hooks';
import { makeSelectIsLoading } from 'store/modules/loading/selectors';
import Enterprise, { EnterpriseStatus, Industry } from 'types/models/enterprise';

interface Props {
  form?: FormInstance;
  initialValues?: Partial<Enterprise>;
  onFinish?: (values: Record<string, unknown>) => void;
}

const selectIsLoading = makeSelectIsLoading([
  ActionTypes.LOAD_COUNTRIES_REQUEST
]);

function BackOfficeEnterpriseFilterForm({
  form,
  initialValues,
  onFinish
}: Props): JSX.Element {
  const isLoadingCountries = useSelector(selectIsLoading);

  const countries = useCountriesAsArray();
  const countryOptions = useMemo(
    () =>
      countries.map(({ name }) => ({
        label: name,
        value: name
      })),
    [countries]
  );

  return (
    <DefaultForm
      form={form}
      layout="vertical"
      name="backOfficeEnterprise"
      autoComplete="off"
      initialValues={initialValues}
      onFinish={onFinish}
      requiredMark="optional"
      closeDrawerOnSubmit
    >
      <DefaultFormItem name="industry">
        <Select
          mode="multiple"
          options={[
            { label: 'Agriculture', value: Industry.AGRICULTURE },
            { label: 'Mining', value: Industry.MINING }
          ]}
          placeholder="Industry"
        />
      </DefaultFormItem>
      <DefaultFormItem name="status">
        <Select
          mode="multiple"
          options={[
            { label: 'Active', value: EnterpriseStatus.ACTIVE },
            { label: 'Demo', value: EnterpriseStatus.DEMO },
            { label: 'Disabled', value: EnterpriseStatus.DISABLED },
            { label: 'Duplicate', value: EnterpriseStatus.DUPLICATE },
            { label: 'Past due', value: EnterpriseStatus.PAST_DUE },
            { label: 'Test', value: EnterpriseStatus.TEST },
            { label: 'Trial', value: EnterpriseStatus.TRIAL },
            { label: 'Trial expired', value: EnterpriseStatus.TRIAL_EXPIRED }
          ]}
          placeholder="Status"
        />
      </DefaultFormItem>
      <DefaultFormItem name="billingAddress.state.country.name">
        {isLoadingCountries ? (
          <LoadingSpinner />
        ) : (
          <Select options={countryOptions} placeholder="Country" showSearch />
        )}
      </DefaultFormItem>
      <Button htmlType="submit" style={{ display: 'none' }} />
    </DefaultForm>
  );
}

export default memo(BackOfficeEnterpriseFilterForm, isEqual);
