import parsedPhoneNumber, {
  CountryCode,
  isSupportedCountry
} from 'libphonenumber-js';

const numberIsValid = () => Promise.resolve();
const numberIsInvalid = () =>
  Promise.reject(new Error('Given phone number is invalid.'));
const shortCodeIsInvalid = () =>
  Promise.reject(new Error('Given shortcode is not valid.'));
const countryNotSupported = () =>
  Promise.reject(new Error('Country is not supported.'));

export function isValidCountryShortCode(
  shortCode?: string
): shortCode is CountryCode {
  return !shortCode || isSupportedCountry(shortCode);
}

/**
 * Validate a given phone number.
 */
function phoneNumberValidator(
  /**
   * The number being validated
   */
  value?: string,
  /**
   * The shortcode for the country this phone number is associated with
   */
  shortCode?: string,
  /**
   * Array of country shortcodes currently supported.
   */
  countryShortCodes?: string[]
) {
  if (!value) {
    return numberIsValid();
  }

  if (!isValidCountryShortCode(shortCode)) {
    return shortCodeIsInvalid();
  }

  // Parse given value.
  const parsed = parsedPhoneNumber(value, shortCode);

  if (parsed) {
    // If the parsed number has a country, and we have an array of country
    // shortcodes, and that parsed country is not in that array, we do not
    // support that country.
    if (
      parsed.country &&
      countryShortCodes &&
      !countryShortCodes.includes(parsed.country)
    ) {
      return countryNotSupported();
    }
  } else {
    return numberIsInvalid();
  }

  return parsed.isValid() ? numberIsValid() : numberIsInvalid();
}

export default phoneNumberValidator;
