import {
  CameraImageType,
  CameraSettings,
  CameraStatus,
  RawCameraImageStatusCode
} from 'types/models/camera';
import { SamplePointId } from 'types/models/samplePoint';
import { UserId } from 'types/models/user';

export type ImageId = number;

export const DATE_KEY_FORMAT = 'YYYY-MM-DD';
export type DateKey = string;

type PageNumber = number;

export type CamerasState = Record<
  SamplePointId,
  {
    images: Record<ImageId, CameraImageType>;
    pagination: Record<
      DateKey,
      {
        count: number;
        pages: Record<PageNumber, ImageId[]>;
      }
    >;
    dates: Record<DateKey, boolean>;
    status: { status: CameraStatus; statusCode: RawCameraImageStatusCode };
    schedules: Record<CameraScheduleResponse['id'], CameraScheduleResponse>;
  }
>;

export interface CameraScheduleRequest {
  actionType: 'take-photo';
  hour: number;
  minute: number;
  dayOfWeek: number;
  dayOfMonth?: number;
  month?: number;
  config: CameraSettings;
}

export interface CameraScheduleResponse {
  id: number;
  createdBy: UserId;
  samplePointSid: string;
  timezoneCode: string;
  actionType: 'take-photo';
  hourTZ: number;
  hourUTC: number;
  minuteTZ: number;
  minuteUTC: number;
  dayOfMonthTZ: number;
  dayOfMonthUTC: number;
  dayOfWeekTZ: number;
  dayOfWeekUTC: number;
  monthUTC: number;
  config: CameraSettings;
  createdAt: number;
  updatedAt: number;
}
