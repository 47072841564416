import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import {
  loadBackOfficeDeviceCameraSamplePoints,
  loadBackOfficeDevices
} from 'store/modules/backOfficeDevices/actions';
import { selectBackOfficeDevicesRequestParameters } from 'store/modules/routerUtils/selectors';

function BackOfficeDevicesRequest(): null {
  const backOfficeDevicesRequestParameters = useSelector(
    selectBackOfficeDevicesRequestParameters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBackOfficeDeviceCameraSamplePoints());
  }, [dispatch]);

  useDeepCompareEffect(() => {
    dispatch(loadBackOfficeDevices());
  }, [dispatch, backOfficeDevicesRequestParameters]);

  return null;
}

export default memo(BackOfficeDevicesRequest);
