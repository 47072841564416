import { Input, Row, Typography } from 'antd';
import React from 'react';

import DefaultFormItem from 'components/composites/DefaultFormItem';

import FormItemCol from '../FormItemCol';

import './styles.less';

export default function PasswordFormItems() {
  return (
    <Row className="PasswordFormItems" gutter={24}>
      <FormItemCol isFullSpanOnMobileHalfOnDesktop>
        <DefaultFormItem
          name="password"
          label={<Typography.Text strong>Password</Typography.Text>}
          rules={[
            {
              required: true,
              message: 'Please enter a password'
            },
            {
              pattern: /^(.+){6,128}$/,
              message: 'At least 6 characters in length'
            }
          ]}
        >
          <Input.Password placeholder="Password" />
        </DefaultFormItem>
      </FormItemCol>
      <FormItemCol isFullSpanOnMobileHalfOnDesktop>
        <DefaultFormItem
          name="confirmPassword"
          label={<Typography.Text strong>Confirm Password</Typography.Text>}
          rules={[
            {
              required: true,
              message: 'Please re-enter the password'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            })
          ]}
          dependencies={['password']}
        >
          <Input.Password placeholder="Password" />
        </DefaultFormItem>
      </FormItemCol>
    </Row>
  );
}
