import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';

const selectAppScaffold = (state: ApplicationRootState) => state.appScaffold;

const selectAppScaffoldInitialDataLoaded = createSelector(
  selectAppScaffold,
  (appScaffold) => appScaffold.initialDataLoaded
);

export { selectAppScaffold, selectAppScaffoldInitialDataLoaded };
