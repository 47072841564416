import { Button, Typography } from 'antd';
import React from 'react';

import { ReactComponent as IntegrationIcon } from 'components/icons/IntegrationIcon/integration.svg';
import getAgriWebbMarketplaceCardUrl from 'utils/agriwebb/get-agriwebb-marketplace-card-url';

import './styles.less';

const PermissionRequest = () => (
  <div className="PermissionRequest-container">
    <IntegrationIcon className="PermissionRequest-icon" />
    <Typography.Title level={5}>
      Request permission to connect your account
    </Typography.Title>
    <Typography.Paragraph>
      <Typography.Text>
        You need to be an Admin or Supervisor to connect enterprises to
        AgriWebb.
      </Typography.Text>
    </Typography.Paragraph>
    <Button
      className="PermissionRequest-button"
      onClick={() => {
        window.location.href = getAgriWebbMarketplaceCardUrl();
      }}
    >
      Back
    </Button>
  </div>
);

export default PermissionRequest;
