import { WhiteLabelTitle } from 'types/models/white-label';

import getWhiteLabelConfig from './get-white-label-config';

export function isRanchbot() {
  const { title: whiteLabel } = getWhiteLabelConfig();
  return whiteLabel === WhiteLabelTitle.RANCHBOT;
}

export function isFarmbot() {
  const { title: whiteLabel } = getWhiteLabelConfig();
  return whiteLabel === WhiteLabelTitle.FARMBOT;
}
