import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest } from 'utils/redux-saga-requests';

import {
  loadBackOfficeEnterpriseSites,
  loadBackOfficeEnterpriseSitesFailure,
  loadBackOfficeEnterpriseSitesSuccess,
  setBackOfficeEnterpriseSites
} from './actions';
import ActionTypes from './constants';

export function* requestBackOfficeEnterpriseSites(
  action: ReturnType<typeof loadBackOfficeEnterpriseSites>
): SagaIterator {
  const {
    payload: { enterpriseId }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `site?filter=enterpriseId::$eq::${enterpriseId}`
    );

    yield all([
      put(loadBackOfficeEnterpriseSitesSuccess(data)),
      put(setBackOfficeEnterpriseSites(data))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadBackOfficeEnterpriseSitesFailure(message, error));
  }
}

export function* watchLoadBackOfficeEnterpriseSitesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_SITES_REQUEST,
    requestBackOfficeEnterpriseSites
  );
}

export default function* backOfficeEnterpriseSitesSaga() {
  yield all([fork(watchLoadBackOfficeEnterpriseSitesRequest)]);
}
