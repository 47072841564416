import classNames from 'classnames';
import React from 'react';

import './styles.less';

type IconLabelProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  gap?: number;
  direction?: 'horizontal' | 'vertical';
  className?: string;
};

function IconLabel({ icon, label, gap = 0, direction = 'horizontal', className = '' }: IconLabelProps) {
  return (
    <div className={classNames(`IconLabel-${direction}-container`, className)} style={{ gap }}>
      <div className="icon-container">{icon}</div>
      {label}
    </div>
  );
}

export default IconLabel;