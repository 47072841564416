interface Options {
  decimalPlaces?: number;
}

/**
 * Round a given number value to a specified number of decimal places.
 * Default to 1 decimal place.
 */
function roundNumber(value?: number, options?: Options) {
  const decimalPlaces = options?.decimalPlaces ?? 1;

  if (!value) {
    return 0;
  }

  if (decimalPlaces === 0) {
    return Math.round(value);
  }

  const multiplier = 10 ** decimalPlaces;

  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export default roundNumber;
