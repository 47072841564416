import React from 'react';

import { WhiteLabelTitle } from 'types/models/white-label';
import getWhiteLabelConfig from 'utils/get-white-label-config';

import { ReactComponent as FarmbotIcon } from './farmbot-logo-horizontal.svg';
import { ReactComponent as MinebotIcon } from './minebot-logo-horizontal.svg';
import { ReactComponent as RanchbotIcon } from './ranchbot-logo-horizontal.svg';

const { title } = getWhiteLabelConfig();

const Icon = (() => {
  switch (title) {
    case WhiteLabelTitle.RANCHBOT:
      return RanchbotIcon;
    case WhiteLabelTitle.MINEBOT:
      return MinebotIcon;
    case WhiteLabelTitle.FARMBOT:
    default:
      return FarmbotIcon;
  }
})();

type Props = {
  style?: React.CSSProperties;
};

function FarmbotLogoHorizontal({
  style = { verticalAlign: 'middle', height: '27px', width: '134px' }
}: Props): JSX.Element {
  return <Icon title={`${title} Logo`} style={style} />;
}

export default FarmbotLogoHorizontal;
