import { ApplicationActions } from 'store/types';

import { LoadingState } from './types';

export const initialState = {};

/**
 * Use a separate reducer to store all isLoading flags instead of polluting all
 * of the other reducers.
 * @see {@link https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6 Handle Loading Flags in Your Reducers}
 */
function submittingReducer(
  state: LoadingState = initialState,
  action: ApplicationActions
): LoadingState {
  const { type } = action;
  // TODO: improve, what if it's just a side-effect with no accompanying request?
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // If it's not a *_REQUEST / *_SUCCESS /  *_FAILURE action, ignore.
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Use `requestState` to determine whether a request is processing or not
    // e.g. will be true when receiving LOGIN_REQUEST
    //      and false when receiving LOGIN_SUCCESS / LOGIN_FAILURE
    [requestName]: requestState === 'REQUEST'
  };
}

export default submittingReducer;
