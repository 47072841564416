import axios from 'axios';
import get from 'lodash/get';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import { getRequest } from 'utils/redux-saga-requests';

import {
  loadBackOfficeUser,
  loadBackOfficeUserFailure,
  loadBackOfficeUserSuccess,
  setBackOfficeUser
} from './actions';
import ActionTypes from './constants';

export function* requestBackOfficeUser(
  action: ReturnType<typeof loadBackOfficeUser>
) {
  const {
    payload: { userId }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `user/${userId}?join=memberships&join=memberships.enterprise`
    );

    let user = parseBackendPhoneNumber(
      data,
      'mobileNumber',
      'mobileNumberCountryCode',
      'mobileNumberFormatted'
    );
    user = parseBackendPhoneNumber(
      user,
      'landlineNumber',
      'landlineNumberCountryCode',
      'landNumberFormatted'
    );
    yield all([
      put(loadBackOfficeUserSuccess(user)),
      put(setBackOfficeUser(user))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadBackOfficeUserFailure(message, error));
  }
}

export function* watchLoadBackOfficeUserRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_USER_REQUEST,
    requestBackOfficeUser
  );
}

export default function* backOfficeUserSaga() {
  yield all([fork(watchLoadBackOfficeUserRequest)]);
}
