enum ActionTypes {
  SET_USERS = 'SET_USERS',
  SET_USER = 'SET_USER',
  UNSET_USER = 'UNSET_USER',
  ADD_USER_REQUEST = 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  USER_LEAVE_ENTERPRISE_REQUEST = 'USER_LEAVE_ENTERPRISE_REQUEST',
  USER_LEAVE_ENTERPRISE_SUCCESS = 'USER_LEAVE_ENTERPRISE_SUCCESS',
  USER_LEAVE_ENTERPRISE_FAILURE = 'USER_LEAVE_ENTERPRISE_FAILURE'
}

export default ActionTypes;
