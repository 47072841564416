import React from 'react';

import AssetTypeIcon from 'components/icons/AssetTypeIcon';
import { AssetTypeId } from 'types/models/asset-type';

import './SimpleMapMarker.less';

/**
 * The lat & lng props are unused, but google-map-react implicitly relies on
 * them to render markers
 */
interface Props {
  lat: number;
  lng: number;
  assetTypeId?: AssetTypeId;
}

function SimpleMapMarker({ assetTypeId }: Props): JSX.Element {
  return (
    <div className="SimpleMapMarker">
      <div className="SimpleMapMarker-inner">
        <div className="SimpleMapMarker-pin" />
        <div className="SimpleMapMarker-flag">
          <AssetTypeIcon assetTypeId={assetTypeId} />
        </div>
      </div>
    </div>
  );
}

export default SimpleMapMarker;
