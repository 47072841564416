import React from 'react';

export type WidgetPositionOverrides = {
  /** in px */
  bottom?: number;
  /** in px */
  right?: number;
};

export type IntercomWidgetContextValue = {
  setIntercomWidgetPositionOverrides: (overrides: WidgetPositionOverrides) => void;
  clearIntercomWidgetPositionOverrides: () => void;
  addDrawerThatSitsAboveIntercomWidget: (drawerId: string) => void;
  removeDrawerThatSitsAboveIntercomWidget: (drawerId: string) => void;
};

const defaultContextValue: IntercomWidgetContextValue = {
  setIntercomWidgetPositionOverrides: () => { },
  clearIntercomWidgetPositionOverrides: () => { },
  addDrawerThatSitsAboveIntercomWidget: () => { },
  removeDrawerThatSitsAboveIntercomWidget: () => { }
};

export const IntercomWidgetContext = React.createContext<IntercomWidgetContextValue>(defaultContextValue);