import { AxiosError } from 'axios';
import { action } from 'typesafe-actions';

import Invitation, { InvitationId, NewInvitation } from 'types/models/invitation';

import ActionTypes from './constants';

export const loadCurrentEnterpriseInvitations = () =>
  action(ActionTypes.LOAD_CURRENT_ENTERPRISE_INVITATIONS_REQUEST);

export const loadCurrentEnterpriseInvitationsSuccess = (
  invitations: Invitation[]
) =>
  action(ActionTypes.LOAD_CURRENT_ENTERPRISE_INVITATIONS_SUCCESS, {
    invitations
  });

export const loadCurrentEnterpriseInvitationsFailure = (
  message: string,
  error: AxiosError
) =>
  action(
    ActionTypes.LOAD_CURRENT_ENTERPRISE_INVITATIONS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadMyInvitations = () =>
  action(ActionTypes.LOAD_MY_INVITATIONS_REQUEST);

export const loadMyInvitationsSuccess = () =>
  action(ActionTypes.LOAD_MY_INVITATIONS_SUCCESS, {});

export const loadMyInvitationsFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.LOAD_MY_INVITATIONS_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadInvitation = (
  invitationId: InvitationId,
  onSuccess: (invitation: Invitation & { isNewCustomer: boolean }) => void
) => action(ActionTypes.LOAD_INVITATION_REQUEST, { invitationId, onSuccess });

export const loadInvitationSuccess = (invitation: Invitation) =>
  action(ActionTypes.LOAD_INVITATION_SUCCESS, { invitation });

export const loadInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.LOAD_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const addInvitation = (
  newInvitation: NewInvitation,
  onSuccess: () => void
) => action(ActionTypes.ADD_INVITATION_REQUEST, { newInvitation, onSuccess });

export const addInvitationSuccess = (invitation: Invitation) =>
  action(ActionTypes.ADD_INVITATION_SUCCESS, { invitation });

export const addInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.ADD_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const acceptInvitation = (
  invitation: Invitation,
  onSuccess: () => void
) => action(ActionTypes.ACCEPT_INVITATION_REQUEST, { invitation, onSuccess });

export const acceptInvitationSuccess = (invitationId: InvitationId) =>
  action(ActionTypes.ACCEPT_INVITATION_SUCCESS, { invitationId });

export const acceptInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.ACCEPT_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const resendInvitation = (invitationId: InvitationId) =>
  action(ActionTypes.RESEND_INVITATION_REQUEST, { invitationId });

export const resendInvitationSuccess = (invitationId: InvitationId) =>
  action(ActionTypes.RESEND_INVITATION_SUCCESS, { invitationId });

export const resendInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.RESEND_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const deleteInvitation = (
  invitationId: InvitationId,
  onSuccess: () => void
) => action(ActionTypes.DELETE_INVITATION_REQUEST, { invitationId, onSuccess });

export const deleteInvitationSuccess = (invitationId: InvitationId) =>
  action(ActionTypes.DELETE_INVITATION_SUCCESS, { invitationId });

export const deleteInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.DELETE_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );

export const updateInvitation = (
  id: InvitationId,
  updatedInvitation: Pick<Invitation, 'role'>
) => action(ActionTypes.UPDATE_INVITATION_REQUEST, { id, updatedInvitation });

export const updateInvitationSuccess = (
  id: InvitationId,
  updatedInvitation: Pick<Invitation, 'role'>
) => action(ActionTypes.UPDATE_INVITATION_SUCCESS, { id, updatedInvitation });

export const updateInvitationFailure = (message: string, error: AxiosError) =>
  action(
    ActionTypes.UPDATE_INVITATION_FAILURE,
    { message, error },
    undefined,
    true
  );
