enum ActionTypes {
  SET_LOCAL_USER_SETTINGS_WATER_LEVEL_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_WATER_LEVEL_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_FUEL_LEVEL_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_FUEL_LEVEL_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_LIQUID_FERTILISER_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_LIQUID_FERTILISER_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_FLOW_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_FLOW_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_SIMPLE_DAM_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_SIMPLE_DAM_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_ADVANCED_DAM_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_ADVANCED_DAM_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_TROUGH_DIRECT_CHART_TYPE = 'SET_LOCAL_USER_SETTINGS_TROUGH_DIRECT_CHART_TYPE',
  SET_LOCAL_USER_SETTINGS_SHOW_CHART_LIST = 'SET_LOCAL_USER_SETTINGS_SHOW_CHART_LIST',
  SET_LOCAL_USER_SETTINGS_DEFAULT_SITE = 'SET_LOCAL_USER_SETTINGS_DEFAULT_SITE',
  SET_DEFAULT_CHART_DATE_RANGE = 'SET_DEFAULT_CHART_DATE_RANGE',
  SET_COMPARISON = 'SET_COMPARISON',
  UNSET_COMPARISON = 'UNSET_COMPARISON'
}

export default ActionTypes;
