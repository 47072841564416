import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadCountries } from 'store/modules/countries/actions';

function CountriesRequest(): null {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  return null;
}

export default CountriesRequest;
