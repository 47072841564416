import isNil from 'lodash/isNil';

import { EM_DASH } from 'constants/index';
import { WIRELESS_TROUGH_LEVEL_PRECISION } from 'constants/samplePoint';
import SamplePoint from 'types/models/samplePoint';
import formatQuantity from 'utils/format-metric';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';
import { baseNumericalSort } from 'utils/sorters';

import { isTroughDirect } from '.';

export const getTroughLevelReading = (sp: SamplePoint, country: string): string => {
  let levelReading: string;
  if (isTroughDirect(sp)) {
    const levelValue = sp.troughDirectStatistics?.level.value;
    const levelUnit = sp.troughDirectStatistics?.level.unit.symbol;
    levelReading = !isNil(levelValue) && levelUnit
      ? `${levelValue} ${levelUnit}`
      : EM_DASH;
  } else {
    const levelValue = sp.troughStatistics?.latestAverage;
    const levelUnit = getUnitByCountry({ unitType: UnitType.LEVEL_SM, country });
    levelReading = !isNil(levelValue)
      ? formatQuantity(levelValue, { suffix: levelUnit, decimalPlaces: WIRELESS_TROUGH_LEVEL_PRECISION })
      : EM_DASH;
  }
  return levelReading;
};

export const troughLevelSorter = (a: SamplePoint, b: SamplePoint) => {
  const aLevel = isTroughDirect(a) ? a.troughDirectStatistics?.level.value : a.troughStatistics?.latestAverage;
  const bLevel = isTroughDirect(b) ? b.troughDirectStatistics?.level.value : b.troughStatistics?.latestAverage;
  return baseNumericalSort(aLevel || 0, bLevel || 0);
};