/**
 * Almost all requests from our app has its response data stored in our redux
 * store, however in some cases we need that data very temporarily.
 */
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { all, call, debounce, fork } from 'redux-saga/effects';

import { getRequest, patchRequest } from 'utils/redux-saga-requests';

import {
  ephemeralGetRequest,
  ephemeralGetRequests,
  ephemeralPatchRequest
} from './actions';
import ActionTypes from './constants';

function* requestEphemeralGetRequest(
  action: ReturnType<typeof ephemeralGetRequest>
) {
  const {
    payload: { path, onSuccess, onError }
  } = action;
  try {
    const { data } = yield call(getRequest, path, {}, false);
    onSuccess(data);
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    onError?.(message);
  }
}

function* requestEphemeralGetRequests(
  action: ReturnType<typeof ephemeralGetRequests>
) {
  const {
    payload: { paths, onSuccess, onError }
  } = action;
  try {
    const responses: AxiosResponse[] = yield all(
      paths.map((path) => call(getRequest, path))
    );
    onSuccess(responses.map((r) => r.data)); // Pass the response thru callback
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    onError?.(message);
  }
}

function* requestEphemeralPatchRequest(
  action: ReturnType<typeof ephemeralPatchRequest>
) {
  const {
    payload: { path, requestBody, onSuccess, onError }
  } = action;
  try {
    const { data } = yield call(patchRequest, path, requestBody);
    onSuccess(data);
  } catch (error) {
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    onError?.(message);
  }
}

function* watchEphemeralGetRequest() {
  yield debounce(
    300,
    ActionTypes.EPHEMERAL_GET_REQUEST,
    requestEphemeralGetRequest
  );
}

function* watchEphemeralGetRequests() {
  yield debounce(
    300,
    ActionTypes.EPHEMERAL_GET_REQUESTS,
    requestEphemeralGetRequests
  );
}

function* watchEphemeralPatchRequest() {
  yield debounce(
    300,
    ActionTypes.EPHEMERAL_PATCH_REQUEST,
    requestEphemeralPatchRequest
  );
}

export default function* ephemeralRequestSaga() {
  yield all([
    fork(watchEphemeralGetRequest),
    fork(watchEphemeralGetRequests),
    fork(watchEphemeralPatchRequest)
  ]);
}
