// TODO: Merge the functions into /utils/convert-unit/index.ts
import round from 'lodash/round';

import { GAL_TO_L } from 'constants/unit-conversion';
import { VolumeDisplayUnit } from 'types/models/samplePoint';

export const DAM_IMPERIAL_TRIGGER_PRECISION = 1;

const CU_FT_TO_L = 28.317;
const AC_FT_TO_L = 1233481.8375;
const FT_TO_CM = 30.48;
const ML_TO_CU_FT = 35315.62;

export const litresToMegalitres = (value?: number) => {
  if (value) {
    return value / 1000000;
  }
  return value;
};

export const megalitresToLitres = (value?: number) => {
  if (value) {
    return value * 1000000;
  }
  return value;
};

export const megalitresToCubicFeet = (value: number, precision = 0) => {
  return round(value * ML_TO_CU_FT, precision);
};

export const cubicFeetToMegalitres = (value: number, precision = 2) => {
  if (value) {
    return round(value / ML_TO_CU_FT, precision);
  }
  return value;
};

/** It converts L to ML by default */
export const convertLitresTo = (
  volumeL?: number,
  destinationUnit?: VolumeDisplayUnit,
  precision = 2
) => {
  if (!volumeL) {
    return volumeL;
  }
  let convertedVolume: number;
  switch (destinationUnit) {
    case VolumeDisplayUnit.GAL:
      convertedVolume = volumeL / GAL_TO_L;
      break;
    case VolumeDisplayUnit.CU_FT:
      convertedVolume = volumeL / CU_FT_TO_L;
      break;
    case VolumeDisplayUnit.AC_FT:
      convertedVolume = volumeL / AC_FT_TO_L;
      break;
    case VolumeDisplayUnit.L:
      convertedVolume = volumeL;
      break;
    case VolumeDisplayUnit.KL:
      convertedVolume = volumeL / 1000;
      break;
    case VolumeDisplayUnit.ML:
    default:
      convertedVolume = litresToMegalitres(volumeL) as number;
      break;
  }
  return precision >= 0 ? round(convertedVolume, precision) : convertedVolume;
};

export const convertToLitres = (
  volume?: number,
  sourceUnit?: VolumeDisplayUnit
) => {
  if (!volume) {
    return volume;
  }
  switch (sourceUnit) {
    case VolumeDisplayUnit.GAL:
      return volume * GAL_TO_L;
    case VolumeDisplayUnit.CU_FT:
      return volume * CU_FT_TO_L;
    case VolumeDisplayUnit.AC_FT:
      return volume * AC_FT_TO_L;
    case VolumeDisplayUnit.L:
      return volume;
    case VolumeDisplayUnit.KL:
      return volume * 1000;
    case VolumeDisplayUnit.ML:
    default:
      return megalitresToLitres(volume);
  }
};

export const metresToCentimetres = (value: number, precision = 0) =>
  round(value * 100, precision);

export const centimetresToMetres = (value: number, precision = 2) =>
  round(value / 100, precision);

export const feetToCentimetres = (value: number, precision = 1) =>
  round(value * FT_TO_CM, precision);

// To reduce the risk of typo and help to locate all the time unit conversions.
export const millisecondsToSeconds = (value: number) =>
  Math.round(value / 1000);

// To reduce the risk of typo and help to locate all the time unit conversions.
export const secondsToMilliseconds = (value: number) => value * 1000;
