import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorBoundaryFallback from 'components/atoms/ErrorBoundaryFallback';
import PageHeaderPusher from 'components/atoms/PageHeaderPusher';
import BackOfficeActionsBar from 'components/features/backOffice/BackOfficeActionsBar';
import { FilterSetName } from 'store/modules/routerUtils/types';

interface Props {
  children: ReactNode;
  actions?: ReactNode;
  table: ReactElement;
  filterSetName?: FilterSetName;
  searchInputPlaceholder: string;
}

function BackOfficeSection({
  children,
  actions,
  table,
  filterSetName,
  searchInputPlaceholder
}: Props) {
  const [paginationDetails, setPaginationDetails] = useState({
    total: 0,
    from: 0,
    to: 0
  });

  const onPaginationChange = useCallback(
    (total: number, from: number, to: number) =>
      setPaginationDetails({ total, from, to }),
    []
  );

  const paginationText = useMemo(
    () =>
      `${paginationDetails.from} - ${paginationDetails.to} of ${paginationDetails.total}`,
    [paginationDetails]
  );

  const tableWithPagination = useMemo(
    () =>
      React.cloneElement(table, {
        onPaginationChange
      }),
    [table, onPaginationChange]
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <PageHeaderPusher>
        <BackOfficeActionsBar
          actions={actions}
          searchInputPlaceholder={searchInputPlaceholder}
          filterSetName={filterSetName}
          paginationText={paginationText}
        >
          {tableWithPagination}
        </BackOfficeActionsBar>
      </PageHeaderPusher>
      {children}
    </ErrorBoundary>
  );
}

export default BackOfficeSection;
