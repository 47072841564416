enum ActionTypes {
  FETCH_IP_CAMERAS_REQUEST = 'FETCH_IP_CAMERAS_REQUEST',
  FETCH_IP_CAMERAS_SUCCESS = 'FETCH_IP_CAMERAS_SUCCESS',
  FETCH_IP_CAMERAS_FAILURE = 'FETCH_IP_CAMERAS_FAILURE',

  FETCH_CAMERA_BY_ID_REQUEST = 'FETCH_CAMERA_BY_ID_REQUEST',
  FETCH_CAMERA_BY_ID_SUCCESS = 'FETCH_CAMERA_BY_ID_SUCCESS',
  FETCH_CAMERA_BY_ID_FAILURE = 'FETCH_CAMERA_BY_ID_FAILURE',

  TAKE_IP_CAMERA_PREVIEW_REQUEST = 'TAKE_IP_CAMERA_PREVIEW_REQUEST',
  TAKE_IP_CAMERA_PREVIEW_SUCCESS = 'TAKE_IP_CAMERA_PREVIEW_SUCCESS',
  TAKE_IP_CAMERA_PREVIEW_FAILURE = 'TAKE_IP_CAMERA_PREVIEW_FAILURE',
  SET_IP_CAMERA_PREVIEW = 'SET_IP_CAMERA_PREVIEW',
  SET_IP_CAMERA_PREVIEW_ERROR = 'SET_IP_CAMERA_PREVIEW_ERROR',

  FETCH_IP_CAMERA_STATE_REQUEST = 'FETCH_IP_CAMERA_STATE_REQUEST',
  FETCH_IP_CAMERA_STATE_SUCCESS = 'FETCH_IP_CAMERA_STATE_SUCCESS',
  FETCH_IP_CAMERA_STATE_FAILURE = 'FETCH_IP_CAMERA_STATE_FAILURE',
  SET_IP_CAMERA_STATE = 'SET_IP_CAMERA_STATE',

  FETCH_IP_CAMERA_CAPABILITIES_REQUEST = 'FETCH_IP_CAMERA_CAPABILITIES_REQUEST',
  FETCH_IP_CAMERA_CAPABILITIES_SUCCESS = 'FETCH_IP_CAMERA_CAPABILITIES_SUCCESS',
  FETCH_IP_CAMERA_CAPABILITIES_FAILURE = 'FETCH_IP_CAMERA_CAPABILITIES_FAILURE',
  SET_IP_CAMERA_CAPABILITIES = 'SET_IP_CAMERA_CAPABILITIES',

  FETCH_IP_CAMERA_PHOTOS_REQUEST = 'FETCH_IP_CAMERA_PHOTOS_REQUEST',
  FETCH_IP_CAMERA_PHOTOS_SUCCESS = 'FETCH_IP_CAMERA_PHOTOS_SUCCESS',
  FETCH_IP_CAMERA_PHOTOS_FAILURE = 'FETCH_IP_CAMERA_PHOTOS_FAILURE',
  SET_IP_CAMERA_PHOTOS = 'SET_IP_CAMERA_PHOTOS',

  TAKE_IP_CAMERA_PHOTO_REQUEST = 'TAKE_IP_CAMERA_PHOTO_REQUEST',
  TAKE_IP_CAMERA_PHOTO_SUCCESS = 'TAKE_IP_CAMERA_PHOTO_SUCCESS',
  TAKE_IP_CAMERA_PHOTO_FAILURE = 'TAKE_IP_CAMERA_PHOTO_FAILURE',
  SET_IP_CAMERA_UNSEEN_PHOTO = 'SET_IP_CAMERA_UNSEEN_PHOTO',

  SEND_CAMERA_PTZ_CONTROL_REQUEST = 'SEND_CAMERA_PTZ_CONTROL_REQUEST',

  FETCH_IP_CAMERA_EVENTS_REQUEST = 'FETCH_IP_CAMERA_EVENTS_REQUEST',
  FETCH_IP_CAMERA_EVENTS_SUCCESS = 'FETCH_IP_CAMERA_EVENTS_SUCCESS',
  FETCH_IP_CAMERA_EVENTS_FAILURE = 'FETCH_IP_CAMERA_EVENTS_FAILURE',
  SET_IP_CAMERA_EVENTS = 'SET_IP_CAMERA_EVENTS',

  EDIT_IP_CAMERA_REQUEST = 'EDIT_IP_CAMERA_REQUEST',
  EDIT_IP_CAMERA_SUCCESS = 'EDIT_IP_CAMERA_SUCCESS',
  EDIT_IP_CAMERA_FAILURE = 'EDIT_IP_CAMERA_FAILURE',

  REMOVE_IP_CAMERA_REQUEST = 'REMOVE_IP_CAMERA_REQUEST',
  REMOVE_IP_CAMERA_SUCCESS = 'REMOVE_IP_CAMERA_SUCCESS',
  REMOVE_IP_CAMERA_FAILURE = 'REMOVE_IP_CAMERA_FAILURE',
  REMOVE_FROM_IP_CAMERA_LIST = 'REMOVE_FROM_IP_CAMERA_LIST',

  FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST = 'FETCH_IP_CAMERA_PTZ_PRESETS_REQUEST',
  FETCH_IP_CAMERA_PTZ_PRESETS_SUCCESS = 'FETCH_IP_CAMERA_PTZ_PRESETS_SUCCESS',
  FETCH_IP_CAMERA_PTZ_PRESETS_FAILURE = 'FETCH_IP_CAMERA_PTZ_PRESETS_FAILURE',

  CREATE_IP_CAMERA_PTZ_PRESET_REQUEST = 'CREATE_IP_CAMERA_PTZ_PRESET_REQUEST',
  CREATE_IP_CAMERA_PTZ_PRESET_SUCCESS = 'CREATE_IP_CAMERA_PTZ_PRESET_SUCCESS',
  CREATE_IP_CAMERA_PTZ_PRESET_FAILURE = 'CREATE_IP_CAMERA_PTZ_PRESET_FAILURE',

  ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST = 'ACTIVATE_IP_CAMERA_PTZ_PRESET_REQUEST',
  ACTIVATE_IP_CAMERA_PTZ_PRESET_SUCCESS = 'ACTIVATE_IP_CAMERA_PTZ_PRESET_SUCCESS',
  ACTIVATE_IP_CAMERA_PTZ_PRESET_FAILURE = 'ACTIVATE_IP_CAMERA_PTZ_PRESET_FAILURE',

  UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST = 'UPDATE_IP_CAMERA_PTZ_PRESET_REQUEST',
  UPDATE_IP_CAMERA_PTZ_PRESET_SUCCESS = 'UPDATE_IP_CAMERA_PTZ_PRESET_SUCCESS',
  UPDATE_IP_CAMERA_PTZ_PRESET_FAILURE = 'UPDATE_IP_CAMERA_PTZ_PRESET_FAILURE',

  DELETE_IP_CAMERA_PTZ_PRESET_REQUEST = 'DELETE_IP_CAMERA_PTZ_PRESET_REQUEST',
  DELETE_IP_CAMERA_PTZ_PRESET_SUCCESS = 'DELETE_IP_CAMERA_PTZ_PRESET_SUCCESS',
  DELETE_IP_CAMERA_PTZ_PRESET_FAILURE = 'DELETE_IP_CAMERA_PTZ_PRESET_FAILURE',

  SET_IP_CAMERA_PTZ_PRESET = 'SET_IP_CAMERA_PTZ_PRESET',
  SET_IP_CAMERA_PTZ_PRESETS = 'SET_IP_CAMERA_PTZ_PRESETS',
  REMOVE_IP_CAMERA_PTZ_PRESET = 'REMOVE_IP_CAMERA_PTZ_PRESET'
}

export default ActionTypes;