import { action } from 'typesafe-actions';

import { UserId } from 'types/models/user';

import { LegalPolicyActionType } from './constants';

// ================================================
// LEGAL POLICY DOCUMENTS
// ================================================
export const fetchLegalPolicyDocuments = () =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_DOCUMENTS_REQUEST);

export const fetchLegalPolicyDocumentsSuccess = () =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_DOCUMENTS_SUCCESS);

export const fetchLegalPolicyDocumentsFailure = (message: string) =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_DOCUMENTS_FAILURE, { message }, undefined, true);

export const setLegalPolicyDocuments = (documents: any[]) =>
  action(LegalPolicyActionType.SET_LEGAL_POLICY_DOCUMENTS, { documents });

// ================================================
// LEGAL POLICY USER ACCEPTANCE STATUS
// ================================================
export const fetchLegalPolicyUserAcceptance = () =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_USER_ACCEPTANCE_REQUEST);

export const fetchLegalPolicyUserAcceptanceSuccess = () =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_USER_ACCEPTANCE_SUCCESS);

export const fetchLegalPolicyUserAcceptanceFailure = (message: string) =>
  action(LegalPolicyActionType.FETCH_LEGAL_POLICY_USER_ACCEPTANCE_FAILURE, { message }, undefined, true);

export const setLegalPolicyUserAcceptance = (userId: UserId, accepted: boolean) =>
  action(LegalPolicyActionType.SET_LEGAL_POLICY_USER_ACCEPTANCE, { userId, accepted });

// ================================================
// ACCEPT LEGAL POLICY
// ================================================
export const acceptLegalPolicies = () =>
  action(LegalPolicyActionType.ACCEPT_LEGAL_POLICY_REQUEST);

export const acceptLegalPoliciesSuccess = () =>
  action(LegalPolicyActionType.ACCEPT_LEGAL_POLICY_SUCCESS);

export const acceptLegalPoliciesFailure = (message: string) =>
  action(LegalPolicyActionType.ACCEPT_LEGAL_POLICY_FAILURE, { message }, undefined, true);

// ================================================
// LAST CHECKED
// ================================================
export const setLastChecked = (timestamp: number) =>
  action(LegalPolicyActionType.SET_LAST_CHECKED, { timestamp });