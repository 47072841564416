import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Site, { SiteId } from 'types/models/site';

import {
  makeSelectSiteById,
  selectSitesAsArray,
  selectSitesState
} from './selectors';
import { selectDefaultSite } from '../localUserSettings/selectors';

function useSite(siteId: SiteId): Site | undefined {
  // TODO: this doesn't need to be memoized
  const selectSiteById = useMemo(() => makeSelectSiteById(siteId), [siteId]);
  return useSelector(selectSiteById, shallowEqual);
}

function useSitesState() {
  return useSelector(selectSitesState, shallowEqual);
}

const useSites = () => {
  const sites = useSelector(selectSitesAsArray, shallowEqual);
  return useMemo(
    () => sortBy(sites, (s) => s.name.toLocaleLowerCase()),
    [sites]
  );
};

const useSiteName = (siteId: SiteId) => {
  return useSite(siteId)?.name;
};

const useDefaultSite = (): Site => {
  const site = useSelector(selectDefaultSite, shallowEqual);
  if (!site) {
    // No need to return undefined because if no sites are present, the app will just crash.
    throw new Error('No sites found in the enterprise.');
  }
  return site;
};

export {
  useSite as default,
  useSites,
  useSitesState,
  useDefaultSite,
  useSiteName
};
