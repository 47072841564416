import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import User from 'types/models/user';

import ActionTypes from './constants';

export const loadMyUser = () => action(ActionTypes.LOAD_MY_USER_REQUEST);

export const loadMyUserSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_MY_USER_SUCCESS, { response });

export const loadMyUserFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.LOAD_MY_USER_FAILURE, { message, error }, undefined, true);

export const editMyUser = (values: Record<string, unknown>) =>
  action(ActionTypes.EDIT_MY_USER_REQUEST, values);

export const editMyUserSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_MY_USER_SUCCESS, { response });

export const editMyUserFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_MY_USER_FAILURE, { message, error }, undefined, true);

export const mergeMyUser = (myUser: Partial<User>) =>
  action(ActionTypes.MERGE_MY_USER, myUser);
