/* LIBRARIES */
import { message as antdMessage } from 'antd';
import axios from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import samplesClient from 'clients/samples';
import { CSV_FILE_NAME_DATE_FORMAT } from 'components/modals/CSVExportModal';
import { commonDateWithBrowserTimezone } from 'utils/Date/common-date-time/common-date-time';
import downloadFile from 'utils/download-file';
import { getRequest } from 'utils/redux-saga-requests';

import {
  downloadDevicesAndSamplePointsCSV,
  downloadDevicesAndSamplePointsCSVSuccess,
  fetchSamplesCsvContent
} from './actions';
import ActionTypes from './constants';

// ==============================
// SAGAS
// ==============================
export function* exportDevicesWithSamplePoints(
  action: ReturnType<typeof downloadDevicesAndSamplePointsCSV>
) {
  const {
    payload: { enterpriseId, enterpriseName }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `device/export?format=csv&enterpriseId=${enterpriseId}`
    );

    const dateText = commonDateWithBrowserTimezone(new Date());
    const fileName = `${enterpriseName.trim()} Devices List - ${dateText}.csv`;

    downloadFile(fileName, data, 'text/csv');

    antdMessage.success('Device list exported');
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    antdMessage.error('Failed to export device list');
  } finally {
    yield put(downloadDevicesAndSamplePointsCSVSuccess());
  }
}

export function* downloadSamplesCsv(action: ReturnType<typeof fetchSamplesCsvContent>) {
  const { assetTypeName, samplePointId, samplePointName, dateRange, setLoading } = action.payload;
  setLoading(true);
  const { data, error } = yield call(samplesClient.fetchSamplesCsvContent, {
    samplePointId,
    dateRange
  });
  if (error) {
    antdMessage.error(`Error exporting CSV: ${error.message}`);
  } else {
    const startDateFormatted = dateRange[0].format(CSV_FILE_NAME_DATE_FORMAT);
    const endDateFormatted = dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT);
    downloadFile(
      `${samplePointName} ${assetTypeName} ${startDateFormatted}-${endDateFormatted}.csv`,
      data,
      'text/csv'
    );
  }
  setLoading(false);
}

// ==============================
// REGISTRATION
// ==============================
export function* watchExportDevicesWithSamplePoints() {
  yield takeLatest(
    ActionTypes.LOAD_CSV_DEVICES_WITH_SAMPLEPOINTS_LIST_REQUEST,
    exportDevicesWithSamplePoints
  );
}

export function* watchDownloadSamplesCsv() {
  yield takeLatest(ActionTypes.FETCH_SAMPLES_CSV_CONTENT_REQUEST, downloadSamplesCsv);
}

// ==============================
// EXPORT
// ==============================
export default function* exportsSaga() {
  yield all([
    fork(watchExportDevicesWithSamplePoints),
    fork(watchDownloadSamplesCsv)
  ]);
}
