import { Form } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeDeviceForm from 'components/forms/BackOfficeDeviceForm';
import BackOfficeDeviceLoadingGate from 'components/loadingGates/BackOfficeDeviceLoadingGate';
import BackOfficeDeviceRequest from 'components/requests/BackOfficeDeviceRequest';
import useBackOfficeDevice from 'store/modules/backOfficeDevice/hooks';
import { editBackOfficeDevice } from 'store/modules/backOfficeDevices/actions';
import ActionType from 'store/modules/backOfficeDevices/constants';
import clearErrors from 'store/modules/error/actions';
import { makeSelectIsLoading } from 'store/modules/loading/selectors';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import {
  FILTER_NULL,
  selectRouterQueryStringSelectedDevice
} from 'store/modules/routerUtils/selectors';

const actions = [ActionType.EDIT_BACK_OFFICE_DEVICE_REQUEST];

const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeDeviceEditDrawer(): JSX.Element {
  const [form] = Form.useForm();
  const deviceId = useSelector(selectRouterQueryStringSelectedDevice);
  const isLoading = useSelector(selectIsLoading);

  const visible = !!deviceId;
  const backOfficeDeviceData = useBackOfficeDevice();

  const initialValues = useMemo(
    () => cloneDeep(backOfficeDeviceData),
    [backOfficeDeviceData]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(clearErrors(actions));
  }, [visible, form, dispatch]);

  const handleClose = () => {
    dispatch(
      appendValuesToQueryString({
        selectedDevice: undefined
      })
    );
  };

  const handleClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (deviceId) {
          const normalisedValues: Record<string, unknown> = {};
          Object.entries(values).forEach(([key, value]) => {
            // Map 'null' string to be actual null.
            normalisedValues[key] = value === FILTER_NULL ? null : value;
            if (typeof normalisedValues[key] === 'string') {
              normalisedValues[key] = (normalisedValues[key] as string).trim();
            }
          });
          if (normalisedValues.positionLat !== null) {
            normalisedValues.positionLat = +values.positionLat;
            normalisedValues.positionLong = +values.positionLong;
          }
          dispatch(editBackOfficeDevice(deviceId, normalisedValues));
        }
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  };

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Edit Device"
      actions={actions}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <BackOfficeDeviceRequest deviceId={deviceId} />
      <BackOfficeDeviceLoadingGate deviceId={deviceId}>
        <BackOfficeDeviceForm
          form={form}
          initialValues={initialValues || undefined}
          isLoading={isLoading}
        />
      </BackOfficeDeviceLoadingGate>
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeDeviceEditDrawer);
