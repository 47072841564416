import { Result } from 'antd';
import React, { useEffect, useMemo } from 'react';

import AnchorTel from 'components/atoms/AnchorTel';
import DefaultButton from 'components/atoms/DefaultButton';
import AlertIcon from 'components/icons/AlertIcon';
import rollbarLogger from 'config/rollbar';
import useGetCustomerServiceContacts from 'hooks/use-get-customer-service-contacts';

interface Props {
  error?: {
    message?: string;
  };
  componentStack?: string;
}

/**
 * This is a component for any error event.
 */
function ErrorBoundaryFallback({ error, componentStack }: Props): JSX.Element {
  const isProduction = useMemo(
    () => process.env.REACT_APP_ENV === 'production',
    []
  );
  const { phoneNumber, phoneNumberText } = useGetCustomerServiceContacts();
  const message = useMemo(() => {
    const errorText = (
      <>
        We were unable to load the page. This could be due to poor reception.
        <br />
        Please reload the page or reach out to Customer Support (
        <AnchorTel phoneNumber={phoneNumber}>{phoneNumberText}</AnchorTel> ) if
        the issue persists.
      </>
    );
    return isProduction ? errorText : error && error.message;
  }, [error, isProduction, phoneNumber, phoneNumberText]);

  // Log error to Rollbar
  useEffect(() => {
    if (message) {
      rollbarLogger.critical(`Error boundary hit: ${message}`, {
        error,
        componentStack,
        message
      });
    }
  }, [error, componentStack, message]);

  return (
    <Result
      icon={<AlertIcon />}
      title="Something went wrong"
      subTitle={message}
      extra={
        isProduction ? (
          <DefaultButton
            type="primary"
            onClick={() => window.location.reload()}
          >
            Refresh
          </DefaultButton>
        ) : (
          componentStack
        )
      }
    />
  );
}

export default ErrorBoundaryFallback;
