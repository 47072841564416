export enum CiboActionType {
  FETCH_CIBO_SUBSCRIPTIONS_REQUEST = 'FETCH_CIBO_SUBSCRIPTIONS_REQUEST',
  FETCH_CIBO_SUBSCRIPTIONS_SUCCESS = 'FETCH_CIBO_SUBSCRIPTIONS_SUCCESS',
  FETCH_CIBO_SUBSCRIPTIONS_FAILURE = 'FETCH_CIBO_SUBSCRIPTIONS_FAILURE',
  SET_CIBO_SUBSCRIPTIONS = 'SET_CIBO_SUBSCRIPTIONS',
  SET_CIBO_SUBSCRIPTION_SUMMARY = 'SET_CIBO_SUBSCRIPTION_SUMMARY',

  FETCH_CIBO_LOCATIONS_REQUEST = 'FETCH_CIBO_LOCATIONS_REQUEST',
  FETCH_CIBO_LOCATIONS_SUCCESS = 'FETCH_CIBO_LOCATIONS_SUCCESS',
  FETCH_CIBO_LOCATIONS_FAILURE = 'FETCH_CIBO_LOCATIONS_FAILURE',
  SET_CIBO_LOCATIONS = 'SET_CIBO_LOCATIONS',

  FETCH_CIBO_LOCATION_REQUEST = 'FETCH_CIBO_LOCATION_REQUEST',
  FETCH_CIBO_LOCATION_SUCCESS = 'FETCH_CIBO_LOCATION_SUCCESS',
  FETCH_CIBO_LOCATION_FAILURE = 'FETCH_CIBO_LOCATION_FAILURE',
  SET_CIBO_LOCATION = 'SET_CIBO_LOCATION',
  UNSET_CIBO_LOCATION = 'UNSET_CIBO_LOCATION',

  ADD_CIBO_LOCATION_REQUEST = 'ADD_CIBO_LOCATION_REQUEST',
  ADD_CIBO_LOCATION_SUCCESS = 'ADD_CIBO_LOCATION_SUCCESS',
  ADD_CIBO_LOCATION_FAILURE = 'ADD_CIBO_LOCATION_FAILURE',

  EDIT_CIBO_LOCATION_REQUEST = 'EDIT_CIBO_LOCATION_REQUEST',
  EDIT_CIBO_LOCATION_SUCCESS = 'EDIT_CIBO_LOCATION_SUCCESS',
  EDIT_CIBO_LOCATION_FAILURE = 'EDIT_CIBO_LOCATION_FAILURE',

  DELETE_CIBO_LOCATION_REQUEST = 'DELETE_CIBO_LOCATION_REQUEST',
  DELETE_CIBO_LOCATION_SUCCESS = 'DELETE_CIBO_LOCATION_SUCCESS',
  DELETE_CIBO_LOCATION_FAILURE = 'DELETE_CIBO_LOCATION_FAILURE',

  FETCH_CIBO_IMAGERIES_REQUEST = 'FETCH_CIBO_IMAGERIES_REQUEST',
  FETCH_CIBO_IMAGERIES_SUCCESS = 'FETCH_CIBO_IMAGERIES_SUCCESS',
  FETCH_CIBO_IMAGERIES_FAILURE = 'FETCH_CIBO_IMAGERIES_FAILURE',
  SET_CIBO_IMAGERIES = 'SET_CIBO_IMAGERIES',

  FETCH_CIBO_IMAGERY_REQUEST = 'FETCH_CIBO_IMAGERY_REQUEST',
  FETCH_CIBO_IMAGERY_SUCCESS = 'FETCH_CIBO_IMAGERY_SUCCESS',
  FETCH_CIBO_IMAGERY_FAILURE = 'FETCH_CIBO_IMAGERY_FAILURE',
  SET_CIBO_IMAGERY = 'SET_CIBO_IMAGERY',

  FETCH_CIBO_LOCATION_STATUS_REQUEST = 'FETCH_CIBO_LOCATION_STATUS_REQUEST',
  FETCH_CIBO_LOCATION_STATUS_SUCCESS = 'FETCH_CIBO_LOCATION_STATUS_SUCCESS',
  FETCH_CIBO_LOCATION_STATUS_FAILURE = 'FETCH_CIBO_LOCATION_STATUS_FAILURE',
  SET_CIBO_LOCATION_STATUS = 'SET_CIBO_LOCATION_STATUS',

  FETCH_CIBO_LOCATION_DATA_POINTS_REQUEST = 'FETCH_CIBO_LOCATION_DATA_POINTS_REQUEST',
  FETCH_CIBO_LOCATION_DATA_POINTS_SUCCESS = 'FETCH_CIBO_LOCATION_DATA_POINTS_SUCCESS',
  FETCH_CIBO_LOCATION_DATA_POINTS_FAILURE = 'FETCH_CIBO_LOCATION_DATA_POINTS_FAILURE',
  SET_CIBO_LOCATION_DATA_POINTS = 'SET_CIBO_LOCATION_DATA_POINTS'
}
