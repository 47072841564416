enum ActionTypes {
  LOAD_BACK_OFFICE_DEVICES_REQUEST = 'LOAD_BACK_OFFICE_DEVICES_REQUEST',
  LOAD_BACK_OFFICE_DEVICES_SUCCESS = 'LOAD_BACK_OFFICE_DEVICES_SUCCESS',
  LOAD_BACK_OFFICE_DEVICES_FAILURE = 'LOAD_BACK_OFFICE_DEVICES_FAILURE',

  ADD_BACK_OFFICE_DEVICE_REQUEST = 'ADD_BACK_OFFICE_DEVICE_REQUEST',
  ADD_BACK_OFFICE_DEVICE_SUCCESS = 'ADD_BACK_OFFICE_DEVICE_SUCCESS',
  ADD_BACK_OFFICE_DEVICE_FAILURE = 'ADD_BACK_OFFICE_DEVICE_FAILURE',

  EDIT_BACK_OFFICE_DEVICE_REQUEST = 'EDIT_BACK_OFFICE_DEVICE_REQUEST',
  EDIT_BACK_OFFICE_DEVICE_SUCCESS = 'EDIT_BACK_OFFICE_DEVICE_SUCCESS',
  EDIT_BACK_OFFICE_DEVICE_FAILURE = 'EDIT_BACK_OFFICE_DEVICE_FAILURE',

  SET_BACK_OFFICE_DEVICES = 'SET_BACK_OFFICE_DEVICES',

  SET_BACK_OFFICE_DEVICE_FILTERS = 'SET_BACK_OFFICE_DEVICE_FILTERS',
  SET_BACK_OFFICE_DEVICE_DATA_SOURCES = 'SET_BACK_OFFICE_DEVICE_DATA_SOURCES',

  LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_REQUEST = 'LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_REQUEST',
  LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_SUCCESS = 'LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_SUCCESS',
  LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_FAILURE = 'LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_FAILURE'
}

export default ActionTypes;
