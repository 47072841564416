import React, { useCallback } from 'react';
import { FiMonitor } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface Props {
  enterpriseId: number;
  propagateClick?: boolean;
}

function DevicesLink({
  enterpriseId,
  propagateClick = false
}: Props): JSX.Element {
  // Prevent event propagation by default.
  const onClickHandler = useCallback(
    (e) => !propagateClick && e.stopPropagation(),
    [propagateClick]
  );
  return (
    <Link
      onClick={onClickHandler}
      to={`/back-office/devices?filter.backOfficeDevices.enterpriseId=${enterpriseId}`}
    >
      <FiMonitor size="22" style={{ verticalAlign: 'middle' }} />
    </Link>
  );
}

export default DevicesLink;
