import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { UserId } from 'types/models/user';

const selectBackOfficeUsersState = (state: ApplicationRootState) =>
  state.backOfficeUsers;

const selectBackOfficeUsersTotal = createSelector(
  selectBackOfficeUsersState,
  (usersState) => usersState.total
);

const selectBackOfficeUsersData = createSelector(
  selectBackOfficeUsersState,
  (usersState) => usersState.data
);

const selectBackOfficeUsersIds = createSelector(
  selectBackOfficeUsersState,
  (usersState) => usersState.ids
);

const selectBackOfficeUsersAsArray = createSelector(
  [selectBackOfficeUsersData, selectBackOfficeUsersIds],
  (backOfficeUsersData, backOfficeUsersIds) =>
    backOfficeUsersIds.map((userId) => backOfficeUsersData[userId])
);

const makeSelectBackOfficeUserById = (userId: UserId) =>
  createSelector(
    selectBackOfficeUsersData,
    (backOfficeUsersData) => backOfficeUsersData[userId]
  );

export {
  selectBackOfficeUsersTotal,
  selectBackOfficeUsersData,
  selectBackOfficeUsersIds,
  selectBackOfficeUsersAsArray,
  makeSelectBackOfficeUserById
};
