import { message as antdMessage } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import useAssetType from 'store/modules/assetTypes/hooks';
import { ephemeralGetRequests } from 'store/modules/ephemeralRequest/actions';
import { RawAssetTypeCode } from 'types/models/asset-type';
import { ExtendedSample } from 'types/models/sample';
import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import getOriginalSamplePointIdsFromMergedSamplePoint from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { mergeSafetyCheckInSamplesChronologically } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import downloadFile from 'utils/download-file';

import { constructSampleUrlForCSVExport } from './utils/construct-sample-url-for-csv-export';

import { CSV_DATE_FORMAT, CSV_FILE_NAME_DATE_FORMAT } from '.';

export default function useExportSafetyCheckInCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const { siteTimezoneCode, assetTypeId } = samplePoint;
  const assetType = useAssetType(assetTypeId);

  if (!siteTimezoneCode) {
    antdMessage.error('Site time zone is not set');
    return;
  }

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      const data = await new Promise<ExtendedSample[][]>((resolve, reject) => {
        setLoading(true);
        const originalSamplePointIds: SamplePointId[] = getOriginalSamplePointIdsFromMergedSamplePoint(samplePoint);
        const paths = originalSamplePointIds.map((id) =>
          constructSampleUrlForCSVExport(id, siteTimezoneCode, dateRange)
        );

        dispatch(ephemeralGetRequests<any[]>(paths, resolve, reject));
      });

      const csvArray = mergeSafetyCheckInSamplesChronologically(
        data[0],
        data[1]
      )
        .sort((a, b) => b.date - a.date)
        .map(
          (s) =>
            `${moment(s.date).tz(siteTimezoneCode).format(CSV_DATE_FORMAT)},${s.rawAssetType === RawAssetTypeCode.SAFETY_CHECK_IN_SOS
              ? 'SOS'
              : 'Check-in'
            }`
        );
      // Add header to csv array.
      csvArray.unshift('Date and Time,Activity');

      // Download CSV file to users' device.
      downloadFile(
        // Filename.
        `${samplePoint.name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        // Data.
        csvArray.join('\n'),
        // MIME type.
        'text/csv'
      );
      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
