import { action } from 'typesafe-actions';

import ActionTypes from './constants';

const ephemeralGetRequest = <T>(
  path: string,
  onSuccess: (data: T) => void,
  onError?: (reason: string) => void
) => action(ActionTypes.EPHEMERAL_GET_REQUEST, { path, onSuccess, onError });

const ephemeralGetRequests = <T>(
  paths: string[],
  onSuccess: (data: T[]) => void,
  onError?: (reason: string) => void
) => action(ActionTypes.EPHEMERAL_GET_REQUESTS, { paths, onSuccess, onError });

const ephemeralPatchRequest = <T>(
  path: string,
  data: any,
  onSuccess: (data: T) => void,
  onError?: (reason: string) => void
) =>
  action(ActionTypes.EPHEMERAL_PATCH_REQUEST, {
    path,
    requestBody: data,
    onSuccess,
    onError
  });

export { ephemeralGetRequest, ephemeralGetRequests, ephemeralPatchRequest };
