import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import Country, { CountryAbbreviation } from 'types/models/country';

import ActionTypes from './constants';

export const setCountries = (countries: Record<CountryAbbreviation, Country>) =>
  action(ActionTypes.SET_COUNTRIES, countries);

export const setCountry = (country: Country) =>
  action(ActionTypes.SET_COUNTRY, country);

export const loadCountries = () => action(ActionTypes.LOAD_COUNTRIES_REQUEST);

export const loadCountriesSuccess = (response?: AxiosResponse) =>
  action(ActionTypes.LOAD_COUNTRIES_SUCCESS, { response });

export const loadCountriesFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.LOAD_COUNTRIES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const getCountryByAbbr = (abbr: CountryAbbreviation) =>
  action(ActionTypes.GET_COUNTRY_BY_ABBR_REQUEST, abbr);

export const getCountryByAbbrSuccess = (response: AxiosResponse) =>
  action(ActionTypes.GET_COUNTRY_BY_ABBR_SUCCESS, { response });

export const getCountryByAbbrFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.GET_COUNTRY_BY_ABBR_FAILURE,
    { message, error },
    undefined,
    true
  );
