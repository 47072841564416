import isNil from 'lodash/isNil';

import { DAM_PRECISION } from 'constants/samplePoint';
import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint, { LevelDisplayUnit } from 'types/models/samplePoint';
import convertImperialToMetric from 'utils/convert-Imperial-to-metric';
import convertUnit from 'utils/convert-unit';
import { UnitType } from 'utils/get-unit-by-country';
import { isTroughDirect } from 'utils/SamplePoints/trough';
import { convertToLitres } from 'utils/unit-conversions';

export function convertImperialConfigToMetric(
  nextConfig: SamplePoint['config'] | undefined,
  samplePoint: SamplePoint,
  country: string
): SamplePoint['config'] {
  const prevConfig: SamplePoint['config'] | undefined = samplePoint.config;

  if (!nextConfig) {
    return prevConfig;
  }

  let maxValue: number;
  let totalCapacity: number | undefined;
  let updatedConfig: SamplePoint['config'] = {};
  let maxDepth: number;
  let maxVolume: number;

  switch (samplePoint.assetTypeId) {
    case AssetTypeCode.WATER_TANK:
    case AssetTypeCode.FUEL_TANK:
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
      maxValue = convertImperialToMetric(
        UnitType.LEVEL_SM,
        country,
        nextConfig.maxValue
      ) ?? 0;
      totalCapacity = convertImperialToMetric(
        UnitType.VOLUME_SM,
        country,
        nextConfig.totalCapacity
      );
      updatedConfig = {
        ...nextConfig,
        maxValue,
        totalCapacity
      };
      if (nextConfig.tankLength) {
        updatedConfig.tankLength = convertImperialToMetric(
          UnitType.LEVEL_SM,
          country,
          nextConfig.tankLength
        );
      }
      if (nextConfig.tankWidth) {
        updatedConfig.tankWidth = convertImperialToMetric(
          UnitType.LEVEL_SM,
          country,
          nextConfig.tankWidth
        );
      }
      break;
    case AssetTypeCode.TROUGH: {
      maxValue = convertImperialToMetric(
        UnitType.LEVEL_SM,
        country,
        nextConfig.maxValue
      ) ?? 0;
      updatedConfig = {
        ...nextConfig,
        maxValue
      };
      if (isTroughDirect(samplePoint) && nextConfig.totalCapacity) {
        updatedConfig.totalCapacity = convertImperialToMetric(
          UnitType.VOLUME_SM,
          country,
          nextConfig.totalCapacity
        );
      }
      break;
    }
    case AssetTypeCode.BORE:
      maxDepth = convertImperialToMetric(
        UnitType.LEVEL_MD,
        country,
        nextConfig.maxDepth
      ) ?? 0;
      updatedConfig = {
        ...nextConfig,
        maxDepth
      };
      break;
    case AssetTypeCode.LINE_PRESSURE:
      maxValue = convertImperialToMetric(
        UnitType.PRESSURE,
        country,
        nextConfig.maxValue
      ) ?? 0;
      updatedConfig = {
        ...nextConfig,
        maxValue
      };
      break;
    case AssetTypeCode.DAM:
      if (isNil(nextConfig.maxDepth) || !nextConfig.levelDisplayUnit) {
        return prevConfig;
      } else {
        let unitType = UnitType.LEVEL_MD;
        if (
          [LevelDisplayUnit.CM, LevelDisplayUnit.IN].includes(
            nextConfig.levelDisplayUnit
          )
        ) {
          unitType = UnitType.LEVEL_SM;
        }
        maxDepth = convertUnit(
          { value: nextConfig.maxDepth, unit: { symbol: nextConfig.levelDisplayUnit } },
          { symbol: 'cm' },
          DAM_PRECISION
        );
        maxVolume =
          convertToLitres(
            Number(nextConfig.maxVolume ?? 0),
            nextConfig.volumeDisplayUnit
          ) ?? 0;
        updatedConfig = {
          ...prevConfig, // Preserve enabledVolumeMappings
          ...nextConfig, // Update enabledVolumeMappings if presents
          maxDepth,
          maxVolume,
          volumeDisplayUnit: nextConfig.volumeDisplayUnit
        };
      }
      break;
    case AssetTypeCode.CAMERA:
      updatedConfig = nextConfig || samplePoint.config;
      break;
    default:
      updatedConfig = {
        ...prevConfig,
        ...nextConfig
      };
  }

  if (nextConfig.offset) {
    updatedConfig.offset = convertImperialToMetric(
      UnitType.LEVEL_SM,
      country,
      Number(nextConfig.offset)
    );
  } else {
    delete updatedConfig.offset;
  }

  return updatedConfig;
}