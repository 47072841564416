import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put, select } from 'redux-saga/effects';

import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { getRequest } from 'utils/redux-saga-requests';
import { getRainfallSamplePeriodDates } from 'utils/Sample/get-rainfall-sample-dates';

import { getUnitTypeByAssetTypeId } from './utils';
import { selectCurrentEnterpriseCountry } from '../../enterprise/selectors';
import { makeSelectSamplePointById } from '../../samplePoints/selectors';
import {
  loadRainfallSamples,
  loadRainfallSamplesFailure,
  loadRainfallSamplesSuccess,
  setSamplePointSamples
} from '../actions';

export function* requestRainfallSamples(
  action: ReturnType<typeof loadRainfallSamples>
) {
  const {
    payload: {
      samplePointId, startDate, endDate, interval, dailyResetTime, siteTimezoneCode
    }
  } = action;
  const samplePoint: SamplePoint = yield select(
    makeSelectSamplePointById(samplePointId)
  );
  const country: string = yield select(selectCurrentEnterpriseCountry);
  const startDateString = moment(startDate).tz(siteTimezoneCode).format();
  const endDateString = moment(endDate).tz(siteTimezoneCode).format();
  try {
    const { data } = yield call(getRequest, `/samplepoint/${samplePoint.sid}/v2/hourly-samples`, {
      params: { startDate: startDateString, endDate: endDateString, interval }
    });

    const samples = data.map((sample) => {
      const { prevDate, date } = getRainfallSamplePeriodDates(sample.date, interval, dailyResetTime, siteTimezoneCode);
      return {
        ...sample,
        id: sample.date,
        samplePointId: samplePoint.id,
        rwValue: convertMetricToImperial(
          getUnitTypeByAssetTypeId(samplePoint.assetTypeId),
          country,
          sample.rwValue
        ) ?? 0,
        date,
        prevDate
      };
    });

    yield all([
      put(loadRainfallSamplesSuccess(data)),
      put(setSamplePointSamples(keyBy(samples as Sample[], 'id')))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }

    yield put(loadRainfallSamplesFailure(message, error));
  }
}
