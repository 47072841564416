import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AssetTypeId } from 'types/models/asset-type';

import { makeSelectAssetTypeById } from './selectors';

export default function useAssetType(assetTypeId: AssetTypeId) {
  const selectAssetTypeById = useMemo(
    () => makeSelectAssetTypeById(assetTypeId),
    [assetTypeId]
  );

  const assetType = useSelector(selectAssetTypeById, shallowEqual);

  return assetType;
}
