import { AutomaticControlType } from 'store/modules/controlPoints/types';
import { LatLong } from 'types/asset';
import { AssetTypeId, RawAssetTypeCode } from 'types/models/asset-type';
import Device, { DeviceId, DeviceModel } from 'types/models/device';
import Sample from 'types/models/sample';
import { MachineControlStatistic } from 'types/models/samplePointsStatistic';
import Site, { SiteId } from 'types/models/site';
import { TankShape } from 'types/tank-shape.enum';
import { Quantity } from 'utils/convert-unit';
import { Modify } from 'utils/TypeScript/modify';

export type SamplePointId = number;
export type Trend = number;
export enum LevelDisplayUnit {
  CM = 'cm',
  IN = 'in',
  M = 'm',
  FT = 'ft'
}
export enum VolumeDisplayUnit {
  L = 'L',
  KL = 'KL',
  ML = 'ML',
  GAL = 'gal',
  CU_FT = 'cu. ft.',
  AC_FT = 'ac. ft.'
}

export type LiquidLevelMeasurementDirection = 'bottomUp' | 'topDown';

/** SensorTagId is the device_ID (device type) in IoT. */
export enum SensorTagId {
  TROUGH = 136,
  TROUGH_DIRECT = 176,
  TANKBOT = 144,
  XTEND = 167,
  WATER_TANK_LITE = 168,
  SOS = 856,
  PRIMARY_MACHINE_CONTROL = 860,
  SECONDARY_MACHINE_CONTROL = 863
}

export const SENSOR_NAME_BY_SENSOR_TAG_ID: Record<SensorTagId, string> = {
  [SensorTagId.TROUGH]: 'Trough',
  [SensorTagId.TROUGH_DIRECT]: 'Trough Direct',
  [SensorTagId.TANKBOT]: 'Trough',
  [SensorTagId.XTEND]: 'Xtend',
  [SensorTagId.SOS]: 'SOS',
  [SensorTagId.PRIMARY_MACHINE_CONTROL]: 'Primary Machine Control',
  [SensorTagId.SECONDARY_MACHINE_CONTROL]: 'Secondary Machine Control',
  [SensorTagId.WATER_TANK_LITE]: 'Water Tank'
};

export type SamplePointLastSampleData = Modify<
  Sample,
  { id: string; date: string; prevDate: string }
>;

export interface BaseSamplePoint {
  id: SamplePointId;
  sid: string;
  name?: string;
  assetTypeId: AssetTypeId;
  siteId: SiteId;
  deviceId: DeviceId;
  // Generated from parseRawSamplePoints()
  device?: Pick<
    Device,
    | 'id'
    | 'name'
    | 'model'
    | 'serialNumber'
    | 'status'
    | 'lastStatusDate'
    | 'statusCause'
    | 'isBatteryLow'
  >;

  location?: {
    coordinates: LatLong;
  };

  siteName?: string;
  siteTimezoneCode?: string;

  lastSampleDate?: number;
  // TODO: Once remove the usage of lastSampleData in FE, should remove it from
  // BE too.
  /**
   * @deprecated Please use samplePointsStatistic.lastSample instead.
   * lastSampleData doesn't refresh often and its extraValues is not complete.
   */
  lastSampleData?: SamplePointLastSampleData;

  userComments: string | null;
  operatorComments?: string;

  isConfigured?: boolean;

  /**
   * The maximum time between samples in seconds.
   */
  maxPacketInterval?: number;

  deviceTags: {
    serialNumber: number;
    portNo: number;
    /** Generated from getPhysicalPortNoByLogicalPortNo() */
    physicalPortNo: number | string;
    internal: number;
    model?: DeviceModel;
  };
  // Sensor attributes. The id is the DeviceID in IoT layer (e.g., 167 means
  // Xtend), and it's the id of the node not hub. e.g.,
  // Water Level Sensor -> Xtend (node) -> Monitor (hub).
  sensorTags: {
    id: SensorTagId;
    type: string;
  };

  site?: Site;

  statusActive: number;
}

export interface SamplePointConfig {
  offset?: number;
  minValue?: number;
  maxValue?: number;
  totalCapacity?: number;
  flowDirection?: 'inflow' | 'outflow';
  maxDepth?: number;
  maxVolume?: number;

  // Trough direct
  maxValueAutoCalibrated?: number;

  // Dam
  enabledVolumeMapping?: boolean;
  levelDisplayUnit?: LevelDisplayUnit;
  volumeDisplayUnit?: VolumeDisplayUnit;
  liquidLevelMeasurementDirection?: 'bottomUp' | 'topDown';

  // Fuel tanks
  tankShape?: TankShape;
  tankWidth?: number;
  tankLength?: number;

  // Camera
  maxKb?: number;
  kbConsumed?: number;
  resetDate?: string | null;

  // Machine Control
  automation?: AutomaticControlType;
};

export default interface SamplePoint extends BaseSamplePoint {
  config: SamplePointConfig;

  /**
   * Water/fuel level related statistics that are calculated and attached on the
   * frontend.
   */
  waterLevelStatistics?: {
    changePerHour?: number;
    forecastedValue?: number;
    forecastedPercentFull?: number;
    maxValue?: number;
    forecastedVolume?: number;
    maxVolume?: number;
  };

  /**
   * Liquid fertiliser related statistics that are calculated and attached on
   * the frontend.
   */
  liquidFertiliserStatistics?: {
    level?: number;
    height?: number;
    volume?: number;
    maxVolume?: number;
    capacity?: number;
  };

  /**
   * Flow related statistics that are calculated and attached on the frontend.
   */
  flowStatistics?: {
    current?: number;
    oneDay?: number;
    sevenDays?: number;
    thirtyDays?: number;
  };

  /**
   * Rainfall related statistics that are calculated and attached on the
   * frontend.
   */
  rainfallStatistics?: {
    today?: number;
    mtd?: number;
    ytd?: number;
    rolling90Days?: number;
  };

  /**
   * Trough related statistics that are calculated and attached on the frontend.
   */
  troughStatistics?: {
    highestAverageThisFortnight?: number;
    minimumThisFortnight?: number;
    latestAverage?: number;
    latestMinimum?: number;
  };

  troughDirectStatistics?: {
    level: Quantity;
    capacity: Quantity;
  }

  /**
   * Soil related statistics that are calculated and attached on the frontend.
   */
  soilStatistics?: {
    maxMoistureThisFortnight?: number;
    minMoistureThisFortnight?: number;
    maxTempThisFortnight?: number;
    minTempThisFortnight?: number;
    latestTemp?: number;
    latestMoisture?: number;
  };

  /**
   * Line pressure related statistics that are calculated and attached on the
   * frontend.
   */
  linePressureStatistics?: {
    currentCapacity?: number;
    currentPressure?: number;
    maxPressure?: number;
  };

  /**
   * Dam related statistics that are calculated and attached on the frontend.
   */
  damStatistics?: {
    changePerHour?: number;
    /** In m/ft */
    level: number;
    reducedLevel?: number;
    /** When it's an advanced dam, the volume is the sum of slice volumes under
     * the reduced level.
     *
     * The volume is in display unit.
     * */
    volume?: number;
    /** In display unit */
    maxVolume?: number;
    /** In m/ft */
    maxDepth?: number;
    capacity?: number;
  };

  /**
   * Bore related statistics that are calculated and attached on the frontend.
   */
  boreStatistics?: {
    level?: number;
  };

  /**
   * Safety check-in related statistics that are calculated and attached on the
   * frontend.
   */
  safetyCheckInStatistics?: {
    checkInDate?: number;
    sosDate?: number;
  };

  machineControlStatistics?: MachineControl['machineControlStatistics'];
}

export interface MachineControl extends BaseSamplePoint {
  config: {
    automation?: AutomaticControlType;
  };

  _hidden?: {
    id: SamplePointId;
  };

  machineControlStatistics: MachineControlStatistic & {
    verboseStatus: string;
    verboseAutomation: string;
  };
}

export interface DamSamplePoint extends BaseSamplePoint {
  config: {
    offset?: number;
    maxDepth?: number;
    maxVolume?: number;
    enabledVolumeMapping?: boolean;
    levelDisplayUnit: LevelDisplayUnit;
    volumeDisplayUnit: VolumeDisplayUnit;
    liquidLevelMeasurementDirection: 'bottomUp' | 'topDown';
  };

  damStatistics: {
    changePerHour?: number;
    /** In m/ft */
    level: number;
    reducedLevel?: number;
    /** When it's an advanced dam, the volume is the sum of slice volumes under
     * the reduced level.
     *
     * The volume is in display unit.
     * */
    volume?: number;
    /** In display unit */
    maxVolume?: number;
    /** In m/ft */
    maxDepth?: number;
    capacity?: number;
  };
}

/**
 * A sample point that may or may not be supported by the frontend.
 */
export type RawSamplePoint = Modify<
  SamplePoint, // TODO: Should be BaseSamplePoint
  {
    assetTypeId: RawAssetTypeCode;
  }
>;

export interface MergedSamplePoint extends SamplePoint {
  _hidden?: RawSamplePoint;
}

export interface SamplePointWithFlattenedNames extends SamplePoint {
  assetTypeName?: string;
  deviceName?: string;
}

export interface SamplePointWithNonNullableLocation extends SamplePoint {
  location: NonNullable<SamplePoint['location']>;
}
