import { Space, Typography } from 'antd';
import React from 'react';

interface SummaryCardProps {
  title: string;
  value: number;
}

function SummaryCard({ title, value }: SummaryCardProps) {
  return (
    <div className="CiboSubscriptionListPage-summary-card">
      <Space direction="vertical" size={8}>
        <Typography>{title}</Typography>
        <Typography className="CiboSubscriptionListPage-summary-card-value">{value}</Typography>
      </Space>
    </div>
  );
}

export default SummaryCard;