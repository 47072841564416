enum ActionTypes {
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_COUNTRY = 'SET_COUNTRY',
  LOAD_COUNTRIES_REQUEST = 'LOAD_COUNTRIES_REQUEST',
  LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS',
  LOAD_COUNTRIES_FAILURE = 'LOAD_COUNTRIES_FAILURE',
  GET_COUNTRY_BY_ABBR_REQUEST = 'GET_COUNTRY_BY_ABBR_REQUEST',
  GET_COUNTRY_BY_ABBR_SUCCESS = 'GET_COUNTRY_BY_ABBR_SUCCESS',
  GET_COUNTRY_BY_ABBR_FAILURE = 'GET_COUNTRY_BY_ABBR_FAILURE'
}

export default ActionTypes;
