import flatMap from 'lodash/flatMap';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';

const selectBackOfficeInviteesState = (state: ApplicationRootState) =>
  state.backOfficeInvitations;

const selectBackOfficeInvitees = (state: ApplicationRootState) =>
  state.backOfficeInvitations.data;

const selectBackOfficeInvitations = (state: ApplicationRootState) =>
  flatMap(state.backOfficeInvitations.data, (invitee) => invitee.invitations);

export const makeSelectInvitationById = (invitationId: number) =>
  createSelector(selectBackOfficeInvitations, (invitations) =>
    invitations.find((invitation) => invitation.id === invitationId)
  );

export const makeSelectInvitationsByEmail = (email?: string) =>
  createSelector(
    selectBackOfficeInvitees,
    (invitees) =>
      invitees.find((invitee) => invitee.email === email)?.invitations ?? []
  );

export default selectBackOfficeInviteesState;
