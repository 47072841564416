import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const loadAppScaffold = () =>
  action(ActionTypes.LOAD_APP_SCAFFOLD_REQUEST);

export const loadAppScaffoldSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_APP_SCAFFOLD_SUCCESS, { response });

export const loadAppScaffoldFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.LOAD_APP_SCAFFOLD_FAILURE,
    { message, error },
    undefined,
    true
  );
