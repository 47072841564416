import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { EnterpriseId } from 'types/models/enterprise';

const selectBackOfficeEnterprisesState = (state: ApplicationRootState) =>
  state.backOfficeEnterprises;

const selectBackOfficeEnterprisesTotal = createSelector(
  selectBackOfficeEnterprisesState,
  (enterprisesState) => enterprisesState.total
);

const selectBackOfficeEnterprisesData = createSelector(
  selectBackOfficeEnterprisesState,
  (enterprisesState) => enterprisesState.data
);

const selectBackOfficeEnterprisesIds = createSelector(
  selectBackOfficeEnterprisesState,
  (enterprisesState) => enterprisesState.ids
);

const selectBackOfficeEnterprisesAsArray = createSelector(
  [selectBackOfficeEnterprisesData, selectBackOfficeEnterprisesIds],
  (backOfficeEnterprisesData, backOfficeEnterprisesIds) =>
    backOfficeEnterprisesIds.map(
      (enterpriseId) => backOfficeEnterprisesData[enterpriseId]
    )
);

const makeSelectBackOfficeEnterpriseById = (enterpriseId: EnterpriseId) =>
  createSelector(
    selectBackOfficeEnterprisesData,
    (backOfficeEnterprisesData) => backOfficeEnterprisesData[enterpriseId]
  );

export {
  selectBackOfficeEnterprisesTotal,
  selectBackOfficeEnterprisesData,
  selectBackOfficeEnterprisesIds,
  selectBackOfficeEnterprisesAsArray,
  makeSelectBackOfficeEnterpriseById
};
