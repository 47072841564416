/* eslint-disable no-underscore-dangle */

import { AssetTypeCode, RawAssetTypeCode } from 'types/models/asset-type';
import Sample, { ExtendedSample } from 'types/models/sample';
import SamplePoint, {
  MergedSamplePoint,
  RawSamplePoint,
  SamplePointLastSampleData,
  SensorTagId
} from 'types/models/samplePoint';
import SamplePointStatistic from 'types/models/samplePointsStatistic';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';
import dateToISOString from 'utils/date-to-iso-string';

export const mergeSafetyCheckInSamplesChronologically = (
  checkInSamples: ExtendedSample[],
  sosSamples: ExtendedSample[]
): ExtendedSample[] => {
  // TODO: should not have any
  const mergedSamples: any[] = [];
  for (let i = 0; i < checkInSamples.length; i += 1) {
    const sample = checkInSamples[i];
    sample.rawAssetType = RawAssetTypeCode.SAFETY_CHECK_IN_VISIT;
    mergedSamples.push(sample);
  }
  for (let i = 0; i < sosSamples.length; i += 1) {
    const sample = sosSamples[i];
    sample.rawAssetType = RawAssetTypeCode.SAFETY_CHECK_IN_SOS;
    mergedSamples.push(sample);
  }
  return mergedSamples.sort((a, b) => a.date - b.date);
};

/**
 * Merge associated SCI (Safety Check-in) check-in & SOS samples into a single
 * sample.
 */
export const mergeSafetyCheckInSamples = (
  checkInSample: Sample,
  sosSample: Sample
): Sample =>
  ({
    ...checkInSample,
    multiDimValues: {
      // Give each value a different dataType so we can tell them apart.
      sampleDim: [
        {
          ...checkInSample,
          dataType: RawAssetTypeCode.SAFETY_CHECK_IN_VISIT
        },
        {
          ...sosSample,
          dataType: RawAssetTypeCode.SAFETY_CHECK_IN_SOS
        }
      ].map(({ rwValue, dataType, date, prevDate }) => ({
        rwValue,
        dataType,
        sampleDate: dateToISOString(date),
        prevSampleDate: dateToISOString(prevDate)
      }))
    },
    // Keep original data of temp sample, so we can recreate it if needed.
    _hidden: sosSample
  }) as Sample;

/**
 * Takes a raw SCI samplePoint and a parsed SCI samplePoint, and merges them
 * into a single parsed samplePoint.
 */
const mergeSafetyCheckInSamplePoints = (
  rawSafetyCheckInSamplePoint: RawSamplePoint,
  safetyCheckInSamplePoint: SamplePoint
): SamplePoint => {
  const isSOS =
    rawSafetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_SOS;

  const [sos, checkIn] = isSOS
    ? [
      parseRawSamplePoint(rawSafetyCheckInSamplePoint) as SamplePoint,
      safetyCheckInSamplePoint
    ]
    : [
      safetyCheckInSamplePoint,
      parseRawSamplePoint(rawSafetyCheckInSamplePoint) as SamplePoint
    ];
  return {
    ...checkIn,
    // Keep original data of SOS sample point, so we can recreate it if needed.
    _hidden: sos
  } as SamplePoint;
};

/**
 * Merge associated SCI check-in & SOS samplePointStatistics into a single
 * samplePointStatistic.
 */
export const mergeSafetyCheckInSamplePointStatistics = (
  checkIn: SamplePointStatistic,
  sos: SamplePointStatistic
) =>
  ({
    ...checkIn,
    lastSample:
      checkIn.lastSample && sos.lastSample
        ? mergeSafetyCheckInSamples(checkIn.lastSample, sos.lastSample)
        : checkIn.lastSample,
    _hidden: sos
  }) as SamplePointStatistic;

/**
 * Get original check-in sample & SOS sample from merged SCI sample.
 */
const getOriginalSafetyCheckInSamples = (
  mergedSafetyCheckInSample: SamplePointLastSampleData & {
    _hidden?: SamplePointLastSampleData;
  }
) => {
  // Get SOS sample from "_hidden" property of merged samplePoint.
  let extractedCheckInSample: SamplePointLastSampleData | undefined;
  let extractedSOSSample: SamplePointLastSampleData | undefined;
  const [checkIn, sos] = [
    { ...mergedSafetyCheckInSample },
    mergedSafetyCheckInSample._hidden as SamplePointLastSampleData
  ];

  delete checkIn._hidden;
  delete checkIn.multiDimValues;

  // Both check-in & SOS originally had the same data type.
  checkIn.dataType = sos.dataType;

  return [extractedCheckInSample, extractedSOSSample] as [
    SamplePointLastSampleData,
    SamplePointLastSampleData
  ];
};

/**
 * Get original check-in samplePoint and SOS samplePoint from merged SCI
 * samplePoint.
 */
export const getOriginalSafetyCheckInSamplePoints = (
  mergedSafetyCheckInSamplePoint: MergedSamplePoint & {
    sensorTags?: { id: number };
  }
) => {
  if (!mergedSafetyCheckInSamplePoint._hidden) {
    // Rarely we don't receive check-in and SOS in pairs.
    const isSos =
      mergedSafetyCheckInSamplePoint.sensorTags?.id !== SensorTagId.SOS;
    return isSos
      ? ([mergedSafetyCheckInSamplePoint, undefined] as [
        MergedSamplePoint,
        undefined
      ])
      : ([undefined, mergedSafetyCheckInSamplePoint] as [
        undefined,
        MergedSamplePoint
      ]);
  }
  // Get SOS samplePoint from "_hidden" property of merged samplePoint.
  const [checkIn, sos] = [
    { ...mergedSafetyCheckInSamplePoint } as unknown as RawSamplePoint & {
      _hidden?: RawSamplePoint;
    },
    mergedSafetyCheckInSamplePoint._hidden as RawSamplePoint
  ];

  delete checkIn._hidden;

  checkIn.lastSampleData = checkIn.lastSampleData
    ? getOriginalSafetyCheckInSamples(checkIn.lastSampleData)[0]
    : undefined;

  checkIn.assetTypeId = RawAssetTypeCode.SAFETY_CHECK_IN_VISIT;
  sos.assetTypeId = RawAssetTypeCode.SAFETY_CHECK_IN_SOS;

  return [checkIn, sos] as [RawSamplePoint, RawSamplePoint];
};

/**
 * Returns true if the given raw & parsed SCI samplePoints are associated.
 * That is, they have the same serial number (and are both SCI samplePoints).
 */
const isMatchingSafetyCheckInSamplePoint = (
  safetyCheckInSamplePoint: RawSamplePoint,
  samplePoint: SamplePoint
) =>
  (safetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_VISIT ||
    safetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_SOS) &&
  samplePoint.assetTypeId === AssetTypeCode.SAFETY_CHECK_IN &&
  // SCI sample point has no SN in their device tags so we use device id here.
  safetyCheckInSamplePoint.deviceId === samplePoint.deviceId;

/**
 * Takes an array of parsed samplePoints (& nulls) and a raw samplePoint, and
 * returns an array of parsed samplePoints (& nulls).
 */
export const safetyCheckInSamplePointReducer = (
  currentSamplePoints: Array<SamplePoint | null>,
  newSafetyCheckInSamplePoint: RawSamplePoint
) => {
  // TODO: should not have any
  const nextSamplePoints: any[] = [];
  let merged = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < currentSamplePoints.length; i++) {
    const samplePoint = currentSamplePoints[i];

    if (
      samplePoint &&
      isMatchingSafetyCheckInSamplePoint(
        newSafetyCheckInSamplePoint,
        samplePoint
      )
    ) {
      nextSamplePoints.push(
        mergeSafetyCheckInSamplePoints(
          newSafetyCheckInSamplePoint,
          samplePoint
        )
      );
      merged = true;
    } else {
      nextSamplePoints.push(samplePoint);
    }
  }

  if (!merged) {
    nextSamplePoints.push(parseRawSamplePoint(newSafetyCheckInSamplePoint));
  }

  return nextSamplePoints;
};
