/* eslint-disable import/prefer-default-export */

import { ValueType } from 'types/trigger.enum';
import { centimetresToMetres } from 'utils/unit-conversions';

import { VolumeMappingQuery } from './types';

export function createVolumeMappingQueryFromTrigger(
  value: number,
  valueType: ValueType
): VolumeMappingQuery {
  switch (valueType) {
    case ValueType.VOLUME:
      return { volume: value };
    case ValueType.REDUCED_LEVEL:
      return { reducedLevels: [centimetresToMetres(value)] };
    case ValueType.VALUE:
      return { levels: [value] };
    case ValueType.RATE:
    default:
      return {};
  }
}
