import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { UserRole } from 'types/models/membership';

import {
  makeSelectUserRoleGranted,
  selectMyUser,
  selectMyUserIsSuperAdmin
} from './selectors';

export function useMyUser() {
  return useSelector(selectMyUser, shallowEqual);
}

export function useIsSuperAdmin() {
  const userRoleGranted = useSelector(selectMyUserIsSuperAdmin, shallowEqual);

  return !!userRoleGranted;
}

export function useIsAdmin() {
  const selectUserRoleGranted = useMemo(
    () => makeSelectUserRoleGranted([UserRole.ADMIN]),
    []
  );

  const userRoleGranted = useSelector(selectUserRoleGranted, shallowEqual);

  return userRoleGranted;
}

export function useIsAdminOrSupervisor() {
  const selectUserRoleGranted = useMemo(
    () => makeSelectUserRoleGranted([UserRole.ADMIN, UserRole.SUPERVISOR]),
    []
  );

  const userRoleGranted = useSelector(selectUserRoleGranted, shallowEqual);

  return userRoleGranted;
}
