enum ActionTypes {
  FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_REQUEST = 'FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_REQUEST',
  FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_SUCCESS = 'FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_SUCCESS',
  FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_FAILURE = 'FETCH_BACK_OFFICE_CIBO_SUBSCRIPTIONS_FAILURE',
  SET_BACK_OFFICE_CIBO_SUBSCRIPTIONS = 'SET_BACK_OFFICE_CIBO_SUBSCRIPTIONS',

  FETCH_BACK_OFFICE_CIBO_LOCATIONS_REQUEST = 'FETCH_BACK_OFFICE_CIBO_LOCATIONS_REQUEST',
  FETCH_BACK_OFFICE_CIBO_LOCATIONS_SUCCESS = 'FETCH_BACK_OFFICE_CIBO_LOCATIONS_SUCCESS',
  FETCH_BACK_OFFICE_CIBO_LOCATIONS_FAILURE = 'FETCH_BACK_OFFICE_CIBO_LOCATIONS_FAILURE',
  SET_BACK_OFFICE_CIBO_LOCATIONS = 'SET_BACK_OFFICE_CIBO_LOCATIONS',

  ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST = 'ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST',
  ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS = 'ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS',
  ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE = 'ADD_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE',

  CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST = 'CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_REQUEST',
  CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS = 'CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_SUCCESS',
  CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE = 'CANCEL_BACK_OFFICE_CIBO_SUBSCRIPTION_FAILURE',

  OPEN_BACK_OFFICE_CIBO_CANCELLATION_MODAL = 'OPEN_BACK_OFFICE_CIBO_CANCELLATION_MODAL',
  CLOSE_BACK_OFFICE_CIBO_CANCELLATION_MODAL = 'CLOSE_BACK_OFFICE_CIBO_CANCELLATION_MODAL',

  OPEN_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL = 'OPEN_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL',
  CLOSE_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL = 'CLOSE_BACK_OFFICE_CIBO_PLACED_ZONES_MODAL',

  UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_REQUEST = 'UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_REQUEST',
  UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_SUCCESS = 'UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_SUCCESS',
  UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_FAILURE = 'UPDATE_BACK_OFFICE_CIBO_SUBSCRIPTION_RENEW_FAILURE'
}

export default ActionTypes;