import {
  MachineControlStatus,
  MachineControlStatusCode,
  RawMachineControlStatus
} from '../../types';

const mockMachineControls: RawMachineControlStatus[] = [
  {
    id: 434777,
    samplePointId: 434777,
    name: 'Mock Pump Control - Looping',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.OFF,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  },
  {
    id: 434888,
    samplePointId: 434888,
    name: 'Mock Pump Control - Off',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.OFF,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  },
  {
    id: 434889,
    samplePointId: 434889,
    name: 'Mock Pump Control - On',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: false,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.ON,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  },
  {
    id: 434890,
    samplePointId: 434890,
    name: 'Mock Pump Control - Turning on',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.TURNING_ON,
    statusCode: MachineControlStatusCode.MS_REQ_DELIVERED_TO_MONITOR
  },
  {
    id: 434891,
    samplePointId: 434891,
    name: 'Mock Pump Control - Turning Off',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.TURNING_OFF,
    statusCode: MachineControlStatusCode.MS_REQ_DELIVERED_TO_MONITOR
  },
  {
    id: 434892,
    samplePointId: 434892,
    name: 'Mock Manual Pump Control - Off',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.OFF,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  },
  {
    id: 434893,
    samplePointId: 434893,
    name: 'Mock Dual Machine Control - Pump',
    assetTypeId: 17,
    config: {
      automation: 1
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.OFF,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  },
  {
    id: 434894,
    samplePointId: 434894,
    name: 'Mock Dual Machine Control - Genset',
    assetTypeId: 17,
    config: {
      automation: 0
    },
    isConfigured: true,
    destinationSamplePoint: null,
    sourceSamplePoint: null,
    startRunningWindow: null,
    stopRunningWindow: null,
    status: MachineControlStatus.ON,
    statusCode: MachineControlStatusCode.MS_PAYLOAD_DELIVERED
  }
];

export default mockMachineControls;
