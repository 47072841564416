import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { BackOfficeUsersState } from './types';

export const initialState: BackOfficeUsersState = {
  total: undefined,
  data: {},
  ids: []
};

function backOfficeUsersReducer(
  state: BackOfficeUsersState = initialState,
  action: ApplicationActions
): BackOfficeUsersState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_USERS: {
      const {
        payload: { total, data, ids }
      } = action;

      return {
        total,
        data,
        ids
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeUsersReducer;
