import { Bounds, Coords } from 'google-map-react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoadingSpinner from 'components/composites/LoadingSpinner';
import BackOfficeDeviceMapDrawer from 'components/drawers/BackOfficeDeviceMapDrawer';
import BackOfficeDeviceMapInner from 'components/features/backOfficeDeviceMap/BackOfficeDeviceMapInner';
import BackOfficeDeviceMapLegend from 'components/features/backOfficeDeviceMap/BackOfficeDeviceMapLegend';
import { DEVICE_MAP_DEBOUNCE_TIME_IN_MS, DEVICE_MAP_DEFAULT_ZOOM, FARMBOT_OFFICE_COORDS, RANCHBOT_OFFICE_COORDS } from 'constants/device-map';
import { loadBackOfficeDeviceMapPoints } from 'store/modules/backOfficeDeviceMapPoints/actions';
import { useBackOfficeDeviceMapPoints } from 'store/modules/backOfficeDeviceMapPoints/hooks';
import { isRanchbot } from 'utils/is-x-bot';

import './styles.less';

export default function BackOfficeDeviceMap(): JSX.Element {
  const officeCoords = isRanchbot() ? RANCHBOT_OFFICE_COORDS : FARMBOT_OFFICE_COORDS;

  const [mapCenterCoords, setMapCenterCoords] = useState<Coords>(officeCoords);
  const [userCoords, setUserCoords] = useState<Coords>(officeCoords);

  const [mapBounds, setMapBounds] = useState<Bounds>({} as Bounds);
  const [mapZoom, setMapZoom] = useState(DEVICE_MAP_DEFAULT_ZOOM);

  const [shouldRenderMap, setShouldRenderMap] = useState(false);
  const [shouldFetchMapPoints, setShouldFetchMapPoints] = useState(true);

  const dispatch = useDispatch();
  const mapPointData = useBackOfficeDeviceMapPoints();

  const geolocationAPI = navigator.geolocation;

  const setCurrentLocation = useCallback(() => {
    geolocationAPI.getCurrentPosition((position) => {
      const { latitude: lat, longitude: lng } = position.coords;
      setMapCenterCoords({ lat, lng });
      setUserCoords({ lat, lng });

      setShouldRenderMap(true);
    }, () => {
      setShouldRenderMap(true);
    });
  }, [geolocationAPI]);

  const fetchDeviceMapPoints = useCallback(() => {
    if (!isEmpty(mapBounds)) dispatch(loadBackOfficeDeviceMapPoints(mapBounds, mapZoom));
  }, [dispatch, mapBounds, mapZoom]);

  const fetchMapPointsWithDebounce = useMemo(() =>
    debounce((bounds, zoom) => {
      dispatch(loadBackOfficeDeviceMapPoints(bounds, zoom));
    }, DEVICE_MAP_DEBOUNCE_TIME_IN_MS), [dispatch]);

  useEffect(() => {
    if (shouldFetchMapPoints && !isEmpty(mapBounds)) {
      fetchDeviceMapPoints();
      setShouldFetchMapPoints(false);
    }
  }, [fetchDeviceMapPoints, shouldFetchMapPoints, mapBounds]);

  useEffect(() => {
    setCurrentLocation();
  }, [setCurrentLocation]);

  if (!shouldRenderMap) return <LoadingSpinner useLogo />;

  return (
    <div className="BackOfficeDeviceMap">
      <>
        <BackOfficeDeviceMapInner
          mapCenterCoords={mapCenterCoords}
          userCoords={userCoords}
          mapZoom={mapZoom}
          mapPoints={mapPointData.data}
          setMapZoom={setMapZoom}
          setMapBounds={setMapBounds}
          setMapCenterCoords={setMapCenterCoords}
          fetchMapPoints={() => setShouldFetchMapPoints(true)}
          fetchMapPointsWithDebounce={fetchMapPointsWithDebounce}
        />
        <BackOfficeDeviceMapDrawer />
        <BackOfficeDeviceMapLegend
          onRecenter={() => setMapCenterCoords(userCoords)}
          onRefresh={() => setShouldFetchMapPoints(true)}
        />
      </>
    </div>
  );
}