import React from 'react';

import './styles.less';

interface BackOfficeDeviceMapClusterMarkerProps {
  lat: number;
  lng: number;
  count: number;
  onClick: () => void;
}

function BackOfficeDeviceMapClusterMarker({ count, onClick }: BackOfficeDeviceMapClusterMarkerProps) {
  return (
    <div className="BackOfficeDeviceMapClusterMarker" onClick={onClick}>
      <div className="BackOfficeDeviceMapClusterMarker-inner">
        {count}
      </div>
    </div>
  );
};

export default BackOfficeDeviceMapClusterMarker;