import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { InvitationsState } from './types';

export const initialState: InvitationsState = [];

export default function invitationsReducer(
  state: InvitationsState = initialState,
  action: ApplicationActions
): InvitationsState {
  switch (action.type) {
    case ActionTypes.LOAD_CURRENT_ENTERPRISE_INVITATIONS_SUCCESS: {
      const {
        payload: { invitations }
      } = action;
      return invitations.map((i) => ({
        ...i,
        id: i.sid
      }));
    }
    case ActionTypes.ADD_INVITATION_SUCCESS: {
      const {
        payload: { invitation }
      } = action;
      return [
        ...state,
        {
          ...invitation,
          id: invitation.sid
        }
      ];
    }
    case ActionTypes.UPDATE_INVITATION_SUCCESS: {
      const {
        payload: { id, updatedInvitation }
      } = action;
      const prevInvitation = state.find((i) => i.id === id);
      if (prevInvitation) {
        return [
          ...state.filter((i) => i.id !== id),
          {
            ...prevInvitation,
            role: updatedInvitation.role
          }
        ];
      }
      return state;
    }
    case ActionTypes.ACCEPT_INVITATION_SUCCESS:
    case ActionTypes.DELETE_INVITATION_SUCCESS: {
      const {
        payload: { invitationId }
      } = action;
      return state.filter(({ id }) => id !== invitationId);
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
