import { Form, Select, Typography } from 'antd';
import React from 'react';

import DefaultFormItem from 'components/composites/DefaultFormItem';
import FieldValue from 'components/forms/FieldValue';
import { useTimezonesForCountry } from 'utils/use-timezones';

const LABEL = 'Time Zone';

interface Props {
  formItemName: string | string[];
  readOnly?: boolean;
  countryAbbreviation: string;
}

function FormItemTimezone({
  formItemName,
  readOnly,
  countryAbbreviation
}: Props): JSX.Element {
  const timezones = useTimezonesForCountry(countryAbbreviation);

  const timezoneOptions = timezones.map((timezone) => ({
    label: timezone,
    value: timezone
  }));

  if (readOnly) {
    return (
      <Form.Item label={<Typography.Text strong>{LABEL}</Typography.Text>}>
        <FieldValue name={formItemName} />
      </Form.Item>
    );
  }

  return (
    <DefaultFormItem
      name={formItemName}
      label={LABEL}
      rules={[
        {
          required: true,
          message: 'Please enter a time zone'
        }
      ]}
    >
      <Select showSearch placeholder="Time Zone" options={timezoneOptions} />
    </DefaultFormItem>
  );
}

export default FormItemTimezone;
