enum ActionTypes {
  SET_VOLUME_MAPPINGS = 'SET_VOLUME_MAPPINGS',
  SET_CALCULATED_VOLUME_MAPPINGS = 'SET_CALCULATED_VOLUME_MAPPINGS',

  UPLOAD_VOLUME_MAPPINGS_REQUEST = 'UPLOAD_VOLUME_MAPPINGS_REQUEST',
  UPLOAD_VOLUME_MAPPINGS_SUCCESS = 'UPLOAD_VOLUME_MAPPINGS_SUCCESS',
  UPLOAD_VOLUME_MAPPINGS_FAILURE = 'UPLOAD_VOLUME_MAPPINGS_FAILURE',

  GET_VOLUME_MAPPINGS_REQUEST = 'GET_VOLUME_MAPPINGS_REQUEST',
  GET_VOLUME_MAPPINGS_SUCCESS = 'GET_VOLUME_MAPPINGS_SUCCESS',
  GET_VOLUME_MAPPINGS_FAILURE = 'GET_VOLUME_MAPPINGS_FAILURE',

  EDIT_VOLUME_MAPPINGS_REQUEST = 'EDIT_VOLUME_MAPPINGS_REQUEST',
  EDIT_VOLUME_MAPPINGS_SUCCESS = 'EDIT_VOLUME_MAPPINGS_SUCCESS',
  EDIT_VOLUME_MAPPINGS_FAILURE = 'EDIT_VOLUME_MAPPINGS_FAILURE',

  GET_CALCULATED_VOLUME_MAPPINGS_REQUEST = 'GET_CALCULATED_VOLUME_MAPPINGS_REQUEST',
  GET_CALCULATED_VOLUME_MAPPINGS_SUCCESS = 'GET_CALCULATED_VOLUME_MAPPINGS_SUCCESS',
  GET_CALCULATED_VOLUME_MAPPINGS_FAILURE = 'GET_CALCULATED_VOLUME_MAPPINGS_FAILURE'
}

export default ActionTypes;
