import moment from 'moment-timezone';
import { useMemo } from 'react';

import { useCountriesAsArray, useCountry } from 'store/modules/countries/hooks';

export function useTimezonesAsArray() {
  const countries = useCountriesAsArray();

  return useMemo(
    () =>
      countries.flatMap((country) =>
        moment.tz.zonesForCountry(country.shortcode)
      ),
    [countries]
  );
}

export function useTimezonesForCountry(countryAbbreviation: string) {
  const country = useCountry(countryAbbreviation);

  return useMemo(
    () => (country ? moment.tz.zonesForCountry(country.shortcode) : []),
    [country]
  );
}
