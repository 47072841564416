import React, { memo } from 'react';

import { useCountry } from 'store/modules/countries/hooks';
import { CountryAbbreviation } from 'types/models/country';

interface Props {
  countryAbbreviation: CountryAbbreviation;
}

/**
 * A component for rendering a county's name.
 * Useful when you have the country abbreviation in another component,
 * and don't want to use a selector to render the name.
 */
function CountryNameValue({ countryAbbreviation }: Props): JSX.Element | null {
  const country = useCountry(countryAbbreviation);

  if (!country) {
    return null;
  }

  return <>{country.name}</>;
}

export default memo(CountryNameValue);
