enum ActionTypes {
  FETCH_NOTIFICATION_POLICIES_REQUEST = 'FETCH_NOTIFICATION_POLICIES_REQUEST',
  FETCH_NOTIFICATION_POLICIES_SUCCESS = 'FETCH_NOTIFICATION_POLICIES_SUCCESS',
  FETCH_NOTIFICATION_POLICIES_FAILURE = 'FETCH_NOTIFICATION_POLICIES_FAILURE',

  FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST = 'FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST',
  FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS = 'FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS',
  FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE = 'FETCH_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE',

  EDIT_NOTIFICATION_POLICIES_REQUEST = 'EDIT_NOTIFICATION_POLICIES_REQUEST',
  EDIT_NOTIFICATION_POLICIES_SUCCESS = 'EDIT_NOTIFICATION_POLICIES_SUCCESS',
  EDIT_NOTIFICATION_POLICIES_FAILURE = 'EDIT_NOTIFICATION_POLICIES_FAILURE',

  EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST = 'EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_REQUEST',
  EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS = 'EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_SUCCESS',
  EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE = 'EDIT_EXTERNAL_DEVICE_NOTIFICATION_POLICIES_FAILURE',

  SET_NOTIFICATION_POLICIES = 'SET_NOTIFICATION_POLICIES',
  SET_EXTERNAL_DEVICE_NOTIFICATION_POLICIES = 'SET_EXTERNAL_DEVICE_NOTIFICATION_POLICIES'
}

export default ActionTypes;
