import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import BackOfficeInvitationsActionType from './constants';
import BackOfficeInvitationsState from './types';

const initialState: BackOfficeInvitationsState = {
  total: undefined,
  data: []
};

/** InviteesReducer */
export default function backOfficeInvitationsReducer(
  state: BackOfficeInvitationsState = initialState,
  action: ApplicationActions
): BackOfficeInvitationsState {
  switch (action.type) {
    case BackOfficeInvitationsActionType.LOAD_BACK_OFFICE_INVITATIONS_SUCCESS: {
      const {
        payload: { total, data }
      } = action;

      return {
        total,
        data: data.map((invitee) => ({
          ...invitee,
          id: invitee.email
        }))
      };
    }
    case BackOfficeInvitationsActionType.LOAD_BACK_OFFICE_USER_INVITATIONS_SUCCESS: {
      const {
        payload: { invitee }
      } = action;
      return {
        total: 1,
        data: [invitee]
      };
    }
    case BackOfficeInvitationsActionType.REMOVE_BACK_OFFICE_INVITATION_SUCCESS: {
      const {
        payload: { email, invitationId }
      } = action;
      // If we're deleting the last invitation of the invitee, delete the invitee.
      if (
        state.data.find((invitee) => invitee.email === email)?.invitations
          .length === 1
      ) {
        return {
          total: (state.total ?? 0) - 1,
          data: state.data.filter(
            (invitee) => invitee.email !== email && invitee.email !== undefined
          )
        };
      }
      return {
        ...state,
        data: state.data.map((invitee) => ({
          ...invitee,
          invitations: invitee.invitations.filter(
            (invitation) => invitation.id !== invitationId
          )
        }))
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
