import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { UserId } from 'types/models/user';

const selectLegalPolicyState = (state: ApplicationRootState) => state.legalPolicy;

const selectLegalPolicyDocuments = createSelector(selectLegalPolicyState, (legalPolicy) => legalPolicy.documents);

const selectLegalPolicyUserAcceptance = (userId: UserId) =>
  createSelector(selectLegalPolicyState, (legalPolicy) => legalPolicy.userAcceptance[userId]);

const selectLegalPoliciesLastChecked = createSelector(selectLegalPolicyState, (legalPolicy) => legalPolicy.lastChecked);

export {
  selectLegalPolicyDocuments,
  selectLegalPolicyUserAcceptance,
  selectLegalPoliciesLastChecked
};