import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { UpdateUserPreferenceDTO } from 'clients/userPreference';
import { EntityId, EntityKind, UserPreference } from 'types/models/user-preference';

import ActionTypes from './constants';

export const fetchMyPreferences = (entityKind: EntityKind, entityId?: EntityId) =>
  action(ActionTypes.FETCH_MY_PREFERENCES_REQUEST, { entityKind, entityId });

export const fetchMyPreferencesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.FETCH_MY_PREFERENCES_SUCCESS, { response });

export const fetchMyPreferencesFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.FETCH_MY_PREFERENCES_FAILURE, { message, error }, undefined, true);

export const setMyPreferences = (preferences: UserPreference[]) =>
  action(ActionTypes.SET_MY_PREFERENCES, preferences);

export const editMyPreference = (updatedPreference: UpdateUserPreferenceDTO) =>
  action(ActionTypes.EDIT_MY_PREFERENCE_REQUEST, updatedPreference);

export const editMyPreferenceSuccess = (response: AxiosResponse) =>
  action(ActionTypes.EDIT_MY_PREFERENCE_SUCCESS, { response });

export const editMyPreferenceFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.EDIT_MY_PREFERENCE_FAILURE, { message, error }, undefined, true);

/** Used when the entity is deleted */
export const unsetMyPreference = (entityKind: EntityKind, entityId: EntityId) =>
  action(ActionTypes.UNSET_MY_PREFERENCE, { entityKind, entityId });