import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import {
  makeSelectRouterQueryStringFilterSet,
  makeSelectStoredSearch,
  selectRouterQueryStringDrawer,
  selectRouterQueryStringDrawerIndex,
  selectRouterQueryStringFocus
} from './selectors';
import { FilterSetName } from './types';

export function useStoredSearch(pathname: string) {
  const selectStoredSearch = useMemo(
    () => makeSelectStoredSearch(pathname),
    [pathname]
  );

  const storedSearch = useSelector(selectStoredSearch, shallowEqual);

  return storedSearch;
}

export function useRouterQueryStringFilterSet(filterSetName: FilterSetName) {
  const selectRouterQueryStringFilterSet = useMemo(
    () => makeSelectRouterQueryStringFilterSet(filterSetName),
    [filterSetName]
  );

  const routerQueryStringFilterSet = useSelector(
    selectRouterQueryStringFilterSet,
    isEqual
  );

  return routerQueryStringFilterSet;
}

export function useRouterQueryStringFocus() {
  const routerQueryStringDrawer = useSelector(
    selectRouterQueryStringFocus,
    shallowEqual
  );

  return routerQueryStringDrawer;
}

export function useRouterQueryStringDrawer() {
  const routerQueryStringDrawer = useSelector(
    selectRouterQueryStringDrawer,
    shallowEqual
  );

  return routerQueryStringDrawer;
}

export function useRouterQueryStringDrawerIndex() {
  const routerQueryStringDrawerIndex = useSelector(
    selectRouterQueryStringDrawerIndex,
    shallowEqual
  );

  return routerQueryStringDrawerIndex;
}
