import { message as antdMessage } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import {
  TROUGH_DIRECT_PRECISION
} from 'constants/samplePoint';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import {
  convertMetricToImperialAlwaysRounding
} from 'utils/convert-metric-to-imperial';
import downloadFile from 'utils/download-file';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';
import percentage from 'utils/percentage';

import { constructSampleUrlForCSVExport } from './utils/construct-sample-url-for-csv-export';

import { CSV_DATE_FORMAT, CSV_FILE_NAME_DATE_FORMAT } from '.';

const mapSampleToCsvRow = (
  sample: Sample,
  country: string,
  siteTimezoneCode: string,
  configuredMaxLevel?: number
): string => {
  const dateLocale = moment(sample.date).tz(siteTimezoneCode).format(CSV_DATE_FORMAT);

  const autoCalibratedMaxLevel = sample.extraValues.maxLevel;
  const capacity = percentage(sample.rwValue, configuredMaxLevel ?? autoCalibratedMaxLevel);

  const levelLocale = convertMetricToImperialAlwaysRounding(
    UnitType.LEVEL_SM,
    country,
    sample.rwValue,
    TROUGH_DIRECT_PRECISION
  );

  return `${dateLocale},${capacity}%,${levelLocale}`;
};

// Check more shared comments in useExportRainGaugeCsv
export default function useExportTroughDirectCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const country = useEnterpriseCountry();
  const {
    id,
    name,
    siteTimezoneCode,
    assetTypeId
  } = samplePoint;
  const assetType = useAssetType(assetTypeId);

  if (!siteTimezoneCode) {
    antdMessage.error('Site time zone is not set');
    return;
  }

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      const samples = await new Promise<Sample[]>((resolve, reject) => {
        setLoading(true);
        const url = constructSampleUrlForCSVExport(id, siteTimezoneCode, dateRange);
        dispatch(
          ephemeralGetRequest<Sample[]>(url, resolve, reject)
        );
      });

      const csvArray = samples
        .sort((a, b) => b.date - a.date)
        .map((s) => mapSampleToCsvRow(s, country, siteTimezoneCode, samplePoint.config.maxValue));

      const levelSuffix = getUnitByCountry({
        unitType: UnitType.LEVEL_SM,
        country
      });

      const headers = `Date and Time,Capacity,Water Level (${levelSuffix})`;
      csvArray.unshift(headers);

      downloadFile(
        `${name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        csvArray.join('\n'),
        'text/csv'
      );

      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
