import React from 'react';

import { ReactComponent as DropletIcon } from './farmbot-icon.svg';

function FarmbotIcon({
  style = { verticalAlign: 'middle', width: '27px', height: '27px' }
}: {
  style?: React.CSSProperties;
}): JSX.Element {
  return <DropletIcon style={style} />;
}

export default FarmbotIcon;
