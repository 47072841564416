import {
  AGRIWEBB_SCOPES,
  AUTHORIZE_URL,
  INSTALL_RESPONSE_TYPE
} from 'constants/agriwebb';
import { WhiteLabelTitle } from 'types/models/white-label';
import getWhiteLabelConfig from 'utils/get-white-label-config';

export default function createAgriWebbAuthorizeUrl(
  organizationCode: string
): string {
  const {
    REACT_APP_AGRIWEBB_FARMBOT_CLIENT_ID,
    REACT_APP_AGRIWEBB_RANCHBOT_CLIENT_ID,
    REACT_APP_AGRIWEBB_BASE_URL,
    REACT_APP_URL_SCHEME,
    REACT_APP_URL_DEFAULT_SUBDOMAIN,
    REACT_APP_URL_DOMAIN,
    REACT_APP_URL_RANCHBOT_DOMAIN
  } = process.env;
  const { title: whiteLabel } = getWhiteLabelConfig();

  const clientId =
    whiteLabel === WhiteLabelTitle.RANCHBOT
      ? REACT_APP_AGRIWEBB_RANCHBOT_CLIENT_ID
      : REACT_APP_AGRIWEBB_FARMBOT_CLIENT_ID;

  const farmbotBaseURL = `${REACT_APP_URL_SCHEME}${REACT_APP_URL_DEFAULT_SUBDOMAIN}.${REACT_APP_URL_DOMAIN}`;
  const ranchbotBaseURL = `${REACT_APP_URL_SCHEME}${REACT_APP_URL_DEFAULT_SUBDOMAIN}.${REACT_APP_URL_RANCHBOT_DOMAIN}`;
  const baseAppUrl =
    whiteLabel === WhiteLabelTitle.RANCHBOT ? ranchbotBaseURL : farmbotBaseURL;

  let agriWebbAuthorizeUrl = `${REACT_APP_AGRIWEBB_BASE_URL}${AUTHORIZE_URL}?`;
  agriWebbAuthorizeUrl += `response_type=${INSTALL_RESPONSE_TYPE}`;
  agriWebbAuthorizeUrl += `&client_id=${clientId}`;
  agriWebbAuthorizeUrl += `&redirect_uri=${baseAppUrl}/connections/agriwebb/install-callback`;
  agriWebbAuthorizeUrl += `&organization=${organizationCode}`;
  agriWebbAuthorizeUrl += `&scope=${AGRIWEBB_SCOPES}`;

  return agriWebbAuthorizeUrl;
}
