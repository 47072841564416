import { Col, Input } from 'antd';
import get from 'lodash/get';
import React, { memo } from 'react';

import ContactSummary from 'components/composites/ContactSummary';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import FieldsValue from 'components/forms/FieldsValue';
import FormItemCol from 'components/forms/FormItemCol';
import FormItemEmail from 'components/forms/FormItemEmail';
import FormItemInputPhoneNumber from 'components/forms/FormItemInputPhoneNumber';
import User from 'types/models/user';

interface Props {
  namePrefix?: string;
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
  readOnly?: boolean;
  initialValues?: Partial<User>;
  phoneRequired?: boolean;
}

/**
 * A reusable set of form items to be used within an antd Form element.
 * It assumes that the address being edited conforms to the standard user model.
 */
function ContactFormItems({
  isFullSpanOnMobileHalfOnDesktop,
  readOnly,
  initialValues,
  phoneRequired = true
}: Props) {
  const firstNameFieldName = 'firstName';
  const lastNameFieldName = 'lastName';
  const mobileNumberFieldName = 'mobileNumber';
  const mobileNumberCountryCodeFieldName = 'mobileNumberCountryCode';
  const landlineNumberFieldName = 'landlineNumber';
  const landlineNumberCountryCodeFieldName = 'landlineNumberCountryCode';
  const emailFieldName = 'email';

  if (readOnly) {
    return (
      <Col span={24}>
        <DefaultFormItem>
          <FieldsValue
            names={[
              firstNameFieldName,
              lastNameFieldName,
              mobileNumberFieldName,
              mobileNumberCountryCodeFieldName,
              landlineNumberFieldName,
              landlineNumberCountryCodeFieldName,
              emailFieldName
            ]}
          >
            {(fieldsValue) => {
              const firstName = get(fieldsValue, firstNameFieldName);
              const lastName = get(fieldsValue, lastNameFieldName);
              const mobileNumber = get(fieldsValue, mobileNumberFieldName);
              const mobileNumberCountry = get(
                fieldsValue,
                mobileNumberCountryCodeFieldName
              );
              const landlineNumber = get(fieldsValue, landlineNumberFieldName);
              const landlineNumberCountry = get(
                fieldsValue,
                landlineNumberCountryCodeFieldName
              );
              const email = get(fieldsValue, emailFieldName);

              const user: Partial<User> = {
                firstName: firstName as string | undefined,
                lastName: lastName as string | undefined,
                mobileNumber: mobileNumber as string | undefined,
                mobileNumberCountryCode: mobileNumberCountry as
                  | string
                  | undefined,
                landlineNumber: landlineNumber as string | undefined,
                landlineNumberCountryCode: landlineNumberCountry as
                  | string
                  | undefined,
                email: email as string | undefined
              };
              return <ContactSummary contact={user} />;
            }}
          </FieldsValue>
        </DefaultFormItem>
      </Col>
    );
  }

  return (
    <>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name={firstNameFieldName}
          label="First Name"
          rules={[
            {
              required: true,
              message: 'Please enter a first name'
            },
            {
              pattern: /^[A-Za-z ,.'-]+$/,
              message: 'First Name should not contain numbers.'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name={lastNameFieldName}
          label="Last Name"
          rules={[
            {
              required: true,
              message: 'Please enter a last name'
            },
            {
              pattern: /^[A-Za-z ,.'-]+$/,
              message: 'Last Name should not contain numbers.'
            }
          ]}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <FormItemInputPhoneNumber
          name={mobileNumberFieldName}
          label="Mobile Number"
          rules={[
            {
              required: phoneRequired,
              message: 'Please enter a phone number.'
            }
          ]}
          prefixName={mobileNumberCountryCodeFieldName}
          hasInitialValues={!!initialValues?.mobileNumber}
        />
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <FormItemInputPhoneNumber
          name={landlineNumberFieldName}
          label="Landline Number"
          prefixName={landlineNumberCountryCodeFieldName}
          hasInitialValues={!!initialValues?.landlineNumber}
        />
      </FormItemCol>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <FormItemEmail />
      </FormItemCol>
    </>
  );
}

export default memo(ContactFormItems);
