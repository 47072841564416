import rollbarLogger from 'config/rollbar';

import { MachineControlStatus, MachineControlStatusCode } from './types';

/** The merge is based on the following matrix:
| **Genset Status** | **Pump Status** | **Merged Status** |
| ----------------- | --------------- | ----------------- |
| Off               | On              | Critical Error    |
| Off               | Off             | Off               |
| Off               | Turning         | Critical Error    |
| Off               | Error           | Error             |
| On                | On              | On                |
| On                | Off             | Tuning on         | <- Special case
| On                | Turning         | Turning           |
| On                | Error           | Error             |
| Turning on        | On              | On                | <- Temporary
| Turning on        | Off             | Turning on        |
| Turning on        | Turning on      | Turning on        |
| Turning on        | Turning off     | Critical Error    |
| Turning on        | Error           | Error             |
| Turning off       | On              | Critical Error    |
| Turning off       | Off             | Turning off       |
| Turning off       | Turning on      | Critical Error    |
| Turning off       | Turning off     | Turning off       |
| Turning off       | Error           | Error             |
| Error             | Error           | Error             |

The merge logic (and order) is 1) If either status is error, the result is error.
2) Merge non-error statuses. 3) Fall through critical error to default case and
optional logging it.

* PS: In dual, the statusCode is 8 in {@link https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/1725792434/Machine+Control+Data+Flows+-+UNDER+CONSTRUCTION many cases}.
*/
const mergeDualStatusesAndCodes = (
  primaryStatus: MachineControlStatus,
  primaryStatusCode: MachineControlStatusCode,
  secondaryStatus: MachineControlStatus,
  secondaryStatusCode: MachineControlStatusCode,
  isLogging?: boolean
): [MachineControlStatus, MachineControlStatusCode] => {
  // 1. Either status is error, the result is error.
  if (primaryStatus === MachineControlStatus.ERROR) {
    return [MachineControlStatus.ERROR, primaryStatusCode];
  }
  if (secondaryStatus === MachineControlStatus.ERROR) {
    return [MachineControlStatus.ERROR, secondaryStatusCode];
  }
  // 2. If both statuses are the same, return the primary.
  if (primaryStatus === secondaryStatus) {
    return [primaryStatus, primaryStatusCode];
  }
  // 3. Any other cases in the matrix.
  if (
    primaryStatus === MachineControlStatus.ON &&
    secondaryStatus === MachineControlStatus.ON
  ) {
    return [MachineControlStatus.ON, primaryStatusCode];
  }
  if (
    primaryStatus === MachineControlStatus.ON &&
    secondaryStatus === MachineControlStatus.OFF
  ) {
    return [MachineControlStatus.TURNING_ON, primaryStatusCode];
  }
  if (
    primaryStatus === MachineControlStatus.ON &&
    [
      MachineControlStatus.TURNING_ON,
      MachineControlStatus.TURNING_OFF
    ].includes(secondaryStatus)
  ) {
    return [secondaryStatus, secondaryStatusCode];
  }
  if (
    primaryStatus === MachineControlStatus.TURNING_ON &&
    secondaryStatus === MachineControlStatus.ON
  ) {
    return [MachineControlStatus.ON, primaryStatusCode];
  }
  if (
    primaryStatus === MachineControlStatus.TURNING_ON &&
    secondaryStatus === MachineControlStatus.OFF
  ) {
    return [MachineControlStatus.TURNING_ON, primaryStatusCode];
  }
  if (
    primaryStatus === MachineControlStatus.TURNING_OFF &&
    secondaryStatus === MachineControlStatus.OFF
  ) {
    return [MachineControlStatus.TURNING_OFF, primaryStatusCode];
  }
  // 4. Critical errors.
  if (isLogging) {
    rollbarLogger.error('Dual Machine Control Error', {
      primaryStatus,
      primaryStatusCode,
      secondaryStatus,
      secondaryStatusCode
    });
  }
  return [MachineControlStatus.ERROR, MachineControlStatusCode.MS_UNKNOWN];
};

export default mergeDualStatusesAndCodes;
