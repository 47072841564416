import { Card, Col, Row, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import DefaultButton from 'components/atoms/DefaultButton';
import DefaultCard from 'components/atoms/DefaultCard';
import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import { editSiteFarmMappings } from 'store/modules/agriWebb/actions';
import AgriWebbActionType from 'store/modules/agriWebb/constants';
import { AgriWebbFarm } from 'store/modules/agriWebb/types';
import { makeSelectIsLoading } from 'store/modules/loading/selectors';
import { selectSitesAsArray } from 'store/modules/sites/selectors';
import { MAX_WIDTH_MOBILE } from 'style/constants';

import './styles.less';

interface Props {
  enterpriseId: number;
  agriWebbFarms: AgriWebbFarm[];
  onSuccess: () => void;
  onError: () => void;
}

const actions = [AgriWebbActionType.EDIT_SITE_FARM_MAPPINGS_REQUEST];
const selectIsSubmitting = makeSelectIsLoading(actions);

export default function AgriWebbSiteFarmMappingForm({
  enterpriseId,
  agriWebbFarms,
  onSuccess,
  onError
}: Props): JSX.Element {
  const isMobile = useMediaQuery({ maxWidth: MAX_WIDTH_MOBILE });
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const isSubmitting = useSelector(selectIsSubmitting);
  const sites = useSelector(selectSitesAsArray);
  const [isInteractionDisabled, setIsInteractionDisabled] = useState(false);

  const farmOptions = useMemo(() => {
    return agriWebbFarms.map(({ id, name }) => ({
      label: name,
      value: id,
      key: id.toString()
    }));
  }, [agriWebbFarms]);

  const initialValues = useMemo(() => {
    return sites.reduce(
      (acc, site) => ({
        ...acc,
        [site.id]: farmOptions?.[0]?.value
      }),
      {} as Record<string, string>
    );
  }, [farmOptions, sites]);

  const submit = useCallback(() => {
    form.validateFields().then((values) => {
      const mapping = Object.keys(values).map((key) => {
        return { siteId: Number(key), agriWebbFarmId: values[key] as string };
      });
      dispatch(
        editSiteFarmMappings(
          { siteMapping: mapping, enterpriseId },
          () => {
            onSuccess();
            setIsInteractionDisabled(true);
          },
          onError
        )
      );
    });
  }, [form, dispatch, enterpriseId, onSuccess, onError]);

  const back = () => history.goBack();

  return (
    <>
      {farmOptions.length && initialValues ? (
        <DefaultForm
          form={form}
          layout="vertical"
          name="SitesMapping"
          initialValues={initialValues}
        >
          {!isMobile && (
            <DefaultCard className="AgriWebbSiteFarmMappingForm-card-form">
              <DefaultCard className="AgriWebbSiteFarmMappingForm-form-header">
                <Row align="middle">
                  <Col span={12}>
                    <Typography.Text strong>Site</Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Typography.Text strong>AgriWebb Farm</Typography.Text>
                  </Col>
                </Row>
              </DefaultCard>
              {sites.map((site) => {
                return (
                  <Row
                    key={site.id}
                    align="middle"
                    className="AgriWebbSiteFarmMappingForm-row"
                  >
                    <Col span={12}>
                      <Typography.Text>{`${site.name}`}</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <DefaultFormItem
                        className="AgriWebbSiteFarmMappingForm-selector"
                        name={site.id}
                        required
                      >
                        <Select
                          className="AgriWebbSiteFarmMappingForm-picker"
                          options={farmOptions}
                        />
                      </DefaultFormItem>
                    </Col>
                  </Row>
                );
              })}
            </DefaultCard>
          )}
          {isMobile && (
            <DefaultCard className="AgriWebbSiteFarmMappingForm-card-form">
              {sites.map((site) => {
                return (
                  <div
                    key={site.id}
                    className="AgriWebbSiteFarmMappingForm-row"
                  >
                    <Typography.Paragraph>
                      <Typography.Text strong>From site: </Typography.Text>
                      <Typography.Text>{`${site.name}`}</Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph strong>
                      To AgriWebb Farm:{' '}
                    </Typography.Paragraph>
                    <DefaultFormItem
                      className="AgriWebbSiteFarmMappingForm-selector"
                      name={site.id}
                      required
                    >
                      <Select
                        className="AgriWebbSiteFarmMappingForm-picker"
                        options={farmOptions}
                      />
                    </DefaultFormItem>
                  </div>
                );
              })}
            </DefaultCard>
          )}
          <div className="AgriWebbSiteFarmMappingForm-footer">
            <DefaultButton
              className={classNames(
                'AgriWebbSiteFarmMappingForm-footer-back-button',
                {
                  disabled: isInteractionDisabled
                }
              )}
              onClick={back}
              disabled={isInteractionDisabled}
            >
              Back
            </DefaultButton>

            <DefaultButton
              className={classNames(
                'AgriWebbSiteFarmMappingForm-footer-submit-button',
                {
                  disabled: isInteractionDisabled
                }
              )}
              onClick={submit}
              loading={isSubmitting}
              disabled={isInteractionDisabled}
            >
              Submit
            </DefaultButton>
          </div>
        </DefaultForm>
      ) : (
        <>
          <br />
          <DefaultCard>
            <Card className="AgriWebbSiteFarmMappingForm-form-header">
              <Row gutter={24} align="middle">
                <Col span={24}>
                  <Typography.Text strong>
                    No Sites or Farms to map
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </DefaultCard>
          <div className="AgriWebbSiteFarmMappingForm-footer">
            <DefaultButton
              className="AgriWebbSiteFarmMappingForm-footer-cancel-button"
              onClick={back}
            >
              Back
            </DefaultButton>
          </div>
        </>
      )}
    </>
  );
}
