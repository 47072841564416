import Rollbar from 'rollbar';

import { WHITELIST_KEYWORDS_FOR_UNCAUGHT_EXCEPTIONS } from './constants/whitelist-messages';

const { REACT_APP_ROLLBAR_ACCESS_TOKEN, REACT_APP_ENV } = process.env;

const isKeywordInMessage = (keywordList: string[], message: string): boolean => {
  return keywordList.some((keyword) => message.includes(keyword));
};

const opts: Rollbar.Configuration = {
  accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // Explicitly typing payload as any because Rollbar.Dictionary is just { [key: string]: unknown }
  checkIgnore: (isUncaught: boolean, args: Rollbar.LogArgument[], payload: any) => {
    let shouldIgnore: boolean = false; // default: false; should return true if the error should be ignored

    const exceptionMessage = payload?.body?.trace?.exception?.message;
    const exceptionDescription = payload?.body?.trace?.exception?.description;
    const bodyMessage = payload?.body?.message?.body;

    if (exceptionMessage) {
      shouldIgnore = shouldIgnore || isKeywordInMessage(
        WHITELIST_KEYWORDS_FOR_UNCAUGHT_EXCEPTIONS,
        exceptionMessage
      );
    }

    if (exceptionDescription && !shouldIgnore) {
      shouldIgnore = shouldIgnore || isKeywordInMessage(
        WHITELIST_KEYWORDS_FOR_UNCAUGHT_EXCEPTIONS,
        exceptionDescription
      );
    }

    if (bodyMessage && !shouldIgnore) {
      shouldIgnore = shouldIgnore || isKeywordInMessage(
        WHITELIST_KEYWORDS_FOR_UNCAUGHT_EXCEPTIONS,
        bodyMessage
      );
    }

    return shouldIgnore;
  },
  payload: {
    environment: REACT_APP_ENV
  },
  enabled: REACT_APP_ENV !== 'local'
};

// Instantiate the Rollbar Logger
const rollbarLogger = new Rollbar(opts);

export default rollbarLogger;
