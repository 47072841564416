import parsePhoneNumber from 'libphonenumber-js';

/**
 * As the keywords are from fuzzy search, we have no idea if it's phone number or
 * username etc. This function extracts the searchable phone number digits once
 * it detects `+` at beginning of the keywords or it validates the phone number.
 *
 * It works for AU and US for now.
 * @param keyword
 * @returns phone number searchable part
 */
const getPhoneNumberSearchablePart = (keyword?: string) => {
  if (!keyword) return keyword;
  // Consider using `libphonenumber.isValidPhoneNumber()` when need to support
  // more countries.

  // Match complete AU/US phone number, then remove +61, 61, 610, etc.
  const parsedAUPhoneNumber = parsePhoneNumber(keyword, 'AU');
  const parsedUSPhoneNumber = parsePhoneNumber(keyword, 'US');
  if (parsedAUPhoneNumber?.isValid()) {
    return parsedAUPhoneNumber.nationalNumber;
  }
  if (parsedUSPhoneNumber?.isValid()) {
    return parsedUSPhoneNumber.nationalNumber;
  }

  return (
    keyword
      // Match any string start with +61, +610, +61\s0, +61\s, etc, then remove
      // matched part.
      .replace(/^\+\b(61|1)\s?0?(.*)/, (_match, _p1, rest) =>
        rest.replace(/\s/g, '')
      )
  );
};

export default getPhoneNumberSearchablePart;
