import axios from 'axios';

import { GetLegalPolicyUserAcceptanceDto, SetLegalPolicyUserAcceptanceDto } from 'types/dto/legal-policy-user-acceptance.dto';
import { LegalBrand } from 'types/legal-brand.enum';
import { LegalPolicyDocument } from 'types/models/legal-policy-document';
import { APIResponseParsed } from 'types/response';
import { getRequest, postRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

async function fetchLatestLegalPolicyDocuments(legalBrand: LegalBrand):
  Promise<APIResponseParsed<LegalPolicyDocument[]>> {
  try {
    const response = await getRequest<LegalPolicyDocument[]>(`/legal-policy/latest/${legalBrand}`);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function fetchLegalPolicyUserAcceptanceStatus(legalBrand: LegalBrand):
  Promise<APIResponseParsed<GetLegalPolicyUserAcceptanceDto>> {
  try {
    const response = await getRequest<GetLegalPolicyUserAcceptanceDto>(`/legal-policy/user-acceptance/${legalBrand}`);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function acceptLatestLegalPolicies(legalBrand: LegalBrand):
  Promise<APIResponseParsed<SetLegalPolicyUserAcceptanceDto>> {
  try {
    const response = await postRequest<SetLegalPolicyUserAcceptanceDto, null>(`/legal-policy/user-acceptance/${legalBrand}`, null);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
};

export default {
  fetchLatestLegalPolicyDocuments,
  fetchLegalPolicyUserAcceptanceStatus,
  acceptLatestLegalPolicies
};