import { DatePicker, Radio, Space, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import DefaultModal, { DefaultModalProps } from 'components/atoms/DefaultModal';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import { useDefaultSite } from 'store/modules/sites/hooks';
import trackEvent from 'store/modules/tracking/actions';
import { EventType } from 'store/modules/tracking/types';
import SamplePoint from 'types/models/samplePoint';
import { DateRange, DateRangeNullable } from 'types/models/time';
import getDateRangeObject from 'utils/Date/get-date-range';

import useExportCsv from './use-export-csv';

import './styles.less';

export const CSV_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const CSV_FILE_NAME_DATE_FORMAT = 'YYYYMMDD';

interface Props extends Pick<DefaultModalProps, 'isOpen' | 'onDismiss'> {
  samplePoint: SamplePoint;
}

function CSVExportModal({ samplePoint, isOpen, onDismiss }: Props) {
  const dispatch = useDispatch();
  const { assetTypeId, id } = samplePoint;
  const { timezoneCode = DEFAULT_TIMEZONE_CODE } = useDefaultSite();

  const [loading, setLoading] = useState(false);

  // It converts the result of getDateRangeObject to DateRange (array).
  const getDateRange = useCallback(
    (unitOfTime: 'day' | 'month', amountOfTime: number) =>
      Object.values(
        getDateRangeObject(Date.now(), unitOfTime, amountOfTime, timezoneCode)
      )
        .sort((a, b) => a - b)
        .map((x) => moment(x).tz(timezoneCode)) as DateRange,
    [timezoneCode]
  );

  const radioValuesToDateRanges: Record<string, DateRange> = useMemo(() => {
    const now = moment().tz(timezoneCode);
    const startOfMonth = moment().tz(timezoneCode).startOf('month');
    const mtdDays = now.diff(startOfMonth, 'days') + 1;
    const startOfYear = moment().tz(timezoneCode).startOf('year');
    const ytdDays = now.diff(startOfYear, 'days') + 1;

    return {
      '7 days': getDateRange('day', 7),
      '30 days': getDateRange('day', 30),
      MTD: getDateRange('day', mtdDays),
      YTD: getDateRange('day', ytdDays)
    };
  }, [getDateRange, timezoneCode]);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    radioValuesToDateRanges['30 days']
  );

  /** Disallows null values */
  const handleDateRangeChange = (values: DateRangeNullable) => {
    if (!values || !values[0] || !values[1]) {
      return;
    }
    setSelectedDateRange(values as DateRange);
  };

  const exportCsv = useExportCsv(
    assetTypeId,
    selectedDateRange,
    samplePoint,
    setLoading,
    onDismiss
  );

  const trackExport = useCallback(
    (status: boolean) =>
      dispatch(
        trackEvent({
          type: EventType.SENSOR_EXPORTED,
          data: {
            samplePointId: id,
            assetTypeId,
            status
          }
        })
      ),
    [dispatch, id, assetTypeId]
  );

  const onConfirm = useCallback(
    (e) =>
      exportCsv?.(e)
        .then(() => {
          trackExport(true);
        })
        .catch((err) => {
          trackExport(false);
          return err;
        }),
    [exportCsv, trackExport]
  );

  // On mobile, opening the RangePicker causes the keyboard to open, which is
  // undesired. Setting 'inputmode="none"' on the input elements inside the
  // RangePicker prevents this from occurring.
  useEffect(() => {
    if (isOpen) {
      const inputElements = document
        .getElementsByClassName('CSVExportModal-picker')[0]
        ?.getElementsByTagName('input');

      if (inputElements.length === 2) {
        inputElements[0].setAttribute('inputmode', 'none');
        inputElements[1].setAttribute('inputmode', 'none');
      }
    }
  }, [isOpen]);

  return (
    <DefaultModal
      closable
      isOpen={isOpen}
      className="CSVExportModal"
      title={
        <Space
          direction="vertical"
          align="start"
          className="CSVExportModal-heading"
        >
          <Typography.Text>Export your data</Typography.Text>
          <Typography.Text className="CSVExportModal-subtitle">
            Choose a date range
          </Typography.Text>
        </Space>
      }
      confirmText="Export"
      isConfirmButtonLoading={loading}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    >
      <Space direction="vertical">
        <Radio.Group
          onChange={(e) => setSelectedDateRange(e.target.value)}
          value={selectedDateRange}
        >
          <Space>
            {Object.entries(radioValuesToDateRanges).map(([key, value]) => (
              <Radio.Button
                key={key}
                className="CSVExportModal-button"
                value={value}
              >
                {key}
              </Radio.Button>
            ))}
          </Space>
        </Radio.Group>
        <DatePicker.RangePicker
          className="CSVExportModal-picker"
          allowClear={false}
          value={selectedDateRange}
          onChange={handleDateRangeChange}
          // Can not select days in the future
          disabledDate={(current) => current && current > moment().tz(timezoneCode).endOf('day')}
        />
      </Space>
    </DefaultModal>
  );
}

export default memo(CSVExportModal);
