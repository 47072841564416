import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { UsersState } from './types';

export const initialState: UsersState = {};

function usersReducer(
  state: UsersState = initialState,
  action: ApplicationActions
): UsersState {
  switch (action.type) {
    case ActionTypes.SET_USERS:
      return action.payload;
    case ActionTypes.SET_USER: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case ActionTypes.UNSET_USER: {
      const { payload: id } = action;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case ActionTypes.ADD_USER_SUCCESS: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case ActionTypes.EDIT_USER_SUCCESS: {
      const { payload } = action;
      const { id } = payload;
      return {
        ...state,
        [id]: payload
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default usersReducer;
