import Enterprise from 'types/models/enterprise';

export default interface Membership {
  id: number;
  role: UserRole;
  enterpriseId: number;
  userId: number;
  createdAt: number;
  updatedAt: number;
  enterprise?: Enterprise;
}

export enum UserRole {
  VIEW_ONLY = 'view-only',
  SUPERVISOR = 'supervisor',
  ADMIN = 'admin'
}

export const UserRoleLabels: Record<UserRole, string> = {
  [UserRole.VIEW_ONLY]: 'View-only',
  [UserRole.SUPERVISOR]: 'Supervisor',
  [UserRole.ADMIN]: 'Admin'
};
