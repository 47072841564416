import { Typography } from 'antd';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom-v5-compat';

import IconLabel from 'components/atoms/IconLabel';
import { EnterpriseId } from 'types/models/enterprise';

interface BackNavigationProps {
  enterpriseId: EnterpriseId;
  enterpriseName: string;
}

function BackNavigation({ enterpriseId, enterpriseName }: BackNavigationProps) {
  const { state } = useLocation();
  const search = state?.previousSearch ? `${state.previousSearch}` : `?selectedEnterprise=${enterpriseId}`;
  return (
    <>
      <Typography.Title level={5}>{enterpriseName}</Typography.Title>
      <Link to={`/back-office/enterprises${search}`}>
        <IconLabel icon={<FiChevronLeft size={22} />} label="Back to Enterprise List" gap={4} />
      </Link>
    </>
  );
}

export default BackNavigation;