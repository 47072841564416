import Address from 'types/models/address';
import User from 'types/models/user';

export type EnterpriseId = number;
export type EnterpriseSubdomain = string | null;
export type EnterpriseName = string;

export enum Industry {
  AGRICULTURE = 'agriculture',
  MINING = 'mining'
}

/** @see {@link https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/1779695621/Back+Office+Enterprise+Statuses Back-office Enterprise Statuses}*/
export enum EnterpriseStatus {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  TRIAL = 'trial',
  TRIAL_EXPIRED = 'trial_expired',
  PAST_DUE = 'past_due',
  DEMO = 'demo',
  TEST = 'test',
  DUPLICATE = 'duplicate'
}

export interface EnterpriseIntegration {
  id: number;
  agriWebbOrganizationId: string;
  agriWebbStatus: 'enabled' | 'error' | 'disconnected';
}

export default interface Enterprise {
  id: EnterpriseId;
  sid: string;
  name: EnterpriseName;
  industry: Industry;
  status: EnterpriseStatus;
  owner: User;
  ownerId: number;
  billingAddress?: Address;
  billingAddressId?: number;
  subdomain: EnterpriseSubdomain;
  note: string | null;
  createdAt: number;
  updatedAt: number;

  enterpriseIntegration?: EnterpriseIntegration | null;

  /**
   * Account for fields that are returned from the api but not used.
   */
  [key: string]: unknown;
}
