import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { BackOfficeEnterpriseSitesState } from './types';

export const initialState: BackOfficeEnterpriseSitesState = [];

function backOfficeEnterpriseSitesReducer(
  state: BackOfficeEnterpriseSitesState = initialState,
  action: ApplicationActions
): BackOfficeEnterpriseSitesState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_ENTERPRISE_SITES: {
      const {
        payload: { sites }
      } = action;

      return sites;
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeEnterpriseSitesReducer;
