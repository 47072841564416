import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { CountriesState } from './types';

export const initialState: CountriesState = {};

function authReducer(
  state: CountriesState = initialState,
  action: ApplicationActions
): CountriesState {
  switch (action.type) {
    case ActionTypes.SET_COUNTRIES:
      return action.payload;
    case ActionTypes.SET_COUNTRY: {
      const { payload } = action;
      const { abbreviation } = payload;
      return {
        ...state,
        [abbreviation]: payload
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
