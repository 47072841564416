import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import Site, { SiteId } from 'types/models/site';

const selectSitesState = (state: ApplicationRootState) => state.sites;

const selectSites = createSelector(selectSitesState, (sitesState) =>
  mapValues(sitesState, (site: Site): Site => {
    const address = site.shippingAddress;
    return {
      ...site,
      addressString: [
        address?.line1,
        address?.line2,
        address?.city,
        address?.postCode,
        address?.state.abbreviation
      ]
        .filter(Boolean)
        .join(', ')
    };
  })
);

const selectSitesAsArray = createSelector(selectSites, (sites) =>
  orderBy(Object.values(sites), 'name')
);

const selectHasMultipleSites = createSelector(
  selectSitesAsArray,
  (sitesAsArray) => sitesAsArray.length > 1
);

const makeSelectSiteById = (siteId: SiteId) =>
  createSelector(selectSites, (sites) => sites[siteId]);

export {
  selectSitesState,
  selectSites,
  selectSitesAsArray,
  selectHasMultipleSites,
  makeSelectSiteById
};
