import { createSelector } from 'reselect';

import { ApplicationRootState } from 'store/types';
import { AssetTypeId } from 'types/models/asset-type';

const selectAssetTypes = (state: ApplicationRootState) => state.assetTypes;

const selectAssetTypesAsArray = createSelector(selectAssetTypes, (assetTypes) =>
  Object.values(assetTypes)
);

const makeSelectAssetTypeById = (assetTypeId: AssetTypeId) =>
  createSelector(selectAssetTypes, (assetTypes) => assetTypes[assetTypeId]);

export { selectAssetTypes, selectAssetTypesAsArray, makeSelectAssetTypeById };
