import { Form, Typography } from 'antd';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import BackOfficeEnterpriseForm from 'components/forms/BackOfficeEnterpriseForm';
import BackOfficeEnterpriseLoadingGate from 'components/loadingGates/BackOfficeEnterpriseLoadingGate';
import BackOfficeEnterpriseRequest from 'components/requests/BackOfficeEnterpriseRequest';
import useBackOfficeEnterprise from 'store/modules/backOfficeEnterprise/hooks';
import { editBackOfficeEnterprise } from 'store/modules/backOfficeEnterprises/actions';
import clearErrors from 'store/modules/error/actions';
import { makeSelectIsLoading } from 'store/modules/loading/selectors';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { selectRouterQueryStringSelectedEnterprise } from 'store/modules/routerUtils/selectors';
import { supportsCibo as supportsCiboFunc } from 'utils/integration/supports-cibo';

const actions = ['EDIT_BACK_OFFICE_ENTERPRISE'];

const selectIsLoading = makeSelectIsLoading(actions);

function BackOfficeEnterpriseEditDrawer(): JSX.Element | null {
  const [form] = Form.useForm();
  const { search } = useLocation();
  const enterpriseId = useSelector(selectRouterQueryStringSelectedEnterprise);
  const isLoading = useSelector(selectIsLoading);

  const visible = !!enterpriseId;
  const backOfficeEnterpriseData = useBackOfficeEnterprise();
  let billingAddressId: number | undefined;
  let supportsCibo = false;
  if (backOfficeEnterpriseData) {
    billingAddressId = backOfficeEnterpriseData.billingAddressId;
    supportsCibo = supportsCiboFunc(backOfficeEnterpriseData.billingAddress?.country);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(clearErrors(actions));
  }, [dispatch, visible]);

  const handleClose = () => {
    dispatch(
      appendValuesToQueryString({
        selectedEnterprise: undefined
      })
    );
  };

  const handleClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (enterpriseId) {
          dispatch(
            editBackOfficeEnterprise(enterpriseId, {
              ...values,
              billingAddressId
            })
          );
        }
      })
      .catch(() => {
        // Ignoring promise rejection
      });
  };

  const handleFinish = useCallback(
    (values) => {
      if (enterpriseId) {
        dispatch(
          editBackOfficeEnterprise(enterpriseId, {
            ...values,
            billingAddressId
          })
        );
      }
    },
    [billingAddressId, dispatch, enterpriseId]
  );

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Edit Enterprise"
      actions={actions}
      handleClick={handleClick}
      handleClose={handleClose}
    >
      <BackOfficeEnterpriseRequest enterpriseId={enterpriseId} />
      <BackOfficeEnterpriseLoadingGate enterpriseId={enterpriseId}>
        <>
          <BackOfficeEnterpriseForm
            form={form}
            initialValues={backOfficeEnterpriseData || undefined}
            onFinish={handleFinish}
            isLoading={isLoading}
          />
          {supportsCibo && (
            <>
              <Typography.Title level={5}>Cibo Labs Pasture Zones</Typography.Title>
              <Link
                to={{
                  pathname: `/back-office/enterprises/${enterpriseId}/cibo-subscriptions`,
                  state: { previousSearch: search }
                }}
              >Manage Subscriptions</Link>
            </>
          )}
        </>
      </BackOfficeEnterpriseLoadingGate>
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeEnterpriseEditDrawer);
