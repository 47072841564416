import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { makeSelectIsAdvancedDam } from 'store/modules/samplePoints/selectors';
import { SamplePointId } from 'types/models/samplePoint';

export default function useIsAdvancedDam(samplePointId: SamplePointId) {
  const selector = useMemo(
    () => makeSelectIsAdvancedDam(samplePointId),
    [samplePointId]
  );
  return useSelector(selector, shallowEqual);
}
