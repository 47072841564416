enum ActionTypes {
  SET_SAMPLE_POINTS = 'SET_SAMPLE_POINTS',
  SET_SAMPLE_POINT = 'SET_SAMPLE_POINT',
  REMOVE_SAMPLE_POINT = 'REMOVE_SAMPLE_POINT',

  EDIT_SAMPLE_POINT_REQUEST = 'EDIT_SAMPLE_POINT_REQUEST',
  EDIT_SAMPLE_POINT_SUCCESS = 'EDIT_SAMPLE_POINT_SUCCESS',
  EDIT_SAMPLE_POINT_FAILURE = 'EDIT_SAMPLE_POINT_FAILURE',

  REMOVE_SAMPLE_POINT_REQUEST = 'REMOVE_SAMPLE_POINT_REQUEST',
  REMOVE_SAMPLE_POINT_SUCCESS = 'REMOVE_SAMPLE_POINT_SUCCESS',
  REMOVE_SAMPLE_POINT_FAILURE = 'REMOVE_SAMPLE_POINT_FAILURE'
}

export default ActionTypes;
