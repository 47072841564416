enum ActionTypes {
  LOAD_BACK_OFFICE_ENTERPRISES_REQUEST = 'LOAD_BACK_OFFICE_ENTERPRISES_REQUEST',
  LOAD_BACK_OFFICE_ENTERPRISES_SUCCESS = 'LOAD_BACK_OFFICE_ENTERPRISES_SUCCESS',
  LOAD_BACK_OFFICE_ENTERPRISES_FAILURE = 'LOAD_BACK_OFFICE_ENTERPRISES_FAILURE',

  ADD_BACK_OFFICE_ENTERPRISE_REQUEST = 'ADD_BACK_OFFICE_ENTERPRISE_REQUEST',
  ADD_BACK_OFFICE_ENTERPRISE_SUCCESS = 'ADD_BACK_OFFICE_ENTERPRISE_SUCCESS',
  ADD_BACK_OFFICE_ENTERPRISE_FAILURE = 'ADD_BACK_OFFICE_ENTERPRISE_FAILURE',

  EDIT_BACK_OFFICE_ENTERPRISE_REQUEST = 'EDIT_BACK_OFFICE_ENTERPRISE_REQUEST',
  EDIT_BACK_OFFICE_ENTERPRISE_SUCCESS = 'EDIT_BACK_OFFICE_ENTERPRISE_SUCCESS',
  EDIT_BACK_OFFICE_ENTERPRISE_FAILURE = 'EDIT_BACK_OFFICE_ENTERPRISE_FAILURE',

  SET_BACK_OFFICE_ENTERPRISES = 'SET_BACK_OFFICE_ENTERPRISES'
}

export default ActionTypes;
