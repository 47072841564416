import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import selectBackOfficeUser from './selectors';

export function useBackOfficeUser() {
  const backOfficeUser = useSelector(selectBackOfficeUser, isEqual);

  return backOfficeUser;
}

export default useBackOfficeUser;
