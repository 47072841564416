/**
 * Sagas for providing utilities for manipulating router store.
 */

import { push } from 'connected-react-router';
import queryString from 'query-string';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  appendToExistingPath,
  appendValuesToQueryString,
  storeSearch
} from './actions';
import ActionTypes from './constants';
import {
  makeSelectStoredSearch,
  selectPathname,
  selectRouterQueryString
} from './selectors';

/**
 * Allows us to remove the last path of the existing path.
 * Handy for going back from a nested page.
 */
export function* removeLastSubPathSaga() {
  const pathname: string = yield select(selectPathname);

  const to = pathname.substring(0, pathname.lastIndexOf('/'));

  const storedSearch: string = yield select(makeSelectStoredSearch(to));

  yield put(
    push({
      pathname: to,
      search: storedSearch
    })
  );
}

export function* watchRemoveLastSubPath() {
  yield takeEvery(ActionTypes.REMOVE_LAST_SUB_PATH, removeLastSubPathSaga);
}

/**
 * Allows us to append a sub path to an existing path and cut the need for a
 * component to know about the existing path, thus preventing unnecessary
 * re-renders.
 */
export function* appendToExistingPathSaga({
  payload: subpath
}: ReturnType<typeof appendToExistingPath>) {
  const pathname: string = yield select(selectPathname);

  const nextPath = `${pathname}${subpath}`;

  yield put(push(nextPath));
}

export function* watchAppendToExistingPath() {
  yield takeEvery(
    ActionTypes.APPEND_TO_EXISTING_PATH,
    appendToExistingPathSaga
  );
}

/**
 * Allows us to append values to an existing query string without overriding
 * other values and cut the need for a component to know about all query string
 * parameters in order to update it thus preventing unnecessary re-renders.
 */
export function* appendValuesToQueryStringSaga({
  payload
}: ReturnType<typeof appendValuesToQueryString>) {
  const pathname: string = yield select(selectPathname);
  const routerQueryString: ReturnType<typeof selectRouterQueryString> =
    yield select(selectRouterQueryString);

  const search = queryString.stringify(
    {
      ...routerQueryString,
      ...payload
    },
    { arrayFormat: 'bracket' }
  );

  yield all([
    put(
      push({
        search
      })
    ),
    put(storeSearch(pathname, search))
  ]);
}

export function* watchAppendValuesToQueryString() {
  yield takeEvery(
    ActionTypes.APPEND_VALUES_TO_QUERY_STRING,
    appendValuesToQueryStringSaga
  );
}

export default function* routerSaga() {
  yield all([
    fork(watchRemoveLastSubPath),
    fork(watchAppendToExistingPath),
    fork(watchAppendValuesToQueryString)
  ]);
}
