import { Alert, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import DefaultForm from 'components/composites/DefaultForm';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import EnterpriseFormItems from 'components/forms/EnterpriseFormItems';
import { makeSelectFirstErrorMessage } from 'store/modules/error/selectors';
import Enterprise from 'types/models/enterprise';

const actions = ['EDIT_BACK_OFFICE_ENTERPRISE', 'ADD_BACK_OFFICE_ENTERPRISE'];

const selectErrorMessage = makeSelectFirstErrorMessage(actions);

interface Props {
  form: FormInstance;
  initialValues?: Partial<Enterprise>;
  onFinish?: (values: Record<string, unknown>) => void;
  isLoading?: boolean;
}

function BackOfficeEnterpriseForm({
  form,
  initialValues,
  onFinish,
  isLoading
}: Props): JSX.Element | null {
  const errorMessage = useSelector(selectErrorMessage);

  const hasInitialValues = !!(
    initialValues?.mobileNumberCountryCode ||
    initialValues?.billingAddress?.country
  );

  useDeepCompareEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  return (
    <DefaultForm
      form={form}
      layout="vertical"
      name="backOfficeEnterprise"
      autoComplete="chrome-off"
      initialValues={initialValues}
      onFinish={isLoading ? undefined : onFinish}
      requiredMark="optional"
      closeDrawerOnSubmit
      closeDrawerIfFieldsAreValid={false}
    >
      <EnterpriseFormItems
        isBackOffice
        isNewEnterprise={!hasInitialValues}
        initialValues={initialValues}
        hasInitialValues={hasInitialValues}
      />
      {errorMessage && (
        <DefaultFormItem>
          <Alert message={errorMessage} type="error" />
        </DefaultFormItem>
      )}
      <Button htmlType="submit" style={{ display: 'none' }} />
    </DefaultForm>
  );
}

export default memo(BackOfficeEnterpriseForm, isEqual);
