import { ColumnsType } from 'antd/lib/table';
import lodashStartCase from 'lodash/startCase';
import moment from 'moment-timezone';

import { CiboSubscription, SubscriptionStatus } from 'store/modules/backOfficeCiboSubscriptions/types';

import { formatSubscriptionDate } from './format-date.util';

export const dataColumns: ColumnsType<CiboSubscription> = [
  {
    title: 'Date Added',
    dataIndex: 'startDate',
    render: (startDate: number) => formatSubscriptionDate(startDate)
  },
  {
    title: 'Duration',
    key: 'duration',
    render: (_, record) => moment(record.endDate).diff(record.startDate, 'months') + ' months (auto-renew)',
    width: 190
  },
  {
    title: 'Auto-Renewal Date',
    dataIndex: 'endDate',
    render: (endDate: number) => formatSubscriptionDate(endDate)
  },
  {
    title: 'Number of Pasture Zones',
    dataIndex: 'numberOfLocations',
    width: 200
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: SubscriptionStatus) => lodashStartCase(status)
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
    render: (createdBy: { firstName: string; lastName: string }) => `${createdBy.firstName} ${createdBy.lastName}`,
    width: 170
  }
];