import AuthActionTypes from 'store/modules/auth/constants';
import SamplePointActionTypes from 'store/modules/samplePoints/constants';
import { ApplicationActions } from 'store/types';
import { AssetTypeCode } from 'types/models/asset-type';

import ActionTypes from './constants';
import { BackOfficeDevicesState } from './types';

export const initialState: BackOfficeDevicesState = {
  total: undefined,
  data: {},
  ids: [],
  deviceFilters: undefined,
  dataSources: undefined,
  cameraSamplePoints: []
};

function backOfficeDevicesReducer(
  state: BackOfficeDevicesState = initialState,
  action: ApplicationActions
): BackOfficeDevicesState {
  switch (action.type) {
    case ActionTypes.SET_BACK_OFFICE_DEVICES: {
      const {
        payload: { total, data, ids }
      } = action;

      return {
        ...state,
        total,
        data,
        ids
      };
    }
    case ActionTypes.SET_BACK_OFFICE_DEVICE_FILTERS: {
      const { payload: deviceFilters } = action;

      return {
        ...state,
        deviceFilters
      };
    }
    case ActionTypes.SET_BACK_OFFICE_DEVICE_DATA_SOURCES: {
      const { payload: dataSources } = action;

      return {
        ...state,
        dataSources
      };
    }
    case ActionTypes.LOAD_BACK_OFFICE_DEVICE_CAMERA_SAMPLEPOINTS_SUCCESS: {
      const {
        payload: { samplePoints }
      } = action;

      return {
        ...state,
        cameraSamplePoints: samplePoints
      };
    }
    case SamplePointActionTypes.SET_SAMPLE_POINT: {
      const { payload: samplePoint } = action;

      return samplePoint.assetTypeId === AssetTypeCode.CAMERA
        ? {
          ...state,
          cameraSamplePoints: state.cameraSamplePoints.map((sp) =>
            sp.id === samplePoint.id
              ? {
                ...sp,
                config: samplePoint.config
              }
              : sp
          )
        }
        : { ...state };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default backOfficeDevicesReducer;
