import { Alert, Result } from 'antd';
import React from 'react';
import { FiWifiOff } from 'react-icons/fi';

interface Props {
  errorMessage?: string;
}

function AlertWithOfflineResult({ errorMessage }: Props): JSX.Element | null {
  if (errorMessage === 'Network Error') {
    return (
      <Result
        icon={<FiWifiOff size="130" style={{ verticalAlign: 'middle' }} />}
        title="You seem to be offline"
        subTitle="Sorry, we can't load this data while you're not connected to the internet. Please reconnect and try again."
      />
    );
  }

  if (errorMessage) {
    return <Alert message={errorMessage} type="error" />;
  }

  return null;
}

export default AlertWithOfflineResult;
