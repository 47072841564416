/**
 * A function that behaves similarly to String.prototype.split, except if a
 * limit is given the last item in the array will contain the rest of the
 * string.
 */
const splitWithRemainder = (
  value: string,
  separator: string,
  limit?: number
) => {
  const baseSplit = value.split(separator);

  return typeof limit !== 'number'
    ? baseSplit
    : [...baseSplit.slice(0, limit), baseSplit.slice(limit).join(separator)];
};

export default splitWithRemainder;
