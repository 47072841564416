import { Form, Typography } from 'antd';
import { FormItemProps, RuleObject } from 'antd/lib/form';
import isNil from 'lodash/isNil';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo
} from 'react';
import { useEffectOnce } from 'react-use';

import { FormContext } from 'components/composites/DefaultForm';
import FieldValue from 'components/forms/FieldValue';

export function convertValueToNullOnFalsy(value: unknown) {
  return value || null;
}

export interface DefaultFormItemProps
  extends Pick<
    FormItemProps,
    | 'className'
    | 'dependencies'
    | 'name'
    | 'style'
    | 'label'
    | 'hidden'
    | 'children'
    | 'rules'
    | 'initialValue'
    | 'fieldKey'
    | 'requiredMark'
    | 'tooltip'
    | 'normalize'
    | 'noStyle'
    | 'hasFeedback'
    | 'validateFirst'
    | 'validateTrigger'
  > {
  /**
   * Use this prop if you want to hide the 'optional' text.
   * The required condition itself is enforced by the `rules` prop.
   */
  required?: FormItemProps['required'];
  /**
   * Specify if the value should be converted to null when the form field is
   * empty
   */
  toNullOnFalsy?: boolean;
  readOnly?: boolean;
  emptyValueTextForReadOnly?: string;
  valuePropName?: string;
  formatReadOnlyValue?: (value: any) => ReactNode;
}

/**
 * A wrapper around the antd Form.Item with our custom config applied.
 */
function DefaultFormItem({
  className,
  dependencies,
  /** NamePath */
  name,
  style,
  label,
  hidden,
  children,
  rules,
  initialValue,
  fieldKey,
  toNullOnFalsy,
  readOnly,
  emptyValueTextForReadOnly = '',
  valuePropName = 'value',
  required,
  requiredMark = 'optional',
  tooltip,
  normalize,
  noStyle,
  hasFeedback,
  validateFirst,
  validateTrigger,
  formatReadOnlyValue
}: DefaultFormItemProps): JSX.Element | null {
  useEffectOnce(() => {
    if (React.Children.count(children) > 1) {
      // eslint-disable-next-line no-console
      console.warn(
        'DefaultFormItem can only have one child otherwise the form value binding may not work'
      );
    }
  });

  const handleNormalize = useCallback(
    (value, prevValue, prevValues) => {
      if (normalize) {
        return normalize(
          toNullOnFalsy ? convertValueToNullOnFalsy(value) : value,
          prevValue,
          prevValues
        );
      }

      if (toNullOnFalsy) {
        return convertValueToNullOnFalsy(value);
      }

      return value;
    },
    [normalize, toNullOnFalsy]
  );

  const { formInstance } = useContext(FormContext);

  // Since updating Ant Design, the 'required' prop must be passed to a
  // Form.Item component in order to hide the 'optional' marking, even if a rule
  // that makes the field mandatory is given. To fix this, we will set the
  // required prop internally here if a 'required' rule is passed in as a
  // prop or required === true is set.
  const innerRequired = useMemo(
    () =>
      required ||
      (rules &&
        rules.some((rule) =>
          typeof rule === 'function' && formInstance
            ? rule(formInstance).required
            : (rule as RuleObject).required
        )),
    [required, rules, formInstance]
  );

  if (readOnly) {
    const input =
      React.Children.count(children) === 1
        ? (React.Children.only(children) as ReactElement)
        : null;
    const { suffix } = input?.props ?? {};

    return (
      <Form.Item
        label={label && <Typography.Text strong>{label}</Typography.Text>}
        required={innerRequired}
        tooltip={tooltip}
      >
        <FieldValue name={name}>
          {formatReadOnlyValue ||
            ((value: any) => {
              if (!isNil(value) && suffix) return `${value} ${suffix}`;
              if (!isNil(value)) return value;
              return emptyValueTextForReadOnly;
            })}
        </FieldValue>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      className={className}
      dependencies={dependencies}
      name={name}
      style={style}
      hidden={hidden}
      label={label && <Typography.Text strong>{label}</Typography.Text>}
      rules={rules}
      initialValue={initialValue}
      fieldKey={fieldKey}
      normalize={handleNormalize}
      valuePropName={valuePropName}
      required={innerRequired}
      // Known issue: Pls ignore the following error "React does not recognize the `requiredMark` prop on a DOM element.
      // If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `requiredmark`
      // instead. If you accidentally passed it from a parent component, remove it from the DOM element.""
      requiredMark={requiredMark}
      tooltip={tooltip}
      noStyle={noStyle}
      hasFeedback={hasFeedback}
      validateFirst={validateFirst}
      validateTrigger={validateTrigger}
    >
      {children}
    </Form.Item>
  );
}

export default DefaultFormItem;
