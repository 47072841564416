import invert from 'lodash/invert';

import { SampleInterval } from 'store/modules/samples/types';
import { UnitOfTime } from 'types/models/time';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = 1000 * 60;
export const ONE_HOUR_IN_MS = 3600_000;
export const ONE_DAY_IN_MS = 86400000;
export const ONE_YEAR_IN_MS = 31536000000;

export const DEFAULT_TIMEZONE_CODE = 'Australia/Sydney'; // Farmbot headquarter in Sydney, NSW
export const DEFAULT_TIMEZONE_CODE_RANCHBOT = 'America/Chicago'; // Ranchbot headquarter in Fort Worth, Texas
export const DATE_TIME_FORMAT = {
  DEFAULT: 'YYYY-MM-DD h:mm A',
  DEFAULT_NO_TIME: 'YYYY-MM-DD',
  FULL_MONTH_FULL_YEAR: 'MMMM YYYY',
  SHORT_MONTH_FULL_YEAR: 'MMM YYYY',
  SUBSCRIPTION: 'YYYY-MM-DD z'
};

export const timeUnitToSampleInterval: Record<UnitOfTime, SampleInterval> = {
  hour: SampleInterval.HOURLY,
  day: SampleInterval.DAILY,
  week: SampleInterval.WEEKLY,
  month: SampleInterval.MONTHLY
};

export const sampleIntervalToTimeUnit = invert(timeUnitToSampleInterval) as Record<SampleInterval, UnitOfTime>;