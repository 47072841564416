import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { userPermissionClient } from 'clients';
import { UserPermission } from 'types/models/user-permission';
import { APIResponseParsed } from 'types/response';

import { fetchUserPermissions, fetchUserPermissionsFailure, fetchUserPermissionsSuccess, setUserPermissions } from './actions';
import { UserPermissionActionTypes } from './constants';

// ================================================
// Sagas
// ================================================
function* requestFetchUserPermissions(action: ReturnType<typeof fetchUserPermissions>) {
  const { entityKind, entityId } = action.payload;

  const response: APIResponseParsed<UserPermission[]> = yield call(
    userPermissionClient.fetchUserPermissions,
    { entityKind, entityId }
  );

  if (response.data) {
    yield all([
      put(setUserPermissions(response.data)),
      put(fetchUserPermissionsSuccess())
    ]);
  } else {
    const message = response.error.message || 'Sorry, something went wrong.';
    yield put(fetchUserPermissionsFailure(message));
  }
}

// ================================================
// Registration
// ================================================
function* watchUserPermissionsRequest() {
  yield takeLatest(UserPermissionActionTypes.FETCH_USER_PERMISSIONS_REQUEST, requestFetchUserPermissions);
}

// ================================================
// Export
// ================================================
export default function* userPermissionsSaga() {
  yield all([
    fork(watchUserPermissionsRequest)
  ]);
}