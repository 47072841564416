import { message as antdMessage } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { RAIN_GAUGE_PRECISION_IMPERIAL, RAIN_GAUGE_PRECISION_METRIC } from 'constants/samplePoint';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import { convertMetricToImperialAlwaysRounding } from 'utils/convert-metric-to-imperial';
import { shiftDateToTimezoneKeepDisplay } from 'utils/Date/shift-date-to-timezone-keep-display';
import downloadFile from 'utils/download-file';
import { UnitType, getUnitByCountry } from 'utils/get-unit-by-country';

import { CSV_FILE_NAME_DATE_FORMAT } from '.';

// Please be aware that this hook executes no matter the type of sample point.
export default function useExportRainGaugeCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const dispatch = useDispatch();
  const { sid, name, assetTypeId, siteTimezoneCode = DEFAULT_TIMEZONE_CODE }
    = samplePoint;
  const assetType = useAssetType(assetTypeId);

  const enterpriseCountry = useEnterpriseCountry();

  const startDateInSiteTimezone = shiftDateToTimezoneKeepDisplay(dateRange[0], siteTimezoneCode);
  const endDateInSiteTimezone = shiftDateToTimezoneKeepDisplay(dateRange[1], siteTimezoneCode);

  const startDateURISafe = encodeURIComponent(startDateInSiteTimezone.format());
  const endDateURISafe = encodeURIComponent(endDateInSiteTimezone.format());

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      const samples = await new Promise<Sample[]>((resolve, reject) => {
        setLoading(true);

        dispatch(
          ephemeralGetRequest<Sample[]>(
            `samplepoint/${sid}/v2/hourly-samples?startDate=${startDateURISafe}&endDate=${endDateURISafe}&interval=daily`,
            resolve,
            reject
          )
        );
      });

      const csvArray = samples
        .sort((a, b) => b.date - a.date)
        .map((s) => {
          const date = moment(s.date).tz(siteTimezoneCode);
          const rainfall = convertMetricToImperialAlwaysRounding(
            UnitType.RAIN,
            enterpriseCountry,
            s.rwValue,
            enterpriseCountry === 'USA' ? RAIN_GAUGE_PRECISION_IMPERIAL : RAIN_GAUGE_PRECISION_METRIC
          ) ?? 0;

          return `${date.format('YYYY-MM-DD (ddd)')},${rainfall}`;
        });

      // Add header to csv array.
      csvArray.unshift(
        `Date,Rain (${getUnitByCountry({
          unitType: UnitType.RAIN,
          country: enterpriseCountry
        })})`
      );

      // Download CSV file to users' device.
      downloadFile(
        // Filename.
        `${name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        // Data.
        csvArray.join('\n'),
        // MIME type.
        'text/csv'
      );

      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
