import axios from 'axios';
import get from 'lodash/get';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import Enterprise from 'types/models/enterprise';
import { getRequest } from 'utils/redux-saga-requests';

import { search, searchFailure, searchSuccess } from './actions';
import ActionTypes from './constants';
import { SearchTypes } from './types';

function getSearchUrlAndParams(
  searchType: SearchTypes,
  searchTerm: string
): [string, Record<string, unknown> | undefined] {
  switch (searchType) {
    case SearchTypes.UserEnterpriseMembershipSearch:
      return [
        'enterprise',
        {
          s: {
            $or: [
              {
                name: { $contL: searchTerm }
              },
              ...(Number.isNaN(Number(searchTerm))
                ? []
                : [{ id: { $eq: Number(searchTerm) } }])
            ]
          }
        }
      ];
    default:
      return ['', undefined];
  }
}

export function* requestSearch(action: ReturnType<typeof search>) {
  const { searchType, searchTerm } = action.payload;
  try {
    const [url, params] = getSearchUrlAndParams(searchType, searchTerm);

    const { data } = yield call(getRequest, url, { params });

    yield all([put(searchSuccess<Enterprise>(searchType, data))]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(searchFailure(message, error));
  }
}

export function* watchSearchRequest() {
  yield takeLatest(ActionTypes.SEARCH_REQUEST, requestSearch);
}

export default function* searchSaga() {
  yield all([fork(watchSearchRequest)]);
}
