import axios from 'axios';
import get from 'lodash/get';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectBackOfficeEnterpriseById } from 'store/modules/backOfficeEnterprises/selectors';
import User from 'types/models/user';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import { getRequest } from 'utils/redux-saga-requests';

import {
  loadBackOfficeEnterprise,
  loadBackOfficeEnterpriseFailure,
  loadBackOfficeEnterpriseSuccess,
  setBackOfficeEnterprise
} from './actions';
import ActionTypes from './constants';

const parserEnterpriseOwner = (enterpriseOwner: User): User => {
  let owner: User = parseBackendPhoneNumber(
    enterpriseOwner,
    'mobileNumber',
    'mobileNumberCountryCode',
    'mobileNumberFormatted'
  );

  owner = parseBackendPhoneNumber(
    owner,
    'landlineNumber',
    'landlineNumberCountryCode',
    'landlineNumberFormatted'
  );
  return owner;
};

export function* requestBackOfficeEnterprise(
  action: ReturnType<typeof loadBackOfficeEnterprise>
): SagaIterator {
  const {
    payload: { enterpriseId }
  } = action;

  const existingEnterprise = yield select(
    makeSelectBackOfficeEnterpriseById(enterpriseId)
  );

  /**
   * If we already have information about the enterprise, pass it to the reducer.
   */
  if (existingEnterprise) {
    yield put(setBackOfficeEnterprise(existingEnterprise));
  }

  try {
    const { data } = yield call(
      getRequest,
      `enterprise/${enterpriseId}?join=billingAddress&join=owner`
    );
    const enterprise = data;
    enterprise.owner = parserEnterpriseOwner(data.owner);

    yield all([
      put(loadBackOfficeEnterpriseSuccess(enterprise)),
      put(setBackOfficeEnterprise(enterprise))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadBackOfficeEnterpriseFailure(message, error));
  }
}

export function* watchloadBackOfficeEnterpriseRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_ENTERPRISE_REQUEST,
    requestBackOfficeEnterprise
  );
}

export default function* samplePointsSaga() {
  yield all([fork(watchloadBackOfficeEnterpriseRequest)]);
}
