enum ActionTypes {
  LOAD_RECENT_NOTIFICATIONS_REQUEST = 'LOAD_RECENT_NOTIFICATIONS_REQUEST',
  LOAD_RECENT_NOTIFICATIONS_SUCCESS = 'LOAD_RECENT_NOTIFICATIONS_SUCCESS',
  LOAD_RECENT_NOTIFICATIONS_FAILURE = 'LOAD_RECENT_NOTIFICATIONS_FAILURE',
  ACKNOWLEDGE_RECENT_NOTIFICATION_REQUEST = 'ACKNOWLEDGE_RECENT_NOTIFICATION_REQUEST',
  ACKNOWLEDGE_RECENT_NOTIFICATION_SUCCESS = 'ACKNOWLEDGE_RECENT_NOTIFICATION_SUCCESS',
  ACKNOWLEDGE_RECENT_NOTIFICATION_FAILURE = 'ACKNOWLEDGE_RECENT_NOTIFICATION_FAILURE',
  SET_PENDING_ACKNOWLEDGEMENT = 'SET_PENDING_ACKNOWLEDGEMENT',
  REMOVE_PENDING_ACKNOWLEDGEMENT = 'REMOVE_PENDING_ACKNOWLEDGEMENT',
  SET_RECENT_NOTIFICATIONS = 'SET_RECENT_NOTIFICATIONS',
  SET_RECENT_NOTIFICATION = 'SET_RECENT_NOTIFICATION'
}

export default ActionTypes;
