import { NetworkState } from 'react-use/lib/useNetwork';

import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';

export interface MFBNetworkState extends NetworkState {
  isReady?: boolean;
}

export const initialState: MFBNetworkState = {
  isReady: false
};

function networkReducer(
  state: MFBNetworkState = initialState,
  action: ApplicationActions
): MFBNetworkState {
  switch (action.type) {
    case ActionTypes.SET_NETWORK_ONLINE:
      return {
        ...state,
        online: true
      };
    case ActionTypes.SET_NETWORK_OFFLINE:
      return {
        ...state,
        online: false
      };
    case ActionTypes.SET_NETWORK_READY:
      return {
        ...state,
        isReady: action.payload
      };
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default networkReducer;
