import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CountryId } from 'types/models/country';
import { StateAbbreviation } from 'types/models/state';

import {
  makeSelectStateByAbbreviation,
  makeSelectStatesByCountryId,
  selectStatesAsArray
} from './selectors';

export function useStatesAsArray() {
  const statesAsArray = useSelector(selectStatesAsArray, isEqual);

  return statesAsArray;
}

export function useStateByAbbreviation(abbreviation: StateAbbreviation) {
  const selectStateByAbbreviation = useMemo(
    () => makeSelectStateByAbbreviation(abbreviation),
    [abbreviation]
  );

  const state = useSelector(selectStateByAbbreviation, isEqual);

  return state;
}

export function useStatesByCountryId(countryId: CountryId | undefined) {
  const selectStatesByCountryId = useMemo(
    () => makeSelectStatesByCountryId(countryId),
    [countryId]
  );

  const statesByCountryId = useSelector(selectStatesByCountryId, isEqual);

  return statesByCountryId;
}
