import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint from 'types/models/samplePoint';

export default function isSamplePointAlwaysConfigured(
  samplePoint: SamplePoint
) {
  return [AssetTypeCode.SOIL, AssetTypeCode.SAFETY_CHECK_IN].includes(
    samplePoint.assetTypeId
  );
}
