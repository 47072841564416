import { Tooltip, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import FarmbotGridIcon from 'components/icons/FarmbotGridIcon';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';

import './styles.less';

const { Paragraph } = Typography;

interface BackOfficeDeviceMapDeviceMarkerProps {
  lat: number;
  lng: number;
  deviceId: number;
  name: string;
  serialNumber: string;
  enterpriseName: string;
  ownerName: string;
}

function BackOfficeDeviceMapDeviceMarker({
  lat,
  lng,
  deviceId,
  name,
  serialNumber,
  enterpriseName,
  ownerName
}: BackOfficeDeviceMapDeviceMarkerProps): JSX.Element {

  const dispatch = useDispatch();

  const deviceIdToOnClickAction = useCallback(
    (id: number) =>
      appendValuesToQueryString({
        selectedDevice: id
      }),
    []
  );

  return (
    <div className="BackOfficeDeviceMapDeviceMarker" onClick={() => {
      dispatch(deviceIdToOnClickAction(deviceId));
    }}>
      <div className="BackOfficeDeviceMapDeviceMarker-inner">
        <div className="BackOfficeDeviceMapDeviceMarker-pin" />
        <Tooltip
          title={
            <Typography className="BackOfficeDeviceMapDeviceMarker-tooltip">
              <Paragraph copyable>{lat.toFixed(5)}, {lng.toFixed(5)}</Paragraph>
              <Paragraph strong>{name}</Paragraph>
              <Paragraph copyable className="serial-number">{serialNumber}</Paragraph>
              <Paragraph>{enterpriseName}</Paragraph>
              <Paragraph>{ownerName}</Paragraph>
            </Typography>
          }
        >
          <div className="BackOfficeDeviceMapDeviceMarkerButton">
            <FarmbotGridIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default BackOfficeDeviceMapDeviceMarker;
