import { FormItemProps } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import React from 'react';

import DefaultFormItem from 'components/composites/DefaultFormItem';

type Props = {
  fieldName: FormItemProps['name'];
  label: string;
  style?: React.CSSProperties;
  initialValue?: boolean;
  /** sets the `id` of the innermost `<input type="checkbox"/>` element, useful for auto-focus etc. */
  id?: string;
};

/**
 * A wrapper around the antd Checkbox that ensures the proper value is used by
 * the enclosing DefaultFormItem.
 * As this checkbox is wrapped in a Form.Item component, it cannot be
 * controlled by setting the 'checked' prop, and must be set via the initial
 * values of the form and changed by user input.
 */
function FormCheckbox({
  fieldName,
  label,
  style,
  initialValue,
  id
}: Props): JSX.Element {
  return (
    <DefaultFormItem name={fieldName} valuePropName="checked" style={style} initialValue={initialValue}>
      <Checkbox id={id}>{label}</Checkbox>
    </DefaultFormItem>
  );
}

export default FormCheckbox;
