import { Typography } from 'antd';
import React from 'react';

import AnchorMailto from 'components/atoms/AnchorMailto';
import AnchorTel from 'components/atoms/AnchorTel';
import User from 'types/models/user';

function ContactSummary({ contact }: { contact: Partial<User> | undefined }) {
  return (
    <Typography.Paragraph>
      {contact?.firstName}
      {contact?.lastName ? ` ${contact?.lastName}` : ''}
      {typeof contact?.mobileNumberFormatted === 'string' && (
        <>
          <br />
          <AnchorTel phoneNumber={`${contact.mobileNumberFormatted}`} />
        </>
      )}
      {typeof contact?.landlineNumberFormatted === 'string' && (
        <>
          <br />
          <AnchorTel phoneNumber={`${contact.landlineNumberFormatted}`} />
        </>
      )}
      {typeof contact?.email === 'string' && (
        <>
          <br />
          <AnchorMailto email={contact?.email} />
        </>
      )}
    </Typography.Paragraph>
  );
}

export default ContactSummary;
