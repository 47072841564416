import parsePhoneNumber from 'libphonenumber-js';

import Enterprise from 'types/models/enterprise';
import Site from 'types/models/site';
import User from 'types/models/user';

function parseBackendPhoneNumber<T extends Enterprise | User | Site>(
  obj: T,
  phoneNumberField: keyof T,
  countryCodeField: keyof T,
  internationalPhoneNumberField: keyof T
): T {
  if (!obj || !obj[phoneNumberField]) {
    return obj;
  }

  const parsedPhoneNumber = parsePhoneNumber(
    obj[phoneNumberField] as unknown as string
  );

  return parsedPhoneNumber
    ? {
      ...obj,
      [countryCodeField]: `+${parsedPhoneNumber.countryCallingCode}`,
      [phoneNumberField]: parsedPhoneNumber.nationalNumber,
      [internationalPhoneNumberField]:
        parsedPhoneNumber.formatInternational()
    }
    : obj;
}

export default parseBackendPhoneNumber;
