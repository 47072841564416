/**
 * Functions for use as sorted in antd table columns
 */

import get from 'lodash/get';

type Item = unknown;

export const baseAlphabeticalSorter = (a: string, b: string) => {
  if (a === b) {
    return 0;
  }
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

export const alphabeticalSorter = (sortField: string) => (a: Item, b: Item) => {
  const aFieldValue = get(a, sortField);
  const bFieldValue = get(b, sortField);

  if (typeof aFieldValue !== 'string') {
    return 1;
  }
  if (typeof bFieldValue !== 'string') {
    return -1;
  }

  return baseAlphabeticalSorter(aFieldValue, bFieldValue);
};

export const baseNumericalSort = (a: number, b: number) => a - b;

export const numericalSorter = (sortField: string) => (a: Item, b: Item) => {
  const aFieldValue = get(a, sortField);
  const bFieldValue = get(b, sortField);

  const aFieldValueNumber = typeof aFieldValue === 'number' ? aFieldValue : 0;
  const bFieldValueNumber = typeof bFieldValue === 'number' ? bFieldValue : 0;

  return baseNumericalSort(aFieldValueNumber, bFieldValueNumber);
};