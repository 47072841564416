import { message as antdMessage } from 'antd';
import axios from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { getRequest } from 'utils/redux-saga-requests';

import {
  loadBackOfficeDeviceMapPoints,
  loadBackOfficeDeviceMapPointsFailure,
  loadBackOfficeDeviceMapPointsSuccess,
  setBackOfficeDeviceMapPoints
} from './actions';
import ActionTypes from './constants';

export function* requestLoadBackOfficeDeviceMapPoints(
  action: ReturnType<typeof loadBackOfficeDeviceMapPoints>
) {
  const { ne, sw, zoom } = action.payload;

  try {
    const response = yield call(getRequest, 'device-map/points', {
      params: {
        northEastLat: ne.lat,
        northEastLng: ne.lng,
        southWestLat: sw.lat,
        southWestLng: sw.lng,
        zoom: zoom
      }
    });

    yield all([
      put(setBackOfficeDeviceMapPoints({ data: response.data })),
      put(loadBackOfficeDeviceMapPointsSuccess(response))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = error.message;
    antdMessage.error(
      'Failed to load map points'
    );
    yield put(loadBackOfficeDeviceMapPointsFailure(message, error));
  }
}

export function* watchGetBackOfficeDeviceMapPointsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_BACK_OFFICE_DEVICE_MAP_POINTS_REQUEST,
    requestLoadBackOfficeDeviceMapPoints
  );
}

export default function* backOfficeDeviceMapPointsSaga() {
  yield all([
    fork(watchGetBackOfficeDeviceMapPointsRequest)
  ]);
}
