import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const DEFAULT_NUMBER_OF_RETRIES = 3;
const DEFAULT_RETRY_DELAY = 2000;

export async function retryAsync<T>(
  toRetry: () => Promise<T>,
  retries: number,
  delay: number
): Promise<T> {
  let attempts = 0;

  while (attempts < retries) {
    try {
      return await toRetry();
    } catch (error) {
      attempts++;

      if (attempts >= retries) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error('Retry attempts exhausted');
}

type RequestFunction<T> = () => Promise<AxiosResponse<T>>;

// TODO: Disable retry when error is not a timeout https://farmbot.atlassian.net/browse/FMBT-801
export async function getRequest<TResponseData>(
  path: string,
  config?: AxiosRequestConfig,
  retryOnError: boolean = true // default to retry on error
): Promise<AxiosResponse<TResponseData>> {
  const request: RequestFunction<TResponseData> = () => axios.get<TResponseData>(path, { ...(config || {}) });
  if (retryOnError) {
    return retryAsync(request, DEFAULT_NUMBER_OF_RETRIES, DEFAULT_RETRY_DELAY);
  } else {
    return request();
  }
}

export async function postRequest<TResponseData, TRequestBody>(
  path: string,
  data: TRequestBody
): Promise<AxiosResponse<TResponseData, TRequestBody>> {
  return axios.post<TResponseData, AxiosResponse<TResponseData, TRequestBody>, TRequestBody>(path, data);
}

export async function putRequest<TResponseData, TRequestBody>(
  path: string,
  data: TRequestBody
): Promise<AxiosResponse<TResponseData, TRequestBody>> {
  return axios.put<TResponseData, AxiosResponse<TResponseData, TRequestBody>, TRequestBody>(path, data);
}

export async function patchRequest<TResponseData, TRequestBody>(
  path: string,
  data: TRequestBody
): Promise<AxiosResponse<TResponseData, TRequestBody>> {
  return axios.patch<TResponseData, AxiosResponse<TResponseData, TRequestBody>, TRequestBody>(path, data);
}

export async function deleteRequest<TResponseData>(path: string): Promise<AxiosResponse<TResponseData>> {
  return axios.delete<TResponseData>(path);
}