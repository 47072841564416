import { Button, Form } from 'antd';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackOfficeDrawer from 'components/drawers/BackOfficeDrawer';
import { BackOfficeInvitationForm } from 'components/forms/BackOfficeInvitationForm';
import { addBackOfficeInvitation } from 'store/modules/backOfficeInvitations/actions';
import ActionType from 'store/modules/backOfficeInvitations/constants';
import clearErrors from 'store/modules/error/actions';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import {
  selectRouterQueryStringDrawer,
  selectRouterQueryStringInvitationEmail
} from 'store/modules/routerUtils/selectors';

import './styles.less';

const actions = [ActionType.ADD_BACK_OFFICE_INVITATION_REQUEST];

function BackOfficeUserCreateDrawer(): JSX.Element | null {
  const [form] = Form.useForm();
  const drawer = useSelector(selectRouterQueryStringDrawer);
  const invitationEmail = useSelector(selectRouterQueryStringInvitationEmail);

  const visible = drawer === 'addUser' || !!invitationEmail;

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch(clearErrors(actions));
      form.resetFields();
    }
  }, [dispatch, form, visible, drawer, invitationEmail]);

  const handleClose = useCallback(() => {
    dispatch(
      appendValuesToQueryString({
        drawer: undefined,
        invitationEmail: undefined
      })
    );
  }, [dispatch]);

  const inviteAsSuperAdmin = useCallback(
    () =>
      form.validateFields().then((values) => {
        dispatch(
          addBackOfficeInvitation(
            {
              ...values,
              isSuperAdmin: true
            },
            handleClose
          )
        );
      }),
    [form, dispatch, handleClose]
  );

  return (
    <BackOfficeDrawer
      visible={visible}
      backOfficeSection="Invite User"
      actions={actions}
      handleClose={handleClose}
      footerButton={
        <Button
          className="BackOfficeUserCreateDrawer-superAdminInvite"
          block
          size="large"
          type="default"
          onClick={inviteAsSuperAdmin}
        >
          Invite as Super Admin
        </Button>
      }
    >
      <BackOfficeInvitationForm
        form={form}
        initialValues={
          invitationEmail
            ? {
              email: invitationEmail
            }
            : undefined
        }
      />
    </BackOfficeDrawer>
  );
}

export default memo(BackOfficeUserCreateDrawer);
