export enum LegalPolicyActionType {
  FETCH_LEGAL_POLICY_DOCUMENTS_REQUEST = 'FETCH_LEGAL_POLICY_DOCUMENTS_REQUEST',
  FETCH_LEGAL_POLICY_DOCUMENTS_SUCCESS = 'FETCH_LEGAL_POLICY_DOCUMENTS_SUCCESS',
  FETCH_LEGAL_POLICY_DOCUMENTS_FAILURE = 'FETCH_LEGAL_POLICY_DOCUMENTS_FAILURE',
  SET_LEGAL_POLICY_DOCUMENTS = 'SET_LEGAL_POLICY_DOCUMENTS',

  FETCH_LEGAL_POLICY_USER_ACCEPTANCE_REQUEST = 'FETCH_LEGAL_POLICY_USER_ACCEPTANCE_REQUEST',
  FETCH_LEGAL_POLICY_USER_ACCEPTANCE_SUCCESS = 'FETCH_LEGAL_POLICY_USER_ACCEPTANCE_SUCCESS',
  FETCH_LEGAL_POLICY_USER_ACCEPTANCE_FAILURE = 'FETCH_LEGAL_POLICY_USER_ACCEPTANCE_FAILURE',
  SET_LEGAL_POLICY_USER_ACCEPTANCE = 'SET_LEGAL_POLICY_USER_ACCEPTANCE',

  ACCEPT_LEGAL_POLICY_REQUEST = 'ACCEPT_LEGAL_POLICY_REQUEST',
  ACCEPT_LEGAL_POLICY_SUCCESS = 'ACCEPT_LEGAL_POLICY_SUCCESS',
  ACCEPT_LEGAL_POLICY_FAILURE = 'ACCEPT_LEGAL_POLICY_FAILURE',

  SET_LAST_CHECKED = 'SET_LAST_CHECKED'
}