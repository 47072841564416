enum ActionTypes {
  LOAD_BACK_OFFICE_USERS_REQUEST = 'LOAD_BACK_OFFICE_USERS_REQUEST',
  LOAD_BACK_OFFICE_USERS_SUCCESS = 'LOAD_BACK_OFFICE_USERS_SUCCESS',
  LOAD_BACK_OFFICE_USERS_FAILURE = 'LOAD_BACK_OFFICE_USERS_FAILURE',

  EDIT_BACK_OFFICE_USER_REQUEST = 'EDIT_BACK_OFFICE_USER_REQUEST',
  EDIT_BACK_OFFICE_USER_SUCCESS = 'EDIT_BACK_OFFICE_USER_SUCCESS',
  EDIT_BACK_OFFICE_USER_FAILURE = 'EDIT_BACK_OFFICE_USER_FAILURE',

  SET_BACK_OFFICE_USERS = 'SET_BACK_OFFICE_USERS'
}

export default ActionTypes;
