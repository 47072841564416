import { Moment } from 'moment-timezone';

/**
 * Converts a date from one timezone to another while preserving the display.
 * For example, if the original date is "2024-05-07 12:00 AM" in Australia/Sydney timezone (+10:00) and the target
 * timezone is America/Chicago (-05:00), the converted date will be "2024-05-06 12:00 AM (-05:00)".
 * The interval value has changed, but the date-time interpretation is preserved.
 *
 * This function is useful for such cases as:
 * - shifting dates when comparing samples across timezones
 * - pre-processing dates from UI elements like date pickers for further data operations such as fetching and caching
 */
export const shiftDateToTimezoneKeepDisplay = (date: Moment, targetTimezone: string): Moment => {
  if (date.tz() === targetTimezone) return date;

  const preserveDisplay = true;
  return date.clone().tz(targetTimezone, preserveDisplay);
};