// Functions in this file are used to handle the effect of dailyResetTime on rainfall sample dates.
import moment from 'moment-timezone';

import { BucketedComparativeSample } from 'components/features/samplePoint/SamplePointSampleChart/types';
import { LEGACY_DAILY_RAINFALL_RESET_HOUR } from 'constants/samplePoint';
import { sampleIntervalToTimeUnit } from 'constants/time';
import { SampleInterval } from 'store/modules/samples/types';

/**
 * Converts the sampleDate from the backend response into date(s) consumed by store/samples module.
 * @returns {Object} prevDate, date - the start and end of the sample period.
 *
 * When dailyResetTime === 12AM, sampleDate 06-13 0:00 means the sample is captured from 06-13 0:00 (to however long
 * the interval is).
 * However, when it is !== 12AM, sampleDate 06-13 9:00 means the sample is captured from 06-12 9:00, so we must
 * subtract one day.
 * Note: this dailyResetTime has no effect when interval = hourly.
 */
export const getRainfallSamplePeriodDates = (
  sampleDate: number,
  sampleInterval: SampleInterval,
  dailyResetTime: number,
  siteTimezoneCode: string
) => {
  const unitOfTime = sampleIntervalToTimeUnit[sampleInterval];

  const prevDate = sampleDate;
  const date = moment(prevDate).tz(siteTimezoneCode).add(1, unitOfTime).valueOf() - 1;

  if (dailyResetTime === LEGACY_DAILY_RAINFALL_RESET_HOUR || sampleInterval === SampleInterval.HOURLY) {
    // dailyResetTime has no effect
    return {
      prevDate,
      date
    };
  } else {
    // dailyResetTime has effect
    return {
      prevDate: moment(prevDate).tz(siteTimezoneCode).subtract(1, 'day').valueOf(),
      date: moment(date).tz(siteTimezoneCode).subtract(1, 'day').valueOf()
    };
  }
};

/**
 * Converts the date(s) in the sample Redux store into date(s) used by the rainfall chart
 * @returns {Object} timeAxisValue, startedAt, endedAt.
 * `timeAxisValue` is used to locate the sample on the time axis.
 * `startedAt` and `endedAt` are used by the tooltip, showing the actual sample period.
 */
export const getRainfallSampleChartDates = (
  sample: { prevDate: number, date: number },
  interval: SampleInterval,
  dailyResetTime: number,
  timezoneCode: string
) => {
  const timeAxisValue = dailyResetTime === LEGACY_DAILY_RAINFALL_RESET_HOUR || interval === SampleInterval.HOURLY
    ? sample.prevDate
    : moment(sample.prevDate).tz(timezoneCode).add(1, 'day').valueOf();

  return {
    timeAxisValue,
    startedAt: sample.prevDate,
    endedAt: sample.date
  };
};

/**
 * Converts original sample date(s) into date(s) used by the rainfall comparison chart
 * @returns {Object} timeAxisValue, startedAt, endedAt.
 * `timeAxisValue` is used to locate the sample on the time axis.
 * `startedAt` and `endedAt` are used by the tooltip, showing the actual sample period.
 */
export const getRainfallSampleComparisonChartDates = (
  sample: Pick<BucketedComparativeSample, 'alignedDate' | 'prevDate' | 'date'>,
  interval: SampleInterval,
  dailyResetTime: number,
  timezoneCode: string
) => {
  /*
   * Context: non-legacy reset hour, daily sample
   *
   * When aligning a daily rainfall sample of Feb 28 - Mar 1 from a non-leap year to a leap year, by default the new
   * sample period would become Feb 28 - Feb 29. We want to fix the Feb 29 to Mar 1.
   *
   * e.g. in the "Last 30 days to same 30 days last year" comparison,
   * - original sample period: `2023 Feb 28 9AM - Mar 1 8:59AM`
   * - aligned incorrectly: `2024 Feb 28 9AM - Feb 29 8:59AM`
   * - aligned correctly: `2024 Feb 28 9AM - Mar 1 8:59AM`
   */
  const isDrawingChartInLeapYear = moment(sample.alignedDate).tz(timezoneCode).isLeapYear();
  const isSampleEndedOnMarch1 = moment(sample.date).tz(timezoneCode).date() === 1;
  const isSampleStartedOnFebruary28 = moment(sample.prevDate).tz(timezoneCode).date() === 28;
  const hasWideGap =
    isDrawingChartInLeapYear
    && interval === SampleInterval.DAILY
    && isSampleEndedOnMarch1
    && isSampleStartedOnFebruary28;

  const timeAxisValue = dailyResetTime === LEGACY_DAILY_RAINFALL_RESET_HOUR || interval === SampleInterval.HOURLY
    ? sample.alignedDate
    : moment(sample.alignedDate).tz(timezoneCode).add(hasWideGap ? 2 : 1, 'day').valueOf();

  return {
    timeAxisValue,
    startedAt: sample.prevDate,
    endedAt: sample.date
  };
};

/**
 * Derives the comparison buckets via the chart labels, taking into account the effect of dailyResetTime
 * @returns {Array} comparisonBuckets
 */
export const getRainfallSampleComparisonBuckets = (
  chartLabels: number[],
  interval: SampleInterval,
  dailyResetTime: number,
  timezoneCode: string
) => {
  if (dailyResetTime === LEGACY_DAILY_RAINFALL_RESET_HOUR || interval === SampleInterval.HOURLY) {
    // dailyResetTime has no effect
    return chartLabels;
  } else {
    // dailyResetTime has effect
    return chartLabels.map((label) => moment(label).tz(timezoneCode).subtract(1, 'day').valueOf());
  }
};
