import axios from 'axios';
import queryString from 'query-string';

import Notification, { NotificationType } from 'types/models/notification';
import { APIResponseParsed } from 'types/response';
import { getRequest, patchRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

/**
 * notificationType is hard-coded to UI type only
 * notification sorting is also set to descending by default in back-end
 * fromDate is set to 7 days ago by default
 */
async function fetchNotifications(
  enterpriseId: number
): Promise<APIResponseParsed<Notification[]>> {
  const path = queryString.stringifyUrl({
    url: 'notifications/v2',
    query: {
      enterpriseId: enterpriseId.toString(),
      notificationType: NotificationType.UI.toString()
    }
  });
  try {
    const response = await getRequest<Notification[]>(path);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function patchNotification(
  notificationId: number,
  status: number
): Promise<APIResponseParsed<Notification>> {
  const path = `notifications/v2/${notificationId}`;
  const data = { status };
  try {
    const response = await patchRequest<Notification, { status: number }>(path, data);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

// Grouping these functions for a namespace export, rather than named exports,
// to avoid naming collisions with action creators
export default {
  fetchNotifications,
  patchNotification
};